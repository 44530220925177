import React from "react";
import Images from "../../utils/styles/images";

// start test pass
export function modifyCell(
  cell,
  pageSelect,
  pageSize,
  handleActionClick,
  consentType
) {
  const { props } = cell;
  const columnName = props.cell?.column?.Header;
  const cellValue = props?.cell?.value;
  const rowNumber = props?.row?.index + 1;
  const rowData = props?.row?.original;

  if (columnName === "NO.") {
    return pageSelect !== 0 ? rowNumber + pageSelect * 10 : rowNumber;
  }

  if (columnName === "USER NAME") {
    return (
      <div>
        <button
          onClick={() =>
            handleActionClick(cellValue, rowData, columnName, pageSelect)
          }
          className="feed-list-article-button"
        >
          {cellValue === null ? "N/A" : cellValue}
        </button>
      </div>
    );
  }

  if (columnName === "CONSENT DATE" && cellValue) {
    return cellValue.substring(0, 19).replace("T", " ");
  }

  if (columnName === "CONSENT TYPE") {
    return consentType;
  }

  if (columnName === "ID") {
    return cellValue ?? cell;
  }

  if (columnName === "ACTION") {
    return (
      <div className="funds-action-buttons">
        <button
          className="consent-action-view"
          onClick={() =>
            handleActionClick(cellValue, rowData, columnName, pageSelect)
          }
        >
          <img src={Images.fundListIcon.view} alt="eye" />
          View
        </button>
      </div>
    );
  }

  return cell;
}
// end test

export function modifyCellHeader(
  cell,
  handleActionHeaderClick
) {
  if (cell === "ACTIONX") {
    return (
      <button
        className="clients-table-header-submission"
        onClick={() => handleActionHeaderClick(cell)}
      >
        SUBMISSION DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (
    cell === "CONSENT DATE" ||
    cell === "CONSENT VERSION" ||
    cell === "CONSENT NO"
  ) {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        {cell}
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
