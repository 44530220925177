import React, { Fragment } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Breadcrumbs } from "../../components/BreadCrumbs";
import Header from "../../components/Header";
import { AlertModal } from "../../components/Modal";
import SideBar from "../../components/SideBar";
import LoginScreen from "../../containers/Authentication";
import {
  resetFailedRequestProps, signOutAction
} from "../../containers/Authentication/redux/actions";
import CallbackScreen from "../../containers/Callback";
import DefaultScreen from "../../containers/Default";
import ForgotPasswordScreen from "../../containers/ForgotPassword";
import LetsSetupYourAccountScreen from "../../containers/LetsSetupYourAccount";
import { stopLoading } from "../../globalRedux/actions";
import RoutesList from "./Routes";
import "./style.scss";

export class Routes extends React.Component {
  constructor(props) {
    super(props);
    if (window.performance && performance.navigation.type === 1) {
      this.props.stopLoading();
      this.props.resetFailedRequestProps();
    }
  }
  state = {
    auth: false,
    Expire: true
  };

  static getDerivedStateFromProps(props) {
    if (props.auth.accessToken && !props.globalProps.loadingReducer.isLoading) {
      return {
        auth: true
      };
    }

    return {};
  }

  static defaultProps = {
    auth: "",
    location: {}
  };

  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    location: PropTypes.object
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderRouts = () => {
    return RoutesList.map(({ exact, path, component }, i) => {
      return (
        <Fragment key={`Route-${i + 1}`}>
          <Route
            exact={exact}
            path={path}
            component={component}
            key={`Route-${i + 1}`}
          />
          {
          this.state.auth && this.props.location.pathname === "/login" 
          && (
            <Redirect to="/" />
          )
          }
        </Fragment>
      );
    });
  };

  renderLogin = () => {
    // in order to avoid prop type error
    const LoginFormComponent = () => <LoginScreen />;
    const ForgotPasswordComponent = () => <ForgotPasswordScreen />;
    const LetsSetupYourAccountComponent = () => <LetsSetupYourAccountScreen />;
    return (
      <Fragment>
        <Route
          path="/login"
          name="LoginScreen"
          component={LoginFormComponent}
          exact={false}
        />
     
         <Route
          path="/forgotpassword"
          name="ForgotPasswordScreen"
          component={ForgotPasswordComponent}
          exact={false}
        />
        <Route
          path="/resetpassword"
          name="LetsSetupYourAccountScreen"
          component={LetsSetupYourAccountComponent}
          exact={false}
        />
        <Route
          path="/"
          name="DefaultScreen"
          component={DefaultScreen}
          exact={false}
        />
        <Route
          path="/authService/callback"
          name="CallbackScreen"
          component={CallbackScreen}
          exact={false}
        />
      </Fragment>
    );
  };

  handleSignOut = () => {
    this.props.signOutAction();
    this.setState({auth: false,Expire:true});
    this.props.history.push("/login");
  };
  
   CHKxLoginEXP = (ptOKTAExpireUTC) => {
    if (ptOKTAExpireUTC === null) {
      console.log('CHKxLoginEXP > ptOKTAExpireUTC === null');
    } else {
      let dNowTH = new Date();
      let dExpTH = new Date(ptOKTAExpireUTC);
     // ------- set time zone thai-----
     dNowTH.setHours(dNowTH.getHours() + 0);
     dExpTH.setHours(dExpTH.getHours() + 0);

      if (dNowTH >= dExpTH) 
      {
        return(     
          <Fragment>
            <AlertModal
              show={this.state.Expire}
              onHide={() =>this.setState({Expire:false})}
              onHandleOK={() => {
                this.setState({Expire:false});
                this.handleSignOut();
                }}
              type={"Disapprove"}
              status={"1"}
              title={`Session is expired at ${dExpTH}`}
            />
          </Fragment>
        );
      }
      
   }
   };
  render() {
    const { selectedObject,OKTAExpireUTC } = this.props;

    return (
      <Fragment>
        {this.state.auth ? (
          <>
          {this.CHKxLoginEXP(OKTAExpireUTC)}
          <div className={`wrapComponent`}>
            <SideBar />
            <div className="tableWrap">
              <Header signOut={this.handleSignOut} />
              <div className="tableContainer">
                <Breadcrumbs selectedObject={selectedObject} />
                {this.renderRouts()}
              </div>
            </div>
          </div>
          </>
        ) : (
          this.renderLogin()
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    clients: state.clientReducer,
    selectedObject: state.breadCrumbsReducer,
    globalProps: state.globalReducer
  };
};
const mapDispatchToProps = {
  signOutAction,
  stopLoading,
  resetFailedRequestProps
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withRouter,
  withConnect
)(Routes);
