import { createAction } from "redux-act";

export const requestClientProfile = createAction("REQUEST_CLIENT_PROFILE");
export const receiveClientProfile = createAction("RECEIVE_CLIENT_PROFILE");
export const failClientProfile = createAction("FAIL_CLIENT_PROFILE");
export const requestClientDocuments = createAction("REQUEST_CLIENT_DOCUMENTS");
export const receiveClientDocuments = createAction("RECEIVE_CLIENT_DOCUMENTS");
export const failClientDocuments = createAction("FAIL_CLIENT_DOCUMENTS");
export const setCurrentCustomer = createAction("SET_CURRENT_CUSTOMER");
export const requestUserAccountLockedStatus = createAction(
  "REQUEST_USER_ACCOUNT_LOCKED_STATUS"
);
export const receiveUserAccountLockedStatus = createAction(
  "RECEIVE_USER_ACCOUNT_LOCKED_STATUS"
);
export const failUserAccountLockedStatus = createAction(
  "FAIL_USER_ACCOUNT_LOCKED_STATUS"
);

export const requestUserAccountUnlock = createAction(
  "REQUEST_USER_ACCOUNT_UNLOCK"
);
export const receiveUserAccountUnlock = createAction(
  "RECEIVE_USER_ACCOUNT_UNLOCK"
);
export const failUserAccountUnlock = createAction("FAIL_USER_ACCOUNT_UNLOCK");

export const requestDocumentApprove = createAction("REQUEST_DOCUMENT_APPROVAL");
export const receiveDocumentApprove = createAction("RECEIVE_DOCUMENT_APPROVAL");
export const failDocumentApprove = createAction("FAIL_DOCUMENT_APPROVAL");

export const requestDocumentReject = createAction("REQUEST_DOCUMENT_REJECTION");
export const receiveDocumentReject = createAction("RECEIVE_DOCUMENT_REJECTION");
export const failDocumentReject = createAction("FAIL_DOCUMENT_REJECTION");

export const requestDocumentUndo = createAction("REQUEST_DOCUMENT_UNDO");
export const receiveDocumentUndo = createAction("RECEIVE_DOCUMENT_UNDO");
export const failDocumentUndo = createAction("FAIL_DOCUMENT_UNDO");

export const requestClientApprove = createAction("REQUEST_CLIENT_APPROVAL");
export const receiveClientApprove = createAction("RECEIVE_CLIENT_APPROVAL");
export const failClientApprove = createAction("FAIL_CLIENT_APPROVAL");

export const requestClientReject = createAction("REQUEST_CLIENT_REJECTION");
export const receiveClientReject = createAction("RECEIVE_CLIENT_REJECTION");
export const failClientReject = createAction("FAIL_CLIENT_REJECTION");

export const requestCancelStatus = createAction("REQUEST_CANCEL_STATUS");
export const receiveCancelStatus = createAction("RECEIVE_CANCEL_STATUS");
export const failCancelStatus = createAction("FAIL_CANCEL_STATUS");

export const requestClientFunds = createAction("REQUEST_CLIENT_FUNDS");
export const receiveClientFunds = createAction("RECEIVE_CLIENT_FUNDS");
export const failClientFunds = createAction("FAIL_CLIENT_FUNDS");

export const clearErrorMessage = createAction("CLEAR_ERROR_MESSAGE");
export const receiveCustomerChannel = createAction("CUSTOMER_CHANNEL");
// -----------------------UserAccount----------------------
export const REQUsrActSrchCli = createAction("REQUEST_USRACT_SRCH_CLI");
export const RESUsrActSrchCli = createAction("RECEIVE_USRACT_SRCH_CLI");
export const FailUsrActSrchCli = createAction("FAILED_USRACT_SRCH_CLI");