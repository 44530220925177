
// -----------------------SubMenu------------------------------
  export const col_SubMenu = [
    {
      Header: "NO.",
      textAlign:"center",
      paddingLeft:0,
      width: 30,
      disableSortBy: true,
      display:""
    },
    {
      Header: "ARTICLE TITLE",
      accessor: "articleShow",
      textAlign:"left",
      paddingLeft:0,
      width: 170,
      display:""
    },
    {
      Header: "MODIFIED BY",
      accessor: "modifiedBy",
      textAlign:"left",
      paddingLeft:0,
      width: 135,
      display:""
    },
    {
      Header: "CREATED DATE",
      accessor: "createdAt",
      textAlign:"left",
      paddingLeft:0,
      width: 110,
      display:""
    },
    {
      Header: "MODIFIED DATE",
      accessor: "modifiedAt",
      textAlign:"left",
      paddingLeft:0,
      width: 120,
      display:""
    },
    {
      Header: "HIGHLIGHT",
      accessor: "isHighlight",
      textAlign:"left",
      paddingLeft:5,
      width: 90,
      display:""
    },
    {
      Header: "STATUS",
      accessor: "statusCode",
      textAlign:"left",
      paddingLeft:5,
      width: 80,
      display:""
    },
    {
      Header: "VIEW",
      display:"",
      disableSortBy: true,
      textAlign:"left",
      width: 80,
      paddingLeft:10
    }
  ];

// -----------------------SubMenu Search only------------------------------
  export const col_SubMenuSrch = [
    {
      Header: "NO.",
      textAlign:"center",
      paddingLeft:0,
      width: 30,
      disableSortBy: true,
      display:""
    },
    {
      Header: "ARTICLE TITLE",
      accessor: "articleShow",
      textAlign:"left",
      paddingLeft:10,
      width: 170,
      display:""
    },
    {
      Header: "ARTICLE ID",
      accessor: "id",
      textAlign:"center",
      paddingLeft:0,
      width: 65,
      disableSortBy: true,
      display:""
    },
    {
      Header: "MODIFIED BY",
      accessor: "modifiedBy",
      textAlign:"left",
      paddingLeft:10,
      width: 135,
      display:""
    },
    {
      Header: "CREATED DATE",
      accessor: "createdAt",
      textAlign:"left",
      paddingLeft:0,
      width: 110,
      display:""
    },
    {
      Header: "MODIFIED DATE",
      accessor: "modifiedAt",
      textAlign:"left",
      paddingLeft:0,
      width: 120,
      display:""
    },
    {
      Header: "HIGHLIGHT",
      accessor: "isHighlight",
      textAlign:"left",
      paddingLeft:5,
      width: 90,
      display:""
    },
    {
      Header: "STATUS",
      accessor: "statusCode",
      textAlign:"left",
      paddingLeft:5,
      width: 80,
      display:""
    } ,{
      Header: "VIEW",
      display:"",
      disableSortBy: true,
      textAlign:"left",
      width: 80,
      paddingLeft:10
    }
  ];