import { createReducer } from "redux-act";

import {
  // --------------ConsentVS Log-----------------
  REQConsentVSList,
  RESConsentVSList,
  FailConsentVSList,
  CleanUpConsentVSList,
  MEMConsentVSList
} from "./actions";

const initialState = {
  REQStrConsentVSList: false,
  ConsentVSList: [],
  errorMessage: false,
  totalConsentVS: 0
};

const consentListVSReducer = createReducer(
  {
  
    [REQConsentVSList]: (state) => {
      return {
        ...state,
        REQStrConsentVSList: true
      };
    },
   
    [RESConsentVSList]: (state, payload) => {
      return {
        ...state,
        REQStrConsentVSList: false,
        ConsentVSList: payload.ConsentVersion,
        totalConsentVS: payload.TotalConsentVersion
      };
    },
     
    [FailConsentVSList]: (state) => {
      return {
        ...state,
        REQStrConsentVSList: false,
        ConsentVSList: [],
        totalConsentVS: 0
      };
    } , 
    [CleanUpConsentVSList]: () => {
      return initialState;
    },
 
    // -------Edit to ConsentVSList----------------
    [MEMConsentVSList]: (state, payload) => {
         return {
           ...state,
           RowDataAt:payload.RowDataAt,
           PageCurrent:payload.PageCurrent,
           UISearch:payload.UISearch
         };
       }
 
  },

  initialState
);

export default consentListVSReducer;
