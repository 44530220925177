import { createReducer } from "redux-act";

import {
  REQRegisDeviceList,
  RESRegisDeviceList,
  FailRegisDeviceList,
  CleanUpRegisDeviceList
} from "./actions";

const initialState = {
  REQStrRegisDeviceList: false,
  RegisDeviceList: [],
  errorMessage: false,
  totalRegisDevice: 0
};

const regisDeviceListReducer = createReducer(
  {
  
    [REQRegisDeviceList]: (state) => {
     
      return {
        ...state,
        REQStrRegisDeviceList: true
      };
    },
   
    [RESRegisDeviceList]: (state, payload) => {
      return {
        ...state,
        REQStrRegisDeviceList: false,
        RegisDeviceList: payload,
        totalRegisDevice: payload.total
      };
    },
     
    [FailRegisDeviceList]: (state) => {
      return {
        ...state,
        REQStrRegisDeviceList: false,
        RegisDeviceList: [],
        totalRegisDevice: 0
      };
    } , 
    [CleanUpRegisDeviceList]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default regisDeviceListReducer;
