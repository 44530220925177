import { createAction } from "redux-act";

// ---------------FundRecommend Risk Edit------
export const MEMFundRecRiskDetailEdt = createAction("MEM_FUNDRECRISKDETAIL_EDIT");
// ----------------Get Risk Detail---------
export const REQByRiskD = createAction("REQUEST_BYRISK_Detail");
export const RESByRiskD = createAction("RECEIVE_BYRISK_Detail");
export const FailByRiskD = createAction("FAILED_BYRISK_Detail");
// ----------------Get FuncodeList---------
// get API fundCode assign to droplist
export const REQFundCodeList = createAction("REQUEST_FUNCODE_Detail");
export const RESFundCodeList = createAction("RECEIVE_FUNCODE_Detail");
export const FailFundCodeList = createAction("FAILED_FUNCODE_Detail");
// ----------------Edit Detail----------------
export const REQByRiskEdt = createAction("REQUEST_BYRISK_EDIT");
export const RESByRiskEdt = createAction("RECEIVE_BYRISK_EDIT");
export const FailByRiskEdt = createAction("FAILED_BYRISK_EDIT");
export const CleanUpByRiskEdt = createAction("CLEANUP_BYRISK_EDIT");
