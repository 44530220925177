import { createAction } from "redux-act";

export const REQFundRecList = createAction("REQUEST_FUNDREC_LIST");
export const RESFundRecList = createAction("RECEIVE_FUNDREC_LIST");

export const FailFundRecList = createAction("FAILED_FUNDREC_LIST");
export const CleanUpFundRecList = createAction("CLEANUP_FUNDREC_LIST");

export const REQFundRecUpDoc = createAction("REQUEST_FUNDREC_UPLOAD_DOCUMENTS");
export const RESFundRecUpDoc = createAction("RECEIVE_FUNDREC_UPLOAD_DOCUMENTS");
export const FailFundRecUpDoc = createAction("FAILED_FUNDREC_UPLOAD_DOCUMENTS");
export const ResetFundRecUpDoc = createAction("RESET_FUNDREC_UPLOAD_DOCUMENTS");
