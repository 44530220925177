import { createReducer } from "redux-act";

import {
  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList,

  REQTagDetailCrt,
  RESTagDetailCrt,
  FailTagDetailCrt,

  REQTagDetailEdt,
  RESTagDetailEdt,
  FailTagDetailEdt,

  MEMTagDetailEdt,
  CleanUpTagDetail
} from "./actions";

const initialState = {
  REQStrFundCodeList: false,
  FundCodeList: [],
  errorMessage: false,
  totalFunds: 0,

  REQStrTagCrt: false,
  REQStrTagEdt: false
};

const tagDetailReducer = createReducer(
  {
    // --------Get Fundcode-----
    [REQFundCodeList]: (state) => {
     
      return {
        ...state,
        REQStrFundCodeList: true
      };
    } ,
    [RESFundCodeList]: (state, payload) => {
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: payload.data,
        totalFundCode: payload.total
      };
    },  
    [FailFundCodeList]: (state) => {
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: [],
        totalFundCode: 0
      };
    },
    [CleanUpTagDetail]: () => {
      return initialState;
    },
 
    // --------Creat Send data to Tagdetail-----
    [REQTagDetailCrt]: (state) => {
      
 
      return {
        ...state,
        REQStrTagCrt: true
      };
    },
    [RESTagDetailCrt]: (state) => {
      
       return {
         ...state,
         REQStrTagCrt: false
       };
     },
     [FailTagDetailCrt]: (state) => {
      return {
        ...state,
        REQStrTagCrt: false,
        FundCodeList: [],
        totalFundCode: 0
      };
    },
    // -------Edit to Tagdetail----------------
    [MEMTagDetailEdt]: (state, payload) => {
      return {
        ...state,
        mode: payload.mode,
        RowDataAt:payload.RowDataAt
    
      };
    },
    [REQTagDetailEdt]: (state) => {
     
      return {
        ...state,
        REQStrTagEdt: true
      };
    },
    [RESTagDetailEdt]: (state) => {
     
      return {
        ...state,
        REQStrTagEdt: false
      };
    },  
    [FailTagDetailEdt]: (state) => {
      return {
        ...state,
        REQStrTagEdt: false,
        FundCodeList: [],
        totalFundCode: 0
      };
    }
  },
  initialState
);

export default tagDetailReducer;
