/*
Convert 1 to first, 2 to second, 4 to fourth for example.
*/

export function numberToOrdinalString(num) {
  let ordinals = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "telfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
    "twentieth"
  ];

  if (num > 20) {
    // No ordinals past the number 20, just use the numbers
    return num;
  }

  return ordinals[num][0].toUpperCase() + ordinals[num].slice(1);
}
