export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const REQUEST_COUNTRIES = "REQUEST_COUNTRIES";
export const RECEIVE_COUNTRIES = "RECEIVE_COUNTRIES";
export const FAILED_COUNTRIES = "FAILED_COUNTRIES";
export const REQUEST_GENDERS = "REQUEST_GENDERS";
export const RECEIVE_GENDERS = "RECEIVE_GENDERS";
export const FAILED_GENDERS = "FAILED_GENDERS";
export const REQUEST_DOCTYPES = "REQUEST_DOCTYPES";
export const RECEIVE_DOCTYPES = "RECEIVE_DOCTYPES";
export const FAILED_DOCTYPES = "FAILED_DOCTYPES";
export const REQUEST_MARITAL = "REQUEST_MARITAL";
export const RECEIVE_MARITAL = "RECEIVE_MARITAL";
export const FAILED_MARITAL = "FAILED_MARITAL";
export const REQUEST_PROVINCES = "REQUEST_PROVINCES";
export const RECEIVE_PROVINCES = "RECEIVE_PROVINCES";
export const FAILED_PROVINCES = "FAILED_PROVINCES";
export const REQUEST_POSTCODE = "REQUEST_POSTCODE";
export const RECEIVE_POSTCODE = "RECEIVE_POSTCODE";
export const FAILED_POSTCODE = "FAILED_POSTCODE";
export const REQUEST_OCCUPATIONS = "REQUEST_OCCUPATIONS";
export const RECEIVE_OCCUPATIONS = "RECEIVE_OCCUPATIONS";
export const FAILED_OCCUPATIONS = "FAILED_OCCUPATIONS";
export const REQUEST_MONTHLY_INCOMES = "REQUEST_MONTHLY_INCOMES";
export const RECEIVE_MONTHLY_INCOMES = "RECEIVE_MONTHLY_INCOMES";
export const FAILED_MONTHLY_INCOMES = "FAILED_MONTHLY_INCOMES";
export const REQUEST_INCOME_SOURCES = "REQUEST_INCOME_SOURCES";
export const RECEIVE_INCOME_SOURCES = "RECEIVE_INCOME_SOURCES";
export const FAILED_INCOME_SOURCES = "FAILED_INCOME_SOURCES";
export const REQUEST_BANKS = "REQUEST_BANKS";
export const RECEIVE_BANKS = "RECEIVE_BANKS";
export const FAILED_BANKS = "RECEIVE_BANKS";
export const REQUEST_NATIONALITIES = "REQUEST_NATIONALITIES";
export const RECEIVE_NATIONALITIES = "RECEIVE_NATIONALITIES";
export const RECEIVE_DATA_FOR_CONTACT_FORM = "RECEIVE_DATA_FOR_CONTACT_FORM";
export const REQUEST_DATA_FOR_CONTACT_FORM = "REQUEST_DATA_FOR_CONTACT_FORM";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const REQUEST_DROPDOWN_DATA = "REQUEST_DROPDOWN_DATA";
export const SAVE_PAGE_NUMBER = "SAVE_PAGE_NUMBER";
export const FUND_CATEGORIES_RECIEVED = 'FUND_CATEGORIES_RECIEVED';

export const REQUEST_DISTRICTS = 'REQUEST_DISTRICTS';
export const RECEIVE_DISTRICTS = 'RECEIVE_DISTRICTS';

export const REQUEST_BUSINESSTYPES = 'REQUEST_BUSINESSTYPES';
export const RECEIVE_BUSINESSTYPES = 'RECEIVE_BUSINESSTYPES';


