import React, { Fragment, useEffect } from "react";
import { HeaderLabel, TextInput } from "../../components/input_hook_form";
import { useForm } from "react-hook-form";
import { withRouter, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import {
  requestForgotPasswordAction,
  clearErrorMessageAction,
  resetStateAction
} from "./redux/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { ErrorModal } from "../../components/Modal";
import * as Button from "../../components/Buttons";
import "./style.scss";

function ForgotPassword(props) {
  const {
    requestForgotPasswordAction,
    clearErrorMessageAction,
    errorMessage,
    requestingForgotPassword,
    resetStateAction,
    success
  } = props;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    // on component did mount
    // we do this here so that reducer can always be refreshed to default when visiting this page
    resetStateAction();
  }, []);

  const onSubmit = data => {
    requestForgotPasswordAction(data);
  };

  const renderError = errorMessage => {
    return <p className="error-message">{errorMessage}</p>;
  };

  return (
    <Fragment>
      <ErrorModal
        show={errorMessage && errorMessage.length > 0}
        onHide={() => clearErrorMessageAction()}
        errorMessageText={errorMessage ? errorMessage : ""}
      />
      <div className="wrapper">
        <div className="imageBackgroundWrap" />
        <div className="formWrap">
          <div className="formContainer">
            {success ? (
              <div className="success-container">
                <HeaderLabel text="SUCCESS!" />
                <span className="Enter-your-email-add">
                  An e-mail with a link to reset your password has been sent to
                  your inbox for the e-mail you just entered. Please click on
                  that link to continue the process of resetting your password.
                </span>

                <Link to="/login" className="Back-to-login">
                  Back to login
                </Link>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formForgotPassword">
                  <HeaderLabel text="FORGOT PASSWORD?" />
                  <span className="Enter-your-email-add">
                    Enter your email address below and we’ll send you a secure
                    code to reset your password.
                  </span>
                  <TextInput
                    label=""
                    placeholder="Email"
                    name="emailInput"
                    register={register({ required: true })}
                    inputStyle={
                      errors.emailInput || errorMessage
                        ? "forgot-password-email-input-error"
                        : "forgot-password-email-input"
                    }
                    disabled={requestingForgotPassword}
                  />
                  {errors.emailInput && renderError("Your email is required")}
                  <Button.Login
                    contentButton={
                      <div className="submit-button-text">
                        {requestingForgotPassword ? (
                          <CircularProgress size={24} />
                        ) : (
                          "SUBMIT"
                        )}
                      </div>
                    }
                    buttonType="primary"
                    width="145px"
                    height="46px"
                    className="submit-button"
                    type="submit"
                  />
                  <Link to="/login" className="Back-to-login">
                    Back to login
                  </Link>
                </div>
              </form>
            )}
          </div>
          <div className="copyright">
            &#169; 2024 All Rights Reserved.
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.forgotPassword,
    requestingResetPassword: state.forgotPassword.requestingResetPassword
  }),
  { requestForgotPasswordAction, clearErrorMessageAction, resetStateAction }
);

export default compose(
  withConnect,
  withRouter
)(ForgotPassword);
