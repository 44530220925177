import { createReducer } from "redux-act";

import {
  MEMConsentVSDetail,
} from "./actions";

const initialState = {

};

const consentVSDetailReducer = createReducer(
  {
    // -------Edit to RegisDevicedetail----------------
    [MEMConsentVSDetail]: (state, payload) => {
   
      return {
        ...state,
        RowDataAt:payload.RowDataAt,
        PageCurrent:payload.PageCurrent,
        UISearch:payload.UISearch
      };
    }
  
  },
  initialState
);

export default consentVSDetailReducer;
