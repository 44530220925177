import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import moment from "moment";
import { requestATSList, receiveATSList, failATSList } from "./actions";

function* fetchATSListWorker({ payload }) {
  try {
    const { startDate, endDate, sortDir, sortingNo, pageNo, searchQuery } =
      payload;
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    const body = {
      PageType: "Y",
      PageRow: 10,
      PageNo: pageNo,
      SortingNo: sortingNo,
      SortDirection: sortDir,
      StartDate: formattedStartDate,
      EndDate: formattedEndDate
    };

    if (searchQuery) {
      body["UnitholderId"] = searchQuery;
    }
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/ATS`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    if (response && response.data && response.data.data) {
      yield put(receiveATSList(response.data));
    } else {
      yield put(failATSList());
    }
  } catch (error) {
    yield put(failATSList());
  }
}

export function* atsListSaga() {
  yield all([takeLatest(requestATSList, fetchATSListWorker)]);
}
