import { createReducer } from "redux-act";

import {
  requestRSPList,
  receiveRSPList,
  failRSPList,
  cleanupRSPList,
  sortRSPList
} from "./actions";

const initialState = {
  requestingRSPList: false,
  rspList: [],
  errorMessage: false,
  totalRSP: 0
};

const rspReducer = createReducer(
  {
    [requestRSPList]: (state) => {
      return {
        ...state,
        requestingRSPList: true
      };
    },
    [receiveRSPList]: (state, payload) => {
      let totalRecords =
        payload.data && payload.data[0] && payload.data[0].TotalRecord
          ? payload.data[0].TotalRecord
          : 0;
      return {
        ...state,
        requestingRSPList: false,
        rspList: payload.data,
        totalRSP: totalRecords
      };
    },
    [sortRSPList]: (state, payload) => {
      return {
        ...state,
        rspList: payload.data
      };
    },
    [failRSPList]: (state) => {
      return {
        ...state,
        requestingRSPList: false,
        rspList: [],
        totalRSP: 0
      };
    },
    [cleanupRSPList]: () => {
      return initialState;
    }
  },
  initialState
);

export default rspReducer;
