import React from "react";
import Images from "../../../utils/styles/images";
import "./style.scss";

function Pagination(props) {
  const { handlePageChange, total, pageSize, defaultPage, pageIndex } = props;

  const totalNumberOfPages =
    total > 0 && total / pageSize > 0 ? Math.ceil(total / pageSize) : 0;

  const handleFirstClick = () => {
    handlePageChange(defaultPage);
  };

  const handleLastClick = () => {
    let lastPage = Math.ceil(total / pageSize);
    handlePageChange(lastPage);
  };

  const handleNextPageClick = () => {
    handlePageChange(pageIndex + 1);
  };

  const handlePreviousPageClick = () => {
    handlePageChange(pageIndex - 1);
  };

  const handlePageClick = buttonPageIndex => {
    handlePageChange(buttonPageIndex);
  };

  const renderPaginationButtons = () => {
    let arrayOfButtons = [];

    // limit the number of shown buttons to 4
    for (let index = 0; index < 5; index += 1) {
      let displayValueForPageIndex = pageIndex + index;
      // so long as there are still pages remaining, we can add buttons for pagination
      if (displayValueForPageIndex <= totalNumberOfPages) {
        arrayOfButtons.push(
          <button
            className={
              displayValueForPageIndex === pageIndex
                ? "pagination-button-selected"
                : "pagination-button"
            }
            onClick={() => handlePageClick(displayValueForPageIndex)}
            key={"pagination-button" + displayValueForPageIndex}
          >
            {displayValueForPageIndex}
          </button>
        );
      }
    }

    return arrayOfButtons;
  };

  return (
    <div className="pagination-container">
      <button
        className="pagination-first"
        onClick={() => handleFirstClick()}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      <button
        className={
          pageIndex - 1 < defaultPage
            ? "pagination-previous-disabled"
            : "pagination-previous"
        }
        onClick={() => handlePreviousPageClick()}
        disabled={pageIndex - 1 < defaultPage}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      {renderPaginationButtons()}
      <button
        className={
          pageIndex + 1 > totalNumberOfPages
            ? "pagination-next-disabled"
            : "pagination-next"
        }
        onClick={() => handleNextPageClick()}
        disabled={pageIndex + 1 > totalNumberOfPages}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
      <button
        className="pagination-last"
        onClick={() => handleLastClick()}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
    </div>
  );
}

export default Pagination;
