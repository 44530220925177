import React from "react";
import { TextInput, DateInput } from "../../../components/input_hook_form";
import "./style.scss";
function Benchmark(props) {
  const { register, show, mode, control, errors ,defaultValues, setValue} = props;
  return (
    <div className={show ? "benchmark-container" : "benchmark-container-hide"}>
      <TextInput
        label={"Country Code"}
        placeholder={"Country Code"}
        error={mode !== "VIEW" && errors["benchmark-countrycode"]}
        register={
          register({ 
            required: true, 
            minLength: 1
        })}
        type={"text"}
        name="benchmark-countrycode"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Fund Code"}
        placeholder={"Fund Code"}
        error={mode !== "VIEW" && errors["benchmark-fundcode"]}
        register={register({
          required: true,
          minLength: 1
        })}
        type={"text"}
        name="benchmark-fundcode"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark Code"}
        placeholder={"Benchmark Code"}
        error={mode !== "VIEW" && errors["benchmark-benchmarkcode"]}
        register={
          register({ 
            required: false
        })}
        type={"text"}
        name="benchmark-benchmarkcode"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark Name"}
        placeholder={"Benchmark Name"}
        error={mode !== "VIEW" && errors["benchmark-benchmarkname"]}
        register={
          register({ 
            required: false
        })}
        type={"text"}
        name="benchmark-benchmarkname"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Fund ID"}
        placeholder={"Fund ID"}
        error={mode !== "VIEW" && errors["benchmark-fundid"]}
        register={register({ required: true })}
        type={"number"}
        name="benchmark-fundid"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark YTD"}
        placeholder={"Benchmark YTD"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-benchmarkytd"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark 03months"}
        placeholder={"Benchmark 03months"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-03months"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark 06months"}
        placeholder={"Benchmark 06months"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-06months"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark 12months"}
        placeholder={"Benchmark 12months"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-12months"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark 03years"}
        placeholder={"Benchmark 03years"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-03years"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark 05years"}
        placeholder={"Benchmark 05years"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-05years"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark 10years"}
        placeholder={"Benchmark 10years"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-10years"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <TextInput
        label={"Benchmark Since Inception"}
        placeholder={"Benchmark Since Inception"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="benchmark-sinceinception"
        disabled={mode === "VIEW"}
      />
      <div className="benchmark-spacer" />
      <DateInput
        label={"As of Date (DD-MM-YYYY)"}
        placeholder={"As of Date (DD-MM-YYYY)"}
        control={control}
        setValue={setValue}
        error={mode !== "VIEW" && errors["benchmark-asofdate"]}
        rules={{ required: false }}
        name="benchmark-asofdate"
        disabled={mode === "VIEW"}
        defaultValues = {defaultValues["benchmark-asofdate"]}

      />
    </div>
  );
}

export default Benchmark;
