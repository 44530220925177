import React from "react";
import { TextInput, DateInput } from "../../../components/input_hook_form";
import "./style.scss";

function Dividend(props) {
  const { register, show, mode, control, errors,defaultValues, setValue } = props;

  return (
    <div className={show ? "dividend-container" : "dividend-container-hide"}>
      <TextInput
        label={"Country Code"}
        placeholder={"Country Code"}
        error={mode !== "VIEW" && errors["dividend-countrycode"]}
        register={
          register({ 
              required: true, 
              minLength: 1
        })}
        type={"text"}
        name={"dividend-countrycode"}
        disabled={mode === "VIEW"}
      />
      <div className={"dividend-spacer"} />
      <TextInput
        label={"Fund Code"}
        placeholder={"Fund Code"}
        error={mode !== "VIEW" && errors["dividend-fundcode"]}
        register={
          register({ 
            required: true, 
            minLength: 1
        })}
        type={"text"}
        name={"dividend-fundcode"}
        disabled={mode === "VIEW"}
      />
      <div className={"dividend-spacer"} />
      <TextInput
        label={"Year"}
        placeholder={"Year"}
        error={mode !== "VIEW" && errors["dividend-year"]}
        register={register({ required: false })}
        type={"text"}
        name={"dividend-year"}
        disabled={mode === "VIEW"}
      />
      <div className={"dividend-spacer"} />
      <DateInput
        label={"Closing Date"}
        placeholder={"Closing Date"}
        error={mode !== "VIEW" && errors["dividend-closingdate"]}
        control={control}
        setValue={setValue}
        rules={{ required: false }}
        name={"dividend-closingdate"}
        disabled={mode === "VIEW"}
        defaultValues = {defaultValues["dividend-closingdate"]}

      />
      <div className={"dividend-spacer"} />
      <TextInput
        label={"Rate"}
        placeholder={"Rate"}
        register={register({ required: false, min: -10, max: 10 })}
        step={0.0001}
        type={"number"}
        name={"dividend-rate"}
        disabled={mode === "VIEW"}
      />
    </div>
  );
}

export default Dividend;
