import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { requestUntHldDetailFunds } from "./redux/actions";
import { CircularProgress } from "@material-ui/core";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import "./style.scss";

function ClientFunds(props) {
  const {
    requestClientFunds,
    requestingClientFunds,
    unitholderId,
    clientFunds
  } = props;

  const columns = React.useMemo(() => list_of_columns, []);

  useEffect(() => {
    // on component did mount, request funds for this user
    if (unitholderId) {
      // we can only request the funds list if there is a unitholderid
      requestClientFunds();
    }
  }, []);

  const renderLoader = () => {
    if (requestingClientFunds) {
      return (
        <div className="client-detail-funds-progress">
          <CircularProgress />
          <p className="clients-list-progress-text">
            {"Retrieving Client Funds list..."}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="client-detail-funds-container">
      {renderLoader()}
      <Table data={clientFunds} columns={columns} />
    </div>
  );
}

const withConnect = connect(
  state => ({
    ...state.untHldDetailReducer
  }),
  {
    requestClientFunds: requestUntHldDetailFunds
  }
);

export default compose(
  withConnect,
  withRouter
)(ClientFunds);
