import { createReducer } from "redux-act";

import {
  MEMCusServiceDetail,
} from "./actions";

const initialState = {

};

const cusServiceDetailReducer = createReducer(
  {
    // -------Edit to RegisDevicedetail----------------
    [MEMCusServiceDetail]: (state, payload) => {
    
      return {
        ...state,
        RowDataAt:payload.RowDataAt,
        ServiceType:payload.ServiceType
      };
    }
  
  },
  initialState
);

export default cusServiceDetailReducer;
