export const aCategory = [
  {
    value: "investment_outlook",
    label: "มุมมองการลงทุน"
  },
  {
    value: "news_and_award",
    label: "ข่าวสารและรางวัล"
  },
  {
    value: "investment_knowledge",
    label: "สาระการลงทุน"
  },
  {
    value: "investor_guideline",
    label: "คู่มือนักลงทุน"
  },
  {
    value: "Provident Fund News and Report",
    label: "รายงาน ข่าวสารกองทุนสำรองเลี้ยงชีพ"
  },
  {
    value: "Financial Planning Content",
    label: "คลังความรู้ด้านการวางแผนการเงิน"
  },
  {
    value: "Provident Fund Fact Sheet",
    label: "หนังสือชี้ชวนส่วนสรุปกองทุนสำรองเลี้ยงชีพ"
  },
  {
    value: "fund_announcement",
    label: "ประกาศกองทุนรวม"
  },
  {
    value: "media_center",
    label: "Media center"
  }
];
