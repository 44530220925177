import React from "react";
import { useTable } from "react-table";
import { modifyCell, modifyCellHeader } from "./cell_formatter";
import Images from "../../utils/styles/images";

function Table({
  columns,
  data,
  handleActionHeaderClick,
  pageIndex,
  pageSize
}) {
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: data,
    columns: columns
  });

  return (
    <table className="rsp-table">
      <thead className="rsp-head-container">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className="rsp-head-row">
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} className="rsp-header">
                {modifyCellHeader(
                  column.render("Header"),
                  handleActionHeaderClick,
                  handleSortHeader
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps} className="rsp-table-body">
        {rows.length <= 0 ? (
          <tr className="rsp-table-empty-row">
            <td colSpan={14}>
              <div className="rsp-table-no-data">
                <img src={Images.noData.board} alt="no-data-found-board" />
                <p className="rsp-table-no-data-header">No result found</p>
                <p className="rsp-table-no-data-body">
                  We couldn’t find what you are looking for.
                  <br /> Please try again.
                </p>
              </div>
            </td>
          </tr>
        ) : (
          rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="rsp-table-row">
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className="rsp-cell">
                      {modifyCell(cell.render("Cell"), pageIndex, pageSize)}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default Table;
