import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

// start test
function formatNumberCell(cellValue, rowNumber, pageSize) {
  if (rowNumber && pageSize) {
    return rowNumber + pageSize * (pageIndex - 1);
  } else {
    return cellValue;
  }
}

function formatAmountCell(cellValue) {
  if ((cellValue !== null && typeof cellValue !== "undefined") || cellValue === 0) {
    return cellValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"; 
  } else {
    return cellValue;
  }
}

function formatDateCell(cellValue) {
  if (cellValue) {
    return moment(cellValue).format("DD/MM/YY");
  } else {
    return cellValue; 
  }
}

function formatStatusCell(cellValue) {
  const statusMap = {
    "Approved": { icon: Images.statusIcon.approved, className: "rsp-table-cell-account-status-approved" },
    "Cancelled": { icon: Images.statusIcon.rejected, className: "rsp-table-cell-account-status-rejected" },
    // Default
    "": { icon: Images.statusIcon.pending, className: "rsp-table-cell-account-status-pending" }
  };

  // Use default if no match
  const statusEntry = statusMap[cellValue] || statusMap[""];

  return (
    <div className="rsp-table-cell-account-status">
      <img src={statusEntry.icon} alt={cellValue} />
      <p className={statusEntry.className}>{cellValue}</p>
    </div>
  );
}

export function modifyCell(cell, pageIndex, pageSize) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1;

  const formatters = {
    "NO.": formatNumberCell,
    "AMOUNT (THB)": formatAmountCell,
    "ENTRY DATE": formatDateCell,
    "EFFECTIVE DATE": formatDateCell,
    "STATUS": formatStatusCell
  };

  return formatters[columnName] ? formatters[columnName](cellValue, rowNumber, pageSize) : cell;
}
// end test

// This is just for the header cells
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (
    cell === "FULL NAME" ||
    cell === "STATUS" ||
    cell === "FUND CODE" ||
    cell === "ENTRY DATE" ||
    cell === "EFFECTIVE DATE"
  ) {
    return (
      <button
        className="rsp-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        {cell}
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
