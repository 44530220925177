import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Images from "../../utils/styles/images";
import { DefaultLoader } from '../Loader';
import "./style.scss";

export const TYPES = {
  PRIMARY: "primary",
  PRIMARY_LINK: "primary-link",
  PRIMARY_EDIT: "primary-edit",
  PRIMARY_BORDER: "primary-border",
  PRIMARY_BORDER_TRANSPARENT: "primary-border-transparent",
  WARNING: "warning",
  DANGER: "danger",
  SUCCESS: "success",
  LINK: "link",
  NON_BORDER: "non-border",
  STATUS_ACTIVE: "status_active",
  STATUS_INACTIVE: "status_inactive",
  CLOSE: "close-button",
  START_UPLOAD: "start_upload",
  SUCCESS_UPLOAD: "success_upload",
  LOGIN: "login"
};
const BaseButton = ({
  contentButton,
  onClick,
  type,
  disabled,
  buttonType,
  className,
  width,
  height,
  style,
  loading
}) => {
  const btnSize = {
    width,
    height,
    ...style
  };

  const content = loading ? <DefaultLoader /> : contentButton;
  const disabledButton = loading || disabled;

  return (
    <button
      type={type}
      disabled={disabledButton}
      onClick={onClick}
      className={classnames("btnProj", buttonType, className)}
      style={btnSize}
    >
      {content}
    </button>
  );
};

BaseButton.propTypes = {
  contentButton: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.any,
  height: PropTypes.any
};

BaseButton.defaultProps = {
  contentButton: "",
  type: "",
  disabled: false,
  buttonType: "",
  style: {},
  width: "auto",
  height: "auto"
};

export const startUploadContent = (
  <span>
    <img src={Images.statusUpload.start} alt="Icon" />
  </span>
);
export const successUploadContent = (
  <span>
    <img src={Images.statusUpload.success} alt="Icon" />
  </span>
);
const closeButtonContent = (
  <span>
    <img src={Images.closeButton.close} alt="Icon" />
  </span>
);
export const Primary = props => (
  <BaseButton {...props} buttonType={TYPES.PRIMARY} />
);
export const PrimaryEdit = props => (
  <BaseButton {...props} buttonType={TYPES.PRIMARY_EDIT} />
);
export const PrimaryBorder = props => (
  <BaseButton {...props} buttonType={TYPES.PRIMARY_BORDER} />
);
export const PrimaryBorderTransparent = props => (
  <BaseButton {...props} buttonType={TYPES.PRIMARY_BORDER_TRANSPARENT} />
);
export const Warning = props => (
  <BaseButton {...props} buttonType={TYPES.WARNING} />
);
export const Danger = props => (
  <BaseButton {...props} buttonType={TYPES.DANGER} />
);
export const Success = props => (
  <BaseButton {...props} buttonType={TYPES.SUCCESS} />
);
export const Link = props => <BaseButton {...props} buttonType={TYPES.LINK} />;
export const NonBorder = props => (
  <BaseButton {...props} buttonType={TYPES.NON_BORDER} />
);
export const PrimaryLink = props => (
  <BaseButton {...props} buttonType={TYPES.PRIMARY_LINK} />
);
export const ActiveStatus = props => {
  return <BaseButton {...props} buttonType={TYPES.STATUS_ACTIVE} />;
};

export const InactiveStatus = props => (
  <BaseButton {...props} buttonType={TYPES.STATUS_INACTIVE} />
);
export const IndicatorStatus = props => (
  <BaseButton
    {...props}
  />
);
export const IndicatorStatusSuccessUpload = props => (
  <BaseButton
    {...props}
    buttonType={TYPES.SUCCESS_UPLOAD}
    contentButton={successUploadContent}
  />
);
export const CloseButton = props => (
  <BaseButton
    {...props}
    buttonType={TYPES.CLOSE}
    contentButton={closeButtonContent}
  />
);
export const Login = props => (
  <BaseButton {...props} buttonType={TYPES.LOGIN} />
);
