import React, { Fragment } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter
} from "react-table";
import { modiCelFundRecRisk, modiCelHFundRecRisk } from "./cell_formatter";

import Images from "../../utils/styles/images";
import Pagination from "./Pagination";

function Table_FundRecRisk({
  columns,
  data,
  handleActionHeaderClick,
  handleSortHeader,
  handleActionClick
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageCount,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Fragment>
      <div className="funds-search-header">
        <div className="funds-search-container">
        </div>
      </div>
      <div className="funds-list-container">
        <table className="funds-table" {...getTableProps()}>
          <thead className="funds-head-container">
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="funds-head-row"
              >
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="funds-header"
                    style={{
                      width: column.render("width")
                    }}
                  >
                    {modiCelHFundRecRisk(
                      column.render("Header"),
                      handleActionHeaderClick,
                      handleSortHeader
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps} className="funds-table-body">
            {page.length <= 0 ? (
              <tr className="funds-table-empty-row">
                <td colSpan={14}>
                  <div className="funds-table-no-data">
                    <img src={Images.noData.board} alt="no-data-found-board" />
                    <p className="funds-table-no-data-header">
                      No result found
                    </p>
                    <p className="funds-table-no-data-body">
                      We couldn’t find what you are looking for.
                      <br /> Please try again.
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="funds-table-row">
                    {row.cells.map(cell => {
                      
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="funds-cell"
                          style={{
                            width: cell.render("width"),
                            paddingLeft: cell.render("paddingLeft"),
                            textAlign: cell.render("textAlign")
                          }}
                        >
                          {modiCelFundRecRisk(
                            cell.render("Cell"),
                            pageIndex,
                            pageSize,
                            handleActionClick
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {pageCount > 1 ? (
        <Pagination
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          total={pageCount}
          gotoPage={gotoPage}
        />
      ) : null}
    </Fragment>
  );
}

export default Table_FundRecRisk;
