export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 40
  },
  {
    Header: "CONSENT TYPE",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:50,
    width: 100
  },
  {
    Header: "CONSENT DATE",
    accessor: "created_at",
    disableSortBy: false,
    display:"",
    textAlign:"left",
    paddingLeft:50,
    width: 100
  },
  {
    Header: "CONSENT VERSION",
    accessor: "consent_version",
    disableSortBy: false,
    display:"",
    textAlign:"left",
    paddingLeft:50,
    width: 100
  },
  {
    Header: "CONSENT NO",
    accessor: "consent_no",
    disableSortBy: false,
    display:"",
    textAlign:"left",
    paddingLeft:50,
    width: 100
  },
  {
    Header: "ACTION",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 80,
    paddingLeft:10
  }
 
];
