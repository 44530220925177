import { createReducer } from "redux-act";

import {
  MEMRegisDeviceDetail,
} from "./actions";

const initialState = {

};

const regisDeviceDetailReducer = createReducer(
  {
    // -------Edit to RegisDevicedetail----------------
    [MEMRegisDeviceDetail]: (state, payload) => {
      return {
        ...state,
        RowDataAt:payload.RowDataAt
      };
    }
  
  },
  initialState
);

export default regisDeviceDetailReducer;
