import { createReducer } from "redux-act";

import {
  setModeFundDetails,
  cleanupFundDetails,
  requestFundDetails,
  receiveFundDetails,
  failFundDetails,
  requestFundCodeExists,
  receiveFundCodeExists,
  failFundCodeExists,
  requestFundCreate,
  receiveFundCreate,
  failFundCreate,
  requestFundEdit,
  receiveFundEdit,
  failFundEdit,
  resetFundCodeExists,
  resetErrorMessage
} from "./actions";

const initialState = {
  mode: null,
  requestingFundDetails: false,
  requestingPutFund: false,
  requestingEditFund: false,
  requestingFundCodeExists: false,
  fundIDFromCheckingFundCodeExists: null,
  fundCodeExistsInDB: null,
  fundCodeExistsInPentacle: null,
  detailsFromList: [],
  fundDetailsEN: [],
  fundDetailsTH: [],
  clonedFundDetails: [],
  errorMessage: false
};

const fundDetailsReducer = createReducer(
  {
    [cleanupFundDetails]: () => {
      return initialState;
    },
    [setModeFundDetails]: (state, payload) => {
      return {
        ...state,
        mode: payload.mode,
        detailsFromList: payload.detailsFromList
      };
    },
    [resetErrorMessage]: (state) => {
      return {
        ...state,
        errorMessage: false
      };
    },
    [requestFundDetails]: (state) => {
      return {
        ...state,
        requestingFundDetails: true
      };
    },
    [receiveFundDetails]: (state, payload) => {
      return {
        ...state,
        requestingFundDetails: false,
        fundDetailsEN: payload.en,
        fundDetailsTH: payload.th
      };
    },
    [failFundDetails]: (state) => {
      return {
        ...state,
        requestingFundDetails: false,
        fundDetailsEN: [],
        fundDetailsTH: []
      };
    },
    [requestFundCodeExists]: (state) => {
      return {
        ...state,
        requestingFundCodeExists: true
      };
    },
    [receiveFundCodeExists]: (state, payload) => {
      return {
        ...state,
        requestingFundCodeExists: false,
        fundCodeExistsInDB: payload.existsDb,
        fundCodeExistsInPentacle: payload.existsPentacle,
        fundIDFromCheckingFundCodeExists: payload.fundNo
      };
    },
    [failFundCodeExists]: (state, payload) => {
      return {
        ...state,
        requestingFundCodeExists: false,
        errorMessage: payload
      };
    },
    [resetFundCodeExists]: (state) => {
      return {
        ...state,
        requestingFundCodeExists: false,
        fundCodeExistsInDB: null,
        fundCodeExistsInPentacle: null,
        fundIDFromCheckingFundCodeExists: null
      };
    },
    [requestFundCreate]: (state) => {
      return {
        ...state,
        requestingPutFund: true
      };
    },
    [receiveFundCreate]: (state) => {
      return {
        ...state,
        requestingPutFund: false
      };
    },
    [failFundCreate]: (state, payload) => {
      return {
        ...state,
        requestingPutFund: false,
        errorMessage: payload
      };
    },
    [requestFundEdit]: (state) => {
      return {
        ...state,
        requestingEditFund: true
      };
    },
    [receiveFundEdit]: (state) => {
      return {
        ...state,
        requestingEditFund: false
      };
    },
    [failFundEdit]: (state, payload) => {
      return {
        ...state,
        requestingEditFund: false,
        errorMessage: payload
      };
    }
  },
  initialState
);

export default fundDetailsReducer;
