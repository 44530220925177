import { combineReducers } from "redux";
import { dropDownDataReducer } from "./dropdownDataReducers";
import { loadingReducer } from "./loadingReducer";
import { pagingReducer } from "./pagingReducer";

const globalReducer = combineReducers({
  dropDownDataReducer,
  loadingReducer,
  page: pagingReducer
});

export default globalReducer;
