import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

// start test pass
export function modifyCell(cell, handleActionClick, pageIndex, pageSize) {
  const { props } = cell;

  if (!props || !props.cell || !props.cell.column || !props.row) {
    return cell;
  }

  const { column, value, row } = props.cell;
  const { Header } = column;
  const { original } = row;

  switch (Header) {
    case "NO.":
      return row.index + 1 + (pageSize * pageIndex);
    case "NAME":
      return original ? original.Name : cell;
    case "EMAIL ADDRESS":
      return original ? original["Email Address"] : cell;
    case "CONTACT NO.":
      return cell ? `+ ${cell}` : cell;
    case "SUBMISSION DATE":
    case "LAST REVIEW DATE":
      return value ? moment(value).format("DD/MM/YYYY") : cell;
    case "ENHANCED KYC":
      return value === true ? "Yes" : value === false ? "No" : cell;
    case "ACCESS STATUS":
      return (
        <div className="clients-table-cell-account-status">
          <img src={rowData.status === "LOCKED_OUT" ? Images.accessStatus.locked : Images.accessStatus.unlocked} alt="pending-icon" />
          <p className={`applicants-table-cell-account-status-${rowData.status.toLowerCase()}`}>{rowData.status === "LOCKED_OUT" ? "Locked" : "Unlocked"}</p>
        </div>
      );
    case "DOC. STATUS":
      const statusMap = {
        "1": { icon: Images.statusIcon.pending, text: "Pending" },
        "2": { icon: Images.statusIcon.approved, text: "Approved" },
        "3": { icon: Images.statusIcon.rejected, text: "Rejected" },
        "4": { icon: Images.statusIcon.pending, text: "In-Review" }
      };
      return (
        <div className="clients-table-cell-document-status">
          <img src={statusMap[cellValue].icon} alt={`${statusMap[cellValue]?.text.toLowerCase()}-icon`} />
          <p className={`clients-table-cell-document-status-${statusMap[cellValue]?.text.toLowerCase()}`}>{statusMap[cellValue]?.text}</p>
        </div>
      );
    case "ACTION":
      if (rowData.status === "LOCKED_OUT") {
        return (
          <button onClick={() => handleActionClick(rowData, "UNLOCK")} className="clients-table-cell-action">
            Unlock
          </button>
        );
      }
      break;
    default:
      return cell;
  }
}
// end test

// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleSortHeader
) {
  if (cell === "SUBMISSION DATE") {
    return (
      <button
        className="clients-table-header-submission"
        onClick={() => handleActionHeaderClick(cell)}
      >
        SUBMISSION DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "DOC. STATUS" || cell === "ACC. STATUS") {
    return handleSortHeader ? handleSortHeader(cell) : cell;
  } else {
    return cell;
  }
}
