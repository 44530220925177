import React, { Fragment, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQTagList, CleanUpTagList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";

import { MEMTagDetailEdt } from "../TagDetail/redux/actions";
import "./style.scss";

function TagList(props) {
  const {
    REQTagList,
    REQStrTagList,
    TagList,

    MEMTagDetailEdt,
    history
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);

  const GETxTag = () => {
    REQTagList();
  };

  useEffect(() => {
    // on component did mount, we call the API
    GETxTag();
  }, []);

  const SETxActTableClick = (cellValue, rowData, columnName, pageIndex) => {
    if (columnName === "TAG NAME" && history.location.pathname === "/Tag") {
        MEMTagDetailEdt({
          mode: "OLD_CONTENT",
          RowDataAt: TagList.find(x => x.id === rowData.id),
          PageCurrent: pageIndex
        });
        window.addEventListener("popstate", function() {
          history.push({ pathname: "/Tag", PageCurrent: pageIndex });
        });
        history.push(`/TagDetail/${rowData.id}`);
    }
  };

  return (
    <Fragment>
      <div className="button-create-container">
        <Link className="button-create-new-content" to={`/TagCrt`}>
          <span style={{ paddingRight: 10 }}>+</span>CREATE NEW TAG
        </Link>
      </div>
      {REQStrTagList ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {REQStrTagList ? "Retrieving Funds list..." : null}
          </p>
        </div>
      ) : null}
      <Table
        data={TagList}
        HightTable={900}
        columns={columns}
        handleActionClick={SETxActTableClick}
        history={history}
      />
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.tagListReducer
  }),
  {
    REQTagList,
    CleanUpTagList,
    MEMTagDetailEdt
  }
);

export default compose(
  withConnect,
  withRouter
)(TagList);
