import { createReducer } from "redux-act";

import {
  REQFundRecList,
  RESFundRecList,
  FailFundRecList,
  CleanUpFundRecList,
  REQFundRecUpDoc,
  RESFundRecUpDoc,
  FailFundRecUpDoc,
  ResetFundRecUpDoc
  // --FundRecommend Risk------
} from "./actions";

const initialState = {
  requestingFundsList: false,
  fundsList: [],
  errorMessage: false,
  totalFunds: 0,
  requestingDocumentUpload: false,
  uploadDocStatus: null
};

const fundRecListReducer = createReducer(
  {
    [REQFundRecList]: (state) => {
      return {
        ...state,
        requestingFundsList: true
      };
    },
    [RESFundRecList]: (state, payload) => {
      return {
        ...state,
        requestingFundsList: false,
        fundsList: payload.data,
        totalFunds: payload.total
      };
    },
    [FailFundRecList]: (state) => {
      return {
        ...state,
        requestingFundsList: false,
        fundsList: [],
        totalFunds: 0
      };
    },
    [CleanUpFundRecList]: () => {
      return initialState;
    },
    [REQFundRecUpDoc]: (state) => {
      return {
        ...state,
        requestingDocumentUpload: true
      };
    },
    [RESFundRecUpDoc]: (state) => {
      return {
        ...state,
        requestingDocumentUpload: false,
        uploadDocStatus: "SUCCESS"
      };
    },
    [FailFundRecUpDoc]: (state) => {
      return {
        ...state,
        requestingDocumentUpload: false,
        uploadDocStatus: "FAILED"
      };
    },
    [ResetFundRecUpDoc]: (state) => {
      return {
        ...state,
        requestingDocumentUpload: false,
        uploadDocStatus: null
      };
    }
  },
  initialState
);

export default fundRecListReducer;
