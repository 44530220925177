import React, { Fragment, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter
} from "react-table";
import { modifyCell, modifyCellHeader } from "./cell_formatter";
import Images from "../../utils/styles/images";
import Pagination from "./Pagination";

function Table(
  { columns,
    data,
    handleActionHeaderClick,
    handleSortHeader,
    handleActionClick,
    HightTable,
    history
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageCount,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
     state,
    setGlobalFilter
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: history.location.PageCurrent, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [searchValue, setSearchValue] = useState(undefined);
  return (
    <Fragment>    
      <div className="regisDevice-search-header">
        <div className="regisDevice-search-container">
          <div className="regisDevice-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="regisDevice-list-begin-search"
              onClick={() =>
                searchValue.length > 0 && setGlobalFilter(searchValue)
              }
            />
            <input
              placeholder="Search by Registered Device"
              className="regisDevice-search-bar"
              value={state.globalFilter || ""}
              onChange={event => {
                setSearchValue(event.target.value);
                setGlobalFilter(event.target.value);
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  setSearchValue(event.target.value);
                  setGlobalFilter(event.target.value);
                }
              }}
            />
            {searchValue && searchValue.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="regisDevice-details-cancel-search"
                onClick={() => {
                  setSearchValue(undefined);
                  setGlobalFilter(undefined);
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="funds-list-container" style={{height:HightTable}}>
        <table className="funds-table" {...getTableProps()}>
          <thead className="funds-head-container">
            {headerGroups.map(headerGroup => (
              <tr
                {
                  ...headerGroup.getHeaderGroupProps()
                }
                    className="funds-head-row"
              >
                {headerGroup.headers.map(column => (
                <th
                 {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="funds-header"
                  style={
                    { width: column.render("width"),
                    paddingLeft: column.render("paddingLeft")
                  } 
                }
                >
                    {modifyCellHeader(
                      column.render("Header"),
                      handleActionHeaderClick,
                      handleSortHeader
                    )}
                </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps} className="funds-table-body">
            {page.length <= 0 ? (
              <tr className="funds-table-empty-row">
                <td colSpan={14}>
                  <div className="funds-table-no-data">
                    <img src={Images.noData.board} alt="no-data-found-board" />
                    <p className="funds-table-no-data-header">
                      No result found
                    </p>
                    <p className="funds-table-no-data-body">
                      We couldn’t find what you are looking for.
                      <br /> Please try again.
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="funds-table-row">
                    {
                    row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()} className="funds-cell"
                        style={
                          { width: cell.render("width"),
                            paddingLeft: cell.render("paddingLeft"),
                            textAlign: cell.render("textAlign")
                          }}
                        
                        >

                          {modifyCell(
                            cell.render("Cell")
                          , pageIndex
                          , pageSize
                          , handleActionClick)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {pageCount > 1 ? (
        <Pagination
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          total={pageCount}
          gotoPage={gotoPage}
        />
      ) : null}
    </Fragment>
  );
}

export default Table;
