import { createAction } from "redux-act";

export const requestRSPList = createAction(
  "REQUEST_RSP_ACCOUNT_LIST"
);
export const receiveRSPList = createAction(
  "RECEIVE_RSP_ACCOUNT_LIST"
);
export const failRSPList = createAction("FAILED_RSP_ACCOUNT_LIST");
export const cleanupRSPList = createAction(
  "CLEANUP_RSP_ACCOUNT_LIST"
);
export const sortRSPList = createAction(
  "SORT_RSP_ACCOUNT_LIST"
);
