import { createReducer } from "redux-act";

import {
  REQTagList,
  RESTagList,
  FailTagList,
  CleanUpTagList
} from "./actions";

const initialState = {
  REQStrTagList: false,
  TagList: [],
  errorMessage: false,
  totalFunds: 0
};

const tagListReducer = createReducer(
  {
  
    [REQTagList]: (state) => {
      return {
        ...state,
        REQStrTagList: true
      };
    },
   
    [RESTagList]: (state, payload) => {
     
      return {
        ...state,
        REQStrTagList: false,
        TagList: payload,
        totalFunds: payload.total
      };
    },
     
    [FailTagList]: (state) => {
      return {
        ...state,
        REQStrTagList: false,
        TagList: [],
        totalFunds: 0
      };
    } , 
    [CleanUpTagList]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default tagListReducer;
