export function FundManagerConverter(data) {
  if (data) {
    // convert the string testA\ntestB\ntestC to [{label: "testA", value: "testA", __isNew__: true}, ...]
    return data.split("\n").map((manager_name) => {
      return { label: manager_name, value: manager_name, __isNew__: true };
    });
  } else {
    return "";
  }
}

export function FundTypeConverter(data) {
  if (data) {
    // convert the string Mixed Fund, Fund of Funds, RMF to  [{label: "Mixed Fund", value: "Mixed Fund", __isNew__: true}, ...]
    return data.split(",").map((fund_type) => {
      return { label: fund_type, value: fund_type, __isNew__: true };
    });
  } else {
    return "";
  }
}

export function FundManagerConvertForBE(data) {
  if (data) {
    // convert the [{label: "testA", value: "testA", __isNew__: true}, ...] to string testA\ntestB\ntestC
    let arr = data.map((obj) => obj.label);
    return arr.join("\n");
  } else {
    return "";
  }
}

export function FundTypeConvertForBE(data) {
  if (data) {
    // convert the [{label: "Mixed Fund", value: "Mixed Fund", __isNew__: true}, ...] to string Mixed Fund, Fund of Funds, RMF
    let arr = data.map((obj) => obj.label);
    return arr.join(",");
  } else {
    return "";
  }
}
