import React from "react";
import Images from "../../utils/styles/images";

// start test pass
export function modifyCell(
  cell,
  interestsList,
  handleActionClick,
  history,
  pageIndex
) {
  const { props } = cell;
  const columnName = props.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row.index + 1;
  const rowData = props?.row?.original || null;

  switch (columnName) {
    case "NO.":
      return formatRowNumber(rowNumber);
    case "ARTICLE TITLE":
      return formatArticleTitle(cellValue, rowData, handleActionClick, pageIndex,columnName);
    case "CREATED DATE":
      return formatDate(cellValue); 
    case "MODIFIED DATE":
      return formatDate(cellValue);
    case "HIGHLIGHT":
      return formatHighlight(cellValue);
    case "STATUS":
      return formatStatus(cellValue);
    case "VIEW":
      return formatViewButton(cellValue, rowData, handleActionClick,columnName);
    default:
      return cell;
  }
}

// Helper Functions
function formatRowNumber(rowNumber, cell) {
    // Or your default if rowNumber is unavailable
    return rowNumber || cell;
}

function formatArticleTitle(cellValue, rowData, handleActionClick, pageIndex,columnName) {
  if (!handleActionClick) return cellValue;

  const { articleTitleTh, articleTitleEn } = rowData;
  if (articleTitleTh && articleTitleEn) {
    return renderTwoButtons(articleTitleTh, articleTitleEn, handleActionClick, columnName, pageIndex,rowData);
  } else {
    return renderSingleOrNone(articleTitleTh, articleTitleEn, handleActionClick, columnName, pageIndex,rowData);
  }
}

function renderTwoButtons(titleTh, titleEn, handleActionClick, columnName, pageIndex,rowData) {
  return (
    <div>
      <button
        onClick={() => handleActionClick(rowData, columnName, pageIndex)}
        className="feed-list-article-button"
      >
        {titleTh}
      </button>
      <button
        onClick={() => handleActionClick(rowData, columnName, pageIndex)} 
        className="feed-list-article-button"
      >
        {titleEn}
      </button>
    </div>
  );
} 

function renderSingleOrNone(titleTh, titleEn, handleActionClick, columnName, pageIndex,rowData) {
  return (
    <div style={{ alignItems: "center", display: "flex" }}>
      <button
        onClick={() => handleActionClick(rowData, columnName, pageIndex)}
        className="feed-list-article-button"
        style={{ display: titleTh ? "block" : "none" }}
      >
        {titleTh}
      </button>
      <button
        onClick={() => handleActionClick(rowData, columnName, pageIndex)}
        className="feed-list-article-button"
        style={{ display: titleEn ? "block" : "none" }}
      >
        {titleEn}
      </button>
    </div>
  );
}

function formatDate(cellValue) {
  if (!cellValue) return cell; 

  const dateParts = cellValue.split("T");
  return cellValue ? `${dateParts[0]} ${dateParts[1].substring(0, 8)}` : cell;
}

function formatHighlight(cellValue) {
  const statusClass = cellValue ? "highlight" : "nohighlight";
  return (
    <div className={`clients-table-cell-account-status status-${statusClass}`}> 
      { cellValue ? (<img src={Images.statusIcon.highlight} alt={`${statusClass}-icon`} /> ) : (<img src={Images.statusIcon.nohighlight} alt={`${statusClass}-icon`} />)}
      <p className={`conFd-table-cell-account-status-${statusClass}`}>
        {cellValue ? "Highlight" : "Highlight"} 
      </p>
    </div> 
  );
}

function formatStatus(cellValue) { 
  const statusClass = cellValue === 2 ? "Published" : "Unpublished";
  return (
    <div className={`clients-table-cell-account-status status-${statusClass}`}> 
      { cellValue === 2 ? (<img src={Images.statusIcon.highlight} alt={`${statusClass}-icon`} /> ) : (<img src={Images.statusIcon.nohighlight} alt={`${statusClass}-icon`} />)}
      <p className={`conFd-table-cell-account-status-${statusClass}`}>
      {cellValue === 2 ? "Published" : "Unpublished"} 
      </p>
    </div> 
  );
}

function formatViewButton(cellValue, rowData, handleActionClick,columnName,pageIndex) {
  return (
    <div>
      <button
        onClick={() => 
          (
            handleActionClick(rowData, columnName, pageIndex)
          )
        }
        className="conFd-synce-button"
        style={{ width: 71, height: 25, fontSize: 12 }}
      >
        VIEW
      </button>
    </div>
  );
}
// end test
export function modifyCellHeader(
  cell,
  handleSortHeader
) {
  if (cell === "MODIFIED BY") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        MODIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "ARTICLE TITLE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        ARTICLE TITLE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "CATEGORY") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        CATEGORY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "CREATED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        CREATED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "MODIFIED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        MODIFIED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "STATUS") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "HIGHLIGHT") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        HIGHLIGHT
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "DOC. STATUS" || cell === "ACC. STATUS") {
    return handleSortHeader ? handleSortHeader(cell) : cell;
  } else {
    return cell;
  }
}
