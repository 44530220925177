import { createReducer } from "redux-act";

import {
  // --------------Consent Log-----------------
  REQConsentList,
  RESConsentList,
  FailConsentList,
  CleanUpConsentList,
  // --------------SYNC-----------------
  REQSyncConsent,
  RESSyncConsent
} from "./actions";

const initialState = {
  REQStrConsentList: false,
  ConsentList: [],
  totalConsent: 0,
  // --------------SYNC-----------------
  REQStrSyncConsentList: false,
  SyncConsentList: [],
  totalSyncConsent: 0
};

const consentListReducer = createReducer(
  {
  
    [REQConsentList]: (state) => {
      return {
        ...state,
        REQStrConsentList: true
      };
    },
   
    [RESConsentList]: (state, payload) => {
      return {
        ...state,
        REQStrConsentList: false,
        ConsentList: payload.Consent,
        totalConsent: payload.TotalConsent
      };
    },
     
    [FailConsentList]: (state) => {
      return {
        ...state,
        REQStrConsentList: false,
        ConsentList: [],
        totalConsent: 0
      };
    } , 
    [CleanUpConsentList]: () => {
      return initialState;
    },
   // --------------SYNC-----------------
   [REQSyncConsent]: (state) => {
    return {
      ...state,
      REQStrSyncConsentList: true
    };
  },
 
  [RESSyncConsent]: (state, payload) => {
    return {
      ...state,
      REQStrSyncConsentList: false,
      SyncConsentList: payload
    };
  },
   
  [FailConsentList]: (state) => {
    return {
      ...state,
      REQStrSyncConsentList: false,
      SyncConsentList: [],
      totalSyncConsent: 0
    };
  }
   
 
  },

  initialState
);

export default consentListReducer;
