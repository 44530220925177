import moment from "moment";

export const transformRawDate = (rawDate) => {
    // convert to TH timezone -8 (-7 timezone & -1 server timestamp)
    const THTimeZone = moment(rawDate).subtract("8", "h").format("DD/MM/YYYY HH:mm");
    return THTimeZone;
};

export const transformRawDateForErrorModal = (rawDate) => {
    const modalDateFormat = moment(rawDate).subtract("8", "h").format("DD/MM/YYYY HH:mm");
    return modalDateFormat;
};