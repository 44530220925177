import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export const authFormInput = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => {
  return (
    <div className={`input-wrap ${type}`}>
      <div className={touched && error ? "input-item error" : "input-item"}>
        <input {...input} type={type} placeholder={label} autoComplete="off" />
        {touched && error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

authFormInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

authFormInput.defaultProps = {
  input: {},
  label: "",
  type: "",
  meta: {
    touched: false,
    error: ""
  }
};
