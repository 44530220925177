import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { WarningModal, AlertModal } from "../../components/Modal";
import Images from "../../utils/styles/images";
import Select from "react-select";
import JoditEditor from "jodit-react";
import {
  REQByRiskEdt,
  REQByRiskD,
  REQFundCodeList,
  CleanUpByRiskEdt
} from "./FundRecRisk_redux/actions";

import { aOrder, aRiskLv } from "../../utils/MockAPI";
import "./style.scss";

const SHWxDropDown = (op1, oMeal, oVal2, name) => {
  let oRw1 = oVal2.map(a => a.ocbRiskOrder2);
  let oOPRender = oMeal.filter(x => !oRw1.includes(x.value));
  if (name !== "ocbRiskOrder1_add" && op1 !== "Please choose order") {  
      oOPRender.push({
        value: op1,
        label: parseInt(op1)
      });
  }

  let oRows = [];

  for (let i = 0; i < oOPRender.length; i++) {
    if (oOPRender[i].value !== null) {
      oRows.push(
        <option
          className="fundrec-DropDown-Op"
          key={oOPRender[i].value}
          value={oOPRender[i].value}
        >
          {oOPRender[i].label}
        </option>
      );
    }
  }

  return oRows;
};

class DropDown extends React.Component {
  onSelect = event => {
    this.props.selectItem(this.props.name, event.target.value);
  };
  render() {
    return (
      <div>
        {
          <div>
            <select
              className="fundrec-DropDown"
              defaultValue={this.props.ocbRiskOrder2}
              onChange={this.onSelect}
            >
              <option className="fundrec-DropDown-Op">
                Please choose order
              </option>
              {SHWxDropDown(
                this.props.ocbRiskOrder2,
                this.props.meals,
                this.props.oVal2,
                this.props.name
              )}
            </select>
          </div>
        }
      </div>
    );
  }
}

function FundRecRiskDetail(props) {
  const {
    RowDataAt,
    REQStrFundCodeList,
    REQFundCodeList,
    FundCodeList,
    REQByRiskEdt,
    history
  } = props;

  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  const [oVal1, SETxVal1] = useState(
    RowDataAt
      ? {
          otbPortNameTH:
            RowDataAt.portnameth !== null ? RowDataAt.portnameth : "",
          otbPortNameEN:
            RowDataAt.portnameen !== null ? RowDataAt.portnameen : "",
          otbexpected1:
            RowDataAt.expected1 !== null
              ? (Math.round(RowDataAt.expected1 * 100) / 100).toFixed(2)
              : "",
          otbexpected2:
            RowDataAt.expected2 !== null
              ? (Math.round(RowDataAt.expected2 * 100) / 100).toFixed(2)
              : "",
          otbexpected3year:
            RowDataAt.expected3year !== null
              ? (Math.round(RowDataAt.expected3year * 100) / 100).toFixed(2)
              : "",
          otbVolatility:
            RowDataAt.volatility !== null
              ? (Math.round(RowDataAt.volatility * 100) / 100).toFixed(2)
              : "",
          otbPDFNameTH: RowDataAt.pdfnameth !== null ? RowDataAt.pdfnameth : "",
          otbPDFNameEN: RowDataAt.pdfnameen !== null ? RowDataAt.pdfnameen : "",
          otbPDFUrl: RowDataAt.pdfurl !== null ? RowDataAt.pdfurl : "",
          otbYoutubeUrl:
            RowDataAt.youtubeurl !== null ? RowDataAt.youtubeurl : "",
          otbVDODescTH:
            RowDataAt.descriptionvideoth !== null
              ? RowDataAt.descriptionvideoth
              : "",
          otbVDODescEN:
            RowDataAt.descriptionvideoen !== null
              ? RowDataAt.descriptionvideoen
              : ""
        }
      : {
          otbPortNameTH: null,
          otbPortNameEN: null,
          otbexpected1: null,
          otbexpected2: null,
          otbexpected3year: null,
          otbVolatility: null,
          otbPDFNameTH: null,
          otbPDFNameEN: null,
          otbPDFUrl: null,
          otbYoutubeUrl: null,
          otbVDODescTH: null,
          otbVDODescEN: null
        }
  );
  const [tPer1, SETxPer1] = useState(null);
  const [oVal2, SETxVal2] = useState([]);
  const [bIsLoaded, SETbIsLoaded] = useState(true);

  const GETxByRisk = () => {
    REQFundCodeList();
  };

  useEffect(() => {
    GETxByRisk();
    const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
    const requestOptions = {
      method: "GET",  
      headers: {
        "Content-Type": "application/json",
        userid: oNeedAuth.GUID,
        id_token: "Bearer " + oNeedAuth.OKTAToken
      }
    };

    fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_fund_risk/${RowDataAt.id}`,
      requestOptions
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      })
      .then(json => {
        console.log(`recommend_by_fund_risk/${RowDataAt.id}:`,json);
        SETbIsLoaded(false);
        if (json === null) {
          return;
        }
        SETxVal2(
          json?.Rule?.length > 0
            ? json?.Rule?.map((x, index) => ({
                IdUni: index.toString(),
                IdRisk: x.IdRisk,
                ocbRiskOrder2:
                  x.risk_order === null ? null : x.risk_order.toString(),
                otbNameTH2: x.name_th,
                otbNameEN2: x.name_en,
                otbPer2: x.ratio,
                ocbFundcode2: x.fund
              }))
            : []
        );
      })
      .catch(error => {
        console.log('SETxVal2 > catch', error);
      })
      .finally(() => SETbIsLoaded(false));
  }, []);

  const [oShwEdtAlr, SETxShwEdtAlr] = useState(false);
  const [oShwEmptyAlr, SETxShwEmptyAlr] = useState(false);
  const [oShwDelCardAlr, SETxDelCardAlr] = useState(false);
  const [oDelCardName, SETxDelCardName] = useState();
  // ----------Prepare data set to Funtag element option-------
  let oOrderOp = aOrder;
  // ------------------------DropDown Custom---------------------------
  const [selectedItems, SETbselectedItems] = useState([]);

  const getFilteredMeals = name => {
    const removedItems = { ...selectedItems };
    delete removedItems[name];
    const removedItemsList = Object.values(removedItems);

    return oOrderOp.filter(itm => {
      let xx = !removedItemsList.includes(itm.value);
      return xx;
    });
  };

  const selectItem = (name, value) => {
    SETbselectedItems({
      ...selectedItems,
      [name]: value
    });

    // -----------Val2---------
    if (name === "ocbRiskOrder1_add") {
      console.log('selectItem > name === "ocbRiskOrder1_add"');
    } else {
      let nInex = name.split("_");
      nInex = parseInt(nInex[1]);
      let aNewArr = [...oVal2];
      let oTmpArr = { ...aNewArr[nInex] };
      oTmpArr.ocbRiskOrder2 = value;
      aNewArr[nInex] = oTmpArr;
      SETxVal2(aNewArr);
    }
  };

  // ---------------Select option-------------
  const oRiskLvOp = aRiskLv.map(d => ({
    value: d.RiskLvNo,
    label: d.RiskLvNo + " - " + d.RiskLv
  }));
  const oFunCodeOp = FundCodeList.map(d => ({
    value: d.fund_no,
    label: d.fund_code
  }));

  const tUniID = () => {
    return (
      Math.random()
        .toString(36)
        .substr(2, 9) +
      "-" +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  };

  // ---------------Select option-------------
  const [oSelFundCodeOp, SETxSelFundCodeOp] = useState([]);
  const SETxOnChgSelect = e => {
    SETxSelFundCodeOp(Array.isArray(e) ? e.map(x => x) : []);
  };

  // ---------------Radio-------------

  const [oVDOShw, SETxVDOShw] = React.useState(() => {
    if (RowDataAt.videoshow === null || RowDataAt.videoshow === "") {
      return "show";
    } else {
      return RowDataAt.videoshow;
    }
  });
  function SETxOnChgRaioAdd(e) {
    const tNameRadio = e.target.getAttribute("name");
    switch (tNameRadio) {
      case "orbVDOShw1":
        SETxVDOShw(e.target.value);
        break;
      default:
        break;
    }
  }
  // ---------------Event------------
  function CRTxAdd(e) {
    
    let otbNameTH1 = document.getElementsByName("otbNameTH1")[0].value;
    let otbNameEN1 = document.getElementsByName("otbNameEN1")[0].value;
    let otbPer1 = document.getElementsByName("otbPer1")[0].value;
    let ocbRiskOrder1;
    if (selectedItems.length === 0) {
      ocbRiskOrder1 = null;
    } else {
      ocbRiskOrder1 = selectedItems.ocbRiskOrder1_add;
    }
    let ocbTagFundCode1 = oSelFundCodeOp.map(x => ({
      fund_code: x.label,
      fund_no: x.value
    }));

    SETxVal2(arr => [
      ...arr,
      {
        IdUni: tUniID(),
        IdRisk: 0,
        ocbRiskOrder2: ocbRiskOrder1,
        otbNameTH2: otbNameTH1,
        otbNameEN2: otbNameEN1,
        otbPer2: otbPer1,
        ocbFundcode2: ocbTagFundCode1
      }
    ]);
    // -----------Reset-----------------
    document.getElementsByName("otbNameTH1")[0].value = "";
    document.getElementsByName("otbNameEN1")[0].value = "";
    document.getElementsByName("otbPer1")[0].value = "";

    SETxSelFundCodeOp(Array.isArray(e) ? e.map(x => x) : []);
    SETbselectedItems([]);
  }
  function DELxCard(e) {
    const tIdUiBtn = e.target.getAttribute("name");
    SETxDelCardAlr(true);
    SETxDelCardName(tIdUiBtn);
  }

  const UPDxOnChgTxt = (index, ptIdUni) => e => {
    let tNameArray = e.target.name;
    let aNewArr = oVal2.map((x) => {
      if (x.IdUni === ptIdUni) {
        let a = { ...x, [tNameArray]: e.target.value };

        return a;
      } else {
        return x;
      }
    });
    SETxVal2(aNewArr);
  };
  const SETxOnChgTxtVal1 = name => e => {
    SETxVal1({ ...oVal1, [name]: e.target.value });
  };
  const UPDxOnChgTxtPer1 = e => {
    
    SETxPer1(e.target.value);
  };
  const UPDxOnChgSelect = (index, ptIdUni) => e => {
    // ---Check Fundcode----
    let aNewArr = oVal2.map((x) => {
      if (x.IdUni === ptIdUni) {
        let aOld;
        let tLabelSel;
        if (e === null) {
          aOld = { ...x, ocbFundcode2: [] };
        } else {
          tLabelSel = e.map(x => ({ fund_code: x.label, fund_no: x.value }));
          aOld = { ...x, ocbFundcode2: tLabelSel };
        }

        return aOld;
      } else {
        return x;
      }
    });
    SETxVal2(aNewArr);
  };
  // ---------------Lang---------------------
  const EN = "ENGLISH";
  const TH = "THAI";
  var [oLang, SETxLang] = useState(EN);
  // -------------JoditEditor 2------------
 
  
  const editor = useRef(null);
  const [tContentDescRiskEN, SETtContentDescRiskEN] = useState(
    RowDataAt.descriptionrisken
  );
  const [tContentDescRiskTH, SETtContentDescRiskTH] = useState(
    RowDataAt.descriptionriskth
  );
  const oConfig = {
    readonly: false,
    height: 200
  };

  const EVTxUPDContentDescRiskEN = e => {
    const tEdtTxtEn = e;
    SETtContentDescRiskEN(tEdtTxtEn);
  };
  const EVTxUPDContentDescRiskTH = e => {
    const tEdtTxtTh = e;
    SETtContentDescRiskTH(tEdtTxtTh);
  };
  // ---------------------------------

  const CHKxInvalidexpected1 = ptExpect1 => {
    if (ptExpect1.substr(0, 2).toString() === ".-") {
      return "Expected 1 ไม่ใช่เปอร์เซ็น";
    }
    if (JSON.stringify(Number(ptExpect1)) === "null") {
      return "Expected 1 ไม่ใช่เปอร์เซ็น";
    }

    if (ptExpect1 === "") {
      return "กรุณากรอก Expected 1 (%)";
    }
    if (ptExpect1 > 100) {
      return "Expected 1 ไม่สามารถ มากกว่า 100%";
    }
  };
  const CHKxInvalidexpected2 = ptExpect2 => {
    if (ptExpect2.substr(0, 2).toString() === ".-") {
      return "Expected 2 ไม่ใช่เปอร์เซ็น";
    }
    if (JSON.stringify(Number(ptExpect2)) === "null") {
      return "Expected 2 ไม่ใช่เปอร์เซ็น";
    }

    if (ptExpect2 === "") {
      return "กรุณากรอก Expected 2 (%)";
    }
    if (ptExpect2 > 100) {
      return "Expected 2 ไม่สามารถ มากกว่า 100%";
    }
  };
  const CHKxInvalidexpected3year = ptExpect3Year => {
    if (ptExpect3Year.substr(0, 2).toString() === ".-") {
      return "Expected Return 3Y (%) ไม่ใช่เปอร์เซ็น";
    }
    if (JSON.stringify(Number(ptExpect3Year)) === "null") {
      return "Expected Return 3Y (%) ไม่ใช่เปอร์เซ็น";
    }
    if (ptExpect3Year === "") {
      return "กรุณากรอก Expected Return 3Y (%)";
    }
    if (ptExpect3Year > 100) {
      return "Expected Return 3Y (%) ไม่สามารถ มากกว่า 100%";
    }
  };
  const CHKxInvalidVolatility = ptVolatility => {
    if (JSON.stringify(Number(ptVolatility)) === "null") {
      return "Volatility ไม่ใช่เปอร์เซ็น";
    }
    if (ptVolatility === "") {
      return "กรุณากรอก Return Volatility (%)";
    }
    if (ptVolatility > 100) {
      return "Return Volatility ไม่สามารถ มากกว่า 100%";
    }
  };

  const CHKxInvalidProportion = ptProportion => {
    if (JSON.stringify(Number(ptProportion)) === "null") {
      return "Proportion ไม่ใช่เปอร์เซ็น";
    }
    if (ptProportion === "") {
      return "กรุณากรอก Proportion (%)";
    }
    if (ptProportion > 100) {
      return "Proportion ไม่สามารถ มากกว่า 100%";
    }
  };

  const CHKxRedLineExpected = ptExpected => {
    if (ptExpected === "") {
      return "fundrec-textInput-option-invalid";
    } else if (ptExpected > 100) {
      return "fundrec-textInput-option-invalid";
    } else if (ptExpected.substr(0, 2).toString() === ".-") {
      return "fundrec-textInput-option-invalid";
    } else if (JSON.stringify(Number(ptExpected)) === "null") {
      return "fundrec-textInput-option-invalid";
    } else {
      return "fundrec-textInput-option";
    }
  };
  const CHKxRedLineVolatility = ptVolatility => {
    if (ptVolatility === "") {
      return "textInput-invalid";
    } else if (ptVolatility > 100) {
      return "textInput-invalid";
    } else if (JSON.stringify(Number(ptVolatility)) === "null") {
      return "textInput-invalid";
    } else {
      return "textInput";
    }
  };
  const CHKxRedLineProportion = ptProportion => {
    if (ptProportion === "") {
      return "textInput-invalid";
    } else if (ptProportion > 100) {
      return "textInput-invalid";
    } else if (JSON.stringify(Number(ptProportion)) === "null") {
      return "textInput-invalid";
    } else {
      return "textInput";
    }
  };

  const tYoutebeCutOnSave = ptYoutube => {
    let tYoutobe = "";
    let nIndex;

    if (ptYoutube !== null && ptYoutube !== "") {
      // -----------check v= ก่อน------------
      if (ptYoutube.indexOf("watch?v=") > 1) {
        nIndex = ptYoutube.indexOf("watch?v=");
        nIndex = nIndex + 8;
        tYoutobe = ptYoutube.substring(nIndex, ptYoutube.length);
        
      } else if (ptYoutube.indexOf("youtu.be/") > 1) {
        nIndex = ptYoutube.indexOf("youtu.be/");
        nIndex = nIndex + 9;
        tYoutobe = ptYoutube.substring(nIndex, ptYoutube.length);
        
      } else {
        tYoutobe = ptYoutube;
      }
    } else {
      tYoutobe = ptYoutube;
    }
    return tYoutobe;
  };
  return (
    <Fragment>
      <div className="fundrec-feed-form-container">
        <div className="fundrec-feed-form-buttons-container">
          <button
            className="fundrec-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
          <button
            type="submit"
            name="ocmSave"
            onClick={() => SETxShwEdtAlr(true)}
            className="content-feed-form-publish-button"
          >
            SAVE
          </button>
          {bIsLoaded ? (
            <div className="funds-list-progress">
              <CircularProgress />
              <p className="funds-list-progress-text">
                {bIsLoaded ? "Retrieving ByRisk Detail..." : null}
              </p>
            </div>
          ) : null}

          {REQStrFundCodeList ? (
            <div className="funds-list-progress">
              <CircularProgress />
              <p className="funds-list-progress-text">
                {REQStrFundCodeList ? "Retrieving ByRisk..." : null}
              </p>
            </div>
          ) : null}

          <WarningModal
            show={oShwEdtAlr}
            onHide={() => SETxShwEdtAlr(false)}
            onHandleGo={() => {
              SETxShwEdtAlr(false);

              // --------SET RiskLevel-----
              let nRiskLvNo = document.getElementsByName("ocbRiskLvOp")[0]
                .value;
              let oRiskLv = aRiskLv.filter(a => a.RiskLvNo === nRiskLvNo);
              let cexpected1 = document.getElementsByName("otbexpected1")[0]
                .value;
              let cexpected2 = document.getElementsByName("otbexpected2")[0]
                .value;
              let cexpected3year = document.getElementsByName(
                "otbexpected3year"
              )[0].value;
              let cVolatility = document.getElementsByName("otbVolatility")[0]
                .value;

              // ----Check main detail
              if (
                cexpected1.substr(0, 2).toString() === ".-" ||
                JSON.stringify(Number(cexpected1)) === "null" ||
                cexpected1 === "" ||
                cexpected1 > 100
              ) {
                SETxShwEmptyAlr(true);
                return;
              }

              if (
                cexpected2.substr(0, 2).toString() === ".-" ||
                JSON.stringify(Number(cexpected2)) === "null" ||
                cexpected2 === "" ||
                cexpected2 > 100
              ) {
                SETxShwEmptyAlr(true);
                return;
              }

              if (
                cexpected3year.substr(0, 2).toString() === ".-" ||
                JSON.stringify(Number(cexpected3year)) === "null" ||
                cexpected3year === "" ||
                cexpected3year > 100
              ) {
                SETxShwEmptyAlr(true);
                return;
              }

              if (
                JSON.stringify(Number(cVolatility)) === "null" ||
                cVolatility === "" ||
                cVolatility > 100
              ) {
                SETxShwEmptyAlr(true);
                return;
              }

              let oSendDataAPI = {
                riskId: RowDataAt.id,
                modify: oNeedAuth?.Email,
                riskLevel: oRiskLv[0]?.RiskLv,
                riskLevelNo: document.getElementsByName("ocbRiskLvOp")[0].value,
                portNameTH: document.getElementsByName("otbPortNameTH")[0]
                  .value,
                portNameEN: document.getElementsByName("otbPortNameEN")[0]
                  .value,
                expected1: cexpected1,
                expected2: cexpected2,
                expected3Year: cexpected3year,
                volatility: cVolatility,
                pdfNameTH: document.getElementsByName("otbPDFNameTH")[0].value,
                pdfNameEN: document.getElementsByName("otbPDFNameEN")[0].value,
                pdfUrl: document.getElementsByName("otbPDFUrl")[0].value,
                youtubeUrl: document.getElementsByName("otbYoutubeUrl")[0]
                  .value,
                vdoShow: oVDOShw,
                description_VDOTH: document.getElementsByName("otbVDODescTH")[0]
                  .value,
                description_VDOEN: document.getElementsByName("otbVDODescEN")[0]
                  .value,
                rule:
                  oVal2 === null
                    ? []
                    : oVal2.map((val) => ({
                        id: val.IdRisk,
                        risk_order:
                          val.ocbRiskOrder2 === "Please choose order"
                            ? null
                            : val.ocbRiskOrder2,
                        name_th: val.otbNameTH2,
                        name_en: val.otbNameEN2,
                        ratio: val.otbPer2,
                        funds:
                          JSON.stringify(val.ocbFundcode2) === "[{}]"
                            ? []
                            : val.ocbFundcode2
                      })),
                description_riskTH: tContentDescRiskTH,
                description_riskEN: tContentDescRiskEN
              };

              // ---when  rule have any text มากกว่า 1---
              let otbNameTH1 = document.getElementsByName("otbNameTH1")[0]
                .value;
              let otbNameEN1 = document.getElementsByName("otbNameEN1")[0]
                .value;
              let otbPer1 = document.getElementsByName("otbPer1")[0].value;
              let ocbTagFundCode1 = oSelFundCodeOp.map(x => ({
                fund_code: x.label,
                fund_no: x.value
              }));

              let ocbRiskOrder1 = [];
              if (selectedItems.length === 0) {
                
                ocbRiskOrder1 = [];
              } else {
                let tOrderTxt = selectedItems.ocbRiskOrder1_add;
                if (
                  tOrderTxt === "Please choose order" ||
                  tOrderTxt === undefined
                ) {
                  ocbRiskOrder1 = [];
                } else {
                  
                  ocbRiskOrder1 = selectedItems.ocbRiskOrder1_add;
                }
              }
              if (
                otbNameTH1 !== "" ||
                otbNameEN1 !== "" ||
                otbPer1 !== "" ||
                ocbTagFundCode1.length > 0 ||
                ocbRiskOrder1.length > 0
              ) {
                
                oSendDataAPI.rule.push({
                  id: tUniID(),
                  risk_order: ocbRiskOrder1,
                  name_th: otbNameTH1,
                  name_en: otbNameEN1,
                  ratio: otbPer1,
                  funds: ocbTagFundCode1
                });
                
              }

              // ----ratio check percentage format----
              let nPercen_Ratio = oSendDataAPI.rule.filter(
                a =>
                  a.ratio === "" ||
                  JSON.stringify(Number(a.ratio)) === "null" ||
                  a.ratio === "" ||
                  a.ratio > 100
              ).length;
              

              if (nPercen_Ratio !== 0) {
                SETxShwEmptyAlr(true);
                return;
              }

              // ----Check Rule array emptry----------
              let nHaveEmpty_NameTH = oSendDataAPI.rule.filter(
                a => a.name_th === ""
              ).length;
              let nHaveEmpty_NameEN = oSendDataAPI.rule.filter(
                a => a.name_en === ""
              ).length;
              let nHaveEmpty_funds = oSendDataAPI.rule.filter(
                a => a.funds.length === 0
              ).length;
              let nHaveEmpty_risk_order = oSendDataAPI.rule.filter(a =>
                a.risk_order == null ? 1 : a.risk_order.length === 0
              ).length;


              if (
                nHaveEmpty_NameTH === 0 &&
                nHaveEmpty_NameEN === 0 &&
                nHaveEmpty_funds === 0 &&
                nHaveEmpty_risk_order === 0
              ) {
                
                REQByRiskEdt({
                  FormData: oSendDataAPI,
                  history
                });
              } else {
                SETxShwEmptyAlr(true);
                return;
              }
            }}
            onHandleCancel={() => {
              SETxShwEdtAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={"Are you sure you want to edit?"}
          />
          <WarningModal
            show={oShwDelCardAlr}
            onHide={() => SETxDelCardAlr(false)}
            onHandleGo={() => {
              SETxDelCardAlr(false);
                            SETxVal2(oVal2.filter(itm => itm.IdUni !== oDelCardName));
            }}
            onHandleCancel={() => {
              SETxDelCardAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={`Are you sure you want to delete?`}
          />
          <AlertModal
            show={oShwEmptyAlr}
            onHide={() => SETxShwEmptyAlr(false)}
            onHandleOK={() => {
              SETxShwEmptyAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={
              "There are incomplete required fields.\nPlease complete them."
            }
          />
        </div>

        <div className="fundrec-feed-form-inputs-containerRisk">
          <div style={{ backgroundColor: "#EDEDED" }}>
            <div style={{ paddingTop: 15, paddingLeft: 40, paddingRight: 40 }}>
              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText ">Port Name (Thai)</span>
                <input
                  value={oVal1.otbPortNameTH}
                  maxLength="100"
                  className="textInput"
                  name="otbPortNameTH"
                  placeholder="Port Name (Thai)"
                  onChange={SETxOnChgTxtVal1("otbPortNameTH")}
                />
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText ">Port Name (English)</span>
                <input
                  value={oVal1.otbPortNameEN}
                  maxLength="100"
                  className="textInput"
                  name="otbPortNameEN"
                  placeholder="Port Name (English)"
                  onChange={SETxOnChgTxtVal1("otbPortNameEN")}
                />
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span
                  style={{
                    paddingRight: 150,
                    fontFamily: "Lato",
                    color: "#000000"
                  }}
                >
                  Risk level
                </span>

                <div style={{ width: 599 }}>
                  <Select
                    name="ocbRiskLvOp"
                    options={oRiskLvOp}
                    defaultValue={{
                      value: RowDataAt.risklevelno,
                      label:
                        RowDataAt.risk_level.toString() +
                        " - " +
                        RowDataAt.risk_level
                    }}
                    maxMenuHeight={200}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Risk level"
                  />
                </div>
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span
                  style={{ width: 210, fontFamily: "Lato", color: "#000000" }}
                >
                  Expected Return 3Y (%)
                </span>
                <input
                  className={CHKxRedLineExpected(oVal1.otbexpected3year)}
                  maxLength="6"
                  value={oVal1.otbexpected3year}
                  name="otbexpected3year"
                  placeholder="0.00"
                  onKeyPress={e => {
                    if (!/\.|-|[0-9\b]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={SETxOnChgTxtVal1("otbexpected3year")}
                  style={{ width: 598 }}
                />
              </div>

              <div className="fundrec-invalid">
                {CHKxInvalidexpected3year(oVal1.otbexpected3year)}
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span
                  style={{ width: 210, fontFamily: "Lato", color: "#000000" }}
                >
                  Expected Return (%)
                </span>
                <input
                  className={
                    CHKxRedLineExpected(oVal1.otbexpected1)
                  }
                  maxLength="6"
                  value={oVal1.otbexpected1}
                  name="otbexpected1"
                  placeholder="0.00"
                  onKeyPress={e => {
                    if (!/\.|-|[0-9\b]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={SETxOnChgTxtVal1("otbexpected1")}
                  style={{ width: 100 }}
                />
                <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>
                <input
                  className={
                    CHKxRedLineExpected(oVal1.otbexpected2)
                  }
                  maxLength="6"
                  value={oVal1.otbexpected2}
                  name="otbexpected2"
                  placeholder="0.00"
                  onKeyPress={e => {
                    if (!/\.|-|[0-9\b]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={SETxOnChgTxtVal1("otbexpected2")}
                  style={{ width: 100 }}
                />
              </div>

              <div className="fundrec-invalid">
                {CHKxInvalidexpected1(oVal1.otbexpected1)}
              </div>
              <div className="fundrec-invalid">
                {CHKxInvalidexpected2(oVal1.otbexpected2)}
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText ">Return Volatility (%)</span>
                <input
                  value={oVal1.otbVolatility}
                  onKeyPress={e => {
                    if (!/\.|[0-9\b]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  maxLength="5"
                  className={
                    CHKxRedLineVolatility(oVal1.otbVolatility)
                  }
                  name="otbVolatility"
                  placeholder="0.00"
                  onChange={SETxOnChgTxtVal1("otbVolatility")}
                />
              </div>
              <div className="fundrec-invalid">
                {CHKxInvalidVolatility(oVal1.otbVolatility)}
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText">PDF Name (Thai)</span>
                <input
                  value={oVal1.otbPDFNameTH}
                  maxLength="100"
                  className="textInput"
                  name="otbPDFNameTH"
                  placeholder="PDF Name (Thai)"
                  onChange={SETxOnChgTxtVal1("otbPDFNameTH")}
                />
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText ">PDF Name (English)</span>
                <input
                  value={oVal1.otbPDFNameEN}
                  maxLength="100"
                  className="textInput"
                  name="otbPDFNameEN"
                  placeholder="PDF Name (English)"
                  onChange={SETxOnChgTxtVal1("otbPDFNameEN")}
                />
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText ">PDF URL</span>
                <input
                  value={oVal1.otbPDFUrl}
                  className="textInput"
                  name="otbPDFUrl"
                  placeholder="PDF URL"
                  onChange={SETxOnChgTxtVal1("otbPDFUrl")}
                />
              </div>

              <div className="tag-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText" style={{ width: 210 }}>
                  Youtube URL
                </span>
                <label className="conFd-pre-label">https://youtu.be/</label>
                <input
                  className="conFd-pre-textInput"
                  name="otbYoutubeUrl"
                  style={{ width: 342 }}
                  value={oVal1.otbYoutubeUrl}
                  onChange={SETxOnChgTxtVal1("otbYoutubeUrl")}
                />
                <button
                  type="button"
                  className="srchRec-preview-button"
                  style={{ marginRight: 0 }}
                  onClick={() => {
                    let tUlrCode = tYoutebeCutOnSave(oVal1.otbYoutubeUrl);
                    tUlrCode = "https://youtu.be/" + tUlrCode;
                    window.open(tUlrCode, "_blank");
                  }}
                >
                  PREVIEW
                </button>
              </div>
              <div>
                <label
                  className="conFd-label-warning"
                  style={{ paddingLeft: 210 }}
                >
                  *รองรับเฉพาะลิงก์จาก Youtube เท่านั้น (กรอกเฉพาะ Code)
                </label>
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText ">VDO Description (Thai)</span>
                <input
                  value={oVal1.otbVDODescTH}
                  className="textInput"
                  name="otbVDODescTH"
                  placeholder="VDO Description (Thai)"
                  onChange={SETxOnChgTxtVal1("otbVDODescTH")}
                />
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span className="labelInputText ">
                  VDO Description (English)
                </span>
                <input
                  value={oVal1.otbVDODescEN}
                  className="textInput"
                  name="otbVDODescEN"
                  placeholder="VDO Description (English)"
                  onChange={SETxOnChgTxtVal1("otbVDODescEN")}
                />
              </div>

              <div className="fundrec-feed-form-input-spacer" />
              <div className="labelInput">
                <span
                  style={{
                    paddingRight: 140,
                    fontFamily: "Lato",
                    color: "#000000"
                  }}
                >
                  VDO Status
                </span>
                <input
                  name="orbVDOShw1"
                  type="radio"
                  value="show"
                  checked={oVDOShw === "show"}
                  onChange={SETxOnChgRaioAdd}
                />
                <span style={{ marginRight: 20, marginLeft: 5 }}>Show</span>
                <input
                  name="orbVDOShw1"
                  type="radio"
                  value="hide"
                  checked={oVDOShw === "hide"}
                  onChange={SETxOnChgRaioAdd}
                />
                <span style={{ marginLeft: 5 }}>Hide</span>
              </div>

              <div className="fundrec-feed-form-input-spacer" />
            </div>
          </div>

          {/*------------------------Investment plan---------------------------*/}
          <div className="fundrec-feed-form-input-spacer" />
          <div className="conFd-feed-form-switch-button-container">
            <button
              onClick={() => SETxLang(EN)}
              className={
                oLang === EN
                  ? "selected-switch-button"
                  : "unselected-switch-button"
              }
              type="button"
            >
              {EN}
            </button>
            <button
              onClick={() => SETxLang(TH)}
              className={
                oLang === TH
                  ? "selected-switch-button"
                  : "unselected-switch-button"
              }
              type="button"
            >
              {TH}
            </button>
          </div>
          <div className="conFd-feed-form-input-spacer" />
          <span className="labelInputText ">Investment plan</span>

          <div className="conFd-feed-form-input-spacer" />
          {/* ENGLISH */}
          {oLang === "ENGLISH" ? (
            <JoditEditor
              ref={editor}
              value={tContentDescRiskEN}
              config={oConfig}
              onBlur={EVTxUPDContentDescRiskEN}
              name="otbEdtEN"
              onChange={() => {}}
            />
          ) : (
            <JoditEditor
              ref={editor}
              value={tContentDescRiskTH}
              config={oConfig}
              onBlur={EVTxUPDContentDescRiskTH}
              name="otbEdtTH"
              onChange={() => {}}
            />
          )}

          <div className="conFd-feed-form-input-spacer" />
          <span
            style={{ fontWeight: "bold", fontSize: 16 }}
            className="labelInputText "
          >
            Fund Recommendation
          </span>

          {/*------------------------Card---------------------------*/}
          <div className="fundrec-feed-form-input-spacer" />
          {REQStrFundCodeList === false
            ? oVal2.map((x, index) => (
                <div
                  key={x.IdUni}
                  style={{ backgroundColor: "#EDEDED", marginBottom: 24 }}
                >
                  <button
                    className="fundrec-feed-form-delete-button"
                    onClick={DELxCard}
                  >
                    <img
                      name={x.IdUni}
                      src={Images.closeButton.close}
                      style={{ marginBottom: 3, width: 18, height: 18 }}
                      alt="close"
                    />
                  </button>

                  <div
                    style={{
                      paddingTop: 15,
                      paddingLeft: 40,
                      paddingRight: 40
                    }}
                  >

                    <div className="fundrec-feed-form-input-spacer" />
                    <div className="labelInput">
                      <span className="labelInputText ">
                        Fund group Name (Thai)
                      </span>
                      <input
                        className={
                          x.otbNameTH2 !== ""
                            ? "textInput"
                            : "textInput-invalid"
                        }
                        name="otbNameTH2"
                        placeholder="Fund group Name (Thai)"
                        defaultValue={x.otbNameTH2}
                        onChange={UPDxOnChgTxt(index, x.IdUni)}
                      />
                    </div>

                    <div className="fundrec-invalid">
                      {x.otbNameTH2 !== ""
                        ? ""
                        : "กรุณากรอก Fund group Name (Thai)"}
                    </div>

                    <div className="fundrec-feed-form-input-spacer" />
                    <div className="labelInput">
                      <span className="labelInputText ">
                        Fund group Name (English)
                      </span>
                      <input
                        className={
                          x.otbNameEN2 !== ""
                            ? "textInput"
                            : "textInput-invalid"
                        }
                        name="otbNameEN2"
                        placeholder="Fund group Name (English)"
                        defaultValue={x.otbNameEN2}
                        onChange={UPDxOnChgTxt(index, x.IdUni)}
                      />
                    </div>
                    <div className="fundrec-invalid">
                      {x.otbNameEN2 !== ""
                        ? ""
                        : "กรุณากรอก Fund group Name (English)"}
                    </div>

                    <div className="fundrec-feed-form-input-spacer" />
                    <div className="labelInput">
                      <span
                        style={{
                          paddingRight: 120,
                          fontFamily: "Lato",
                          color: "#000000"
                        }}
                      >
                        Funds in group
                      </span>
                      <div style={{ width: 595 }}>
                        <Select
                          defaultValue={oFunCodeOp.filter(a =>
                            x.ocbFundcode2.some(x => a.label === x.fund_code)
                          )}
                          isMulti
                          name={x.IdUni + "_ocbTagFundCode"}
                          options={oFunCodeOp}
                          maxMenuHeight={150}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Tag Fund"
                          onChange={UPDxOnChgSelect(index, x.IdUni)}
                        />
                      </div>
                    </div>
                    {
                      <div className="fundrec-invalid">
                        {x.ocbFundcode2.length === 0 ||
                        JSON.stringify(x.ocbFundcode2) === "[{}]"
                          ? "กรุณาเลือก Funds in group"
                          : ""}
                      </div>
                    }

                    <div className="fundrec-feed-form-input-spacer" />
                    <div className="labelInput">
                      <div
                        style={{
                          width: 250,
                          fontFamily: "Lato",
                          color: "#000000"
                        }}
                      >
                        Proportion (%)
                      </div>
                      <input
                        className={CHKxRedLineProportion(
                          (Math.round(x.otbPer2 * 100) / 100).toFixed(2)
                        )}
                        name="otbPer2"
                        placeholder="0.00"
                        defaultValue={(
                          Math.round(x.otbPer2 * 100) / 100
                        ).toFixed(2)}
                        onChange={UPDxOnChgTxt(index, x.IdUni)}
                        maxLength="5"
                        onKeyPress={e => {
                          if (!/\.|[0-9\b]+$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="fundrec-invalid">
                      {CHKxInvalidProportion(
                        (Math.round(x.otbPer2 * 100) / 100).toFixed(2)
                      )}
                    </div>

                    <div className="fundrec-feed-form-input-spacer" />
                    <div className="labelInput">
                      <span
                        style={{
                          paddingRight: 171,
                          fontFamily: "Lato",
                          color: "#000000"
                        }}
                      >
                        Order
                      </span>
                      <div style={{ width: 599 }}>
                        <DropDown
                          name={"Name_" + index}
                          selectItem={selectItem}
                          meals={getFilteredMeals("Name_" + index)}
                          ocbRiskOrder2={x.ocbRiskOrder2}
                          oVal2={oVal2}
                        />
                      </div>
                    </div>
                    {
                      <div className="fundrec-invalid">
                        {x.ocbRiskOrder2 === null ? "กรุณาเลือก Order" : ""}
                      </div>
                    }
                    <div className="fundrec-feed-form-input-spacer" />
                  </div>
                </div>
              ))
            : null}
          {/*------------------------Input Card---------------------------*/}

          <div
            style={{
              backgroundColor: "#EDEDED",
              paddingLeft: 40,
              paddingRight: 40
            }}
          >
            <div className="fundrec-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Fund group Name (Thai)</span>
              <input
                className="textInput"
                name="otbNameTH1"
                placeholder="Fund group Name (Thai)"
              />
            </div>
            <div className="fundrec-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Fund group Name (English)</span>
              <input
                className="textInput"
                name="otbNameEN1"
                placeholder="Fund group Name (English)"
              />
            </div>

            <div className="fundrec-feed-form-input-spacer" />
            <div className="labelInput">
              <span
                style={{
                  paddingRight: 120,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Funds in group
              </span>
              <div style={{ width: 595 }}>
                <Select
                  isMulti
                  name="ocbTagFundCode1"
                  options={oFunCodeOp}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Tag Fund"
                  onChange={SETxOnChgSelect}
                  value={oSelFundCodeOp}
                />
              </div>
            </div>

            <div className="fundrec-feed-form-input-spacer" />
            <div className="labelInput">
              <span
                style={{
                  width: 250,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Proportion (%)
              </span>
              <input
                className={
                  CHKxRedLineProportion(tPer1)
                }
                name="otbPer1"
                placeholder="0.00"
                maxLength="5"
                defaultValue={tPer1}
                onChange={UPDxOnChgTxtPer1}
                onKeyPress={event => {
                  if (!/\.|[0-9\b]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="fundrec-invalid">
              {CHKxInvalidProportion(tPer1)}
            </div>

            <div className="fundrec-feed-form-input-spacer" />
            <div className="labelInput">
              <span
                style={{
                  paddingRight: 171,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Order
              </span>
              <div style={{ width: 599 }}>
                <DropDown
                  name="ocbRiskOrder1_add"
                  id="ocbRiskOrder1_add"
                  selectItem={selectItem}
                  meals={getFilteredMeals("ocbRiskOrder1_add")}
                  ocbRiskOrder2={""}
                  oVal2={oVal2}
                />
              </div>
            </div>

            <div className="fundrec-feed-form-input-spacer" />
          </div>
          <button
            name="ocmAdd"
            style={{
              marginTop: 20,
              fontSize: 12,
              width: 110,
              height: 40,
              paddingTop: 4
            }}
            className="fundrec-detail-cancel"
            onClick={CRTxAdd}
          >
            <img
              src={Images.buttonIcon.plus}
              style={{
                marginBottom: 3,
                marginRight: 5,
                width: 16,
                height: 16
              }}
              alt="delete"
            />
            ADD RULE
          </button>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundRecRiskDetailReducer
  }),
  {
    REQByRiskD,
    REQByRiskEdt,
    CleanUpByRiskEdt,
    REQFundCodeList
  }
);

export default compose(
  withConnect,
  withRouter
)(FundRecRiskDetail);
