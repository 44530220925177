import { createReducer } from "redux-act";

import {
  // --------------Register Log-----------------
  REQRegisLogList,
  RESRegisLogList,
  FailRegisLogList,
  CleanUpRegisLogList,
  // --------------Ndid Log-----------------
  REQCusDetailList,
  RESCusDetailList,
  FailCusDetailList,
  CleanUpCusDetailList
} from "./actions";

let tRegisLogType="";

const initialState = {
  // --------------Register Log-----------------
  REQStrRegisLogList: false,
  RegisLogList: [],
  errorMessage: false,
  totalRegisLog: 0,

  // --------------Cusdetail Log-----------------
  REQStrCusDetailList: false,
  CusDetailList: [],
  errorMessageCusDetail: false,
  totalCusDetail: 0
};

const regisLogListReducer = createReducer(
  {
   // --------------Register Log-----------------
    [REQRegisLogList]: (state, payload) => {
      tRegisLogType=payload.tRegisLogType;

      return {
        ...state,
        REQStrRegisLogList: true
      };
    },
   
    [RESRegisLogList]: (state, payload) => {
      return {
        ...state,
        REQStrRegisLogList: false,
        RegisLogList: tRegisLogType==="counter_service"?
              payload.RegisterLogCounterService:payload.RegisterLogNDID,
        totalRegisLog: tRegisLogType==="counter_service"?
              payload.TotalRegisterLogCounterService:payload.TotalRegisterLogNDID
      };
    },
     
    [FailRegisLogList]: (state) => {
      return {
        ...state,
        REQStrRegisLogList: false,
        RegisLogList: [],
        totalRegisLog: 0
      };
    } , 
    [CleanUpRegisLogList]: () => {
      return initialState;
    },
  // --------------Cusdetail Log-----------------
  [REQCusDetailList]: (state) => {
    return {
      ...state,
      REQStrCusDetailList: true
    };
  },

  [RESCusDetailList]: (state, payload) => {
    return {
      ...state,
      REQStrCusDetailList: false,
      CusDetailList: payload.CusDetail,
      totalCusDetail: payload.TotalCusDetail

    };
  },
  
  [FailCusDetailList]: (state) => {
    return {
      ...state,
      REQStrCusDetailList: false,
      CusDetailList: [],
      totalCusDetail: 0
    };
  } , 
  [CleanUpCusDetailList]: () => {
    return initialState;
  }
  },

  initialState
);

export default regisLogListReducer;
