export const list_of_columns = [
  {
    Header: "NO.",
     width: 30,
     disableSortBy: true
  },
  {
    Header: "TAG NAME",
    accessor: "tagNameShow",
    width: 185
  },
  {
    Header: "MODIFIED BY",
    accessor: "modify",
    width: 135
  },
  {
    Header: "CREATED DATE",
    accessor: "createDate",
    width: 125
  },
  {
    Header: "MODIFIED DATE",
    accessor: "modifyDate",
    width: 120
  },
  {
    Header: "TYPE",
    accessor: "type",
    width: 70
  },
  {
    Header: "STATUS",
    accessor: "status",
    width: 80
  }
];
