import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { WarningModal } from "../../components/Modal";
import { REQConFdSortList,CleanUpConFdSortList,REQConFdSortEdt } from "./redux/actions";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import "./style.scss";

function ContentFeedSortList(props) {
    const {
        REQConFdSortList,
        REQStrConFdSortList,
        REQConFdSortEdt,
        ConFdSrtList,
        history,
    } = props;

    const GETxConFdSort = () => {
        REQConFdSortList();
    };

    useEffect(() => {
        GETxConFdSort();
    }, []);

    const [oShw, SETxShwModelAlert] = useState(false);

    const SortableItem = SortableElement(({ value, indexShw }) => (
        // prp เพิ่มเติมได้จาก  SortableItem
        <li className="ListShw-li" tabIndex={0}>
             <span style={{ width: 53 }}>{indexShw}</span>{value}
        </li>

    ));
    
    const aItmOp = 
        ConFdSrtList.length===0?[]:
        ConFdSrtList[0].contentFeed.map((c,index) => ({
          "No": index+1,
          "ConFdName": c
        }));    
       
   const [aItm, SETaItm] = useState([]);
    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul className="ListShw">
                {
                items?
                items.map((value, index) => (
                    <SortableItem
                        key={`item-${value.No}`}
                        valId={value.No}
                        index={index}
                        indexShw={index+1}
                        value={value.ConFdName}
                    />
                )):null
                }
            </ul>

        );
    });

    const [oDisabled, SETxDisabled] = useState(true);
    function SETxClick() {
        SETxDisabled(!oDisabled);
    }
    function SEtxOnSortEnd({ oldIndex, newIndex }) {
      if(aItm.length===0)
      {
        SETaItm(arrayMove(aItmOp, oldIndex, newIndex));
      }else
      {
        SETaItm(arrayMove(aItm, oldIndex, newIndex));
      }
    }


    return (
        <Fragment>
            <div className="funds-buttons-container">
                <button className="conFdS-create-fund-button" name="ocmCancel" style={{ width: 120, marginRight: 10 }}
                    hidden={oDisabled}
                    onClick={SETxClick}>
                    CANCEL
                </button>
                <button className="conFdS-save-button" name="ocmSave" style={{ width: 120 }}
                     onClick={() => SETxShwModelAlert(true)}
                    hidden={oDisabled}
                >
                    SAVE
                </button>
                <button className="conFdS-create-fund-button" name="ocmEdit" style={{ width: 120 }}
                    hidden={!oDisabled}
                    onClick={SETxClick}>
                    EDIT
                </button>
            </div>


         <WarningModal
          show={oShw}
          onHide={() => SETxShwModelAlert(false)}
          onHandleGo={() => {
            SETxShwModelAlert(false);
            SETxDisabled(!oDisabled);
             REQConFdSortEdt({
              FormData:aItm.map(x=>x.ConFdName),
              IdForAPI:ConFdSrtList[0].id,
              history
            });
          }}
          onHandleCancel={() => {
            SETxDisabled(oDisabled);
            SETxShwModelAlert(false);
          }}
          type={"Disapprove"}
          status={"1"}
          title={"Are you sure you want to save?"}
        />

            {REQStrConFdSortList ? (
                <div className="funds-list-progress">
                    <CircularProgress />
                    <p className="funds-list-progress-text">
                        {REQStrConFdSortList ? "Retrieving Content feed sort list..." : null}
                    </p>
                </div>
            ) : null }
            <br />
          
            <div className="funds-list-container">
                <table className="TbShw">
                    <tbody>
                        <tr>
                            <th style={{ paddingLeft: 20 }}>NO.</th>
                            <th style={{ paddingLeft: 38 }}>CONTENT FEED</th>
                        </tr>
                    </tbody>
                </table>
         
                {
                <SortableList     
                    items={aItm.length===0?aItmOp:aItm}
                    onSortEnd={SEtxOnSortEnd}
                    useDragHandle={oDisabled}
                />
                }
                



            </div>
        </Fragment>
    );
}
const withConnect = connect(
    state => ({
        ...state.conFdSortListReducer
    }),
    {
        REQConFdSortList,
        CleanUpConFdSortList,
        REQConFdSortEdt
    }
);
export default compose(
    withConnect,
    withRouter
)(ContentFeedSortList);

