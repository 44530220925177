import { createAction } from "redux-act";

// -----------------FundRecommend Risk -----------
export const REQFundRecRiskList = createAction("REQUEST_BYRISK_LIST");
export const RESFundRecRiskList = createAction("RECEIVE_BYRISK_LIST");
export const FailFundRecRiskList = createAction("FAILED_BYRISK_LIST");
export const CleanUpFundRecRiskList = createAction("CLEANUP_BYRISK_LIST");


// -----------------FundRecommend Risk -----------
export const REQFundRiskChgSta = createAction("REQUEST_BYRISK_STA");
export const RESFundRiskChgSta = createAction("RECEIVE_BYRISK_STA");
export const FailFundRiskChgSta = createAction("FAILED_BYRISK_STA");
export const CleanUpFundRiskChgSta = createAction("CLEANUP_BYRISK_STA");