import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  REQRegisDeviceList,
  RESRegisDeviceList,
  FailRegisDeviceList
} from "./actions";

function* fetchRegisDeviceListWorker() {

  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/registerDeviceList`,
      {
        method: "GET",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    const response = yield request.json();

    if (response && response.data) {
      yield put(RESRegisDeviceList(response.data));
    } else {
            yield put(FailRegisDeviceList());
    }
  } catch (error) {
    
    yield put(FailRegisDeviceList());
  }
}

export function* regisDeviceListSaga() {
  yield all([takeLatest(REQRegisDeviceList, fetchRegisDeviceListWorker)]);
}
