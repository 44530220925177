import { createAction } from "redux-act";
// --------------------Import view--------------
export const REQImpRGT2B2CList = createAction("REQUEST_IMPRGT2B2C_LIST");
export const RESImpRGT2B2CList = createAction("RECEIVE_IMPRGT2B2C_LIST");
export const FailImpRGT2B2CList = createAction("FAILED_IMPRGT2B2C_LIST");
export const CleanUpImpRGT2B2CList= createAction("CLEANUP_IMPRGT2B2C_LIST");
// --------------------UPD--------------
export const REQImpRGT2B2CUPD = createAction("REQUEST_IMPRGT2B2C_UPD");
export const RESImpRGT2B2CUPD = createAction("RECEIVE_IMPRGT2B2C_UPD");
export const FailImpRGT2B2CUPD = createAction("FAILED_IMPRGT2B2C_UPD");
export const CleanUpImpRGT2B2CUPD= createAction("CLEANUP_IMPRGT2B2C_UPD");