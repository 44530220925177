import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  // ------EasyInvest---
  REQEasyInv,
  RESEasyInv,
  FailEasyInv,
  // ------UntHld---
  REQUntHld,
  RESUntHld,
  FailUntHld
} from "./actions";
import moment from "moment";

function* fetchEasyInvListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const {
      page, tEasyInvType,  tStartD, tEndD, bAllD,tSrch,tOpSrch, tSortH
    } = payload;
    let tEmailSrch = "";
    let tUsrNameSrch = "";
    let tUUIDSrch = "";
    let tNameENSrch = "";
    let tNameTHSrch = "";
    let tIDNoSrch = "";
    let tMobileSrch = "";

    const tStartD1 = moment(tStartD).format("YYYY-MM-DD");
    let tEndD1 = moment(tEndD).format("YYYY-MM-DD");
    tEndD1 = tEndD1 + " 23:59";

    switch (tOpSrch) {
      case "Email":
        tEmailSrch = tSrch;
        break;
      case "Username":
        tUsrNameSrch = tSrch;
        break;
      case "UUID":
        tUUIDSrch = tSrch;
        break;
      case "English Name":
        tNameENSrch = tSrch;
        break;
      case "Thai Name":
        tNameTHSrch = tSrch;
        break;
      case "ID No. / Passport No.":
        tIDNoSrch = tSrch;
        break;
      case "Mobile":
        tMobileSrch = tSrch;
        break;
      default:
        break;
    }

     const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/analyticUserAccountDetail?page=${page}`,
      {
        method: "POST",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          EasyInvestConversionType: tEasyInvType,
          startDate: tStartD1,
          endDate: tEndD1,
          allDate: bAllD,
          emailSrch: tEmailSrch,
          usernameSrch: tUsrNameSrch,
          uuidSrch: tUUIDSrch,
          nameENSrch: tNameENSrch,
          nameTHSrch: tNameTHSrch,
          idnumberSrch: tIDNoSrch,
          mobileSrch: tMobileSrch,

          sortH: tSortH
        })
      }
    );

    const response = yield request.json();
    
    if (response) {
      let aNewRES = {
        ...response,
        UISearch: {
          tStartD: tStartD,
          tEndD: tEndD,
          bAllD: bAllD
        }
      };

      yield put(RESEasyInv(aNewRES));
    } else {
      yield put(RESEasyInv());
    }
  } catch (error) {
    yield put(FailEasyInv());
  }
}
function* fetchUntHldListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const {
      page,
      tAccStatusCodeType,
      tStartD,
      tEndD,
      bAllD,
      tSrch,
      tOpSrch,
      tSortH
    } = payload;
    let tEmailSrch = "";
    let tUsrNameSrch = "";
    let tUUIDSrch = "";
    let tNameENSrch = "";
    let tNameTHSrch = "";
    let tIDNoSrch = "";
    let tMobileSrch = "";

    const tStartD1 = moment(tStartD).format("YYYY-MM-DD");
    let tEndD1 = moment(tEndD).format("YYYY-MM-DD");
    tEndD1 = tEndD1 + " 23:59";

    switch (tOpSrch) {
      case "Email":
        tEmailSrch = tSrch;
        break;
      case "Username":
        tUsrNameSrch = tSrch;
        break;
      case "UUID":
        tUUIDSrch = tSrch;
        break;
      case "English Name":
        tNameENSrch = tSrch;
        break;
      case "Thai Name":
        tNameTHSrch = tSrch;
        break;
      case "ID No. / Passport No.":
        tIDNoSrch = tSrch;
        break;
      case "Mobile":
        tMobileSrch = tSrch;
        break;
      default:
        break;
    }

    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/analyticUnitHolderDetail?page=${page}`,
      {
        method: "POST",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          AccStatusCodeType: tAccStatusCodeType,
          startDate: tStartD1,
          endDate: tEndD1,
          allDate: bAllD,
          emailSrch: tEmailSrch,
          usernameSrch: tUsrNameSrch,
          uuidSrch: tUUIDSrch,
          nameENSrch: tNameENSrch,
          nameTHSrch: tNameTHSrch,
          idnumberSrch: tIDNoSrch,
          mobileSrch: tMobileSrch,

          sortH: tSortH
        })
      }
    );

    const response = yield request.json();
    
    if (response) {
      let aNewRES = {
        ...response,
        UISearch: {
          tStartD: tStartD,
          tEndD: tEndD,
          bAllD: bAllD
        }
      };

      yield put(RESUntHld(aNewRES));
    } else {
      yield put(RESUntHld());
    }
  } catch (error) {
    yield put(FailUntHld());
  }
}

export function* analyticDetailSaga() {
  yield all([
    takeLatest(REQEasyInv, fetchEasyInvListWorker),
    takeLatest(REQUntHld, fetchUntHldListWorker)
  ]);
}
