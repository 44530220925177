import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  REQTagList,
  RESTagList,
  FailTagList,
} from "./actions";

function* fetchTagListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/tag/list`,
      {
        method: "GET",  
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    const response = yield request.json();
    if (response && response.data) {
      yield put(RESTagList(response.data));
    } else {
      
      yield put(FailTagList());
    }

  } catch (error) {
    
    yield put(FailTagList());
  }
}

export function* tagListSaga() {
  yield all([
    takeLatest(REQTagList, fetchTagListWorker)  
  ]);
}
