import { createReducer } from "redux-act";

import {
  // --------------EasyInvest Detail-----------------
  REQEasyInv,
  RESEasyInv,
  FailEasyInv,
  CleanUpEasyInv,
    // --------------UntHld Detail-----------------
  REQUntHld,
  RESUntHld,
  FailUntHld,
  CleanUpUntHld
} from "./actions";

const initialState = {
  REQStrEasyInvList: false,
  EasyInvList: [],
  totalEasyInvList: 0,

  REQStrUntHldList: false,
  UntHldList: [],
  totalUntHldList: 0
};
const analyticDetailReducer = createReducer(
  {
    // --------------EasyInvest Detail-----------------
    [REQEasyInv]: (state) => {
      return {
        ...state,
        REQStrEasyInvList: true
      };
    },
   
    [RESEasyInv]: (state, payload) => {
      return {
        ...state,
        REQStrEasyInvList: false,
        EasyInvList: payload.Results,
        totalEasyInvList: payload.TotalEasyInvestConversion,
        UISearch:payload.UISearch
      };
    },
     
    [FailEasyInv]: (state) => {
      return {
        ...state,
        REQStrEasyInvList: false,
        EasyInvList: [],
        totalEasyInvList: 0
      };
    } , 
    [CleanUpEasyInv]: () => {
      return initialState;
    },
  // --------------UntHld Detail-----------------
    [REQUntHld]: (state) => {
      //
      return {
        ...state,
        REQStrUntHldList: true
      };
    },
   
    [RESUntHld]: (state, payload) => {
     
      return {
        ...state,
        REQStrUntHldList: false,
        UntHldList: payload.Results,
        totalUntHldList: payload.TotalUnitHolder,
        UISearch:payload.UISearch
      };
    },
     
    [FailUntHld]: (state) => {
      return {
        ...state,
        REQStrUntHldList: false,
        UntHldList: [],
        totalUntHldList: 0
      };
    } , 
    [CleanUpUntHld]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default analyticDetailReducer;
