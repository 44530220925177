import { createReducer } from "redux-act";

import {
  MEMFundRecCodiDetailEdt,
    // ---FundCode----
    REQFundCode,
    RESFundCode,
    FailFundCode,
  // --Get Option Risk----
    REQRisk,
    RESRisk,
    FailRisk,
  // --Get Option Occu----
    REQOccu,
    RESOccu,
    FailOccu,
  // --Get Option Income----
    REQIncome,
    RESIncome,
    FailIncome,

  // --Edit Codi---
  REQByCodiEdt,
  RESByCodiEdt,
  FailByCodiEdt,
  // --Create Codi---
  REQByCodiCrt,
  RESByCodiCrt,
  FailByCodiCrt,
  CleanUpByCodiEdt
} from "./actions";

const initialState = {
  // --FundRecommend Codi------
  ByCodiD: [],
  REQStrByCodi: false,
  // --Get Option Risk----
  FundCode: [],
  totalFund: 0,
  // --Get Option Risk----
  Risk: [],
  totalRisk: 0,
  // --Get Option Occupation----
    Occu: [],
    totalOccu: 0,
// --Get Option Income----
    Income: [],
    totalIncome: 0
};

const fundRecCodiDetailReducer = createReducer(
  {
    // -------FundRecommend Codi Edit------------
    [MEMFundRecCodiDetailEdt]: (state, payload) => {
      console.log("MEMFundRecCodiDetailEdt >>> payload:",payload);
      return {
        ...state,
        mode: payload.mode,
        RowDataAt: payload.RowDataAt

      };
    },
    // ---------By Codi Detail------
 
    // ---------By Codi Edit------
    [REQByCodiEdt]: (state) => {
      
      return {
        ...state,
        REQStrByCodi: true
      };
    },
    [RESByCodiEdt]: (state) => {
      
      return {
        ...state,
        REQStrByCodi: false
      };
    },
    [FailByCodiEdt]: (state) => {
      return {
        ...state,
        REQStrByCodi: false,
        ByCodiD: [],
        totalByCodiD: 0
      };
    },
    // ---------By Codi Create------
    [REQByCodiCrt]: (state) => {
      
      return {
        ...state,
        REQStrByCodi: true
      };
    },
    [RESByCodiCrt]: (state) => {
      
      return {
        ...state,
        REQStrByCodi: false
      };
    },
    [FailByCodiCrt]: (state) => {
      return {
        ...state,
        REQStrByCodi: false,
        ByCodiD: [],
        totalByCodiD: 0
      };
    },
    // ----------Get Option Risk-------
    [REQRisk]: (state) => {
      
      return {
        ...state,
        REQStrByCodi: true
      };
    },
    [RESRisk]: (state, payload) => {
      
      return {
        ...state,
        REQStrByCodi: false,
        Risk: payload.data,
        totalRisk: payload.total
      };
    },
    [FailRisk]: (state) => {
      return {
        ...state,
        REQStrByCodi: false,
        Risk: [],
        totalRisk: 0
      };
    },
    // --------Get Fundcode-----
    [REQFundCode]: (state) => {
      return {
        ...state,
        REQStrByCodi: true
      };
    } ,
    [RESFundCode]: (state, payload) => {
      return {
        ...state,
        REQStrByCodi: false,
        FundCode: payload.data,
        totalFund: payload.data.total
      };
    },  
    [FailFundCode]: (state) => {
      return {
        ...state,
        REQStrByCodi: false,
        FundCode: [],
        totalFund: 0
      };
    },

    // ----------Get Occupation-------
    [REQOccu]: (state) => {
      
      return {
        ...state,
        REQStrByCodi: true
      };
    },
    [RESOccu]: (state, payload) => {
      return {
        ...state,
        REQStrByCodi: false,
        Occu: payload,
        totalOccu: payload.total
      };
    },
    [FailOccu]: (state) => {
      return {
        ...state,
        REQStrByCodi: false,
        Occu: [],
        totalOccu: 0
      };
    },
    // ----------Get Income-------
    [REQIncome]: (state) => {
      
      return {
        ...state,
        REQStrByCodi: true
      };
    },
    [RESIncome]: (state, payload) => {
    
      return {
        ...state,
        REQStrByCodi: false,
        Income: payload,
        totalIncome: payload.total
      };
    },
    [FailIncome]: (state) => {
      return {
        ...state,
        REQStrByCodi: false,
        Income: [],
        totalIncome: 0
      };
    },
        [CleanUpByCodiEdt]: () => {
      return initialState;
    }

  },
  initialState
);

export default fundRecCodiDetailReducer;




