export const aColNDID = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 40
  },
  {
    Header: "ID",
    accessor: "id",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 50
  },
  {
    Header: "USER NAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:0
  },
  {
    Header: "EMAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:0
  },
  {
    Header: "CUSTOMER UUID",
    accessor: "customer_uuid",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10
  },
 {
    Header: "CREATED AT",
    accessor: "created_at",
    disableSortBy: false,
    display:"",
    textAlign:"left",
    width: 130,
    paddingLeft:10
  },
  {
    Header: "EXPORT",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 80,
    paddingLeft:10
  },
  {
    Header: "STATUS CODE",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:10
  }

];

export const aColCounterService = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 40
  },
  {
    Header: "ID",
    accessor: "id",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 50
  },
  {
    Header: "ID TRAN",
    accessor: "transaction_id",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 80
  },
 {
    Header: "USER NAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:0
  },
   {
    Header: "EMAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:0
  },
  {
    Header: "CUSTOMER UUID",
    accessor: "uuid",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10
  },
  {
    Header: "CREATED AT",
    accessor: "created_at",
    disableSortBy: false,
    display:"",
    textAlign:"left",
    width: 130,
    paddingLeft:10
  },
  {
    Header: "EXPORT",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 80,
    paddingLeft:10
  },
  {
    Header: "STATUS CODE",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:10
  }

];

export const aColCusdetail = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 40
  },
  {
    Header: "USER NAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:0
  } ,
  {
    Header: "IDENTITY CARD",
    accessor: "identification_no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:0
  } ,
  {
    Header: "EMAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:0
  } ,
  {
    Header: "CUSTOMER UUID",
    accessor: "customer_uuid",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10
  } 
 ,{
    Header: "CREATED AT",
    accessor: "created_at",
    disableSortBy: false,
    display:"",
    textAlign:"left",
    width: 130,
    paddingLeft:10
  } ,
  {
    Header: "EXPORT",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 80,
    paddingLeft:10
  }
  

];