import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

// start test

function formatNumberCell(cellValue, rowNumber, pageSize) {
  if (rowNumber && pageSize) {
    return rowNumber + (pageSize * (pageIndex - 1));
  } else {
    return cellValue;
  }
}

function formatAmountCell(cellValue) {
  if ((cellValue !== null && typeof cellValue !== "undefined") || cellValue === 0) {
    return cellValue + ".00";
  } else {
    return cellValue; 
  }
}

function formatOrderDateCell(cellValue) {
  if (cellValue) {
    return moment(cellValue).format("DD/MM/YY HH:MM:SS");
  } else {
    return cellValue;
  }
}

function formatEffectiveDateCell(cellValue) {
  if (cellValue) {
    return moment(cellValue).format("DD/MM/YY");
  } else {
    return cellValue;
  }
}

function formatStatusCell(cellValue) {
  if (cellValue === "Approved" || cellValue === "Completed") {
    return (
      <div className="transactions-table-cell-account-status">
        <img src={Images.statusIcon.approved} alt="approved-icon" />
        <p className="transactions-table-cell-account-status-approved">{cellValue}</p>
      </div>
    );
  } else if (cellValue === "Cancelled" || cellValue === "Cancel") {
    return (
      <div className="transactions-table-cell-account-status">
        <img src={Images.statusIcon.rejected} alt="rejected-icon" />
        <p className="transactions-table-cell-account-status-rejected">{cellValue}</p>
      </div>
    );
  } else {
    return (
      <div className="transactions-table-cell-account-status">
        <img src={Images.statusIcon.pending} alt="pending-icon" />
        <p className="transactions-table-cell-account-status-pending">{cellValue}</p>
      </div>
    );
  }
}

export function modifyCell(cell, pageIndex, pageSize) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1;

  const formatters = {
    "NO.": formatNumberCell,
    "AMOUNT (THB)": formatAmountCell,
    "ORDER DATE": formatOrderDateCell,
    "EFFECTIVE DATE": formatEffectiveDateCell,
    "STATUS": formatStatusCell
  };

  return formatters[columnName] ? formatters[columnName](cellValue, rowNumber, pageSize) : cell;
}
// end test

// This is just for the header cells
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (cell === "ORDER DATE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        ORDER DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "FUND CODE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FUND CODE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "EFFECTIVE DATE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        EFFECTIVE DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "STATUS") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
