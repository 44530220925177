import { createReducer } from "redux-act";

import {
  requestClientProfile,
  receiveClientProfile,
  failClientProfile,
  requestClientDocuments,
  receiveClientDocuments,
  failClientDocuments,
  setCurrentCustomer,
  requestUserAccountLockedStatus,
  receiveUserAccountLockedStatus,
  failUserAccountLockedStatus,
  requestDocumentApprove,
  receiveDocumentApprove,
  failDocumentApprove,
  requestDocumentReject,
  receiveDocumentReject,
  failDocumentReject,
  requestDocumentUndo,
  receiveDocumentUndo,
  failDocumentUndo,
  requestClientApprove,
  receiveClientApprove,
  failClientApprove,
  requestClientReject,
  receiveClientReject,
  failClientReject,
  requestCancelStatus,
  receiveCancelStatus,
  failCancelStatus,
  clearErrorMessage,
  requestClientFunds,
  receiveClientFunds,
  failClientFunds,
  requestUserAccountUnlock,
  receiveUserAccountUnlock,
  failUserAccountUnlock,
  receiveCustomerChannel,
   // --------------User ACCOUNT-----------------
   REQUsrActSrchCli,
   RESUsrActSrchCli,
   FailUsrActSrchCli
} from "./actions";

const initialState = {
  requestingClientProfile: false,

  NameTH:null,
  UsrAccountType:null,
  CUS_PDPA:[],
  clientDetail: [],

  requestingClientDocuments: false,
  documentDetails: [],
  requestingClientFunds: false,
  clientFunds: [],
  customerUuid: null,
  unitholderId: null,
  requestingLockedStatus: false,
  isLockedStatus: false,
  requestingUnlock: false,
  requestingDocumentApproval: false,
  requestingDocumentRejection: false,
  requestingDocumentUndo: false,
  requestingClientApproval: false,
  requestingClientRejection: false,
  requestingCancelStatus: false,
  errorMessage: null,
  customerChannel: null,
  // --------------User ACCOUNT-----------------
  REQStrUsrActSrch: false,
  UsrActSrch: [],
  totalUsrAct: 0
};

const clientDetailReducer = createReducer(
  {
    [requestClientProfile]: (state) => {
      return {
        ...state,
        requestingClientProfile: true
      };
    },
    [receiveClientProfile]: (state, payload) => {
      return {
        ...state,
        requestingClientProfile: false,
        clientDetail: payload
      };
    },
    [failClientProfile]: (state) => {
      return {
        ...state,
        requestingClientProfile: false,
        clientDetail: []
      };
    },
    [requestClientDocuments]: (state) => {
      return {
        ...state,
        requestingClientDocuments: true
      };
    },
    [receiveClientDocuments]: (state, payload) => {
      return {
        ...state,
        requestingClientDocuments: false,
        documentDetails: payload
      };
    },
    [failClientDocuments]: (state) => {
      return {
        ...state,
        requestingClientDocuments: false,
        documentDetails: []
      };
    },
    [setCurrentCustomer]: (state, payload) => {
      return {
        ...state,
        customerUuid: payload.uuid,
        unitholderId: payload.unitholderid,
        NameTH:payload.NameTH,
        UsrAccountType:payload.UsrAccountType,
        UserName:payload.UserName,
        PageCurrent: payload.PageCurrent,

        documentDetails: [],
        clientFunds: [],
        isLockedStatus: false,
        requestingDocumentApproval: false,
        requestingDocumentRejection: false,
        requestingDocumentUndo: false,
        requestingClientApproval: false,
        requestingClientRejection: false,
        requestingCancelStatus: false,
        errorMessage: null
      };
    },
    [requestUserAccountLockedStatus]: (state) => {
      return {
        ...state,
        requestingLockedStatus: true
      };
    },
    [receiveUserAccountLockedStatus]: (state, payload) => {
      return {
        ...state,
        requestingLockedStatus: false,
        isLockedStatus: payload
      };
    },
    [failUserAccountLockedStatus]: (state) => {
      return {
        ...state,
        requestingLockedStatus: false,
        isLockedStatus: false
      };
    },
    [requestUserAccountUnlock]: (state) => {
      return {
        ...state,
        requestingUnlock: true
      };
    },
    [receiveUserAccountUnlock]: (state) => {
      return {
        ...state,
        requestingUnlock: false,
        isLockedStatus: "UNLOCKED"
      };
    },
    [failUserAccountUnlock]: (state) => {
      return {
        ...state,
        requestingUnlock: false
      };
    },
    [requestDocumentApprove]: (state) => {
      return {
        ...state,
        requestingDocumentApproval: true
      };
    },
    [receiveDocumentApprove]: (state) => {
      return {
        ...state,
        requestingDocumentApproval: false
      };
    },
    [failDocumentApprove]: (state) => {
      return {
        ...state,
        requestingDocumentApproval: false
      };
    },
    [requestDocumentReject]: (state) => {
      return {
        ...state,
        requestingDocumentRejection: true
      };
    },
    [receiveDocumentReject]: (state) => {
      return {
        ...state,
        requestingDocumentRejection: false
      };
    },
    [failDocumentReject]: (state) => {
      return {
        ...state,
        requestingDocumentRejection: false
      };
    },
    [requestDocumentUndo]: (state) => {
      return {
        ...state,
        requestingDocumentUndo: true
      };
    },
    [receiveDocumentUndo]: (state) => {
      return {
        ...state,
        requestingDocumentUndo: false
      };
    },
    [failDocumentUndo]: (state) => {
      return {
        ...state,
        requestingDocumentUndo: false
      };
    },
    [requestClientApprove]: (state) => {
      return {
        ...state,
        requestingClientApproval: true
      };
    },
    [receiveClientApprove]: (state) => {
      return {
        ...state,
        requestingClientApproval: false
      };
    },
    [failClientApprove]: (state, payload) => {
      return {
        ...state,
        requestingClientApproval: false,
        errorMessage: payload
      };
    },
    [requestClientReject]: (state) => {
      return {
        ...state,
        requestingClientRejection: true
      };
    },
    [receiveClientReject]: (state) => {
      return {
        ...state,
        requestingClientRejection: false
      };
    },
    [failClientReject]: (state) => {
      return {
        ...state,
        requestingClientRejection: false
      };
    },
    [requestCancelStatus]: (state) => {
      return {
        ...state,
        requestingCancelStatus: true
      };
    },
    [receiveCancelStatus]: (state) => {
      return {
        ...state,
        requestingCancelStatus: false
      };
    },
    [failCancelStatus]: (state) => {
      return {
        ...state,
        requestingCancelStatus: false
      };
    },
    [requestClientFunds]: (state) => {
      return {
        ...state,
        requestingClientFunds: true
      };
    },
    [receiveClientFunds]: (state, payload) => {
      return {
        ...state,
        requestingClientFunds: false,
        clientFunds: payload
      };
    },
    [failClientFunds]: (state) => {
      return {
        ...state,
        requestingClientFunds: false
      };
    },
    [clearErrorMessage]: (state) => {
      return {
        ...state,
        errorMessage: null
      };
    },
    [receiveCustomerChannel]: (state, payload) => {
      return {
        ...state,
        customerChannel: payload
      };
    },
      // --------------User ACCOUNT-----------------
      [REQUsrActSrchCli]: (state) => {
        //
        return {
          ...state,
          REQStrUsrActSrch: true
        };
      },
     
      [RESUsrActSrchCli]: (state, payload) => {
       
        return {
          ...state,
          REQStrUsrActSrch: false,
          UsrActSrch: payload.Cli,
          totalUsrAct: payload.TotalCli
        };
      },
       
      [FailUsrActSrchCli]: (state) => {
        return {
          ...state,
          REQStrUsrActSrch: false,
          UsrActSrch: [],
          totalUsrAct: 0
        };
      }
  },
  initialState
);

export default clientDetailReducer;
