const Images = {
  logo: {
    lg: require("../../assets/images/logo/cwa-logo.svg").default,
    md: require("../../assets/images/logo/cwa-logo.svg").default,
    sx: require("../../assets/images/logo/cwa-logo.svg").default
  },
  error: require("../../assets/images/error-red.svg").default,
  success: require("../../assets/images/success-green.svg").default,
  menuIcon: {
    clients: require("../../assets/images/sidebarMenuIcons/ic-client.png"),
    tag: require("../../assets/images/sidebarMenuIcons/ic-tag.png"),
    search_rec: require("../../assets/images/sidebarMenuIcons/ic-search.png"),
    fund_rec: require("../../assets/images/sidebarMenuIcons/ic-fundrec.png"),
    fundList: require("../../assets/images/sidebarMenuIcons/ic-fundlist.png"),
    transaction: require("../../assets/images/sidebarMenuIcons/ic-trans.png"),
    content_feed: require("../../assets/images/sidebarMenuIcons/ic-confeed.png"),
    con_feed_sort: require("../../assets/images/sidebarMenuIcons/ic-confeedsort.png"),
    device: require("../../assets/images/sidebarMenuIcons/ic-device.png"),
    acti: require("../../assets/images/sidebarMenuIcons/ic-acti.png"),
    exprot: require("../../assets/images/sidebarMenuIcons/ic-export.png"),
    consent: require("../../assets/images/sidebarMenuIcons/ic-shield.png")
  },
  clients: {
    view: require("../../assets/images/edit.svg").default
  },
  pagination: {
    leftArrow: require("../../assets/images/ic-arrow-darkpurple-left.svg").default,
    rightArrow: require("../../assets/images/ic-arrow-darkpurple-right.svg").default
  },
  arrowDownWhite: {
    arrow: require("../../assets/images/arrowDownWhite/ic-arrow-white-down_3x.png")
  },
  accessStatus: {
    locked: require("../../assets/images/access_locked.svg").default,
    unlocked: require("../../assets/images/access_unlocked.svg").default
  },
  documents: {
    undo: require("../../assets/images/undo.svg").default,
    zoom: require("../../assets/images/zoom.svg").default,
    leftArrow: require("../../assets/images/left_arrow.svg").default,
    rightArrow: require("../../assets/images/right_arrow.svg").default
  },
  logOut: {
    icon: require("../../assets/images/logoutIcon/logout_3x.png")
  },
  statusIcon: {
    approved: require("../../assets/images/statusIcon/document_3x.png"),
    pending: require("../../assets/images/statusIcon/pending_3x.png"),
    rejected: require("../../assets/images/statusIcon/rejected_3x.png"),
    nohighlight: require("../../assets/images/statusIcon/nohighlight.png"),
    highlight: require("../../assets/images/statusIcon/document_3x.png")
  },
  filer: {
    filer: require("../../assets/images/filer/filer.svg").default,
    filerRed: require("../../assets/images/filer/filer-red.svg").default
  },
  sorting: {
    sorting: require("../../assets/images/filer/sorting.svg").default
  },
  calendar: {
    calendar: require("../../assets/images/calendar/calendar.svg").default
  },
  avatar: {
    avatar: require("../../assets/images/avatar-2.png")
  },
  arrowGreen: {
    arrowGreen: require("../../assets/images/arrow-up-green.svg").default
  },
  arrowRed: {
    arrowRed: require("../../assets/images/arrow-up-red.svg").default
  },
  buttonIcon: {
    document: require("../../assets/images/buttonIcon/document.svg").default,
    plus: require("../../assets/images/addrule.png")
  },
  fundListIcon: {
    view: require("../../assets/images/fundListicom/edit1.svg").default,
    edit: require("../../assets/images/fundListicom/edit.svg").default,
    document: require("../../assets/images/document.svg").default,
    plus: require("../../assets/images/plus.svg").default,
    locked: require("../../assets/images/fundListicom/pending.svg").default,
    unlocked: require("../../assets/images/fundListicom/pending_1.svg").default
  },
  statusUpload: {
    start: require("../../assets/images/modal/up-arrow.svg").default,
    success: require("../../assets/images/modal/p-1.svg").default
  },
  closeButton: {
    close: require("../../assets/images/close/close.svg").default
  },

  deleteButton: {
    delete: require("../../assets/images/contentFeed/ic-trash-purple.svg").default,
    delete1: require("../../assets/images/trash1.svg").default
  },
  cancelButton: {
    cancel1: require("../../assets/images/cancel1.png")
  },

  tooltipIcon: {
    tooltip: require("../../assets/images/contentFeed/info.svg").default,
    tooltipError: require("../../assets/images/contentFeed/info-red.svg").default,
    tooltipWarnning: require("../../assets/images/contentFeed/info-yellow.svg").default
  },
  search: {
    search: require("../../assets/images/search/search.svg").default,
    upload: require("../../assets/images/search/upload.svg").default
  },
  checkbox: {
    checked: require("../../assets/images/selected.svg").default,
    unchecked: require("../../assets/images/empty.svg").default
  },
  noData: {
    board: require("../../assets/images/no-result-found.svg").default
  },
  transactions: {
    filter: require("../../assets/images/filter-date.svg").default,
    calendar: require("../../assets/images/calendar.svg").default
  },
  uploadDocuments: {
    upload: require("../../assets/images/up-arrow.svg").default,
    tick: require("../../assets/images/p-1.svg").default,
    error: require("../../assets/images/error.svg").default
  }
};

export default Images;
