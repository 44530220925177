import { createGlobalStyle } from "styled-components";

import BoldTTF from "./assets/fonts/Lato-Bold.ttf";
import BoldWOFF from "./assets/fonts/Lato-Bold.woff";
import BoldWOFF2 from "./assets/fonts/Lato-Bold.woff2";
import BoldEOT from "./assets/fonts/Lato-Bold.eot";
import BoldOTF from "./assets/fonts/Lato-Bold.otf";
import Regular from "./assets/fonts/Lato-Regular.ttf";
import SemiBold from "./assets/fonts/Lato-SemiBold.ttf";

import UbuntuRegular from "./assets/fonts/Ubuntu-Regular.ttf";

const GlobalStyle = createGlobalStyle`
 
  @font-face {
    font-family: Lato;
    url(${Regular})  format('truetype');
    letter-spacing: 0.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: Lato-Med;
    url(${SemiBold})  format('truetype');
    letter-spacing: 0.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
   @font-face {
    font-family: Lato-Bold;
    url(${BoldTTF})  format('truetype');
    url(${BoldWOFF})  format('woff');
    url(${BoldWOFF2})  format('woff2');
    url(${BoldEOT})  format('embedded-opentype');
    url(${BoldOTF})  format('woff2');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
  }
  @font-face {
    font-family: Ubuntu-Regular;
    url(${UbuntuRegular})  format('truetype');
    letter-spacing: 0.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
  }
  // body.body-overflow-hidden {
  // overflow-y: hidden;
  // }
  

  body.fontLoaded {
    font-family: 'Lato', sans-serif;
  }

  #root {
    display: flex;
    position: relative;
    min-height: 100vh;
    min-width: 100%;
    // padding-bottom: 60px;
    overflow-x: hidden;
    letter-spacing: 0.1px;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
  }
  h2 {
          font-family: "Lato", sans-serif;
          font-weight: 400;
          font-size: 30px;
          line-height: 0.77;
          letter-spacing: 0.3px;
          text-align: center;
          color: $black;
          text-transform: uppercase;
        }

  


`;
export default GlobalStyle;
