import { createReducer } from "redux-act";

import {
  REQSrchRecList,
  RESSrchRecList,
  FailSrchRecList,
  CleanUpSrchRecList
} from "./actions";

const initialState = {
  REQStrSrchRecList: false,
  SrchRecList: [],
  errorMessage: false,
  totalSrchRec: 0
};

const srchRecListReducer = createReducer(
  {
  
    [REQSrchRecList]: (state) => {
      return {
        ...state,
        REQStrSrchRecList: true
      };
    },
   
    [RESSrchRecList]: (state, payload) => {
    
      return {
        ...state,
        REQStrSrchRecList: false,
        SrchRecList: payload,
        totalSrchRec: payload.total
      };
    },
     
    [FailSrchRecList]: (state) => {
      return {
        ...state,
        REQStrSrchRecList: false,
        SrchRecList: [],
        totalSrchRec: 0
      };
    } , 
    [CleanUpSrchRecList]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default srchRecListReducer;
