import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  REQConsentList,
  RESConsentList,
  FailConsentList,
// ----- GetSYNC -------
  REQSyncConsent,
  RESSyncConsent,
  FailSyncConsent
} from "./actions";

function* fetchConsentListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page,tSortH} = payload;
   
    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/consent?page=${page}`, 
    { 
      method: "POST",  
      headers:
       {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      },
      body: JSON.stringify({
       sortH:tSortH
      })
       
    });
   
    const response = yield request.json();
    if (response && response.Consent) {
      yield put(RESConsentList(response));
    } 
    else{
      yield put(RESConsentList());
    }

  } catch (error) {
    
    yield put(FailConsentList());
  }
}

function* fetchSyncConsentListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { history } = payload;
    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/syncMasterConsent`, 
    { 
      method: "GET",
      headers:
       {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      },
    });
   
    const response = yield request.json();
    
    if (response) 
    {
     yield put(RESSyncConsent(response));
     history.push(`/Consent`); 
    } 
    else
     {
      
      yield put(RESSyncConsent());
    }

  } catch (error) {
    
    yield put(FailSyncConsent());
  }
}

export function* consentListSaga() {
  yield all([
    takeLatest(REQConsentList, fetchConsentListWorker) ,
    takeLatest(REQSyncConsent, fetchSyncConsentListWorker)
     
  ]);
}
