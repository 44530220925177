import { createAction } from "redux-act";
// get API fundCode assign to droplist
export const REQFundCodeList = createAction("REQUEST_FUNCODE_CONTENT");
export const RESFundCodeList = createAction("RECEIVE_FUNCODE_CONTENT");
export const FailFundCodeList = createAction("FAILED_FUNCODE_LIST");
export const CleanUpTagDetail = createAction("CLEANUP_TAGDETAIL");
// Creat
export const REQTagDetailCrt = createAction("REQUEST_TAGDETAIL_CREATE");
export const RESTagDetailCrt = createAction("RECEIVE_TAGDETAIL_CREATE");
export const FailTagDetailCrt = createAction("FAILED_TAGDETAIL_CREATE");
// Edit
export const MEMTagDetailEdt = createAction("MEM_TAGDETAIL_EDIT");
export const FailTagDetailEdt = createAction("FAILED_TAGDETAIL_EDIT");
export const REQTagDetailEdt = createAction("REQUEST_TAGDETAIL_EDIT");
export const RESTagDetailEdt = createAction("RECEIVE_TAGDETAIL_EDIT");
