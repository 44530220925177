import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQFundRecList, CleanUpFundRecList } from "./redux/actions";
import { setModeFundDetails } from "../FundDetail/redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import UploadDocument from "./upload_document";
import "./style.scss";

function FundRecList(props) {
  const {
    REQFundRecList,
    requestingFundsList,
    fundsList,
    CleanUpFundRecList,
    history,
    setModeFundDetails,
    selectedObjectUuid
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [showUploadDocs, setShowUploadDocs] = useState(false);

  const getFunds = () => {
    REQFundRecList();
  };

  useEffect(() => {
    // on component did mount, we call the API
    getFunds();

    // on component unmount, do cleanup
    return () => {
      CleanUpFundRecList();
    };
  }, []);

  const handleActionClick = ({ mode, rowData, pageIndex }) => {
    if (mode === "view") {
      setModeFundDetails({
        mode: "VIEW",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      window.addEventListener("popstate", function() {
        history.push({ pathname: "/FundRec_FundMan", PageCurrent: pageIndex });
      });
      history.push("/FundRec_FundManView/0");
    }

    if (mode === "edit") {
      setModeFundDetails({
        mode: "EDIT",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      window.addEventListener("popstate", function() {
        history.push({ pathname: "/FundRec_FundMan", PageCurrent: pageIndex });
      });
      history.push("/FundRec_FundManEdit/0");
    }
  };

  return (
    <Fragment>
      <div className="fundrec-buttons-container" style={{ marginBottom: 50 }}>
      </div>

      {showUploadDocs ? (
        <UploadDocument setShowUploadDocs={setShowUploadDocs} />
      ) : null}
      {requestingFundsList ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {requestingFundsList ? "Retrieving Funds list..." : null}
          </p>
        </div>
      ) : null}

      <Table
        data={fundsList}
        columns={columns}
        handleActionClick={handleActionClick}
        history={history}
      />
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundRecListReducer
  }),
  {
    REQFundRecList,
    CleanUpFundRecList,
    selectedObjectUuid,
    setModeFundDetails
  }
);

export default compose(
  withConnect,
  withRouter
)(FundRecList);
