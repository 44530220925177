import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import "./style.scss";
function CusServiceDetail(poPrp) {
  const { RowDataAt, ServiceType, history } = poPrp;
  return (
    <Fragment>
      <div className="tag-feed-form-container">
        <div className="tag-feed-form-buttons-container">
          <button
            className="tag-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
        </div>

        <div
          className="ndid-feed-form-inputs-container"
          style={{ height: 1140 }}
        >
          {ServiceType === "NDID" ? (
            <div className="client-detail-profile-account-photo-container">
              <p>PHOTO</p>
              <div>
                <img
                  width="110px"
                  height="110px"
                  src={
                    RowDataAt.Image === "" || RowDataAt.Image === null
                      ? "/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"
                      : "data:image/png;base64," + RowDataAt.Image
                  }
                />
                <p>NDID</p>
              </div>
            </div>
          ) : (
            <div className="client-detail-profile-account-photo-container">
              <p>PHOTO</p>
              <div>
                <img
                  width="110px"
                  height="110px"
                  src={
                    RowDataAt.Image === "" || RowDataAt.Image === null
                      ? "/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"
                      : "data:image/png;base64," + RowDataAt.Image
                  }
                />
                <p>Picture Card</p>
              </div>
              <div>
                <img
                  width="110px"
                  height="110px"
                  src={
                    RowDataAt.Image === "" || RowDataAt.Image === null
                      ? "/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"
                      : "data:image/png;base64," + RowDataAt.Image
                  }
                />
                <p>Picture Verification</p>
              </div>
            </div>
          )}

          <div className="client-detail-profile-account-photo-container">
            <p>PERSONAL DETAILS</p>
          </div>

          <div style={{ marginLeft: 20 }}>
            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">ID Card</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.IdCard}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Title Name TH</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.TitleNameTh}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">First Name TH</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.FirstNameTh}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Last Name TH</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.LastNameTh}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Title Name EN</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.TitleNameEn}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">First Name EN</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.FirstNameEn}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Last Name EN</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.LastNameEn}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Birth Date</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.BirthDate}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Issued Date </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.IssuedDate}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Expire Date</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.ExpireDate}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address No.</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressNo}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address Moo.</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressMoo}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address Alley</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressAlley}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address Soi</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressSoi}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address Road</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressRoad}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address Tambol</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressTambol}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address Amphur</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressAmphur}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Address Province</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.AddressProvince}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Zip Code</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.ZipCode}</span>
            </div>

            <div className="ndid-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Mobile No.</span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{RowDataAt.MobileNo}</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.cusServiceDetailReducer
  }),
  {}
);

export default compose(
  withConnect,
  withRouter
)(CusServiceDetail);
