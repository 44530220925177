import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import {
  requestFundsList,
  cleanupFundsList,
  REQSyncNAV
} from "./redux/actions";
import { WarningModal } from "../../components/Modal";
import { setModeFundDetails } from "../FundDetail/redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import UploadDocument from "./upload_document";
import Images from "../../utils/styles/images";
import "./style.scss";

function FundsList(props) {
  const {
    requestFundsList,
    requestingFundsList,
    fundsList,
    cleanupFundsList,
    history,
    setModeFundDetails,
    selectedObjectUuid,
    // -----Sync NAV---
    REQSyncStrNAV,
    REQSyncNAV
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [showUploadDocs, setShowUploadDocs] = useState(false);

  const [oShwSyncAlr, SETxShwSyncAlr] = useState(false);

  const getFunds = () => {
    requestFundsList();
  };

  
  useEffect(() => {
    // on component did mount, we call the API
    getFunds();

    // on component unmount, do cleanup
    return () => {
      cleanupFundsList();
    };
  }, []);

  const handleActionClick = ({ mode, rowData, pageIndex }) => {
    if (mode === "view") {
      setModeFundDetails({
        mode: "VIEW",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      window.addEventListener("popstate", function() {
        history.push({ pathname: "/fund_list", PageCurrent: pageIndex });
      });
      history.push("/fund_listDetail/" + rowData.FundNo);
    }

    if (mode === "edit") {
      setModeFundDetails({
        mode: "EDIT",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      window.addEventListener("popstate", function() {
        history.push({ pathname: "/fund_list", PageCurrent: pageIndex });
      });
      history.push("/fund_listDetail/" + rowData.FundNo);
    }
  };
  const GETxSynceNAV = () => {
    SETxShwSyncAlr(true);
  };
  return (
    <Fragment>
      {showUploadDocs ? (
        <UploadDocument setShowUploadDocs={setShowUploadDocs} />
      ) : null}

      {requestingFundsList ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {requestingFundsList ? "Retrieving Funds list..." : null}
          </p>
        </div>
      ) : null}

      {REQSyncStrNAV === true ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {REQSyncStrNAV === true ? "Retrieving Sync NAV..." : null}
          </p>
        </div>
      ) : null
      }

      <div className="funds-buttons-container">
        <button
          className="funds-create-fund-button"
          style={{ marginRight: 14 }}
          onClick={GETxSynceNAV}
        >
          Sync NAV
        </button>
        <button
          className="funds-upload-doc-button"
          onClick={() => setShowUploadDocs(true)}
        >
          <img
            src={Images.fundListIcon.document}
            style={{ marginBottom: 3 }}
            alt="document"
          />
          UPLOAD DOCUMENT
        </button>
        <button
          className="funds-create-fund-button"
          onClick={() => {
            setModeFundDetails({ mode: "CREATE" });
            selectedObjectUuid("CREATE NEW FUND");
            history.push("/fund_listDetail/0");
          }}
        >
          <img
            src={Images.fundListIcon.plus}
            style={{ marginBottom: 3 }}
            alt="plus"
          />
          CREATE NEW FUND
        </button>
      </div>

      <WarningModal
        show={oShwSyncAlr}
        onHide={() => SETxShwSyncAlr(false)}
        onHandleGo={() => {
          SETxShwSyncAlr(false);
          try {
            REQSyncNAV({ history });
          } catch (ex) {
            console.log('catch onHandleGo', ex);
          }
        }}
        onHandleCancel={() => {
          SETxShwSyncAlr(false);
        }}
        type={"Disapprove"}
        status={"1"}
        title={"Are you sure you want to sync NAV from website?"}
      />

      <Table
        data={fundsList}
        columns={columns}
        handleActionClick={handleActionClick}
        history={history}
      />
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundsListReducer
  }),
  {
    requestFundsList,
    cleanupFundsList,
    selectedObjectUuid,
    setModeFundDetails,

    REQSyncNAV
  }
);
export default compose(
  withConnect,
  withRouter
)(FundsList);
