import { createReducer } from "redux-act";

import {
  // --------------UntHld ACCOUNT-----------------
  REQUntHldList,
  RESUntHldList,
  FailUntHldList,
  CleanUpUntHldList,
  // ---------DisArpove----
  requestDisapproveClient,
  receiveDisapproveClient,
  failDisapproveClient,
} from "./actions";

const initialState = {
  REQStrUntHldList: false,
  UntHldList: [],
  errorMessage: false,
  totalUntHldAct: 0
};

const untHldListReducer = createReducer(
  {
  
    [REQUntHldList]: (state) => {
      return {
        ...state,
        REQStrUntHldList: true
      };
    },
   
    [RESUntHldList]: (state, payload) => {
     
      return {
        ...state,
        REQStrUntHldList: false,
        UntHldList: payload.data,
        totalUntHldAct: payload.total
      };
    },
     
    [FailUntHldList]: (state) => {
      return {
        ...state,
        REQStrUntHldList: false,
        UntHldList: [],
        totalUntHldAct: 0
      };
    } , 
    [CleanUpUntHldList]: () => {
      return initialState;
    },
    // ----------------DisAprove---------------
    [requestDisapproveClient]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: true
      };
    },
    [receiveDisapproveClient]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: false
      };
    },
    [failDisapproveClient]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: false
      };
    }
  },

  initialState
);

export default untHldListReducer;
