import React from "react";
import Routes from "../../components/Routes";
import { Switch, BrowserRouter} from "react-router-dom";
import { getUserConfirmation } from "./getUserConfirmation";
import { connect } from "react-redux";
import { compose } from "redux";
import { signOutAction } from "../../containers/Authentication/redux/actions";
function App() {
  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
    <Switch>
      <Routes  OKTAExpireUTC={oNeedAuth?.OKTAExpireUTC} />
    </Switch>
  </BrowserRouter>
  );
}

const withConnect = connect(
  state => ({
    ...state.auth
  }),
  {
    signOutAction
  }
);

export default compose(withConnect)(App);
