import { createReducer } from "redux-act";

import {
  // --------------User ACCOUNT-----------------
  REQUsrActList,
  RESUsrActList,
  FailUsrActList,
  CleanUpUsrActList
} from "./actions";

const initialState = {
  REQStrUsrActList: false,
  UsrActList: [],
  errorMessage: false,
  totalUsrAct: 0
};

const usrActListReducer = createReducer(
  {
  
    [REQUsrActList]: (state) => {
      return {
        ...state,
        REQStrUsrActList: true
      };
    },
   
    [RESUsrActList]: (state, payload) => {
      return {
        ...state,
        REQStrUsrActList: false,
        UsrActList: payload.Cli,
        totalUsrAct: payload.TotalCli
      };
    },
     
    [FailUsrActList]: (state) => {
      return {
        ...state,
        REQStrUsrActList: false,
        UsrActList: [],
        totalUsrAct: 0
      };
    } , 
    [CleanUpUsrActList]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default usrActListReducer;
