export const list_of_columns = [

  {
    Header: "NO.",
     disableSortBy: true,
     textAlign:"left",
     paddingLeft:5,
     width: 40
  },
  {
    Header: "ID CARD",
    accessor: "IdCard",
    disableSortBy: false,
    textAlign:"left",
    width: 120,
    paddingLeft:0
  }, 
  {
    Header: "TITLE NAME (TH)",
    accessor: "TitleNameTh",
    disableSortBy: false,
    textAlign:"left",
    width: 120,
    paddingLeft:0
  }
  ,{
    Header: "FIRST NAME (TH)",
    accessor: "FirstNameTh",
    disableSortBy: false,
    textAlign:"left",
    width: 140,
    paddingLeft:0
  } 
  ,{
    Header: "LAST NAME (TH)",
    accessor: "LastNameTh",
    disableSortBy: false,
    textAlign:"left",
    width: 140,
    paddingLeft:0
  } ,{
    Header: "BIRTH DATE",
    accessor: "BirthDate",
    disableSortBy: false,
    textAlign:"left",
    width: 140,
    paddingLeft:0
  },{
    Header: "CREATED DATE",
    accessor: "CreatAt",
    disableSortBy: false,
    textAlign:"left",
    width: 140,
    paddingLeft:0
  }
 
];
