import { createReducer } from "redux-act";

import {
  MEMActiDetail,
} from "./actions";

const initialState = {

};

const actiDetailReducer = createReducer(
  {
    // -------Edit to RegisDevicedetail----------------
    [MEMActiDetail]: (state, payload) => {
      return {
        ...state,
        RowDataAt:payload.RowDataAt,
        PageCurrent:payload.PageCurrent,
        UISearch:payload.UISearch
      };
    }
  },
  initialState
);

export default actiDetailReducer;
