import { createAction } from "redux-act";

export const requestClientsListAction = createAction("REQUEST_CLIENTS_LIST");
export const receiveClientsListAction = createAction("RECEIVE_CLIENTS_LIST");
export const failClientsListAction = createAction("FAIL_CLIENTS_LIST");

export const requestDisapproveClient = createAction(
  "REQUEST_DISAPPROVE_CLIENT"
);
export const receiveDisapproveClient = createAction(
  "RECEIVE_DISAPPROVE_CLIENT"
);
export const failDisapproveClient = createAction("FAIL_DISAPPROVE_CLIENT");
export const getApplicants = createAction("GET_APPLICANTS");
export const setApplicants = createAction("SET_APPLICANTS");
export const failApplicants = createAction("FAIL_APPLICANTS");
