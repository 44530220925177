import { createReducer } from "redux-act";

import {
  requestResetPasswordAction,
  receiveResetPasswordAction,
  failResetPasswordAction,
  clearErrorMessageResetPassword,
  resetStateAction
} from "./actions";

const initialState = {
  requestingResetPassword: false,
  errorMessage: null,
  success: false
};

const resetPasswordReducer = createReducer(
  {
    [requestResetPasswordAction]: (state) => {
      return {
        ...state,
        requestingResetPassword: true,
        success: false
      };
    },
    [receiveResetPasswordAction]: (state) => {
      return {
        ...state,
        requestingResetPassword: false,
        success: true
      };
    },
    [failResetPasswordAction]: (state, payload) => {
      return {
        ...state,
        requestingResetPassword: false,
        errorMessage: payload,
        success: false
      };
    },
    [clearErrorMessageResetPassword]: (state) => {
      return {
        ...state,
        errorMessage: null
      };
    },
    [resetStateAction]: (state) => {
      return {
        ...state,
        requestingResetPassword: false,
        errorMessage: null,
        success: false
      };
    }
  },
  initialState
);

export default resetPasswordReducer;
