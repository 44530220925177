export const col_ImpRGTView = [
  {
    Header: "ID.",
    accessor: "id",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 40
  },  
  
  {
    Header: "SEND GROUP",
    accessor: "send_date",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 70,
    paddingLeft:0
  }, 
  {
    Header: "CREATE DATE",
    accessor: "created_at",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 70,
    paddingLeft:10
  }, 
  {
    Header: "FILE NAME",
    accessor: "filename",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  },
  {
    Header: "CODE",
    accessor: "code_error",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 50,
    paddingLeft:10
  },{
    Header: "MESSAGE FILE",
    accessor: "msgfile",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10
  },{
    Header: "UNITHOLDER ID",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 100,
    paddingLeft:10
  }

];



export const col_ImpRGTUpd = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 40
  },  
  {
    Header: "UPDATEAT DATE",
    accessor: "UpdateAt",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 70,
    paddingLeft:0
  }, 
  {
    Header: "FILE NAME",
    accessor: "Name",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  },
  {
    Header: "CODE",
    accessor: "Code",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 50,
    paddingLeft:10
  },{
    Header: "MESSAGE FILE",
    accessor: "MessageFile",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 220,
    paddingLeft:10
  }
  

];
