import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQCusDetailList } from "./redux/actions";
import Table from "./table";
import { aColCusdetail } from "./list_of_columns";
import DateInput from "./DateInput";
import { REQNDIDLogDetail,MEMNDIDLogDetail } from "../RegisLogDetail/redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";
import Pagination from "./Pagination";
function NDIDLogList(props) {
  const {
    REQCusDetailList,
    REQStrCusDetailList,
    CusDetailList,
    totalCusDetail,

    REQNDIDLogDetail,
    REQStrNDIDLoglist,
    NDIDLoglist,
    MEMNDIDLogDetail,

    history
  } = props;
  const tColumeCusdetail = React.useMemo(() => aColCusdetail, []);


  const [tStartD, SETxStartD] = useState(new Date());
  const [tEndD, SETxEndD] = useState(new Date());
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("Email");
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH, SETtSortH] = useState("created_at DESC");
  const [tFltStaCode, SETtFltStaCode] = useState(["Success"]);

    const CALLxNDIDLog = () => {
      if(history.location.PageCurrent===undefined)
      {
         SETxPageIndex(0);
         REQCusDetailList({
           page: "1",
           tStartD,
           tEndD,
           tSrch,
           tOpSrch,
           tSortH
         });
      }
      else
      {
       let nPage = history.location.PageCurrent+1;
       if(history.location.pathname === "/NDIDLog")
       {
        // SET value UI
        SETxStartD(history.location.UISearch.tStartD);
        SETxEndD(history.location.UISearch.tEndD);
        SETxOpSrch(history.location.UISearch.tOpSrch);
        SETxSrch(history.location.UISearch.tSrch);

        SETxPageIndex(history.location.PageCurrent);
        REQCusDetailList({
         page: nPage.toString(),
         tStartD:history.location.UISearch.tStartD,
         tEndD:history.location.UISearch.tEndD,
         tSrch:history.location.UISearch.tSrch,
         tOpSrch:history.location.UISearch.tOpSrch,
         tSortH:history.location.UISearch.tSortH
       });
       }

      }
     };

     useEffect(() => {
      CALLxNDIDLog();
  
      }, [tSortH,tFltStaCode]);

     const SRCHxUsrAct = () => {
      SETxPageIndex(0);
      REQCusDetailList({
        page: "1",
        tStartD,
        tEndD,
        tSrch,
        tOpSrch,
        tSortH
       
      });
    };
     const SRCHxUsrActClr = () => {
      SETxSrch("");
      SETxPageIndex(0);
      REQCusDetailList({
        page: "1",
        tStartD,
        tEndD,
        tSrch: "",
        tOpSrch,
        tSortH
     
      });
    };
    const RadioInput = ({ label, value, checked, setter }) => {
      return (
        <span>
          <input
            type="radio"
            checked={checked === value}
            onChange={() =>
              {
                setter(value);
              }
            }
          />
          <span style={{ marginLeft: 0 }}>{label}</span>
        </span>
      );
    };
    // start test
    const SETxActHeaderClick = cell => {
      const sortMapping = {
        "REGISTER DATE": "created_at",
        "USERNAME": "username",
        "E-MAIL": "email",
        "ACCOUNT TYPE": "hasConverted",
        "UNITHOLDER": "UnitCount"
      };
    
      const sortField = sortMapping[cell];
    
      // Check if a mapping exists
      if (sortField) {
        const newSortH = tSortH === `${sortField} ASC` ? `${sortField} DESC` : `${sortField} ASC`;
        SETtSortH(newSortH);
      }
    };
     // end test
    const SETxActTableClick = (cellValue, rowData, columnName) => {
  
      if (columnName === "USER NAME") {    
        MEMNDIDLogDetail({ RowDataAt: rowData ,PageCurrent: nPageIndex,
          UISearch:{
            tStartD:tStartD,
            tEndD:tEndD,
            tSrch:tSrch,
            tOpSrch:tOpSrch,
            tSortH:tSortH
          }
        });
        history.push(`/NDIDLogDetail`);
      }
      else if (columnName === "EXPORT")
      {

        REQNDIDLogDetail({
          identification_no:rowData.identification_no
        });
        

        if(REQStrNDIDLoglist!==null)
        {
        let tJson="";
        const createdDate = new Date(rowData.created_at);
        createdDate.setHours(createdDate.getHours() - 1);
        const formattedDate = createdDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');
        tJson={
           customer_uuid: rowData.customer_uuid,
           created_at: formattedDate,
           username:  rowData.username,
           email:  rowData.email,
           full_name:  rowData.full_name,
           mobile:  rowData.mobile,
           identification_no:  rowData.identification_no,
           first_name:  rowData.first_name,
           last_name:  rowData.last_name,
           first_name_th:  rowData.first_name_th,
           last_name_th:  rowData.last_name_th,
           NDIDHistory:NDIDLoglist
        };

         let oElement2 = document.createElement("a");
         let oFile2 = new Blob(
           [JSON.stringify(tJson,null,2)]
         ,{
           fileType: 'text/json'
         });
         oElement2.href = URL.createObjectURL(oFile2);
         oElement2.download = "NDIDLog["+rowData.identification_no+"]-["+rowData.username+"]-["+formattedDate+"].json";
         document.body.appendChild(oElement2);
         oElement2.click();
        }
       
         
      }
    };
    const SETxPageGo = pnPageAt => {
      let nPageAt = pnPageAt + 1;
      
     
      SETxPageIndex(pnPageAt);
      REQCusDetailList({
        page: nPageAt.toString(),
        tStartD,
        tEndD,
        tSrch,
        tOpSrch,
        tSortH

      });
      
    };
    const GETxWidthSrch = () => {
      if(tOpSrch==="UUID")
      {
        return 450;
      }else 
      {
        return 331;
      }
    };
    const SETxFltHeader = cell => 
    {
      if (cell === "STATUS CODE") 
      {
        return (
          <div className="clients-header-sortby-converted-user">
            <div className="clients-header-sortby-text-container-converted-user">
              <p>{cell}</p>
              <img
                className="clients-header-sortby-img-converted-user"
                src={Images.filer.filer}
                alt="down-arrow"
              />
            </div>
            <div className="clients-header-sortby-radio-container-converted-user" style={{width:150}}>
              <p>FILTER</p>
              <div className="clients-header-sortby-row">
                <input
                  type="checkbox"
                  value="Success"
                  checked={tFltStaCode.find(f => f ==="Success")}
                   onChange={() =>   
                    {
                        let bChk= tFltStaCode.includes("Success");
                        if(bChk===true)
                        {
                          let tFlt= tFltStaCode.filter(f=>f!=="Success");
                          SETtFltStaCode(tFlt);
                        }
                        else if(bChk===false)
                        {
                          let tAdd = [...tFltStaCode, "Success"];
                          SETtFltStaCode(tAdd);
                        }
                    }
                  }
                />
                <label htmlFor="Success by-user" className="checkbox-label">
                  Success
                </label>
              </div>
              <div className="clients-header-sortby-row">
                <input
                  type="checkbox"
                  value="Error by"
                  checked={tFltStaCode.find(f => f ==="Error")}
                  onChange={() =>   
                    {
                        let bChk= tFltStaCode.includes("Error");
                        if(bChk===true)
                        {
                          let tFlt= tFltStaCode.filter(f=>f!=="Error");
                          SETtFltStaCode(tFlt);
                        }
                        else if(bChk===false)
                        {
                          let tAdd = [...tFltStaCode, "Error"];
                          SETtFltStaCode(tAdd);
                        }
                    }
                  }
                />
                <label htmlFor="Error by-user" className="checkbox-label">
                  Waiting
                </label>
              </div>
             
   
            </div>
          </div>
        );
      }
    };
    const SHWxTypeTable =()=>{  
     
     return(
        <Table
          columns={tColumeCusdetail}
          data={CusDetailList}
          handleActionHeaderClick={SETxActHeaderClick}
          handleFltHeader={SETxFltHeader}
          handleActionClick={SETxActTableClick} 
          pageSelect={nPageIndex}
        />
      );
    };
    const CALxPaging = () => {
      let nPageCnt = Math.ceil(totalCusDetail / 10);
       return nPageCnt > 1 ? (
        <Pagination
          nPageIndex={nPageIndex}
          total={nPageCnt}
          gotoPage={SETxPageGo}
        />
      ) 
      : null;
   
    };
  return (
    <Fragment>
    <div className="button-create-container" style={{ marginBottom: 1 }}>
      <div style={{ height: 40 }} />
      {REQStrCusDetailList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrCusDetailList ? "Retrieving Cusdetail list..." : null}
          </p>
        </div>
      ) : null}

      {REQStrNDIDLoglist ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrNDIDLoglist ? "Retrieving NDIDLog list..." : null}
            </p>
          </div>
        ) : null}

    </div>

    <div
      className="labelInput"
      style={{ marginLeft: 20, marginTop: 50 }}
    >
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div className="usrAct-search" style={{ marginTop: 0,width:GETxWidthSrch() }}>
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxUsrAct()}
          />
          <input
            placeholder={"Search by " + tOpSrch}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={event => {
              SETxSrch(event.target.value); 
            }}
            onKeyDown={event => {
              // enter
              if (event.keyCode === 13) {
                SETxSrch(event.target.value); 
                SRCHxUsrAct();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxUsrActClr("")}
            
            />
          ) : null}
        </div>
    </div>
    <div className="clients-radio-container"style={{ marginLeft: 145, marginBottom: 20 }}>
      <RadioInput
        label="E-mail"
        value="Email"
        checked={tOpSrch}
        setter={SETxOpSrch}
      />
      <span style={{ marginRight: 20 }} />
      <RadioInput
        label="Username"
        value="Username"
        checked={tOpSrch}
        setter={SETxOpSrch}
      />
    
       <span style={{ marginRight: 20 }} />
        <RadioInput
        label="First Name"
        value="First Name"
        checked={tOpSrch}
        setter={SETxOpSrch}
      />
      <span style={{ marginRight: 20 }} />
        <RadioInput
        label="ID No. / Passport No."
        value="ID No. / Passport No."
        checked={tOpSrch}
        setter={SETxOpSrch}
      />
       <span style={{ marginRight: 20 }} />
        <RadioInput
        label="Mobile"
        value="Mobile"
        checked={tOpSrch}
        setter={SETxOpSrch}
      />
    </div>


    <div className="date-range-container" style={{ width: 400 }}>
        <p className="labelInputText" style={{ width: 290 }}>
          Date
          <span style={{ color: "#FF0000" }}>*</span>
        </p>
        <p style={{ paddingRight: 10 }}>:</p>
        <DatePicker
          selected={tStartD}
          onChange={date => SETxStartD(date)}
          selectsStart
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <p className="date-range-separator">-</p>
        <DatePicker
          selected={tEndD}
          onChange={date => moment(date).isAfter(tStartD) && SETxEndD(date)}
          selectsEnd
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
    </div>

    <button
        style={{ marginLeft: 20,marginBottom:20 }}
        onClick={() => {
          SRCHxUsrAct();
        }}
        className="conFd-synce-button"
      >
        Search
    </button>
    {REQStrCusDetailList ? null : (SHWxTypeTable())}
    {REQStrCusDetailList ? null : <CALxPaging />}
  </Fragment>
  );
}

 
const withConnect = connect(
  state => ({
    ...state.regisLogListReducer,
    ...state.regisLogDetailReducer
  }),
  {
    REQCusDetailList,
    REQNDIDLogDetail,
    MEMNDIDLogDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(NDIDLogList);
