import React from "react";
import Images from "../../utils/styles/images";

// start test
export function modifyCell(cell, pageSelect, pageSize, handleActionClick) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1 || null;
  const rowData = props?.row?.original || null;

  switch (columnName) {
    case "ACTION":
      return rowData ? (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() => handleActionClick({ mode: "view", rowData })}
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() => handleActionClick({ mode: "edit", rowData })}
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      ) : cell;
    case "NO.":
      return pageSelect !== 0 ? rowNumber + pageSelect * 10 : rowNumber;
    case "USERNAME":
    case "E-MAIL":
    case "UUID":
      return cellValue || (columnName === "USERNAME" ? "N/A" : "-");
    case "UNITHOLDER ID":
      return cellValue ? (
        <div>
          {cellValue.map((r, i) => (
            <div key={i}>
              {(r.unitholder_type || "MF")}|{(r.unitholder_id || "N/A")}
            </div>
          ))}
        </div>
      ) : "-";
    case "REGISTER DATE":
    case "CREATE DATE":
      return cellValue
        ? cellValue.split("T")[0] + " " + cellValue.split("T")[1].substring(0, 8)
        : "-";
    default:
      return cell;
  }
}
// end test

// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleFltHeader
) {
  if (cell === "DEVICE NAME") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        DEVICE NAME
      </button>
    );
  } else if (cell === "REGISTER DATE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        REGISTER DATE1
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "USERNAME") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        USERNAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "E-MAIL") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        E-MAIL
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "UNITHOLDER") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        UNITHOLDER
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }

  if (cell === "ACCOUNT STAGE") {
    return handleFltHeader ? handleFltHeader(cell) : cell;
  } else {
    return cell;
  }
}
