export const validate = values => {
  const errors = {};
  // fix remove "\"
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,12}$/;

  if (!values.email) {
    errors.email = "Email field is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Please, enter a valid email address";
  }

  if (!values.password) {
    errors.password = "Password field is required";
  } else if (!passwordRegex.test(values.password)) {
    errors.password = "Password format is not correct";
  }

  if (values.email && values.token === null && values.password) {
    errors.token = "The username or password you entered is incorrect.";
  }

  return errors;
};

export const thailandCountry = (value, form) => {
  if (form.country === "THAILAND" && !value) {
    return "This field is required";
  }
  return undefined;
};

export const required = value =>
  value || value === false ? undefined : "This field is required";
