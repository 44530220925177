import { createAction } from "redux-act";

export const REQRegisLogList = createAction("REQUEST_RESGIS_LOG_LIST");
export const RESRegisLogList = createAction("RECEIVE_RESGIS_LOG_LIST");
export const FailRegisLogList = createAction("FAILED_RESGIS_LOG_LIST");
export const CleanUpRegisLogList= createAction("CLEANUP_RESGIS_LOG_LIST");

// ----------------Cusdetail ------

export const REQCusDetailList = createAction("REQUEST_CUSDETAIL_LIST");
export const RESCusDetailList = createAction("RECEIVE_CUSDETAIL_LIST");
export const FailCusDetailList = createAction("FAILED_CUSDETAIL_LIST");
export const CleanUpCusDetailList= createAction("CLEANUP_CUSDETAIL_LIST");