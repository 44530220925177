import { put, call, all, takeLatest, select } from "redux-saga/effects";

import moment from "moment";
import {
  REQCusServiceList,
  RESCusServiceList,
  FailCusServiceList
} from "./actions";

function* fetchCusServiceListWorker({ payload }) {
  console.log("fetchCusServiceListWorker:",payload);
  try {
    const { tStartD, tEndD, tSrch, tOpSrch, tUserType } = payload;
    const tStartD1 = moment(tStartD).format("YYYY-MM-DD");
    let tEndD1 = moment(tEndD).format("YYYY-MM-DD");
    tEndD1 = tEndD1 + " 23:59";
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let tMobile = "";
    let tEmail = "";
    let tUUID = "";
    switch (tOpSrch) {
      case "Mobile":
        tMobile = tSrch;
        break;
      case "E-Mail":
        tEmail = tSrch;
        break;
      case "UUID":
        tUUID = tSrch;
        break;
      default:
        break;
    }
    console.log("fetchCusServiceListWorker start:");
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/activityLog/search`,
      {
        method: "PUT",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          startDate: tStartD1,
          endDate: tEndD1,
          email: tEmail,
          mobile: tMobile,
          uuid: tUUID,
          userType: tUserType
        })
      }
    );

    const response = yield request.json();
    console.log("fetchCusServiceListWorker response:",response);
    if (response && response.data) {
      yield put(RESCusServiceList(response));
    } else {
            yield put(FailCusServiceList());
    }
  } catch (error) {
    console.error("fetchCusServiceListWorker error:",error);
    yield put(FailCusServiceList());
  }
}

export function* cusServiceListSaga() {
  yield all([takeLatest(REQCusServiceList, fetchCusServiceListWorker)]);
}
