import { createReducer } from "redux-act";

import {
  // ----Get Funtag---
  REQFundTag,
  RESFundTag,
  FailFundTag,

  REQSrchRecDetailEdt,
  RESSrchRecDetailEdt,
  FailSrchRecDetailEdt,

  MEMSrchRecDetailEdt
} from "./actions";

const initialState = {
  REQStrSrchRecEdt: false,
  SrchRecList: [],
  FunTagOp: [],

  errorMessage: false,
  totalSrchRec: 0,
  totalFundTagOp: 0

 
};

const srchRecDetailReducer = createReducer(
  {
    // --------Get Funtag------------
    [REQFundTag]: (state) => {
     
       return {
         ...state,
         REQStrSrchRecList: true
       };
     },
     [RESFundTag]: (state, payload) => {
      
       return {
         ...state,
         REQStrSrchRecList: false,
         FunTagOp: payload.body,
         totalFundTagOp: payload.total
       };
     },  
     [FailFundTag]: (state) => {
       return {
         ...state,
         REQStrSrchRecList: false,
         FunTagOp: [],
         totalFundTagOp: 0
       };
     },


    // -------Edit to SrchRecdetail----------------
    [MEMSrchRecDetailEdt]: (state, payload) => {
    
      return {
        ...state,
        mode: payload.mode,
        RowDataAt:payload.RowDataAt
    
      };
    },
    [REQSrchRecDetailEdt]: (state) => {
     
      return {
        ...state,
        REQStrSrchRecList: true
      };
    },
    [RESSrchRecDetailEdt]: (state) => {
     
      return {
        ...state,
        REQStrSrchRecList: false
      };
    },  
    [FailSrchRecDetailEdt]: (state) => {
      return {
        ...state,
        REQStrSrchRecList: false,
        SrchRecList: [],
        totalSrchRec: 0
      };
    }
  },
  initialState
);

export default srchRecDetailReducer;
