import React from "react";
import Images from "../../../utils/styles/images";
import "./style.scss";

function ConfirmationFDModal(props) {
  const { showConfirmation, setShowConfirmation } = props;
  return (
    <div
      className={
        showConfirmation ? "confirm-fd-modal" : "confirm-fd-modal-hide"
      }
    >
      <div className={"confirm-fd-modal-content"}>
        <img src={Images.closeButton.close} alt="close" />
        <h3>Confirm</h3>
        <p className={"confirm-fd-modal-message"}>
          Your previous data for this fund will no longer be available once this
          new set of data has been saved.
        </p>
        <div className={"confirm-fd-modal-buttons-container"}>
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              setShowConfirmation(false);
            }}
            className={"confirm-fd-modal-button"}
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={() => setShowConfirmation(false)}
            className={"confirm-fd-modal-button"}
          >
            CONFIRM
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationFDModal;
