import React, { useState } from "react";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { modifyCell, modifyCellHeader } from "./cell_formatter";
import Images from "../../utils/styles/images";
import Pagination from "./Pagination";
function TableSubMenu({
  columns,
  data,
  handleActionClick,

  ShowOnlyHighlight,
  handleChangeHighlight,

  handleActionHeaderClick,
  handleSortHeader,
  interestsList,
  HightTable,
  history
}) {
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageCount,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state,
    setGlobalFilter

  }
    = useTable(
      {
        data,
        columns,
        initialState: { pageIndex: history.location.PageCurrent, pageSize: 10 }
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  const [searchValue, setSearchValue] = useState(undefined);
  return (
    <div>

      <div className="fundrec-search-header">
        <div className="fundrec-search-container">
          <div className="fundrec-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="fundrec-list-begin-search"
              onClick={() =>
                searchValue.length > 0 && setGlobalFilter(searchValue)
              }
            />
            <input
              placeholder={history.location.pathname === "/ConFd_Srch" ? "Search by Article ID or Article Title" : "Search by Article Title"}

              className="fundrec-search-bar"
              value={state.globalFilter || ""}
              onChange={event => {
                setSearchValue(event.target.value);
                setGlobalFilter(event.target.value);
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  setSearchValue(event.target.value);
                  setGlobalFilter(event.target.value);
                }
              }}
            />
            {searchValue && searchValue.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="fundrec-details-cancel-search"
                onClick={() => {
                  setSearchValue(undefined);
                  setGlobalFilter(undefined);
                }}
              />
            ) : null}
          </div>
        </div>

        {
          history.location.pathname === "/ConFd_Srch" ?
            <div className="labelInput" style={{ height: 40, marginTop: 22, marginLeft: 20 }}>
              <input style={{ width: 18, height: 18, marginRight: 10 }}
                type="checkbox"
                id="Highlight"
                name="Highlight"
                defaultChecked={ShowOnlyHighlight}
                onChange={handleChangeHighlight}
              />
              <span className="labelInputText ">Show only Highlight</span>
            </div>
            : null
        }

      </div>

      <div className="funds-list-container" style={{ height: HightTable }}>
        <table className="feed-table">
          <thead className="feed-head-container">

            {headerGroups.map(headerGroup => (

              <tr
                {
                ...headerGroup.getHeaderGroupProps()
                }
                className="funds-head-row"
              >
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="funds-header"

                    style={
                      {
                        width: column.render("width"),
                        paddingLeft: column.render("paddingLeft"),
                        display: column.render("display")
                      }
                    }
                  >



                    {modifyCellHeader(
                      column.render("Header"),
                      handleActionHeaderClick,
                      handleSortHeader,
                      history
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps} className="feed-table-body">
            {page.length <= 0 ? (
              <tr className="feed-table-empty-row">
                <td colSpan={14}>
                  <div className="feed-table-no-data">
                    <img src={Images.noData.board} alt="no-data-found-board" />
                    <p className="feed-table-no-data-header">No result found</p>
                    <p className="feed-table-no-data-body">
                      It does not look like there are any content feed articles to be displayed at the moment.
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="feed-table-row">
                    {
                      row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()} className="feed-cell"
                            style={
                              {
                                width: cell.render("width"),
                                paddingLeft: cell.render("paddingLeft"),
                                textAlign: cell.render("textAlign"),
                                display: cell.render("display")
                              }

                            }
                          >

                            {modifyCell(
                              cell.render("Cell"),
                              interestsList,
                              handleActionClick,
                              history,
                              pageIndex,
                              pageSize
                            )}
                          </td>
                        );
                      })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {pageCount > 1 ? (
        <Pagination
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          total={pageCount}
          gotoPage={gotoPage}
        />
      ) : null}
    </div>
  );
}

export default TableSubMenu;
