import { createReducer } from "redux-act";

import {
  REQConFdSortList,
  RESConFdSortList,
  FailConFdSortList,
  CleanUpConFdSortList,
  // ------Edit-------
  REQConFdSortEdt,
  RESConFdSortEdt,
  FailConFdSortEdt
} from "./actions";

const initialState = {
  REQStrConFdSortList: false,
  ConFdSrtList:[],
  errorMessage: false,
  totalConFdSort: 0
};

const conFdSortListReducer = createReducer(
  {
  
    [REQConFdSortList]: (state) => {
      return {
        ...state,
        REQStrConFdSortList: true
      };
    },
   
    [RESConFdSortList]: (state, payload) => {
    
      return {
        ...state,
        REQStrConFdSortList: false,
        ConFdSrtList: payload,
        totalConFdSort: payload.total
      };
    },
     
    [FailConFdSortList]: (state) => {
      return {
        ...state,
        REQStrConFdSortList: false,
        ConFdSrtList: [],
        totalConFdSort: 0
      };
    } , 
    [CleanUpConFdSortList]: () => {
      return initialState;
    },
    // ----------------ContentFeedSort EDT-----------------
    [REQConFdSortEdt]: (state) => {
       return {
         ...state,
         REQStrConFdSortList: true
       };
     },  
     [RESConFdSortEdt]: (state) => {
      
       return {
         ...state,
         REQStrConFdSortList: false
       };
     },  
     [FailConFdSortEdt]: (state) => {
       return {
         ...state,
         REQStrConFdSortList: false,
         ConFdSrtList: [],
         totalConFdSort: 0
       };
     }
  },

  initialState
);

export default conFdSortListReducer;
