import React, { Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import JoditEditor from "jodit-react";
import { aCategory } from "./DataInclude";
import { TextInput } from "../../components/input_hook_form";
import { WarningModal, AlertModal } from "../../components/Modal";
import { REQContFdNotiInvestCrt } from "./redux/actions";
function ConFdNotiInvestCrt(props) {
  const {
    REQContFdNotiInvestCrt,
    history
  } = props;

  // ---------------Text Intial---------------------
  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  const [oSetValInit, SETxValInit] = useState({
    otbTitleEN: "",
    otbTitleTH: "",

    ordUsr: "All",
    otbKM: "",
    otbImgUrl: "",
    otbFileUrl: ""
  });
  const [oShwCrtAlr, SETxShwCrtAlr] = useState(false);
  const [oShwErrAlr, SETxShwErrAlr] = useState(false);
  const [oLangAlr, SETxLangAlr] = useState("");
  const SETxOnChgTxt = name => e => {
    SETxValInit({ ...oSetValInit, [name]: e.target.value });
  };
  // ---------------Radio-------------
  var [oUsr, SETxUsr] = React.useState(oSetValInit.ordUsr);
  const [oStatus, SETxStatus] = React.useState("Publish");

  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 10 }}>{label}</span>
      </span>
    );
  };

  // ------------------Category----------------
  let aCategory1 = aCategory
    ? aCategory.length === 0
      ? []
      : aCategory.filter(
          c => c.value === "fund_announcement" || c.value === "media_center"
        )
    : [];

  const [oCategory, SEToCategory] = useState(() => {
    return aCategory1[0];
  });

  const SETxOnChgSelCategory = e => {
    SEToCategory({ value: e.value, label: e.label });
  };
  // ----------------CheckBox---------------

  var [oHighlight] = useState([
    {
      value: 1,
      label: "Highlight",
      name: "Highlight-en",
      isCheck: false
    }
  ]);
  function SETxOnHightlight(e) {
    let nIndex = oHighlight.findIndex(
      item => e.target.value === item.value.toString()
    );
    oHighlight[nIndex].isCheck = e.target.checked;
  }

  // -------------JoditEditor 2------------
  
  const editor = useRef(null);
  const [tContentEN, SETtContentEN] = useState("");
  const [tContentTH, SETtContentTH] = useState("");
  

  const oConfig = {
    readonly: false,
    height: 400
  };
  const EVTxUPDContentEN = e => {
    const tEdtTxtEn = e;
    SETtContentEN(tEdtTxtEn);
  };
  const EVTxUPDContentTH = e => {
    const tEdtTxtTh = e;
    SETtContentTH(tEdtTxtTh);
  };

  function isEmptyOrTest(value) {
    return value === null || value.replace("test", "") === "";
  }
  
  function showValidationError(language) {
    SETxLangAlr(language);
    SETxShwErrAlr(true);
    return false; 
  }

  return (
    <Fragment>
      <div className="conFd-feed-form-container">
        <div className="conFd-buttons-container">
          <button
            className="conFd-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={() => SETxShwCrtAlr(true)}
            className={
              (oSetValInit.otbTitleEN === "" &&
                oSetValInit.otbTitleTH === "") ||
              (oSetValInit.otbTitleEN === null &&
                oSetValInit.otbTitleTH === null)
                ? "content-feed-form-publish-button-disabled"
                : "content-feed-form-publish-button"
            }
            disabled={
              (oSetValInit.otbTitleEN === "" &&
                oSetValInit.otbTitleTH === "") ||
              (oSetValInit.otbTitleEN === null &&
                oSetValInit.otbTitleTH === null)
            }
            // className="content-feed-form-publish-button"
          >
            SAVE
          </button>

          <WarningModal
            show={oShwCrtAlr}
            onHide={() => SETxShwCrtAlr(false)}
            onHandleGo={() => {
              SETxShwCrtAlr(false);

              // Get values
              const tTitleTH = document.getElementsByName("otbTitleTH")[0].value;
              const tTitleEN = document.getElementsByName("otbTitleEN")[0].value; 

              // Validation
              if (isEmptyOrTest(tTitleTH)) {
                return showValidationError("TH");
              } 
              if (isEmptyOrTest(tTitleEN)) {
                return showValidationError("EN");
              }
              if (isEmptyOrTest(tContentTH)) {
                return showValidationError("TH");
              }
              if (isEmptyOrTest(tContentEN)) {
                return showValidationError("EN");
              }
              
              // -------------------Highlight------------------
              const oHili = oHighlight.filter(CHK => CHK.isCheck === true);
              let oHili1;
              if (oHili.length > 0) {
                oHili1 = oHili[0].isCheck;
              } else {
                oHili1 = false;
              }
              let oSendDataAPI = {
                imageUrl:
                  oSetValInit.otbImgUrl === null ? "" : oSetValInit.otbImgUrl,
                fileUrl: document.getElementsByName("otbFileUrl")[0].value,
                videoUrl: "",

                articleTitleTh: tTitleTH,
                articleTitleEn: tTitleEN,
                shortDesTh: "",
                shortDesEn: "",

                contentTh: tContentTH === null ? "" : tContentTH,
                contentEn: tContentEN === null ? "" : tContentEN,

                showUser: oUsr,
                statusCode: oStatus === "Publish" ? 2 : 1,
                contentType: oCategory.value,
                isHighlight: oHili1 === true ? 1 : 0,
                knowledgeCenter: document.getElementsByName("otbKM")[0].value,
                modifiedBy: oNeedAuth?.Email,

                tag: [{ tagName: "", tagNo: "" }],
                fund: [{ fundCode: "", fundNo: "" }]
              };

              // ----Edtor
              

              REQContFdNotiInvestCrt({
                FormData: oSendDataAPI,
                history
              });
            }}
            onHandleCancel={() => {
              SETxShwCrtAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={"Are you sure you want to create content?"}
          />

          <AlertModal
            show={oShwErrAlr}
            onHide={() => SETxShwErrAlr(false)}
            onHandleOK={() => {
              SETxShwErrAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={`Please config <strong>${
              oLangAlr === "TH" ? "THAI" : "ENGLISH"
            }</strong> version.`}
          />
        </div>

        <div className="conFd-feed-form-inputs-container">
          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Type</span>
            <div style={{ width: 600 }}>
              <Select
                name="ocbCategory"
                options={aCategory1}
                maxMenuHeight={150}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select contenttype"
                // assign onChange function
                onChange={SETxOnChgSelCategory}
                value={oCategory}
              />
            </div>
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Knowledge Center</span>
            <input
              className="conFd-pre-textInput"
              name="otbKM"
              placeholder="Knowledge Center"
              style={{ width: 598 }}
              value={oSetValInit.otbKM}
              onChange={SETxOnChgTxt("otbKM")}
            />
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Title in Thai"
            placeholder="Title in Thai"
            name="otbTitleTH"
            value={oSetValInit.otbTitleTH}
            onChange={SETxOnChgTxt("otbTitleTH")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />
          <div className="fundrec-invalid" style={{ marginLeft: 20 }}>
            {oSetValInit.otbTitleTH !== "" ? "" : "กรุณากรอก Title in Thai"}
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Title in English"
            placeholder="Title in English"
            name="otbTitleEN"
            value={oSetValInit.otbTitleEN}
            onChange={SETxOnChgTxt("otbTitleEN")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />
          <div className="fundrec-invalid" style={{ marginLeft: 20 }}>
            {oSetValInit.otbTitleEN !== "" ? "" : "กรุณากรอก Title  in English"}
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">File URL</span>
            <input
              className="conFd-pre-textInput"
              name="otbFileUrl"
              placeholder="File URL"
              style={{ width: 598 }}
              value={oSetValInit.otbFileUrl}
              onChange={SETxOnChgTxt("otbFileUrl")}
            />
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Media Feature Image</span>
            <input
              className="conFd-pre-textInput"
              name="otbImgUrl"
              placeholder="Enter Media Feature Image URL"
              style={{ width: 475 }}
              value={oSetValInit.otbImgUrl}
              onChange={SETxOnChgTxt("otbImgUrl")}
            />
            <button
              type="button"
              className="srchRec-preview-button"
              onClick={() => {
                var url = document.getElementsByName("otbImgUrl")[0].value;
                window.open(url, "_blank");
              }}
            >
              PREVIEW
            </button>
          </div>

          <div style={{ marginTop: 10 }}>
            <table border="0" className="conFd-feed-form-table">
              <tbody>
                <tr height={30}>
                  <td rowSpan="2" width={240} style={{ paddingBottom: 28 }}>
                    User
                  </td>
                  <td>
                    <RadioInput
                      className="labelInputText"
                      label="แสดงทุกกลุ่ม"
                      value="All"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      className="labelInputText"
                      label="เฉพาะลูกค้า PVD เท่านั้น"
                      value="PVD"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="เฉพาะลูกค้า MUTUAL FUND"
                      value="MutualFund"
                      checked={oUsr}
                      setter={SETxUsr}
                    />
                  </td>
                </tr>
                <tr height={30}>
                  <td>
                    <RadioInput
                      label="SOFT ONBOARD เท่านั้น"
                      value="SoftOnboard"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="เฉพาะลูกค้าที่มี PVD และ MUTUAL FUND"
                      value="PVDAndMutualFund"
                      checked={oUsr}
                      setter={SETxUsr}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table border="0" className="conFd-feed-form-table">
              <tbody>
                <tr height={30}>
                  <td rowSpan="2" width={240}>
                    Content Status
                  </td>
                  <td>
                    <RadioInput
                      label="Unpublished"
                      value="Unpublish"
                      checked={oStatus}
                      setter={SETxStatus}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="Published"
                      value="Publish"
                      checked={oStatus}
                      setter={SETxStatus}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            {/*-----------------------------Highlight--------------------------------------*/}

            <div className="labelInput" style={{ height: 40, marginTop: 10 }}>
              <span className="labelInputText ">Highlight</span>
              {oHighlight
                ? oHighlight.map((option, index) => {
                    return (
                      <Fragment key={`${option.label}${index}`}>
                        <input
                          type="checkbox"
                          id={option.label}
                          value={option.value}
                          name={option.name}
                          style={{ width: 18, height: 18, marginRight: 10 }}
                          defaultChecked={
                            option.isCheck === true ? true : false
                          }
                          onChange={SETxOnHightlight}
                        />
                        <label
                          htmlFor={option.label}
                          className={"checkbox-label"}
                        >
                          <label />
                          <div style={{ paddingBottom: 17, fontSize: 14 }}>
                            {option.label}
                          </div>
                        </label>
                      </Fragment>
                    );
                  })
                : null}
            </div>

            {/*-----------------------------The content-------------------------------------- */}
            <div className="conFd-feed-form-input-spacer" />

            <div>
              <div className="labelInput">
                <span className="labelInputText ">The Content in Thai</span>
                <button
                  type="button"
                  className={"conFd-coppy-form-button"}
                  style={{ width: 120, marginRight: 10 }}
                >
                  COPY
                </button>
                <button
                  type="button"
                  className={"conFd-coppy-form-button"}
                  style={{ width: 120 }}
                >
                  SYNC
                </button>
              </div>
              <div className="conFd-feed-form-input-spacer" />
              <div className="fundrec-invalid" style={{ paddingLeft: 0 }}>
                {tContentTH.replace("test","") !== ""
                  ? ""
                  : "กรุณากรอก The Content in Thai"}
              </div>
              <JoditEditor
                ref={editor}
                value={tContentTH}
                config={oConfig}
                onBlur={EVTxUPDContentTH}
                name="EdtTH"
                onChange={() => {}}
              />
            </div>

            <div className="conFd-feed-form-input-spacer" />
            <div>
              <div className="labelInput">
                <span className="labelInputText ">The Content</span>
                <button
                  type="button"
                  className={"conFd-coppy-form-button"}
                  style={{ width: 120, marginRight: 10 }}
                >
                  COPY
                </button>
                <button
                  type="button"
                  className={"conFd-coppy-form-button"}
                  style={{ width: 120 }}
                >
                  SYNC
                </button>
              </div>

              <div className="conFd-feed-form-input-spacer" />
              <div className="fundrec-invalid" style={{ paddingLeft: 0 }}>
                {tContentEN.replace("test","") !== ""
                  ? ""
                  : "กรุณากรอก The Content in English"}
              </div>
              <JoditEditor
                ref={editor}
                value={tContentEN}
                config={oConfig}
                onBlur={EVTxUPDContentEN}
                name="EdtEN"
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.conFdDetailReducer
  }),
  {
    REQContFdNotiInvestCrt
  }
);
export default compose(
  withConnect,
  withRouter
)(ConFdNotiInvestCrt);
