import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

// start test
function formatActionCell(cellValue, rowNumber , rowData, columnName, pageIndex, handleActionClick) {
  if (rowData) {
    return (
      <div className="funds-action-buttons">
        <button
          className="funds-action-view"
          onClick={() =>
            handleActionClick({ mode: "view", rowData, pageIndex })
          }
        >
          <img src={Images.fundListIcon.view} alt="eye" />
          View
        </button>
        <button
          className="funds-action-edit"
          onClick={() =>
            handleActionClick({ mode: "edit", rowData, pageIndex })
          }
        >
          <img src={Images.fundListIcon.edit} alt="pencil" />
          Edit
        </button>
      </div>
    );
  } else {
    // Or whatever you want to return if rowData is missing
    return cellValue;
  }
}


function formatNumberCell(cellValue, rowNumber, rowData, columnName, pageIndex, handleActionClick) {
  return rowNumber; 
}

function formatDeviceNameCell(cellValue, rowNumber, rowData, columnName, pageIndex, handleActionClick) {
  if (handleActionClick) { 
    return (
      <div>
        <button
          onClick={() => handleActionClick(cellValue, rowNumber ,rowData, columnName, pageIndex)}
          className="feed-list-article-button"
        >
          {cellValue}
        </button>      
      </div>
    );
  } else {
    return cellValue;
  }
}


function formatDateCell(cellValue, rowNumber , rowData, columnName, pageIndex, handleActionClick) {
   if (cellValue) {
      return moment(cellValue).format("YYYY-MM-DD");
    } else {
      return cellValue;
    }
}

export function modifyCell(cell, pageIndex, pageSize, handleActionClick) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1;
  const rowData = props?.row?.original || null;

  const formatters = {
    "ACTION": formatActionCell,
    "NO.": formatNumberCell,
    "DEVICE NAME": formatDeviceNameCell,
    "REGISTERED DATE": formatDateCell,
    "DEREGISTERED DATE": formatDateCell 
  };

  // Early return for known columns
  if (formatters[columnName]) return formatters[columnName](cellValue, rowNumber , rowData, columnName, pageIndex, handleActionClick);

  // Default: return the cell as-is
  return cell; 
}
// end test

// This is just for the header cells
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (cell === "DEVICE NAME") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        DEVICE NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else if (cell === "VERIFIED BY") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        VERIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }  else if (cell === "CREATE DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        CREATE DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "REGISTERED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        REGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "DEREGISTERED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        DEREGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  
  
  else if (cell === "TYPE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "STATUS") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else {
    return cell;
  }
}
