export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no"
  },
  {
    Header: "UNITHOLDER ID",
    accessor: "UnitholderId"
  },
  {
    Header: "FULL NAME",
    accessor: "UnitholderName"
  },
  {
    Header: "FUND CODE",
    accessor: "FundCode"
  },
  {
    Header: "TRANS. TYPE",
    accessor: "TransType_EN"
  },
  {
    Header: "ENTRY DATE",
    accessor: "TransDate"
  },
  {
    Header: "EFFECTIVE DATE",
    accessor: "StartDate"
  },
  {
    Header: "AMOUNT (THB)",
    accessor: "Amount"
  },
  {
    Header: "FREQUENCY",
    accessor: "Frequency_EN"
  },
  {
    Header: "STATUS",
    accessor: "StatusDesc"
  }
];
