import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import moment from "moment";
import {
  requestTransactionsList,
  receiveTransactionsList,
  failTransactionsList
} from "./actions";

function* fetchTransactionsListWorker({ payload }) {
  try {
/*
sortingNo:
0 : TransDate
1 : EffectiveDate
2 : Fund_Code
3 : TransNo
4 : Status
*/
    const { startDate, endDate, sortDir, sortingNo, pageNo, searchQuery } =
      payload;

    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    const accessToken = yield select((state) => state.auth.accessToken);
    const tGUID = yield select((state) => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/orders?fields=amount%2Cchannel%2CeffectiveDate%2Cfee%2CfundCode%2CfundNo%2Cnav%2CprofitLoss%2CstatusCode%2CstatusDesc%2Ctax%2CtoFundCode%2CtoFundNo%2CtransCode%2CtransDatetime%2CtransNo%2Cunit%2CunitholderId%2CunitholderName&fundNo=0&startDate=${formattedStartDate}&endDate=${formattedEndDate}&pageRow=10&pageNo=${pageNo}&sortingNo=${sortingNo}&sortDirection=${sortDir}${searchQuery}`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    if (response && response.data && response.data.data) {
      yield put(receiveTransactionsList(response.data));
    } else {
      yield put(failTransactionsList());
    }
  } catch (error) {
    yield put(failTransactionsList());
  }
}

export function* transactionsListSaga() {
  yield all([takeLatest(requestTransactionsList, fetchTransactionsListWorker)]);
}
