import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQRegisDeviceList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { MEMRegisDeviceDetail } from "../RegisDeviceDetail/redux/actions";
import "./style.scss";
function RegisDeviceList(props) {
  const {
    REQRegisDeviceList,
    REQStrRegisDeviceList,
    RegisDeviceList,
    MEMRegisDeviceDetail,
    history
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);

  const GETxRegisDevice = () => {
    REQRegisDeviceList();
  };

  useEffect(() => {
    GETxRegisDevice();
  }, []);

  const SETxActTableClick = (cellValue ,pageIndex, rowData, columnName) => {
    if (columnName === 'DEVICE NAME' && history.location.pathname === "/RegisDevice") {
        MEMRegisDeviceDetail({
          RowDataAt: RegisDeviceList.find(x => x.id === rowData.id),
          PageCurrent: pageIndex
        });
        window.addEventListener("popstate", function() {
          history.push({ pathname: "/RegisDevice", PageCurrent: pageIndex });
        });
        history.push(`/RegisDeviceDetail/${rowData.id}`);
    }
  };

  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 1 }}>
        <div style={{ height: 40 }} />
      </div>
      {REQStrRegisDeviceList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrRegisDeviceList ? "Retrieving Regisdeivce list..." : null}
          </p>
        </div>
      ) : null}
      <Table
        data={RegisDeviceList}
        HightTable={900}
        columns={columns}
        handleActionClick={SETxActTableClick}
        history={history}
      />
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.regisDeviceListReducer
  }),
  {
    REQRegisDeviceList,
    MEMRegisDeviceDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(RegisDeviceList);
