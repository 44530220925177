import React, { useState } from "react";
import Images from "../../../utils/styles/images";
import "./style.scss";

function Pagination(props) {
  const { handlePageChange, total, pageSize, defaultPage } = props;
  const [pageIndex, setPageIndex] = useState(defaultPage);
  const totalNumberOfPages =
    total > 0 && total / pageSize > 0 ? Math.trunc(total / pageSize) : 0;

  

  const handleFirstClick = () => {
    setPageIndex(0);
    handlePageChange(0);
  };

  const handleLastClick = () => {
    let lastPage = Math.trunc(total / pageSize) - 1;
    setPageIndex(lastPage);
    handlePageChange(lastPage);
  };

  const handleNextPageClick = () => {
    setPageIndex(pageIndex + 1);
    handlePageChange(pageIndex + 1);
  };

  const handlePreviousPageClick = () => {
    setPageIndex(pageIndex - 1);
    handlePageChange(pageIndex - 1);
  };

  const handlePageClick = buttonPageIndex => {
    setPageIndex(buttonPageIndex);
    handlePageChange(buttonPageIndex);
  };

  const renderPaginationButtons = () => {
    let arrayOfButtons = [];
    // limit the number of shown buttons to 4
    for (let index = 1; index < 5; index += 1) {
      let displayValueForPageIndex = pageIndex + index;
      // so long as there are still pages remaining, we can add buttons for pagination
      if (displayValueForPageIndex <= totalNumberOfPages) {
        arrayOfButtons.push(
          <button
            className={
              displayValueForPageIndex - 1 === pageIndex
                ? "clients-table-pagination-button-selected"
                : "clients-table-pagination-button"
            }
            onClick={() => handlePageClick(displayValueForPageIndex - 1)}
            key={"pagination-button" + displayValueForPageIndex}
          >
            {displayValueForPageIndex}
          </button>
        );
      }
    }

    return arrayOfButtons;
  };

  return (
    <div className="clients-table-pagination-container">
      <button
        className="clients-table-pagination-first"
        onClick={() => handleFirstClick()}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>

      <button
        className={
          pageIndex - 1 < 0
            ? "clients-table-pagination-previous-disabled"
            : "clients-table-pagination-previous"
        }
        onClick={() => handlePreviousPageClick()}
        disabled={pageIndex - 1 < 0}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      {renderPaginationButtons()}
      <button
        className={
          pageIndex + 1 >= totalNumberOfPages
            ? "clients-table-pagination-next-disabled"
            : "clients-table-pagination-next"
        }
        onClick={() => handleNextPageClick()}
        disabled={pageIndex + 1 >= totalNumberOfPages}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
      <button
        className="clients-table-pagination-last"
        onClick={() => handleLastClick()}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
    </div>
  );
}

export default Pagination;
