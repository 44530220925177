import { createAction } from "redux-act";

export const setModeFundDetails = createAction("SET_MODE_FUND_DETAILS");
export const cleanupFundDetails = createAction("CLEANUP_FUND_DETAILS");
export const resetErrorMessage = createAction("RESET_FD_ERROR_MESSAGE");

export const requestFundDetails = createAction("REQUEST_FUND_DETAILS");
export const receiveFundDetails = createAction("RECEIVE_FUND_DETAILS");
export const failFundDetails = createAction("FAILED_FUNDS_DETAILS");

export const requestFundCreate = createAction("REQUEST_FUND_CREATE");
export const receiveFundCreate = createAction("RECEIVE_FUND_CREATE");
export const failFundCreate = createAction("FAIL_FUND_CREATE");

export const requestFundCodeExists = createAction("REQUEST_FUND_CODE_EXISTS");
export const receiveFundCodeExists = createAction("RECEIVE_FUND_CODE_EXISTS");
export const failFundCodeExists = createAction("FAIL_FUND_CODE_EXISTS");
export const resetFundCodeExists = createAction("RESET_FUND_CODE_EXISTS");

export const requestFundEdit = createAction("REQUEST_FUND_EDIT");
export const receiveFundEdit = createAction("RECEIVE_FUND_EDIT");
export const failFundEdit = createAction("FAIL_FUND_EDIT");