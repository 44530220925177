import { createReducer } from "redux-act";

import {
  REQCusServiceList,
  RESCusServiceList,
  FailCusServiceList,
  CleanUpCusServiceList
} from "./actions";

const initialState = {
  REQStrCusServiceList: false,
  CusServiceList: [],
  errorMessage: false,
  totalCusService: 0
};

const cusServiceListReducer = createReducer(
  {
  
    [REQCusServiceList]: (state) => {
      return {
        ...state,
        REQStrCusServiceList: true
      };
    },
   
    [RESCusServiceList]: (state, payload) => {
     
      return {
        ...state,
        REQStrCusServiceList: false,
        CusServiceList: payload,
        totalCusService: payload.total
      };
    },
     
    [FailCusServiceList]: (state) => {
      return {
        ...state,
        REQStrCusServiceList: false,
        CusServiceList: [],
        totalCusService: 0
      };
    } , 
    [CleanUpCusServiceList]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default cusServiceListReducer;
