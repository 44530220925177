import { createAction } from "redux-act";

// ---------------FundRecommend Codi Edit------
export const MEMFundRecCodiDetailEdt = createAction("MEM_FUNDRECCODIDETAIL_EDIT");
export const CleanUpByCodiEdt = createAction("CLEANUP_BYCODI_EDIT");

// ----------------Get Funcode ---------
// get API fundCode assign to droplist
export const REQFundCode = createAction("REQUEST_FUNCODE_BYCODI_Detail");
export const RESFundCode = createAction("RECEIVE_FUNCODE_BYCODI_Detail");
export const FailFundCode = createAction("FAILED_FUNCODE_BYCODI_Detail");
// ------------------Get Option Risk------
export const REQRisk = createAction("REQUEST_Risk_BYCODI_Detail");
export const RESRisk = createAction("RECEIVE_Risk_BYCODI_Detail");
export const FailRisk= createAction("FAILED_Risk_BYCODI_Detail");

// ------------------Get Option Occupation------
export const REQOccu = createAction("REQUEST_OCCU_BYCODI_Detail");
export const RESOccu = createAction("RECEIVE_OCCU_BYCODI_Detail");
export const FailOccu= createAction("FAILED_OCCU_BYCODI_Detail");

// ------------------Get Option Income------
export const REQIncome = createAction("REQUEST_INCOME_BYCODI_Detail");
export const RESIncome = createAction("RECEIVE_INCOME_BYCODI_Detail");
export const FailIncome= createAction("FAILED_INCOME_BYCODI_Detail");


// ----------------Edit Codi Detail----------------
export const REQByCodiEdt = createAction("REQUEST_BYCODI_EDIT");
export const RESByCodiEdt = createAction("RECEIVE_BYCODI_EDIT");
export const FailByCodiEdt = createAction("FAILED_BYCODI_EDIT");
// ----------------Create Codi Detail----------------
export const REQByCodiCrt = createAction("REQUEST_BYCODI_CRT");
export const RESByCodiCrt = createAction("RECEIVE_BYCODI_CRT");
export const FailByCodiCrt = createAction("FAILED_BYCODI_CRT");