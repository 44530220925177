import { createReducer } from "redux-act";

import {
  REQFundRecCodiList,
  RESFundRecCodiList,
  FailFundRecCodiList,
  CleanUpFundRecCodiList,
} from "./actions";

const initialState = {
// --FundRecommend Condition------
    REQStrFundRecCodiList: false,
    FundRecCodiList:[],
    totalFundRecCodiList: 0
};

const fundRecCodiListReducer = createReducer(
  {   
    // -------FundRecommend Condition------------
    [REQFundRecCodiList]: (state) => {
      return {
        ...state,
        REQStrFundRecCodiList: true
      };
    },
    [RESFundRecCodiList]: (state, payload) => {  
      return {
        ...state,     
        REQStrFundRecCodiList: false,
        FundRecCodiList: payload,
        totalFundRecCodiList: payload.total
     
      };
    },
    [FailFundRecCodiList]: (state) => {
      return {
        ...state,
        REQStrFundRecCodiList: false,
        FundRecCodiList: [],
        totalFundRecRiskList: 0
      };
    },
    [CleanUpFundRecCodiList]: () => {
      return initialState;
    }
  },
  initialState
);

export default fundRecCodiListReducer;




