import { createReducer } from "redux-act";

import {
  REQFundRecRiskList,
  RESFundRecRiskList,
  FailFundRecRiskList,
  CleanUpFundRecRiskList,
  // ---Chage status-------
  REQFundRiskChgSta,
  RESFundRiskChgSta,
  FailFundRiskChgSta,
  CleanUpFundRiskChgSta
} from "./actions";

const initialState = {
// --FundRecommend Risk------
    REQStrFundRecRiskList: false,
    FundRecRiskList:[],
    totalFundRecRiskList: 0,
// ---Chage status-------
    REQStrFundRiskChgStaList: false,
    FundRiskChgStaList:[],
    totalFundRiskList: 0
};


const oInitChgSta = {

  // ---Chage status-------
      REQStrFundRiskChgStaList: false,
      FundRiskChgStaList:[],
      totalFundRiskList: 0
  };

const fundRecRiskListReducer = createReducer(
  {   
    // -------FundRecommend Risk------------
    [REQFundRecRiskList]: (state) => {
      return {
        ...state,
        REQStrFundRecRiskList: true
      };
    },
    [RESFundRecRiskList]: (state, payload) => { 
      
      return {
        ...state,     
        REQStrFundRecRiskList: false,
        FundRecRiskList: payload.data,
        totalFundRecRiskList: payload.total
 
      };
    },
    [FailFundRecRiskList]: (state) => {
      return {
        ...state,
        REQStrFundRecRiskList: false,
        FundRecRiskList: [],
        totalFundRecRiskList: 0
      };
    },
    [CleanUpFundRecRiskList]: () => {
      return initialState;
    },
     // -------FundRecommend Risk Chage status------------
     [REQFundRiskChgSta]: (state) => {
       
       return {
         ...state,
         REQStrFundRiskChgStaList: true
       };
     },
     [RESFundRiskChgSta]: (state, payload) => { 
     
       return {
         ...state,     
         REQStrFundRiskChgStaList: false,
         FundRiskChgStaList: payload.data,
         totalFundRiskList: payload.total
  
       };
     },
     [FailFundRiskChgSta]: (state) => {
       return {
         ...state,
         REQStrFundRiskChgStaList: false,
         FundRiskChgStaList: [],
         totalFundRiskList: 0
       };
     },
     [CleanUpFundRiskChgSta]: () => {
       return oInitChgSta;
     }
  },
  initialState
);

export default fundRecRiskListReducer;




