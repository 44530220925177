import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { OktaAuth } from "@okta/okta-auth-js";
import { requestDropDownData, stopLoading } from "../../../globalRedux/actions";
import { signIn as signInConstant } from "../../../globalRedux/constants/loader";
import { hideLoader, showLoader } from "../../../store/actions/loader";
import { aConfigUrl } from "../../../utils/ConfigUrl";
import * as api from "../../../utils/api";
import * as actions from "./actions";

const getToken = state => state.auth.accessToken;

function* signIn({ payload }) {
  try {
    yield put(showLoader(signInConstant));
    let API_URL = aConfigUrl().API_URL;
    let authClient = new OktaAuth(aConfigUrl());

    const authData = yield authClient.signInWithCredentials({
      username: payload.email,
      password: payload.password
    });

    const accessTokenData = yield authClient.token.getWithoutPrompt({
      responseType: "id_token",
      sessionToken: authData.sessionToken
    });

    const oktaAccessToken = accessTokenData.tokens.accessToken.accessToken;
    const userClaims = yield authClient.token.getUserInfo(accessTokenData.tokens.accessToken, accessTokenData.tokens.idToken);

    const oNeedAuth = {
      Email: payload.email,
      Token: oktaAccessToken,
      GUID: userClaims.customerId,
      OKTAExpireUTC: new Date(Number(accessTokenData.tokens.accessToken.expiresAt) * 1000),
      OKTAToken: oktaAccessToken,
      b2cAccessToken: oktaAccessToken,
      b2cUserId: userClaims.customerId
    };
    console.log("oNeedAuth:",oNeedAuth);
    localStorage.setItem("NeedAuth", JSON.stringify(oNeedAuth));

    yield put(actions.userSignedIn({
      id: oktaAccessToken,
      ttl: authData.expiresAt,
      created: authData.expiresAt,
      userId: authData.user,
      accessToken: oktaAccessToken,
      GUID: userClaims.customerId
    }));
    yield put(stopLoading());
    yield call(setAccessTokenToHeader);
    yield put(requestDropDownData());
  } catch (error) {
    
    yield put(actions.RESOkta(error));
    yield put(actions.signInRequestFailedAction());
  } finally {
    yield put(hideLoader(signInConstant));
  }
}

function* signUp({ payload }) {
  try {
    const response = yield call(api.post, 'SIGN_UP_ENDPOINT', payload);
    yield put(actions.userSignedIn(response.data));
    yield call(setAccessTokenToHeader);
  } catch (error) {
    
    yield put(actions.signUpRequestFailedAction());
  }
}

function* signOut() {
  localStorage.removeItem("NeedAuth");

  yield put(actions.userSignedOut());
  yield call(api.resetAuthHeader);
}

function* setAccessTokenToHeader() {
  const token = yield select(getToken);
  yield call(api.setAuthHeader, token);
  return token;
}

export default function* authSagas() {
  yield all([
    takeLatest(actions.signInAction, signIn),
    takeLatest(actions.signUpAction, signUp),
    takeLatest(actions.signOutAction, signOut)
  ]);
}
