export const aColEayInv = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 60
  },
  {
    Header: "REGISTER DATE",
    accessor: "created_at",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:0
  }, 
  {
    Header: "UNITHOLDER ID",
    accessor: "CUS_UnitHolder",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10
  },
  {
    Header: "USERNAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  },
  {
    Header: "E-MAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 180,
    paddingLeft:0
  }
  ,{
    Header: "NAME",
    accessor: "FirstNameCli",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  } 

];


export const aColUntHld = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 60
  },
  {
    Header: "CREATE DATE",
    accessor: "creUpdatedAt",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:0
  }, 
  {
    Header: "UUID",
    accessor: "uuid",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10
  },
  {
    Header: "USERNAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  },
  {
    Header: "E-MAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 180,
    paddingLeft:0
  }
  ,{
    Header: "NAME",
    accessor: "firstNameEn",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  } 
];
