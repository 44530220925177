import { createAction } from "redux-act";

export const requestResetPasswordAction = createAction(
  "REQUEST_RESET_PASSWORD"
);
export const receiveResetPasswordAction = createAction(
  "RECEIVE_RESET_PASSWORD"
);
export const failResetPasswordAction = createAction("FAIL_RESET_PASSWORD");

export const clearErrorMessageResetPassword = createAction(
  "CLEAR_ERROR_MESSAGE_RESET_PASSWORD"
);

export const resetStateAction = createAction(
  "CLEAR_RESET_PASSWORD_STATE_ACTION"
);
