import React from "react";
import Images from "../../utils/styles/images";
export function modifyCell(cell, pageSelect, pageSize, handleActionClick) {
  // Format the cell data here
  const { props } = cell;

  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

    const SETxTab = ptSta => {
      handleActionClick(cellValue, rowData, columnName, ptSta);
    };

  if (columnName === "ACTION") {
    if (rowData) {
      return (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() =>
              handleActionClick({ mode: "view", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() =>
              handleActionClick({ mode: "edit", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } else if (columnName === "NO.") 
  {
    
    if(pageSelect!==0)
    {
     return rowNumber+(pageSelect*10);
    }
    else
    {
      return rowNumber;
    }
  }  
  else if (columnName === "USERNAME") {
  
    return (
      <div>
      <button
        onClick={() => 
          handleActionClick(cellValue, rowData, columnName,pageSelect)
        }
        className="feed-list-article-button"
      >
        {cellValue===null?"N/A":cellValue}
      </button>
    
    </div>
    );
  }
  else if (columnName === "E-MAIL") {

    if(cellValue===null)
    {
      return ("-");
    }else
    {
      return cellValue;
    }
   
  }
  else if (columnName === "STATUS") {
    if(cellValue!=null)
    {
      return (     
        <div className= {
             cellValue ==="LOCKED_OUT"?"usrAct-lock-bar":"usrAct-unlock-bar"
          }>
        <button
          className=
          {
            cellValue ==="ACTIVE"?"usrAct-active-unlock":"usrAct-unlock"
          }
          onClick={() => SETxTab("ACTIVE")}
          disabled=
          {
            cellValue ==="ACTIVE"?true:false
          }
        >
          UNLOCK
        </button>
        <button
          className=
          {
            cellValue ==="LOCKED_OUT"?"usrAct-active-lock":"usrAct-lock"
          }
          onClick={() => SETxTab("LOCKED_OUT")}
          disabled=
          {
              cellValue ==="LOCKED_OUT"?true:false
          }
        >
          LOCK
        </button>
  
      </div>
        );
    }
    else
    {
      return "ไม่ได้อยู่ใน Web Service Email";
    }
  } 
  else if (columnName === "REGISTER DATE") 
  {
    if (cellValue) {
      const dateObject = new Date(cellValue);
      dateObject.setHours(dateObject.getHours() - 1);
    
      const tDate = `${dateObject.toISOString().slice(0, 19).replace("T", " ")}`;
      return tDate;
    } else {
      return "-";
    }
  } 
  else if (columnName === "ACCOUNT TYPE") 
  { 
    return rowData.accounttype;
  }
  else if (columnName === "ID") {
    if (cellValue) {
      return cell;
    } else {
      return cell;
    }
  } 
  else 
  {
    return cell;
  }
}

// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleFltHeader
) {
 
  if (cell === "DEVICE NAME") 
  {
    return (
      <button
        className="transactions-header-with-sort"
      >
        DEVICE NAME
      </button>
    );
  } 
  else if (cell === "REGISTER DATE") 
  {
    return (
      <button className="transactions-header-with-sort" 
       onClick={() => handleActionHeaderClick(cell)}
      >
        REGISTER DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else if (cell === "USERNAME") 
  {
    return (
      <button className="transactions-header-with-sort" 
       onClick={() => handleActionHeaderClick(cell)}
      >
        USERNAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else if (cell === "E-MAIL") 
  {
    return (
      <button className="transactions-header-with-sort" 
       onClick={() => handleActionHeaderClick(cell)}
      >
        E-MAIL
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else if (cell === "ACCOUNT TYPE") 
  {
    return (
      <button className="transactions-header-with-sort" 
       onClick={() => handleActionHeaderClick(cell)}
      >
        ACCOUNT TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else if (cell === "UNITHOLDER") 
  {
    return (
      <button className="transactions-header-with-sort" 
       onClick={() => handleActionHeaderClick(cell)}
      >
        UNITHOLDER
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  if(cell === "ACCOUNT STAGE") 
  {
     return handleFltHeader ? handleFltHeader(cell) : cell;
   } 
  else {
    return cell;
  }
}
