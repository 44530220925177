import { createAction } from "redux-act";
// ------EasyInvest---
export const REQAnalyticList = createAction("REQUEST_ANALYTIC_LIST");
export const RESAnalyticList = createAction("RECEIVE_ANALYTIC_LIST");
export const FailAnalyticList = createAction("FAILED_ANALYTIC_LIST");
export const CleanUpAnalyticList= createAction("CLEANUP_ANALYTIC_LIST");

// ------UntHld---
export const REQAnalyticUntHldList = createAction("REQUEST_ANALYTIC_UNTHLD_LIST");
export const RESAnalyticUntHldList = createAction("RECEIVE_ANALYTIC_UNTHLD_LIST");
export const FailAnalyticUntHldList = createAction("FAILED_ANALYTIC_UNTHLD_LIST");
export const CleanUpAnalyticUntHldList= createAction("CLEANUP_ANALYTIC_UNTHLD_LIST");