import axios from "axios";

let instance = null;
function makeInstance() {
  if (instance) {
    return instance;
  } else {
    instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    });
    return instance;
  }
}

var axiosInstance = makeInstance();

export const setAuthHeader = () => {
};

export const resetAuthHeader = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
};

export default axiosInstance;
