export const list_of_columns = [
  {
    Header: "FUND NO.",
    accessor: "Fund_No",
    sortType: "alphanumeric",
    width: 110
  },
  {
    Header: "FUND CODE",
    accessor: "FundCode",
    width: 150
  },
  {
    Header: "ASSET CLASS",
    accessor: "AssetClassNameEN",
    width: 130
  },
  {
    Header: "RISK LEVEL",
    accessor: "risk_level",
    width: 120
  },
  {
    Header: "NAV (THB)",
    accessor: "NAVperUnit",
    width: 120
  },
  {
    Header: "NAV DATE",
    accessor: "nav_effdate",
    disableSortBy: true,
    width: 115
  },
  {
    Header: "MODIFIED DATE",
    accessor: "updated_date",
    width: 155
  },
  {
    Header: "FUND STATUS",
    accessor: "active",
    width: 140
  },
  {
    Header: "ACTION",
    accessor: "action",
    disableSortBy: true,
    width: 150
  }
];
// --------------------FundRecommend Codition --------------------
export const col_FundRecCodi = [
  {
    Header: "NO.",
    width: 50,
    disableSortBy: true
  },
  {
    Header: "CONDITION NAME",
    accessor: "codiname",
    width: 200
  },
  {
    Header: "MODIFIED BY",
    accessor: "modifyby",
    width: 120
  },
  {
    Header: "CREATED DATE",
    accessor: "created_at",
    width: 119
  },
  {
    Header: "MODIFIED DATE",
    accessor: "updated_at",
    width: 130
  },
  {
    Header: "STATUS",
    accessor: "status",
    width: 80
  },
  {
    Header: "ACTION",
    accessor: "usersta",
    disableSortBy: true,
    width: 80
  }
 
];

// --------------------FundRecommend Risk --------------------

export const col_FundRecRisk = [
  {
    Header: "RISK LEVEL",
    accessor: "id",
    disableSortBy: true,
    width: 100,
    paddingLeft:20,
    textAlign:"left"
  },
    {
    Header: "PORT NAME EN",
    accessor: "portnameen",
    sortType: "alphanumeric",
    width: 170,
    paddingLeft:20,
    textAlign:"left"
  },
  {
    Header: "MODIFIED BY",
    accessor: "created_by",
    width: 150,
    paddingLeft:20,
    textAlign:"left"
  },
  {
    Header: "CREATED DATE",
    accessor: "created_at",
    disableSortBy: true,
    width: 150,
    paddingLeft:20,
    textAlign:"left"
  },
  {
    Header: "MODIFIED DATE",
    accessor: "updated_at",
    width: 155,
    paddingLeft:20,
    textAlign:"left"
  },
  {
    Header: "ACTION",
    accessor: "action",
    disableSortBy: true,
    width: 100,
    paddingLeft:10,
    textAlign:"left"
  }
];

