export const list_of_columns = [

  {
    Header: "NO.",
     disableSortBy: true,
     textAlign:"left",
     paddingLeft:5,
     width: 40
  },
  {
    Header: "DEVICE NAME",
    accessor: "deviceName",
    disableSortBy: true,
    textAlign:"left",
    width: 140,
    paddingLeft:0
  },
  {
    Header: "USERNAME",
    accessor: "userName",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:20,
    width: 140
  }, 
  {
    Header: "CREATED AT",
    accessor: "createdAt",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:10,
    width: 150
  },
  {
    Header: "ACTIVITY TYPE",
    accessor: "activityType",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:10,
    width: 130
  },
  {
    Header: "APP VERSION",
    accessor: "appVersion",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:5,
    width: 100
  },
  {
    Header: "APP PLATFORM",
    accessor: "appPlatform",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:10,
    width: 120
  },
  {
    Header: "FIREBASE ID",
    accessor: "firebaseFid",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:10,
    width: 205

  },
  {
    Header: "EXPORT",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 80,
    paddingLeft:10
  }
];
