import React from "react";
import Images from "../../../utils/styles/images";
import "./style.scss";

function Pagination(props) {
  const {
    total,
    pageIndex,
    gotoPage,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage
  } = props;

  const totalNumberOfPages = total;

  const handleFirstClick = () => {
    gotoPage(0);
  };

  const handleLastClick = () => {
    gotoPage(total - 1);
  };

  const handleNextPageClick = () => {
    nextPage();
  };

  const handlePreviousPageClick = () => {
    previousPage();
  };

  const handlePageClick = buttonPageIndex => {
    gotoPage(buttonPageIndex);
  };

  const renderPaginationButtons = () => {
    let arrayOfButtons = [];

    // limit the number of shown buttons to 4
    for (let index = 1; index < 5; index += 1) {
      let displayValueForPageIndex = pageIndex + index;
      // so long as there are still pages remaining, we can add buttons for pagination
      if (displayValueForPageIndex <= totalNumberOfPages) {
        arrayOfButtons.push(
          <button
            className={
              displayValueForPageIndex === pageIndex + 1
                ? "pagination-button-selected"
                : "pagination-button"
            }
            onClick={() => handlePageClick(displayValueForPageIndex - 1)}
            key={"pagination-button" + displayValueForPageIndex}
          >
            {displayValueForPageIndex}
          </button>
        );
      }
    }

    return arrayOfButtons;
  };

  return (
    <div className="pagination-container">
      <button className="pagination-first" onClick={() => handleFirstClick()}>
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      <button
        className={
          !canPreviousPage
            ? "pagination-previous-disabled"
            : "pagination-previous"
        }
        onClick={() => handlePreviousPageClick()}
        disabled={!canPreviousPage}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      {renderPaginationButtons()}
      <button
        className={
          !canNextPage ? "pagination-next-disabled" : "pagination-next"
        }
        onClick={() => handleNextPageClick()}
        disabled={!canNextPage}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
      <button className="pagination-last" onClick={() => handleLastClick()}>
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
    </div>
  );
}

export default Pagination;
