import { createReducer } from "redux-act";

import {
  REQActiList,
  RESActiList,
  FailRegisActiList,
  CleanUpActiList
} from "./actions";

const initialState = {
  REQStrActivityList: false,
  ActivityList: [],
  errorMessage: false,
  totalActivity: 0
};

const actiListReducer = createReducer(
  {
  
    [REQActiList]: (state) => {
      return {
        ...state,
        REQStrActivityList: true
      };
    },
   
    [RESActiList]: (state, payload) => {
      return {
        ...state,
        REQStrActivityList: false,
        ActivityList: payload,
        totalActivity: payload.total
      };
    },
     
    [FailRegisActiList]: (state) => {
      return {
        ...state,
        REQStrActivityList: false,
        ActivityList: [],
        totalActivity: 0
      };
    } , 
    [CleanUpActiList]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default actiListReducer;
