import React, { Fragment,useEffect,useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter  } from "react-router-dom";
import "./style.scss";
function ConsentVSDetail(poPrp) {
  const {
    RowDataAt,
    history,
    PageCurrent,
    UISearch
  } = poPrp;

 useEffect(() => {
  return () => {      
       if (history.action === "POP") {
        history.replace({ pathname: '/Version',PageCurrent:PageCurrent, UISearch:UISearch });
       }
     };
 }, []);

    // ---------------Lang---------------------
    const EN = "ENGLISH";
    const TH = "THAI";
    var [oLang, SETxLang] = useState(EN);
    const SHWxDetailBlock = () => {
    
    if (oLang==="ENGLISH") {
      if(RowDataAt.consent_desc_en!=null && RowDataAt.consent_desc_en!=="") {
        return  (
          <span 
            style={{wordBreak:"break-all",
            border:"2px solid #D9D9D9",
            paddingLeft:"10px",
            paddingRight:"10px",
            paddingTop:"20px",
            paddingBottom:"20px",
            marginTop:"20px"
           }}
            dangerouslySetInnerHTML={{ __html:
              RowDataAt.consent_desc_en
            }} 
          > 
          </span>
        );
      }
    }
    else if (oLang==="THAI" && (RowDataAt.consent_desc_th!=null && RowDataAt.consent_desc_th!=="")) {
        return  (
          <span 
            style={{wordBreak:"break-all",
            border:"2px solid #D9D9D9",
            paddingLeft:"10px",
            paddingRight:"10px",
            paddingTop:"20px",
            paddingBottom:"20px",
            marginTop:"20px"
           }}
            dangerouslySetInnerHTML={{ __html:
              RowDataAt.consent_desc_th
            }} 
          > 
          </span>
        );
    }
  };

  return (

    <Fragment>
    <div className="tag-feed-form-container">
      <div className="acti-feed-form-inputs-container" style={{marginTop:50}}>
      

        <div className="conFd-feed-form-switch-button-container">
            <button
              onClick={() => SETxLang(EN)}
              className={
                oLang === EN
                  ? "selected-switch-button"
                  : "unselected-switch-button"
              }
              type="button"
            >
              {EN}
            </button>
            <button
              onClick={() => SETxLang(TH)}
              className={
                oLang === TH
                  ? "selected-switch-button"
                  : "unselected-switch-button"
              }
              type="button"
            >
              {TH}
            </button>
          </div>

            {SHWxDetailBlock()}
      </div>
    </div>
  </Fragment>

  );
}

const withConnect = connect(

  state => ({
    ...state.consentVSDetailReducer
  }),
  {}
);

export default compose(
  withConnect,
  withRouter
)(ConsentVSDetail);
