export const list_of_columns = [
    {
      Header: "NO.",
      accessor: "no"
    },
    {
      Header: "FUND NAME",
      accessor: "fundCode"
    },
    {
      Header: "MARKET VALUE (THB)",
      accessor: "amount"
    },
    {
      Header: "COST INVESTED (THB)",
      accessor: "costInvest"
    },
    {
      Header: "LATEST NAV (THB)",
      accessor: "navPrice"
    },
    {
      Header: "NO. OF UNITS",
      accessor: "unit"
    },
    {
      Header: "UNREALISED PROFIT & LOSS (THB)",
      accessor: "profitLoss"
    }
  ];