import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestFundsList,
  receiveFundsList,
  failFundsList,
  requestUploadDocuments,
  receiveUploadDocuments,
  failUploadDocuments,
  // --------GET Synce NAV--------
  REQSyncNAV,
  RESSyncNAV,
  FailSyncNAV
} from "./actions";

function* fetchFundsListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {

    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds`,
      {
        method: "GET",  
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    const response = yield request.json();

    if (response && response.data) {
      yield put(receiveFundsList(response));
    } else {
      yield put(failFundsList());
    }
  } catch (error) {
    
    yield put(failFundsList());
  }
}

function* uploadDocumentsWorker({ payload }) {
  try {
    let responsePerformance = false;
    if (payload.performanceDocument.length > 0) {
      let performanceFormData = new FormData();
      const accessToken = yield select(state => state.auth.accessToken);
      const tGUID = yield select(state => state.auth.GUID);
      performanceFormData.append("file", payload.performanceDocument[0]);

      responsePerformance = yield call(
        api.post,
        `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/performances/upload`,
        performanceFormData,
        {
          headers: {
            
          userid: tGUID,
          id_token: "Bearer " + accessToken
          }
        }
      );
    }

    let responseBenchmark = false;
    if (payload.benchmarkDocument.length > 0) {
      let benchmarkFormData = new FormData();
      const accessToken = yield select(state => state.auth.accessToken);
      const tGUID = yield select(state => state.auth.GUID);
      benchmarkFormData.append("file", payload.benchmarkDocument[0]);

      responseBenchmark = yield call(
        api.post,
        `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/benchmarks/upload`,
        benchmarkFormData,
        {
          headers: {
            
          userid: tGUID,
          id_token: "Bearer " + accessToken
          }
        }
      );
    }

    let responseDividend = false;
    if (payload.dividendDocument.length > 0) {
      let dividendFormData = new FormData();
      const accessToken = yield select(state => state.auth.accessToken);
      const tGUID = yield select(state => state.auth.GUID);
      dividendFormData.append("file", payload.dividendDocument[0]);

      responseDividend = yield call(
        api.post,
        `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/dividends/upload`,
        dividendFormData,
        {
          headers: {
            
          userid: tGUID,
          id_token: "Bearer " + accessToken
          }
        }
      );
    }

    if (responsePerformance || responseBenchmark || responseDividend) {
      yield put(receiveUploadDocuments());
    } else {
      yield put(failUploadDocuments());
    }
  } catch (error) {
    
    yield put(failUploadDocuments());
  }
}
// ---------------GET Sync NAV-------------
function* SYNCxNAV() {
  console.log("Start SYNCxNAV >>>>");
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const response = yield call(api.get, `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/syncFundsNAV`, { 
      headers: {
        
          userid: tGUID,
          id_token: "Bearer " + accessToken
      }
    });

    if (response && response.data && response.data.status) {
      console.log("syncFundsNAV true:",response.data);
      yield put(RESSyncNAV(response.data));
    } else {
      console.error("syncFundsNAV failed:",response.data);
      yield put(FailSyncNAV());
    }
  } catch (error) {
    
    yield put(FailSyncNAV());
  }
}

export function* fundsListSaga() {
  yield all([
    takeLatest(REQSyncNAV, SYNCxNAV),
    takeLatest(requestFundsList, fetchFundsListWorker),
    takeLatest(requestUploadDocuments, uploadDocumentsWorker)
  ]);
}
