export const sourceOfFundCodeToNameMap = [
    {
      abbr: "TH",
      code: "77",
      label: "ไทย",
      name: "ไทย",
      value: 1
    },
    {
      abbr: "AF",
      code: "105",
      label: "AFGHANISTAN",
      name: "AFGHANISTAN",
      value: 2
    },
    {
      abbr: "DZ",
      code: "112",
      label: "ALGERIA",
      name: "ALGERIA",
      value: 3
    },
    {
      abbr: "AD",
      code: "117",
      label: "ANDORRA",
      name: "ANDORRA",
      value: 4
    },
    {
      abbr: "AO",
      code: "114",
      label: "ANGOLA",
      name: "ANGOLA",
      value: 5
    },
    {
      abbr: "AI",
      code: "118",
      label: "ANGUILLA",
      name: "ANGUILLA",
      value: 6
    },
    {
      abbr: "AQ",
      code: "292",
      label: "ANTARCTICA",
      name: "ANTARCTICA",
      value: 7
    },
    {
      abbr: "AG",
      code: "119",
      label: "ANTIGUA AND BARBUDA",
      name: "ANTIGUA AND BARBUDA",
      value: 8
    },
    {
      abbr: "AM",
      code: "273",
      label: "ARMENIA",
      name: "ARMENIA",
      value: 9
    },
    {
      abbr: "AW",
      code: "293",
      label: "ARUBA",
      name: "ARUBA",
      value: 10
    },
    {
      abbr: "AZ",
      code: "145",
      label: "AZERBAIJAN",
      name: "AZERBAIJAN",
      value: 11
    },
    {
      abbr: "ZO",
      code: "269",
      label: "AZORES",
      name: "AZORES",
      value: 12
    },
    {
      abbr: "BB",
      code: "120",
      label: "BARBADOS",
      name: "BARBADOS",
      value: 13
    },
    {
      abbr: "BY",
      code: "275",
      label: "BELARUS",
      name: "BELARUS",
      value: 14
    },
    {
      abbr: "BZ",
      code: "294",
      label: "BELIZE",
      name: "BELIZE",
      value: 15
    },
    {
      abbr: "BT",
      code: "295",
      label: "BHUTAN",
      name: "BHUTAN",
      value: 16
    },
    {
      abbr: "BO",
      code: "146",
      label: "BOLIVIA",
      name: "BOLIVIA",
      value: 17
    },
    {
      abbr: "BA",
      code: "121",
      label: "BOSNIA & HERZEGOVINA",
      name: "BOSNIA & HERZEGOVINA",
      value: 18
    },
    {
      abbr: "BW",
      code: "122",
      label: "BOTSWANA",
      name: "BOTSWANA",
      value: 19
    },
    {
      abbr: "BV",
      code: "296",
      label: "BOUVET ISLAND",
      name: "BOUVET ISLAND",
      value: 20
    },
    {
      abbr: "BU",
      code: "276",
      label: "BURMA",
      name: "BURMA",
      value: 21
    },
    {
      abbr: "BI",
      code: "147",
      label: "BURUNDI",
      name: "BURUNDI",
      value: 22
    },
    {
      abbr: "CT",
      code: "297",
      label: "CANTON AND ENDERBURY ISLAND",
      name: "CANTON AND ENDERBURY ISLAND",
      value: 23
    },
    {
      abbr: "CV",
      code: "298",
      label: "CAPE VERDE",
      name: "CAPE VERDE",
      value: 24
    },
    {
      abbr: "CY",
      code: "329",
      label: "CAYMAN",
      name: "CAYMAN",
      value: 25
    },
    {
      abbr: "CF",
      code: "148",
      label: "CENTRAL AFRICAN REPUBLIC",
      name: "CENTRAL AFRICAN REPUBLIC",
      value: 26
    },
    {
      abbr: "TD",
      code: "149",
      label: "CHAD",
      name: "CHAD",
      value: 27
    },
    {
      abbr: "CX",
      code: "299",
      label: "CHRISTMAS ISLAND",
      name: "CHRISTMAS ISLAND",
      value: 28
    },
    {
      abbr: "CC",
      code: "300",
      label: "COCOS (KEELING) ISLANDS",
      name: "COCOS (KEELING) ISLANDS",
      value: 29
    },
    {
      abbr: "KM",
      code: "277",
      label: "COMOROS",
      name: "COMOROS",
      value: 30
    },
    {
      abbr: "CG",
      code: "150",
      label: "CONGO",
      name: "CONGO",
      value: 31
    },
    {
      abbr: "CK",
      code: "301",
      label: "COOK ISLANDS",
      name: "COOK ISLANDS",
      value: 32
    },
    {
      abbr: "CR",
      code: "123",
      label: "COSTA RICA",
      name: "COSTA RICA",
      value: 33
    },
    {
      abbr: "CI",
      code: "278",
      label: "COTE D'IVOIRE (IVORY COAST)",
      name: "COTE D'IVOIRE (IVORY COAST)",
      value: 34
    },
    {
      abbr: "HR",
      code: "279",
      label: "CROATIA",
      name: "CROATIA",
      value: 35
    },
    {
      abbr: "CW",
      code: "125",
      label: "CURACAO",
      name: "CURACAO",
      value: 36
    },
    {
      abbr: "KP",
      code: "137",
      label: "DEMOCRATIC PEOPLE'S REPUBLIC OF KOREA",
      name: "DEMOCRATIC PEOPLE'S REPUBLIC OF KOREA",
      value: 37
    },
    {
      abbr: "YD",
      code: "272",
      label: "DEMOCRATIC REPUBLIC OF YEMEN",
      name: "DEMOCRATIC REPUBLIC OF YEMEN",
      value: 38
    },
    {
      abbr: "DJ",
      code: "280",
      label: "DJIBOUTI",
      name: "DJIBOUTI",
      value: 39
    },
    {
      abbr: "DM",
      code: "127",
      label: "DOMINICA",
      name: "DOMINICA",
      value: 40
    },
    {
      abbr: "DO",
      code: "281",
      label: "DOMINICAN REPUBLIC",
      name: "DOMINICAN REPUBLIC",
      value: 41
    },
    {
      abbr: "NQ",
      code: "302",
      label: "DRONNING MAUD LAND",
      name: "DRONNING MAUD LAND",
      value: 42
    },
    {
      abbr: "EG",
      code: "153",
      label: "EGYPT",
      name: "EGYPT",
      value: 43
    },
    {
      abbr: "GQ",
      code: "154",
      label: "EQUATORIAL GUINEA",
      name: "EQUATORIAL GUINEA",
      value: 44
    },
    {
      abbr: "ER",
      code: "155",
      label: "ERITREA",
      name: "ERITREA",
      value: 45
    },
    {
      abbr: "EE",
      code: "303",
      label: "ESTONIA",
      name: "ESTONIA",
      value: 46
    },
    {
      abbr: "ET",
      code: "288",
      label: "ETHIOPIA",
      name: "ETHIOPIA",
      value: 47
    },
    {
      abbr: "FO",
      code: "304",
      label: "FAEROE ISLANDS",
      name: "FAEROE ISLANDS",
      value: 48
    },
    {
      abbr: "FK",
      code: "305",
      label: "FALKLAND ISLANDS (MALVINAS)",
      name: "FALKLAND ISLANDS (MALVINAS)",
      value: 49
    },
    {
      abbr: "FJ",
      code: "306",
      label: "FIJI",
      name: "FIJI",
      value: 50
    },
    {
      abbr: "GF",
      code: "307",
      label: "FRENCH GUIANA",
      name: "FRENCH GUIANA",
      value: 51
    },
    {
      abbr: "GA",
      code: "156",
      label: "GABON",
      name: "GABON",
      value: 52
    },
    {
      abbr: "GM",
      code: "157",
      label: "GAMBIA",
      name: "GAMBIA",
      value: 53
    },
    {
      abbr: "GP",
      code: "308",
      label: "GUADELOUPE",
      name: "GUADELOUPE",
      value: 54
    },
    {
      abbr: "GU",
      code: "309",
      label: "GUAM",
      name: "GUAM",
      value: 55
    },
    {
      abbr: "GT",
      code: "115",
      label: "GUATEMALA",
      name: "GUATEMALA",
      value: 56
    },
    {
      abbr: "GN",
      code: "289",
      label: "GUINEA",
      name: "GUINEA",
      value: 57
    },
    {
      abbr: "GW",
      code: "159",
      label: "GUINEA BISSAU",
      name: "GUINEA BISSAU",
      value: 58
    },
    {
      abbr: "GY",
      code: "130",
      label: "GUYANA",
      name: "GUYANA",
      value: 59
    },
    {
      abbr: "HM",
      code: "310",
      label: "HEARD AND MCDONALD ISLANDS",
      name: "HEARD AND MCDONALD ISLANDS",
      value: 60
    },
    {
      abbr: "HN",
      code: "290",
      label: "HONDURAS",
      name: "HONDURAS",
      value: 61
    },
    {
      abbr: "00",
      code: "311",
      label: "INTL ORG (OTH NON-EU)",
      name: "INTL ORG (OTH NON-EU)",
      value: 62
    },
    {
      abbr: "EO",
      code: "312",
      label: "INTL ORGANIZATION (EU)",
      name: "INTL ORGANIZATION (EU)",
      value: 63
    },
    {
      abbr: "IV",
      code: "282",
      label: "IVORY COAST",
      name: "IVORY COAST",
      value: 64
    },
    {
      abbr: "KZ",
      code: "131",
      label: "KAZAKHSTAN",
      name: "KAZAKHSTAN",
      value: 65
    },
    {
      abbr: "KE",
      code: "160",
      label: "KENYA",
      name: "KENYA",
      value: 66
    },
    {
      abbr: "KI",
      code: "313",
      label: "KIRIBATI",
      name: "KIRIBATI",
      value: 67
    },
    {
      abbr: "KW",
      code: "314",
      label: "KUWAIT",
      name: "KUWAIT",
      value: 68
    },
    {
      abbr: "KG",
      code: "161",
      label: "KYRGYZSTAN",
      name: "KYRGYZSTAN",
      value: 69
    },
    {
      abbr: "MO",
      code: "283",
      label: "MACAU",
      name: "MACAU",
      value: 70
    },
    {
      abbr: "MK",
      code: "284",
      label: "MACEDONIA",
      name: "MACEDONIA",
      value: 71
    },
    {
      abbr: "MG",
      code: "162",
      label: "MADAGASCAR",
      name: "MADAGASCAR",
      value: 72
    },
    {
      abbr: "MW",
      code: "163",
      label: "MALAWI",
      name: "MALAWI",
      value: 73
    },
    {
      abbr: "ML",
      code: "164",
      label: "MALI",
      name: "MALI",
      value: 74
    },
    {
      abbr: "MH",
      code: "133",
      label: "MARSHALL ISLANDS",
      name: "MARSHALL ISLANDS",
      value: 75
    },
    {
      abbr: "MQ",
      code: "315",
      label: "MARTINIQUE",
      name: "MARTINIQUE",
      value: 76
    },
    {
      abbr: "MR",
      code: "165",
      label: "MAURITANIA",
      name: "MAURITANIA",
      value: 77
    },
    {
      abbr: "FM",
      code: "134",
      label: "MICRONESIA",
      name: "MICRONESIA",
      value: 78
    },
    {
      abbr: "MC",
      code: "166",
      label: "MONACO",
      name: "MONACO",
      value: 79
    },
    {
      abbr: "MN",
      code: "316",
      label: "MONGOLIA",
      name: "MONGOLIA",
      value: 80
    },
    {
      abbr: "ME",
      code: "317",
      label: "MONTENEGRO",
      name: "MONTENEGRO",
      value: 81
    },
    {
      abbr: "MZ",
      code: "167",
      label: "MOZAMBIQUE",
      name: "MOZAMBIQUE",
      value: 82
    },
    {
      abbr: "NR",
      code: "135",
      label: "NAURU",
      name: "NAURU",
      value: 83
    },
    {
      abbr: "AN",
      code: "168",
      label: "NETHERLANDS ANTILLES",
      name: "NETHERLANDS ANTILLES",
      value: 84
    },
    {
      abbr: "NC",
      code: "169",
      label: "NEW CALEDONIA",
      name: "NEW CALEDONIA",
      value: 85
    },
    {
      abbr: "NI",
      code: "170",
      label: "NICARAGUA",
      name: "NICARAGUA",
      value: 86
    },
    {
      abbr: "NE",
      code: "171",
      label: "NIGER",
      name: "NIGER",
      value: 87
    },
    {
      abbr: "NG",
      code: "113",
      label: "NIGERIA",
      name: "NIGERIA",
      value: 88
    },
    {
      abbr: "NU",
      code: "172",
      label: "NIUE",
      name: "NIUE",
      value: 89
    },
    {
      abbr: "PU",
      code: "318",
      label: "PACIFIC ISLANDS",
      name: "PACIFIC ISLANDS",
      value: 90
    },
    {
      abbr: "PS",
      code: "213",
      label: "PALESTINIAN TERRITORY, OCCUPIED",
      name: "PALESTINIAN TERRITORY, OCCUPIED",
      value: 91
    },
    {
      abbr: "PA",
      code: "291",
      label: "PANAMA",
      name: "PANAMA",
      value: 92
    },
    {
      abbr: "PG",
      code: "110",
      label: "PAPUA NEW GUINEA",
      name: "PAPUA NEW GUINEA",
      value: 93
    },
    {
      abbr: "PY",
      code: "173",
      label: "PARAGUAY",
      name: "PARAGUAY",
      value: 94
    },
    {
      abbr: "CM",
      code: "174",
      label: "REPUBLIC OF CAMEROON",
      name: "REPUBLIC OF CAMEROON",
      value: 95
    },
    {
      abbr: "MD",
      code: "175",
      label: "REPUBLIC OF MOLDOVA",
      name: "REPUBLIC OF MOLDOVA",
      value: 96
    },
    {
      abbr: "YE",
      code: "106",
      label: "REPUBLIC OF YEMEN",
      name: "REPUBLIC OF YEMEN",
      value: 97
    },
    {
      abbr: "RE",
      code: "176",
      label: "REUNION",
      name: "REUNION",
      value: 98
    },
    {
      abbr: "LC",
      code: "136",
      label: "SAINT LUCIA",
      name: "SAINT LUCIA",
      value: 99
    },
    {
      abbr: "SM",
      code: "177",
      label: "SAN MARINO",
      name: "SAN MARINO",
      value: 100
    },
    {
      abbr: "ST",
      code: "138",
      label: "SAO TOME & PRINCIPE",
      name: "SAO TOME & PRINCIPE",
      value: 101
    },
    {
      abbr: "RS",
      code: "220",
      label: "SERBIA",
      name: "SERBIA",
      value: 102
    },
    {
      abbr: "SC",
      code: "139",
      label: "SEYCHELLES",
      name: "SEYCHELLES",
      value: 103
    },
    {
      abbr: "SL",
      code: "178",
      label: "SIERRA LEONE",
      name: "SIERRA LEONE",
      value: 104
    },
    {
      abbr: "SX",
      code: "140",
      label: "SINT MAARTEN",
      name: "SINT MAARTEN",
      value: 105
    },
    {
      abbr: "SX",
      code: "238",
      label: "SINT MAARTEN (DUTCH PART)",
      name: "SINT MAARTEN (DUTCH PART)",
      value: 106
    },
    {
      abbr: "SO",
      code: "179",
      label: "SOMALIA",
      name: "SOMALIA",
      value: 107
    },
    {
      abbr: "ZA",
      code: "180",
      label: "SOUTH AFRICA",
      name: "SOUTH AFRICA",
      value: 108
    },
    {
      abbr: "GS",
      code: "319",
      label: "SOUTH GEORGIA AND SOUTH SANDWICH ISLANDS",
      name: "SOUTH GEORGIA AND SOUTH SANDWICH ISLANDS",
      value: 109
    },
    {
      abbr: "SS",
      code: "141",
      label: "SOUTH SUDAN",
      name: "SOUTH SUDAN",
      value: 110
    },
    {
      abbr: "KN",
      code: "142",
      label: "ST.KITTS-NEVISANGUILLA",
      name: "ST.KITTS-NEVISANGUILLA",
      value: 111
    },
    {
      abbr: "SD",
      code: "143",
      label: "SUDAN",
      name: "SUDAN",
      value: 112
    },
    {
      abbr: "SR",
      code: "181",
      label: "SURINAME",
      name: "SURINAME",
      value: 113
    },
    {
      abbr: "SY",
      code: "108",
      label: "SYRIAN ARAB REPUBLIC",
      name: "SYRIAN ARAB REPUBLIC",
      value: 114
    },
    {
      abbr: "TJ",
      code: "182",
      label: "TAJIKISTAN",
      name: "TAJIKISTAN",
      value: 115
    },
    {
      abbr: "CD",
      code: "151",
      label: "The Democratic Republic of the CONGO",
      name: "The Democratic Republic of the CONGO",
      value: 116
    },
    {
      abbr: "TL",
      code: "183",
      label: "TIMOR-LESTE",
      name: "TIMOR-LESTE",
      value: 117
    },
    {
      abbr: "TG",
      code: "184",
      label: "TOGO",
      name: "TOGO",
      value: 118
    },
    {
      abbr: "TT",
      code: "144",
      label: "TRINIDAD AND TOBAGO",
      name: "TRINIDAD AND TOBAGO",
      value: 119
    },
    {
      abbr: "TM",
      code: "185",
      label: "TURKMENISTAN",
      name: "TURKMENISTAN",
      value: 120
    },
    {
      abbr: "UG",
      code: "111",
      label: "UGANDA",
      name: "UGANDA",
      value: 121
    },
    {
      abbr: "UA",
      code: "186",
      label: "UKRAINE",
      name: "UKRAINE",
      value: 122
    },
    {
      abbr: "TZ",
      code: "187",
      label: "UNITED REPUBLIC OF TANZANIA",
      name: "UNITED REPUBLIC OF TANZANIA",
      value: 123
    },
    {
      abbr: "UZ",
      code: "188",
      label: "UZBEKISTAN",
      name: "UZBEKISTAN",
      value: 124
    },
    {
      abbr: "VU",
      code: "109",
      label: "VANUATU",
      name: "VANUATU",
      value: 125
    },
    {
      abbr: "VE",
      code: "189",
      label: "VENEZUELA",
      name: "VENEZUELA",
      value: 126
    },
    {
      abbr: "WK",
      code: "320",
      label: "WAKE ISLAND",
      name: "WAKE ISLAND",
      value: 127
    },
    {
      abbr: "WB",
      code: "285",
      label: "WB WEST BANK-PALESTINE",
      name: "WB WEST BANK-PALESTINE",
      value: 128
    },
    {
      abbr: "EH",
      code: "286",
      label: "WESTERN SAHARA",
      name: "WESTERN SAHARA",
      value: 129
    },
    {
      abbr: "GR",
      code: "29",
      label: "กรีซ",
      name: "กรีซ",
      value: 130
    },
    {
      abbr: "KH",
      code: "15",
      label: "กัมพูชา",
      name: "กัมพูชา",
      value: 131
    },
    {
      abbr: "QA",
      code: "217",
      label: "กาตาร์",
      name: "กาตาร์",
      value: 132
    },
    {
      abbr: "GH",
      code: "30",
      label: "กานา",
      name: "กานา",
      value: 133
    },
    {
      abbr: "KR",
      code: "44",
      label: "เกาหลี",
      name: "เกาหลี",
      value: 134
    },
    {
      abbr: "NF",
      code: "201",
      label: "เกาะนอร์ฟอล์ก",
      name: "เกาะนอร์ฟอล์ก",
      value: 135
    },
    {
      abbr: "PZ",
      code: "216",
      label: "คลองปานามา",
      name: "คลองปานามา",
      value: 136
    },
    {
      abbr: "CM",
      code: "17",
      label: "เคย์แมน ไอแลนด์",
      name: "เคย์แมน ไอแลนด์",
      value: 137
    },
    {
      abbr: "PR",
      code: "212",
      label: "เครือรัฐเปอร์โตริโก",
      name: "เครือรัฐเปอร์โตริโก",
      value: 138
    },
    {
      abbr: "CA",
      code: "16",
      label: "แคนาดา",
      name: "แคนาดา",
      value: 139
    },
    {
      abbr: "CO",
      code: "20",
      label: "โคลัมเบีย",
      name: "โคลัมเบีย",
      value: 140
    },
    {
      abbr: "JO",
      code: "42",
      label: "จอร์แดน",
      name: "จอร์แดน",
      value: 141
    },
    {
      abbr: "CN",
      code: "19",
      label: "จีน",
      name: "จีน",
      value: 142
    },
    {
      abbr: "TD",
      code: "242",
      label: "ชาด",
      name: "ชาด",
      value: 143
    },
    {
      abbr: "CH",
      code: "18",
      label: "แชนแนล ไอแลนด์",
      name: "แชนแนล ไอแลนด์",
      value: 144
    },
    {
      abbr: "SM",
      code: "231",
      label: "ซานมารีโน",
      name: "ซานมารีโน",
      value: 145
    },
    {
      abbr: "WS",
      code: "69",
      label: "ซามัว",
      name: "ซามัว",
      value: 146
    },
    {
      abbr: "ZR",
      code: "270",
      label: "ซาอีร์",
      name: "ซาอีร์",
      value: 147
    },
    {
      abbr: "SA",
      code: "222",
      label: "ซาอุดีอาระเบีย",
      name: "ซาอุดีอาระเบีย",
      value: 148
    },
    {
      abbr: "ZW",
      code: "85",
      label: "ซิมบับเว",
      name: "ซิมบับเว",
      value: 149
    },
    {
      abbr: "SY",
      code: "239",
      label: "ซีเรีย",
      name: "ซีเรีย",
      value: 150
    },
    {
      abbr: "SD",
      code: "225",
      label: "ซูดาน",
      name: "ซูดาน",
      value: 151
    },
    {
      abbr: "SR",
      code: "234",
      label: "ซูรินาเม",
      name: "ซูรินาเม",
      value: 152
    },
    {
      abbr: "SC",
      code: "224",
      label: "เซเชลส์",
      name: "เซเชลส์",
      value: 153
    },
    {
      abbr: "VC",
      code: "260",
      label: "เซนต์วินเซนต์ และเกรนาดีนส์",
      name: "เซนต์วินเซนต์ และเกรนาดีนส์",
      value: 154
    },
    {
      abbr: "SH",
      code: "226",
      label: "เซนต์เฮเลนา",
      name: "เซนต์เฮเลนา",
      value: 155
    },
    {
      abbr: "SN",
      code: "232",
      label: "เซเนกัล",
      name: "เซเนกัล",
      value: 156
    },
    {
      abbr: "ST",
      code: "236",
      label: "เซาตูเมและปรินซิปี",
      name: "เซาตูเมและปรินซิปี",
      value: 157
    },
    {
      abbr: "SL",
      code: "230",
      label: "เซียร์ราลีโอน",
      name: "เซียร์ราลีโอน",
      value: 158
    },
    {
      abbr: "PM",
      code: "210",
      label: "แซงปิแยร์และมีเกอลง",
      name: "แซงปิแยร์และมีเกอลง",
      value: 159
    },
    {
      abbr: "ZM",
      code: "268",
      label: "แซมเบีย",
      name: "แซมเบีย",
      value: 160
    },
    {
      abbr: "SO",
      code: "233",
      label: "โซมาเลีย",
      name: "โซมาเลีย",
      value: 161
    },
    {
      abbr: "CY",
      code: "21",
      label: "ไซปรัส",
      name: "ไซปรัส",
      value: 162
    },
    {
      abbr: "JP",
      code: "41",
      label: "ญี่ปุ่น",
      name: "ญี่ปุ่น",
      value: 163
    },
    {
      abbr: "DK",
      code: "22",
      label: "เดนมาร์ก",
      name: "เดนมาร์ก",
      value: 164
    },
    {
      abbr: "DM",
      code: "23",
      label: "โดมินิกัน รีพับลิค",
      name: "โดมินิกัน รีพับลิค",
      value: 165
    },
    {
      abbr: "TT",
      code: "251",
      label: "ตรินิแดดและโตเบโก",
      name: "ตรินิแดดและโตเบโก",
      value: 166
    },
    {
      abbr: "TP",
      code: "250",
      label: "ติมอร์ตะวันออก",
      name: "ติมอร์ตะวันออก",
      value: 167
    },
    {
      abbr: "TL",
      code: "247",
      label: "ติมอร์-เลสเต",
      name: "ติมอร์-เลสเต",
      value: 168
    },
    {
      abbr: "TR",
      code: "79",
      label: "ตุรกี",
      name: "ตุรกี",
      value: 169
    },
    {
      abbr: "TN",
      code: "249",
      label: "ตูนิเซีย",
      name: "ตูนิเซีย",
      value: 170
    },
    {
      abbr: "TV",
      code: "252",
      label: "ตูวาลู",
      name: "ตูวาลู",
      value: 171
    },
    {
      abbr: "TM",
      code: "248",
      label: "เติร์กเมนิสถาน",
      name: "เติร์กเมนิสถาน",
      value: 172
    },
    {
      abbr: "TK",
      code: "246",
      label: "โตเกเลา",
      name: "โตเกเลา",
      value: 173
    },
    {
      abbr: "TG",
      code: "244",
      label: "โตโก",
      name: "โตโก",
      value: 174
    },
    {
      abbr: "TW",
      code: "76",
      label: "ไต้หวัน",
      name: "ไต้หวัน",
      value: 175
    },
    {
      abbr: "TO",
      code: "78",
      label: "ทองก้า",
      name: "ทองก้า",
      value: 176
    },
    {
      abbr: "TJ",
      code: "245",
      label: "ทาจิกิสถาน",
      name: "ทาจิกิสถาน",
      value: 177
    },
    {
      abbr: "TZ",
      code: "253",
      label: "แทนซาเนีย",
      name: "แทนซาเนีย",
      value: 178
    },
    {
      abbr: "VA",
      code: "259",
      label: "นครรัฐวาติกัน",
      name: "นครรัฐวาติกัน",
      value: 179
    },
    {
      abbr: "MP",
      code: "58",
      label: "นอร์ธเทิร์น ไอร์แลนด์",
      name: "นอร์ธเทิร์น ไอร์แลนด์",
      value: 180
    },
    {
      abbr: "NO",
      code: "59",
      label: "นอร์เวย์",
      name: "นอร์เวย์",
      value: 181
    },
    {
      abbr: "NA",
      code: "198",
      label: "นามิเบีย",
      name: "นามิเบีย",
      value: 182
    },
    {
      abbr: "NR",
      code: "204",
      label: "นาอูรู",
      name: "นาอูรู",
      value: 183
    },
    {
      abbr: "NI",
      code: "203",
      label: "นิการากัว",
      name: "นิการากัว",
      value: 184
    },
    {
      abbr: "NC",
      code: "199",
      label: "นิวแคลิโดเนีย, นูแวลกาเลโดนี",
      name: "นิวแคลิโดเนีย, นูแวลกาเลโดนี",
      value: 185
    },
    {
      abbr: "NZ",
      code: "57",
      label: "นิวซีแลนด์",
      name: "นิวซีแลนด์",
      value: 186
    },
    {
      abbr: "NU",
      code: "205",
      label: "นีอูเอ",
      name: "นีอูเอ",
      value: 187
    },
    {
      abbr: "NL",
      code: "56",
      label: "เนเธอร์แลนด์",
      name: "เนเธอร์แลนด์",
      value: 188
    },
    {
      abbr: "NP",
      code: "55",
      label: "เนปาล",
      name: "เนปาล",
      value: 189
    },
    {
      abbr: "NG",
      code: "202",
      label: "ไนจีเรีย",
      name: "ไนจีเรีย",
      value: 190
    },
    {
      abbr: "NE",
      code: "200",
      label: "ไนเจอร์",
      name: "ไนเจอร์",
      value: 191
    },
    {
      abbr: "BR",
      code: "10",
      label: "บราซิล",
      name: "บราซิล",
      value: 192
    },
    {
      abbr: "VG",
      code: "11",
      label: "บริติช เวอจิน ไอแลนด์",
      name: "บริติช เวอจิน ไอแลนด์",
      value: 193
    },
    {
      abbr: "BN",
      code: "13",
      label: "บรูไน",
      name: "บรูไน",
      value: 194
    },
    {
      abbr: "BD",
      code: "6",
      label: "บังคลาเทศ",
      name: "บังคลาเทศ",
      value: 195
    },
    {
      abbr: "BH",
      code: "5",
      label: "บาห์เรน",
      name: "บาห์เรน",
      value: 196
    },
    {
      abbr: "BS",
      code: "4",
      label: "บาฮาม่า",
      name: "บาฮาม่า",
      value: 197
    },
    {
      abbr: "BJ",
      code: "8",
      label: "เบนิน",
      name: "เบนิน",
      value: 198
    },
    {
      abbr: "BE",
      code: "7",
      label: "เบลเยี่ยม",
      name: "เบลเยี่ยม",
      value: 199
    },
    {
      abbr: "BM",
      code: "9",
      label: "เบอมิวดา",
      name: "เบอมิวดา",
      value: 200
    },
    {
      abbr: "PK",
      code: "62",
      label: "ปากีสถาน",
      name: "ปากีสถาน",
      value: 201
    },
    {
      abbr: "PA",
      code: "63",
      label: "ปานามา",
      name: "ปานามา",
      value: 202
    },
    {
      abbr: "PG",
      code: "209",
      label: "ปาปัวนิวกินี",
      name: "ปาปัวนิวกินี",
      value: 203
    },
    {
      abbr: "PY",
      code: "215",
      label: "ปารากวัย",
      name: "ปารากวัย",
      value: 204
    },
    {
      abbr: "PW",
      code: "214",
      label: "ปาเลา",
      name: "ปาเลา",
      value: 205
    },
    {
      abbr: "PE",
      code: "64",
      label: "เปรู",
      name: "เปรู",
      value: 206
    },
    {
      abbr: "PO",
      code: "67",
      label: "โปรตุเกส",
      name: "โปรตุเกส",
      value: 207
    },
    {
      abbr: "PL",
      code: "66",
      label: "โปแลนด์",
      name: "โปแลนด์",
      value: 208
    },
    {
      abbr: "FR",
      code: "27",
      label: "ฝรั่งเศส",
      name: "ฝรั่งเศส",
      value: 209
    },
    {
      abbr: "MM",
      code: "14",
      label: "พม่า",
      name: "พม่า",
      value: 210
    },
    {
      abbr: "FI",
      code: "26",
      label: "ฟินแลนด์",
      name: "ฟินแลนด์",
      value: 211
    },
    {
      abbr: "PH",
      code: "65",
      label: "ฟิลิปปินส์",
      name: "ฟิลิปปินส์",
      value: 212
    },
    {
      abbr: "TF",
      code: "243",
      label: "เฟรนช์เซาเทิร์น, แอนตาร์กติกเทร์ริทอรีส์",
      name: "เฟรนช์เซาเทิร์น, แอนตาร์กติกเทร์ริทอรีส์",
      value: 213
    },
    {
      abbr: "PF",
      code: "208",
      label: "เฟรนช์โปลินีเซีย",
      name: "เฟรนช์โปลินีเซีย",
      value: 214
    },
    {
      abbr: "MS",
      code: "193",
      label: "มอนต์เซอร์รัต",
      name: "มอนต์เซอร์รัต",
      value: 215
    },
    {
      abbr: "MU",
      code: "53",
      label: "มอริเชียส",
      name: "มอริเชียส",
      value: 216
    },
    {
      abbr: "MT",
      code: "194",
      label: "มอลตา",
      name: "มอลตา",
      value: 217
    },
    {
      abbr: "MV",
      code: "195",
      label: "มัลดีฟส์",
      name: "มัลดีฟส์",
      value: 218
    },
    {
      abbr: "YT",
      code: "266",
      label: "มายอต",
      name: "มายอต",
      value: 219
    },
    {
      abbr: "MW",
      code: "196",
      label: "มาลาวี",
      name: "มาลาวี",
      value: 220
    },
    {
      abbr: "MY",
      code: "52",
      label: "มาเลเซีย",
      name: "มาเลเซีย",
      value: 221
    },
    {
      abbr: "MX",
      code: "54",
      label: "เม็กซิโก",
      name: "เม็กซิโก",
      value: 222
    },
    {
      abbr: "MZ",
      code: "197",
      label: "โมซัมบิก",
      name: "โมซัมบิก",
      value: 223
    },
    {
      abbr: "NO",
      code: "0",
      label: "ไม่ทราบรหัสประเทศ",
      name: "ไม่ทราบรหัสประเทศ",
      value: 224
    },
    {
      abbr: "ZZ",
      code: "271",
      label: "ไม่มีสัญชาติที่ระบุได้",
      name: "ไม่มีสัญชาติที่ระบุได้",
      value: 225
    },
    {
      abbr: "UG",
      code: "255",
      label: "ยูกันดา",
      name: "ยูกันดา",
      value: 226
    },
    {
      abbr: "YU",
      code: "84",
      label: "ยูโกสลาเวีย",
      name: "ยูโกสลาเวีย",
      value: 227
    },
    {
      abbr: "UA",
      code: "254",
      label: "ยูเครน",
      name: "ยูเครน",
      value: 228
    },
    {
      abbr: "UM",
      code: "256",
      label: "ยูเอส ไมเนอร์",
      name: "ยูเอส ไมเนอร์",
      value: 229
    },
    {
      abbr: "YE",
      code: "265",
      label: "เยเมน",
      name: "เยเมน",
      value: 230
    },
    {
      abbr: "DE",
      code: "28",
      label: "เยอรมัน",
      name: "เยอรมัน",
      value: 231
    },
    {
      abbr: "RW",
      code: "221",
      label: "รวันดา",
      name: "รวันดา",
      value: 232
    },
    {
      abbr: "OMAN",
      code: "191",
      label: "รัฐสุลต่านโอมาน",
      name: "รัฐสุลต่านโอมาน",
      value: 233
    },
    {
      abbr: "RU",
      code: "68",
      label: "รัสเซีย",
      name: "รัสเซีย",
      value: 234
    },
    {
      abbr: "RE",
      code: "218",
      label: "เรอูนียง",
      name: "เรอูนียง",
      value: 235
    },
    {
      abbr: "RO",
      code: "219",
      label: "โรมาเนีย",
      name: "โรมาเนีย",
      value: 236
    },
    {
      abbr: "LU",
      code: "51",
      label: "ลักเซมเบอร์ก",
      name: "ลักเซมเบอร์ก",
      value: 237
    },
    {
      abbr: "LA",
      code: "45",
      label: "ลาว",
      name: "ลาว",
      value: 238
    },
    {
      abbr: "LI",
      code: "49",
      label: "ลิกเคนสไตน์",
      name: "ลิกเคนสไตน์",
      value: 239
    },
    {
      abbr: "LT",
      code: "50",
      label: "ลิทัวเนีย",
      name: "ลิทัวเนีย",
      value: 240
    },
    {
      abbr: "LY",
      code: "48",
      label: "ลิเบีย",
      name: "ลิเบีย",
      value: 241
    },
    {
      abbr: "LB",
      code: "46",
      label: "เลบานอน",
      name: "เลบานอน",
      value: 242
    },
    {
      abbr: "LR",
      code: "47",
      label: "ไลบีเรีย",
      name: "ไลบีเรีย",
      value: 243
    },
    {
      abbr: "VU",
      code: "263",
      label: "วานูอาตู",
      name: "วานูอาตู",
      value: 244
    },
    {
      abbr: "VE",
      code: "261",
      label: "เวเนซุเอลา",
      name: "เวเนซุเอลา",
      value: 245
    },
    {
      abbr: "VN",
      code: "83",
      label: "เวียดนาม",
      name: "เวียดนาม",
      value: 246
    },
    {
      abbr: "LK",
      code: "73",
      label: "ศรีลังกา",
      name: "ศรีลังกา",
      value: 247
    },
    {
      abbr: "SC",
      code: "70",
      label: "สก๊อตแลนด์",
      name: "สก๊อตแลนด์",
      value: 248
    },
    {
      abbr: "ES",
      code: "72",
      label: "สเปน",
      name: "สเปน",
      value: 249
    },
    {
      abbr: "SJ",
      code: "228",
      label: "สฟาลบาร์",
      name: "สฟาลบาร์",
      value: 250
    },
    {
      abbr: "SK",
      code: "229",
      label: "สโลวาเกีย",
      name: "สโลวาเกีย",
      value: 251
    },
    {
      abbr: "SI",
      code: "227",
      label: "สโลวีเนีย",
      name: "สโลวีเนีย",
      value: 252
    },
    {
      abbr: "SZ",
      code: "240",
      label: "สวาซิแลนด์",
      name: "สวาซิแลนด์",
      value: 253
    },
    {
      abbr: "CH",
      code: "75",
      label: "สวิตเซอร์แลนด์",
      name: "สวิตเซอร์แลนด์",
      value: 254
    },
    {
      abbr: "SE",
      code: "74",
      label: "สวีเดน",
      name: "สวีเดน",
      value: 255
    },
    {
      abbr: "US",
      code: "80",
      label: "สหรัฐอเมริกา",
      name: "สหรัฐอเมริกา",
      value: 256
    },
    {
      abbr: "AE",
      code: "81",
      label: "สหรัฐอาหรับเอมีเรส",
      name: "สหรัฐอาหรับเอมีเรส",
      value: 257
    },
    {
      abbr: "GB",
      code: "82",
      label: "สหราชอาณาจักร",
      name: "สหราชอาณาจักร",
      value: 258
    },
    {
      abbr: "SG",
      code: "71",
      label: "สิงคโปร์",
      name: "สิงคโปร์",
      value: 259
    },
    {
      abbr: "SB",
      code: "223",
      label: "หมู่เกาะโซโลมอน",
      name: "หมู่เกาะโซโลมอน",
      value: 260
    },
    {
      abbr: "TC",
      code: "241",
      label: "หมู่เกาะเติกส์และเคคอส",
      name: "หมู่เกาะเติกส์และเคคอส",
      value: 261
    },
    {
      abbr: "PN",
      code: "211",
      label: "หมู่เกาะพิตแคร์น",
      name: "หมู่เกาะพิตแคร์น",
      value: 262
    },
    {
      abbr: "WF",
      code: "264",
      label: "หมู่เกาะวาลลิสและหมู่เกาะฟุตูนา",
      name: "หมู่เกาะวาลลิสและหมู่เกาะฟุตูนา",
      value: 263
    },
    {
      abbr: "VI",
      code: "262",
      label: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
      name: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
      value: 264
    },
    {
      abbr: "AU",
      code: "2",
      label: "ออสเตรเลีย",
      name: "ออสเตรเลีย",
      value: 265
    },
    {
      abbr: "AT",
      code: "3",
      label: "ออสเตรีย",
      name: "ออสเตรีย",
      value: 266
    },
    {
      abbr: "GB",
      code: "25",
      label: "อังกฤษ",
      name: "อังกฤษ",
      value: 267
    },
    {
      abbr: "AR",
      code: "1",
      label: "อาร์เจนติน่า",
      name: "อาร์เจนติน่า",
      value: 268
    },
    {
      abbr: "IT",
      code: "40",
      label: "อิตาลี",
      name: "อิตาลี",
      value: 269
    },
    {
      abbr: "IN",
      code: "35",
      label: "อินเดีย",
      name: "อินเดีย",
      value: 270
    },
    {
      abbr: "ID",
      code: "36",
      label: "อินโดนีเซีย",
      name: "อินโดนีเซีย",
      value: 271
    },
    {
      abbr: "IQ",
      code: "104",
      label: "อิรัก",
      name: "อิรัก",
      value: 272
    },
    {
      abbr: "IL",
      code: "39",
      label: "อิสราเอล",
      name: "อิสราเอล",
      value: 273
    },
    {
      abbr: "IR",
      code: "37",
      label: "อิหร่าน",
      name: "อิหร่าน",
      value: 274
    },
    {
      abbr: "OTH",
      code: "60",
      label: "อื่น ๆ",
      name: "อื่น ๆ",
      value: 275
    },
    {
      abbr: "UZ",
      code: "258",
      label: "อุซเบกิสถาน",
      name: "อุซเบกิสถาน",
      value: 276
    },
    {
      abbr: "UY",
      code: "257",
      label: "อุรุกวัย",
      name: "อุรุกวัย",
      value: 277
    },
    {
      abbr: "EC",
      code: "24",
      label: "เอกวาดอร์",
      name: "เอกวาดอร์",
      value: 278
    },
    {
      abbr: "SV",
      code: "237",
      label: "เอลซัลวาดอร์",
      name: "เอลซัลวาดอร์",
      value: 279
    },
    {
      abbr: "ZA",
      code: "267",
      label: "แอฟริกาใต้",
      name: "แอฟริกาใต้",
      value: 280
    },
    {
      abbr: "OM",
      code: "206",
      label: "โอมาน",
      name: "โอมาน",
      value: 281
    },
    {
      abbr: "IS",
      code: "34",
      label: "ไอซ์แลนด์",
      name: "ไอซ์แลนด์",
      value: 282
    },
    {
      abbr: "IE",
      code: "38",
      label: "ไอร์แลนด์",
      name: "ไอร์แลนด์",
      value: 283
    },
    {
      abbr: "HK",
      code: "33",
      label: "ฮ่องกง",
      name: "ฮ่องกง",
      value: 284
    },
    {
      abbr: "HN",
      code: "32",
      label: "ฮอนดูรัส",
      name: "ฮอนดูรัส",
      value: 285
    },
    {
      abbr: "HT",
      code: "31",
      label: "ไฮติ",
      name: "ไฮติ",
      value: 286
    }
  ];
  
