import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  REQByRiskD,
  RESByRiskD,
  FailByRiskD,
  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList,
  REQByRiskEdt,
  RESByRiskEdt,
  FailByRiskEdt
} from "./actions";

function* fetchByRiskDetailWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
  
  try {
    const response = "";
    
    if (response === "") {
      let aAuth = {
        Token: accessToken,
        GUID: tGUID
      };
      console.log("aAuth:",aAuth);
      yield put(RESByRiskD(aAuth));
    } else {
            yield put(FailByRiskD());
    }
  } catch (error) {
    
    yield put(FailByRiskD());
  }
}
function* fetchFundCodeListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);

  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    

    
    if (response && response.data) {
      yield put(RESFundCodeList(response.data));
    } else {
            yield put(FailFundCodeList());
    }
  } catch (error) {
    
    yield put(FailFundCodeList());
  }
}
function* EDTxByRiskDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_fund_risk/edit`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    console.log("funds/recommend_by_fund_risk/edit response.data:",response.data);
    if (response && response.data && response.status === 200) {
    
      yield put(RESByRiskEdt(response));
      history.goBack();
    } else {
      yield put(FailByRiskEdt());
    }
  } catch (error) {
    
    yield put(FailByRiskEdt());
  }
}

export function* fundRecRiskDetailSaga() {
  yield all([ 
    takeLatest(REQFundCodeList, fetchFundCodeListWorker),
    takeLatest(REQByRiskD, fetchByRiskDetailWorker),
    takeLatest(REQByRiskEdt, EDTxByRiskDetail)
  ]);
}
