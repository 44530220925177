import React, { useState } from "react";
import {
  TextInput,
  DropdownInput
} from "../../../../components/input_hook_form";
import TabForms from "./TabForms";
import "./style.scss";

const fundCategoryOptions = [
  {
    value: "TAX Saving Fund",
    label: "TAX Saving Fund",
    name: "TAX Saving Fund"
  },
  {
    value: "Regional & Global Fund",
    label: "Regional & Global Fund",
    name: "Regional & Global Fund"
  },
  {
    value: "Fixed Income Fund",
    label: "Fixed Income Fund",
    name: "Fixed Income Fund"
  },
  { value: "Equity Fund", 
    label: "Equity Fund", 
     name: "Equity Fund" 
  },
  {
    value: "Money Market Fund",
    label: "Money Market Fund",
    name: "Money Market Fund"
  },
  {
    value: "Alternative Fund",
    label: "Alternative Fund",
    name: "Alternative Fund"
  },
  {
    value: "Mixed Assets Fund",
    label: "Mixed Assets Fund",
    name: "Mixed Assets Fund"
  }
];

const fundCategoryThaiOptions = [
  {
    value: "กองทุนลดหย่อนภาษี",
    label: "กองทุนลดหย่อนภาษี",
    name: "กองทุนลดหย่อนภาษี"
  },
  {
    value: "กองทุนต่างประเทศ",
    label: "กองทุนต่างประเทศ",
    name: "กองทุนต่างประเทศ"
  },
  {
    value: "กองทุนตราสารหนี้",
    label: "กองทุนตราสารหนี้",
    name: "กองทุนตราสารหนี้"
  },
  {
    value: "กองทุนตราสารทุน",
    label: "กองทุนตราสารทุน",
    name: "กองทุนตราสารทุน"
  },
  {
    value: "กองทุนตลาดเงิน",
    label: "กองทุนตลาดเงิน",
    name: "กองทุนตลาดเงิน"
  },
  {
    value: "กองทุนทรัพย์สินทางเลือก",
    label: "กองทุนทรัพย์สินทางเลือก",
    name: "กองทุนทรัพย์สินทางเลือก"
  },
  {
    value: "กองทุนผสม",
    label: "กองทุนผสม",
    name: "กองทุนผสม"
  }
];

const validateURL = url => {
  // URL must begin with either HTTP:// or HTTPS:// or www. otherwise it will not work in app
  if (
    !/^http:\/\//.test(url) &&
    !/^https:\/\//.test(url) &&
    !/^www./.test(url)
  ) {
    // does not contain either http or https, invalid input
    return false;
  } else {
    return true;
  }
};

// start test
function FundDetailsExtra(props) {
  const { register, getValues, show, mode, control, errors } = props;
  const [isEnglish, setIsEnglish] = useState(true);

  const handleTabChange = (value) => {
    setIsEnglish(value);
  };

  const renderPreviewButton = (name, disabled) => {
    const url = getValues()[name];
    return (
      <button
        type="button"
        className={"preview-button"}
        onClick={() => {
          if (!disabled) {
            window.open(url, "_blank");
          }
        }}
        disabled={disabled}
      >
        PREVIEW
      </button>
    );
  };

  const renderTextURLInput = (label, placeholder, name, urlName) => {
    return (
      <TextInput
        label={label}
        placeholder={placeholder}
        error={mode !== "VIEW" && errors[name]}
        register={register({
          required: true,
          minLength: 1,
          validate: (value) => validateURL(value)
        })}
        type="text"
        name={name}
        inputStyle="funddetails-preview-input"
        action={renderPreviewButton(urlName, mode === "VIEW" || errors[name])}
        disabled={mode === "VIEW"}
      />
    );
  };

  const renderTextInput = (label, placeholder, name, urlName) => {
    return (
      <TextInput
        label={label}
        placeholder={placeholder}
        error={mode !== "VIEW" && errors[name]}
        register={register({
          required: true,
          minLength: 1
        })}
        type="text"
        name={name}
        inputStyle="textInput"
        disabled={mode === "VIEW"}
      />
    );
  };

  return (
    <div className={show ? "funddetailsextra-container" : "funddetailsextra-hide"}>
      <div className="funddetailsextra-tab-container">
        <button
          type="button"
          onClick={() => handleTabChange(true)}
          className={isEnglish ? "funddetailsextra-tab-selected" : "funddetailsextra-tab"}
        >
          ENGLISH
        </button>
        <button
          type="button"
          onClick={() => handleTabChange(false)}
          className={!isEnglish ? "funddetailsextra-tab-selected" : "funddetailsextra-tab"}
        >
          THAI
        </button>
      </div>

    
        <div className={isEnglish ? "funddetailsextra-english-container" : "funddetailsextra-english-container-hide"}>
          {renderTextInput("Fund Name", "Enter Fund Name", "funddetailsextra-english-fundname", "funddetailsextra-english-fundname")}
          <div className="funddetailsextra-spacer" />
          
          <DropdownInput
            label={"Fund Category"}
            placeholder={"Select Fund Category"}
            error={mode !== "VIEW" && errors["funddetailsextra-english-fundcategory"]}
            register={register({ required: true })}
            name="funddetailsextra-english-fundcategory"
            options={fundCategoryOptions}
            disabled={mode === "VIEW"}
          />
          <div className="funddetailsextra-spacer" />
          {renderTextInput("Fund Currency", "Enter Fund Currency", "funddetailsextra-english-fundcurrency", "funddetailsextra-english-fundcurrency")}
          <div className="funddetailsextra-spacer" />
          {renderTextURLInput("Fund Factsheet", "Enter Fund Factsheet URL", "funddetailsextra-english-factsheeturl", "funddetailsextra-english-factsheeturl")}
          <div className="funddetailsextra-spacer" />
          {renderTextURLInput("Prospectus", "Enter Prospectus URL", "funddetailsextra-english-prospectusurl", "funddetailsextra-english-prospectusurl")}
          <div className="funddetailsextra-spacer" />
          {renderTextURLInput("Investor Guide", "Enter Investor Guide URL", "funddetailsextra-english-highlightsheeturl", "funddetailsextra-english-highlightsheeturl")}
        </div>


        <div className={!isEnglish ? "funddetailsextra-english-container" : "funddetailsextra-english-container-hide"}>
          {renderTextInput("Fund Name", "Enter Fund Name", "funddetailsextra-thai-fundname", "funddetailsextra-thai-fundname")}
          <div className="funddetailsextra-spacer" />
          <DropdownInput
            label={"Fund Category"}
            placeholder={"Select Fund Category"}
            error={mode !== "VIEW" && errors["funddetailsextra-thai-fundcategory"]}
            register={register({ required: true })}
            options={fundCategoryThaiOptions}
            name="funddetailsextra-thai-fundcategory"
            disabled={mode === "VIEW"}
          />
          <div className="funddetailsextra-spacer" />
          {renderTextInput("Fund Currency", "Enter Fund Currency", "funddetailsextra-thai-fundcurrency", "funddetailsextra-thai-fundcurrency")}
          <div className="funddetailsextra-spacer" />
          {renderTextURLInput("Fund Factsheet", "Enter Fund Factsheet URL", "funddetailsextra-thai-factsheeturl", "funddetailsextra-thai-factsheeturl")}
          <div className="funddetailsextra-spacer" />
          {renderTextURLInput("Prospectus", "Enter Prospectus URL", "funddetailsextra-thai-prospectusurl", "funddetailsextra-thai-prospectusurl")}
          <div className="funddetailsextra-spacer" />
          {renderTextURLInput("Product Highlight Sheet", "Enter Product Highlight Sheet URL", "funddetailsextra-thai-highlightsheeturl", "funddetailsextra-thai-highlightsheeturl")}
        </div>

      <div className={isEnglish ? "" : "hide-tab-form"}>
        <TabForms language={"english"} register={register} mode={mode} control={control} errors={errors} />
      </div>
      <div className={!isEnglish ? "" : "hide-tab-form"}>
        <TabForms language={"thai"} register={register} mode={mode} control={control} errors={errors} />
      </div>
    </div>
  );
}
// end test

export default FundDetailsExtra;
