import { createReducer } from "redux-act";

import {
  requestClientsListAction,
  receiveClientsListAction,
  failClientsListAction,
  requestDisapproveClient,
  receiveDisapproveClient,
  failDisapproveClient,
  getApplicants,
  setApplicants,
  failApplicants
} from "./actions";

const initialState = {
  requestingClientsList: false,
  clientsList: [],
  totalAmountOfClients: 0,
  requestingDisapproveClient: false,
  applicantList: [],
  requestApplicants: false
};

const clientsListReducer = createReducer(
  {
    [requestClientsListAction]: (state) => {
     
      return {
        ...state,
        requestingClientsList: true
      };
    },
    [receiveClientsListAction]: (state, payload) => {
      return {
        ...state,
        requestingClientsList: false,
        clientsList: payload.data,
        totalAmountOfClients: payload.total
      };
    },
    [failClientsListAction]: (state) => {
      return {
        ...state,
        requestingClientsList: false,
        clientsList: [],
        totalAmountOfClients: 0
      };
    },
    [requestDisapproveClient]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: true
      };
    },
    [receiveDisapproveClient]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: false
      };
    },
    [failDisapproveClient]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: false
      };
    },
    [getApplicants]: (state) => {
      return {
        ...state,
        requestApplicants: true
      };
    },
    [setApplicants]: (state, payload) => {
      return {
        ...state,
        requestApplicants: false,
        applicantList: payload.applicants
      };
    },
    [failApplicants]: (state) => {
      return {
        ...state,
        requestApplicants: false,
        applicantList: []
      };
    }
  },
  initialState
);

export default clientsListReducer;
