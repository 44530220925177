import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { TextInput } from "../../components/input_hook_form";
import {
  REQArticleTag,
  REQFund,
  REQContFdDel,
  CleanConFdDetail,
  REQContFdEdt
} from "./redux/actions";
import {
  WarningModal,
  WarningDeleteModal,
  AlertModal
} from "../../components/Modal";
import { CircularProgress } from "@material-ui/core";
import Images from "../../utils/styles/images";
import Select from "react-select";
import JoditEditor from "jodit-react";
import "./style.scss";
import { aCategory } from "./DataInclude";
function ContentFeedDetail(props) {
  const {
    REQArticleTag,
    REQFund,
    REQContFdDel,
    REQContFdEdt,

    ArticleTagList,

    FundList,
    totalFund,

    RowDataAt,
    history
  } = props;


  useEffect(() => {
    REQArticleTag();
    REQFund();
    return () => {
      CleanConFdDetail();
    };
  }, []);

  // ---------------Text Intial---------------------
  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));

  const tYoutebeCut = () => {
    let tYoutobe = "";
    let nIndex;
    if (RowDataAt.videoUrl !== null && RowDataAt.videoUrl !== "") {
      // -----------check v= ก่อน------------
      if (RowDataAt.videoUrl.indexOf("watch?v=") > 1) {
        nIndex = RowDataAt.videoUrl.indexOf("watch?v=");
        nIndex = nIndex + 8;
        tYoutobe = RowDataAt.videoUrl.substring(
          nIndex,
          RowDataAt.videoUrl.length
        );
      } else if (RowDataAt.videoUrl.indexOf("youtu.be/") > 1) {
        nIndex = RowDataAt.videoUrl.indexOf("youtu.be/");
        nIndex = nIndex + 9;
        tYoutobe = RowDataAt.videoUrl.substring(
          nIndex,
          RowDataAt.videoUrl.length
        );
      } else {
        tYoutobe = "";
      }
    } else {
      tYoutobe = "";
    }
    return tYoutobe;
  };

  const tYoutebeCutOnSave = ptYoutube => {
    let tYoutobe = "";
    let nIndex;
    if (ptYoutube !== null && ptYoutube !== "") {
      // -----------check v= ก่อน------------
      if (ptYoutube.indexOf("watch?v=") > 1) {
        nIndex = ptYoutube.indexOf("watch?v=");
        nIndex = nIndex + 8;
        tYoutobe = ptYoutube.substring(nIndex, ptYoutube.length);
      } else if (ptYoutube.indexOf("youtu.be/") > 1) {
        nIndex = ptYoutube.indexOf("youtu.be/");
        nIndex = nIndex + 9;
        tYoutobe = ptYoutube.substring(nIndex, ptYoutube.length);
      } else {
        tYoutobe = ptYoutube;
      }
    } else {
      tYoutobe = ptYoutube;
    }
    return tYoutobe;
  };

  const [oSetValInit, SETxValInit] = useState(
    RowDataAt
      ? {
          otbTitleEN:
            RowDataAt.articleTitleEn !== null ? RowDataAt.articleTitleEn : "",
          otbDesEN: RowDataAt.shortDesEn !== null ? RowDataAt.shortDesEn : "",
          ordUsr:
            RowDataAt.showUser !== "" && RowDataAt.showUser !== null
              ? RowDataAt.showUser
              // All, PVD, MutualFund, SoftOnboard, PVDAndMutualFund
              : "All",

          otbTitleTH:
            RowDataAt.articleTitleTh !== null ? RowDataAt.articleTitleTh : "",
          otbDesTH: RowDataAt.shortDesTh !== null ? RowDataAt.shortDesTh : "",
          coverPhoto: RowDataAt.imageUrl,
          otbYoutubeUrl: tYoutebeCut()
        }
      : {
          otbTitleEN: null,
          otbDesEN: null,
          ordUsr: "All",

          otbTitleTH: null,
          otbDesTH: null,
          coverPhoto: null,
          otbYoutubeUrl: null
        }
  );

  
  const SETxOnChgTxt = name => e => {
    SETxValInit({ ...oSetValInit, [name]: e.target.value });
  };

  const [oShwEdtAlr, SETxShwEdtAlr] = useState(false);
  const [oShwDelAlr, SETxShwDelAlr] = useState(false);
  const [oShwErrAlr, SETxShwErrAlr] = useState(false);
  const [oLangAlr, SETxLangAlr] = useState("");

  // ---------------Radio-------------
  const [oUsr, SETxUsr] = React.useState(oSetValInit.ordUsr);
  
  const [oStatus, SETxStatus] = React.useState(() => {
    if (RowDataAt.statusCode === 1) {
      return "Unpublish";
    } else if (RowDataAt.statusCode === 2) {
      return "Publish";
    } else {
      return "Unpublish";
    }
  });
  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 10 }}>{label}</span>
      </span>
    );
  };
  // ----------------------Combobox Selection-----------
  const [oFunCodeRow, SETxFunCodeRow] = useState(
    RowDataAt.fund.map((d) => ({
      value: d.fundNo,
      label: d.fundCode
    }))
  );
  const oFunCodeOp =
    FundList.length > 0
      ? FundList.map(d => ({
          value: d.fund_no.toString(),
          label: d.fund_code
        }))
      : [];
  
  const [oArtTagRow, SETxArtTagRow] = useState(
    RowDataAt.tag.map((d) => ({
      value: d.tagNo,
      label: d.tagName
    }))
  );
  const oArtTagOp =
    ArticleTagList.length > 0
      ? ArticleTagList.map(d => ({
          value: d.id.toString(),
          label: d.tagNameEN
        }))
      : [];

  // -------------------Article Tag---------------------

  const SETxOnChgSelArtTag = e => {
    SETxArtTagRow(Array.isArray(e) ? e.map(x => x) : []);
  };
  // -------------------Fund---------------------
  const SETxOnChgSelReleteFund = e => {
    SETxFunCodeRow(Array.isArray(e) ? e.map(x => x) : []);
  };
  // ------------------Category----------------
  const [oCategory, SEToCategory] = useState(() => {
    if (RowDataAt.contentType === "investment_outlook") {
      return aCategory[0];
    } else if (RowDataAt.contentType === "news_and_award") {
      return aCategory[1];
    } else if (RowDataAt.contentType === "investment_knowledge") {
      return aCategory[2];
    } else if (RowDataAt.contentType === "investor_guideline") {
      return aCategory[3];
    } else if (RowDataAt.contentType === "Provident Fund News and Report") {
      return aCategory[4];
    } else if (RowDataAt.contentType === "Financial Planning Content") {
      return aCategory[5];
    } else if (RowDataAt.contentType === "Provident Fund Fact Sheet") {
      return aCategory[6];
    } else if (RowDataAt.contentType === "fund_announcement") {
      return aCategory[7];
    } else if (RowDataAt.contentType === "media_center") {
      return aCategory[8];
    } else {
      return aCategory[0];
    }
  });
  const SETxOnChgSelCategory = e => {
    SEToCategory({ value: e.value, label: e.label });
  };
  // ----------------CheckBox---------------

  var [oHighlight] = useState([
    {
      value: 1,
      label: "Highlight",
      name: "Highlight-en",
      isCheck: RowDataAt.isHighlight
    }
  ]);
  function SETxOnHightlight(e) {
    let nIndex = oHighlight.findIndex(
      item => e.target.value === item.value.toString()
    );
    oHighlight[nIndex].isCheck = e.target.checked;
  }

  // -------------JoditEditor 2------------
  
  const editor = useRef(null);
  const [tContentEN, SETtContentEN] = useState(
    RowDataAt.contentEn === null ? "" : RowDataAt.contentEn
  );
  const [tContentTH, SETtContentTH] = useState(
    RowDataAt.contentTh === null ? "" : RowDataAt.contentTh
  );
  

  const oConfig = {
    readonly: false,
    height: 400
  };
  const EVTxUPDContentEN = e => {
    const tEdtTxtEn = e;
    SETtContentEN(tEdtTxtEn);
  };
  const EVTxUPDContentTH = e => {
    const tEdtTxtTh = e;
    SETtContentTH(tEdtTxtTh);
  };
  const SHWxRelateFund = () => {
    if (oFunCodeOp.length === 0) {
      
      if (totalFund != null) {
        
        return (
          <div className="funds-list-progress">
            <CircularProgress />
            <p className="funds-list-progress-text">
              {oFunCodeOp.length < 0 ? "Loading Related Fund..." : null}
            </p>
          </div>
        );
      } else {
        
        return null;
      }
    } else {
      return null;
    }
  };

  function validateContentData(oSetValInit, tContentTH, tContentEN) {
    let language = "";
    if (oSetValInit.otbTitleTH === null || oSetValInit.otbTitleTH === "") {
      language = "TH";
    } else if (oSetValInit.otbTitleEN === null || oSetValInit.otbTitleEN === "") {
      language = "EN";
    } else if (tContentTH.replace("test","") === "") {
      language = "TH";
    } else if (tContentEN.replace("test","") === "") {
      language = "EN";
    }
    if (language !== "") {
      SETxLangAlr(language);
      SETxShwErrAlr(true);
      return false;
    }
    return true;
  }

  function showValidationError(language) {
    SETxLangAlr(language);
    SETxShwErrAlr(true);
    // Indicate validation failure
    return false;
  }

  return (
    <Fragment>
      <div className="conFd-feed-form-container">
        <div className="conFd-buttons-container">
          <button
            className="conFd-detail-cancel"
            onClick={() => SETxShwDelAlr(true)}
            style={{ width: 120 }}
          >
            <img
              src={Images.deleteButton.delete1}
              style={{ marginBottom: 3, marginRight: 5 }}
              alt="delete"
            />
            DELETE
          </button>
          <button
            className="conFd-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={() => SETxShwEdtAlr(true)}
            className={
              (oSetValInit.otbTitleEN === "" &&
                oSetValInit.otbTitleTH === "") ||
              (oSetValInit.otbTitleEN === null &&
                oSetValInit.otbTitleTH === null)
                ? "content-feed-form-publish-button-disabled"
                : "content-feed-form-publish-button"
            }
            disabled={
              (oSetValInit.otbTitleEN === "" &&
                oSetValInit.otbTitleTH === "") ||
              (oSetValInit.otbTitleEN === null &&
                oSetValInit.otbTitleTH === null)
            }
          >
            SAVE
          </button>

          <WarningModal
            show={oShwEdtAlr}
            onHide={() => SETxShwEdtAlr(false)}
            onHandleGo={() => {
              SETxShwEdtAlr(false);

              // Validation
              if (!validateContentData(oSetValInit, tContentTH, tContentEN)) {
                return; 
              }

              // ------------------- Check Title not emptry
              
              if (oSetValInit.otbTitleTH === null || oSetValInit.otbTitleTH === "") {
                return showValidationError("TH");
              }
              if (oSetValInit.otbTitleEN === null || oSetValInit.otbTitleEN === "") { 
                return showValidationError("EN");
              }
              
              // Checks for tContentTH and tContentEN 
              if (tContentTH.replace("test","") === "") {
                return showValidationError("TH");
              }
              if (tContentEN.replace("test","") === "") {
                return showValidationError("EN");
              }

              // -------------------Highlight------------------
              const oHili = oHighlight.filter(CHK => CHK.isCheck === true);
              let oHili1;
              if (oHili.length > 0) {
                oHili1 = oHili[0].isCheck;
              } else {
                oHili1 = false;
              }

              let tYbFlt = tYoutebeCutOnSave(oSetValInit.otbYoutubeUrl);
              let tYbFlt1 = "";
              if (tYbFlt !== "") {
                tYbFlt1 = "https://youtu.be/" + tYbFlt;
              }

              let oSendDataAPI = {
                id: parseInt(RowDataAt.id),
                imageUrl:
                  oSetValInit.coverPhoto === null ? "" : oSetValInit.coverPhoto,
                fileUrl: "",
                videoUrl: tYbFlt1,
                articleTitleTh: oSetValInit.otbTitleTH,
                articleTitleEn: oSetValInit.otbTitleEN,
                shortDesTh: oSetValInit.otbDesTH,
                shortDesEn: oSetValInit.otbDesEN,

                contentTh: tContentTH === null ? "" : tContentTH,
                contentEn: tContentEN === null ? "" : tContentEN,

                showUser: oUsr,
                statusCode: oStatus === "Publish" ? 2 : 1,
                contentType: oCategory.value,
                isHighlight: oHili1 === true ? 1 : 0,
                knowledgeCenter: "",
                modifiedBy: oNeedAuth?.Email,
                modifiedAt: RowDataAt.modifiedAt,
                createdAt: RowDataAt.createdAt,
                tag:
                  oArtTagRow.length > 0
                    ? oArtTagRow.map(a => ({
                        tagName: a.label,
                        tagNo: a.value
                      }))
                    : [{ tagName: "", tagNo: "" }],
                fund:
                  oFunCodeRow.length > 0
                    ? oFunCodeRow.map(a => ({
                        fundCode: a.label,
                        fundNo: a.value
                      }))
                    : [{ fundCode: "", fundNo: "" }]
              };

              
              // ----Edtor
              REQContFdEdt({
                FormData: oSendDataAPI,
                history
              });
            }}
            onHandleCancel={() => {
              SETxShwEdtAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={"Are you sure you want to edit content?"}
          />
          <WarningDeleteModal
            show={oShwDelAlr}
            onHide={() => SETxShwDelAlr(false)}
            onHandleGo={() => {
             
              SETxShwDelAlr(false);

              let oSendDataAPI = {
                id: parseInt(RowDataAt.id)
              };
              console.log("oSendDataAPI:",oSendDataAPI);
              // ----Delete
              REQContFdDel({
                FormData: oSendDataAPI,
                history
              });
              console.log("history:",history);
            }}
            onHandleCancel={() => {
              SETxShwDelAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={"Are you sure you want to delete ?"}
          />
          <AlertModal
            show={oShwErrAlr}
            onHide={() => SETxShwErrAlr(false)}
            onHandleOK={() => {
              SETxShwErrAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={`Please config <strong>${
              oLangAlr === "TH" ? "THAI" : "ENGLISH"
            }</strong> version.`}
          />
        </div>

        <div className="conFd-feed-form-inputs-container">
          <div className="labelInput">
            <span className="labelInputText ">Category</span>
            <div style={{ width: 600 }}>
              <Select
                name="ocbCategory"
                options={aCategory}
                maxMenuHeight={150}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select FunTag"
                // assign onChange function
                onChange={SETxOnChgSelCategory}
                value={oCategory}
              />
            </div>
          </div>

          <div className="tag-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Cover Photo URL</span>

            <input
              className="conFd-pre-textInput"
              name="coverPhoto"
              placeholder="Enter Cover Photo URL"
              style={{ width: 475 }}
              value={
                oSetValInit.coverPhoto === null ? "" : oSetValInit.coverPhoto
              }
              onChange={SETxOnChgTxt("coverPhoto")}
            />
            <button
              type="button"
              className="srchRec-preview-button"
              onClick={() => {
                var url = document.getElementsByName("coverPhoto")[0].value;
                window.open(url, "_blank");
              }}
            >
              PREVIEW
            </button>
          </div>

          <div className="tag-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Youtube URL</span>
            <label className="conFd-pre-label">https://youtu.be/</label>
            <input
              className="conFd-pre-textInput"
              name="otbYoutubeUrl"
              style={{ width: 342 }}
              value={oSetValInit.otbYoutubeUrl}
              onChange={SETxOnChgTxt("otbYoutubeUrl")}
            />
            <button
              type="button"
              className="srchRec-preview-button"
              onClick={() => {
                let tUlrCode = tYoutebeCutOnSave(oSetValInit.otbYoutubeUrl);
                tUlrCode = "https://youtu.be/" + tUlrCode;
                window.open(tUlrCode, "_blank");
              }}
            >
              PREVIEW
            </button>
          </div>
          <div>
            <label className="conFd-label-warning">
              *รองรับเฉพาะลิงก์จาก Youtube เท่านั้น (กรอกเฉพาะ Code)
            </label>
          </div>
          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Title in Thai"
            placeholder="Title in Thai"
            name="otbTitleTH"
            value={oSetValInit.otbTitleTH}
            onChange={SETxOnChgTxt("otbTitleTH")}
            info={
              "Title about the article. Content will not be shown in the app."
            }
          />
          <div className="fundrec-invalid" style={{ marginLeft: 20 }}>
            {oSetValInit.otbTitleTH !== "" ? "" : "กรุณากรอก Title in Thai"}
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Title in English"
            placeholder="Title in English"
            name="otbTitleEN"
            value={oSetValInit.otbTitleEN}
            onChange={SETxOnChgTxt("otbTitleEN")}
            info={
              "Title about the article. Content will not be shown in the app."
            }
          />
          <div className="fundrec-invalid" style={{ marginLeft: 20 }}>
            {oSetValInit.otbTitleEN !== "" ? "" : "กรุณากรอก Title in English"}
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Short Description in Thai"
            placeholder="Short Description"
            name="otbDesTH"
            value={oSetValInit.otbDesTH}
            onChange={SETxOnChgTxt("otbDesTH")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />
          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Short Description"
            placeholder="Short Description"
            name="otbDesEN"
            value={oSetValInit.otbDesEN}
            onChange={SETxOnChgTxt("otbDesEN")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />

          {/*---------------------------Article Tag<-------------------------------------*/}
          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText">Article Tag</span>
            {oArtTagOp.length < 0 ? (
              <div className="funds-list-progress">
                <CircularProgress />
                <p className="funds-list-progress-text">
                  {oArtTagOp.length < 0 ? "Loading Article Tag..." : null}
                </p>
              </div>
            ) : null}
            <div style={{ width: 600 }}>
              {
                <Select
                  value={
                    oArtTagRow.length > 0 && oArtTagOp.length > 0
                      ? oArtTagOp.filter(a =>
                          oArtTagRow.some(x => a.value === x.value)
                        )
                      : []
                  }
                  isMulti
                  name="ocbArtiTag"
                  options={oArtTagOp}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Article Tag"
                  // assign onChange function
                  onChange={SETxOnChgSelArtTag}
                />
              }
            </div>
          </div>
          {/*-----------------------------Related Fund--------------------------------------*/}
          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Related Fund</span>
            <SHWxRelateFund />
            <div style={{ width: 600 }}>
              {
                <Select
                  value={
                    oFunCodeRow.length > 0 && oFunCodeOp.length > 0
                      ? oFunCodeOp.filter(a =>
                          oFunCodeRow.some(x => a.value === x.value)
                        )
                      : []
                  }
                  isMulti
                  name="ocbRelateFund"
                  options={oFunCodeOp}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Related Fund"
                  // assign onChange function
                  onChange={SETxOnChgSelReleteFund}
                />
              }
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <table border="0" className="conFd-feed-form-table">
              <tbody>
                <tr height={30}>
                  <td rowSpan="2" width={240} style={{ paddingBottom: 28 }}>
                    User
                  </td>
                  <td>
                    <RadioInput
                      className="labelInputText"
                      label="แสดงทุกกลุ่ม"
                      value="All"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      className="labelInputText"
                      label="เฉพาะลูกค้า PVD เท่านั้น"
                      value="PVD"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="เฉพาะลูกค้า MUTUAL FUND"
                      value="MutualFund"
                      checked={oUsr}
                      setter={SETxUsr}
                    />
                  </td>
                </tr>
                <tr height={30}>
                  <td>
                    <RadioInput
                      label="SOFT ONBOARD เท่านั้น"
                      value="SoftOnboard"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="เฉพาะลูกค้าที่มี PVD และ MUTUAL FUND"
                      value="PVDAndMutualFund"
                      checked={oUsr}
                      setter={SETxUsr}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table border="0" className="conFd-feed-form-table">
              <tbody>
                <tr height={30}>
                  <td rowSpan="2" width={240}>
                    Content Status
                  </td>
                  <td>
                    <RadioInput
                      label="Unpublished"
                      value="Unpublish"
                      checked={oStatus}
                      setter={SETxStatus}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="Published"
                      value="Publish"
                      checked={oStatus}
                      setter={SETxStatus}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/*-----------------------------Highlight--------------------------------------*/}

          <div className="labelInput" style={{ height: 40, marginTop: 10 }}>
            <span className="labelInputText ">Highlight</span>
            {oHighlight
              ? oHighlight.map((option, index) => {
                  return (
                    <Fragment key={`${option.label}${index}`}>
                      <input
                        type="checkbox"
                        id={option.label}
                        value={option.value}
                        name={option.name}
                        style={{ width: 18, height: 18, marginRight: 10 }}
                        defaultChecked={option.isCheck === true ? true : false}
                        onChange={SETxOnHightlight}
                      />
                      <label
                        htmlFor={option.label}
                        className={"checkbox-label"}
                      >
                        <label />
                        <div style={{ paddingBottom: 17, fontSize: 14 }}>
                          {option.label}
                        </div>
                      </label>
                    </Fragment>
                  );
                })
              : null}
          </div>

          {/*-----------------------------The content-------------------------------------- */}
          <div className="conFd-feed-form-input-spacer" />
          <div>
            <div className="labelInput">
              <span className="labelInputText ">The Content in Thai</span>
            </div>
            <div className="conFd-feed-form-input-spacer" />
            <div className="fundrec-invalid" style={{ paddingLeft: 0 }}>
              {tContentTH.replace("test","") !== ""
                ? ""
                : "กรุณากรอก The Content in Thai"}
            </div>
            <JoditEditor
              ref={editor}
              value={tContentTH}
              config={oConfig}
              onBlur={EVTxUPDContentTH}
              name="EdtTH"
            />
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <div>
            <div className="labelInput">
              <span className="labelInputText ">The Content</span>
            </div>

            <div className="conFd-feed-form-input-spacer" />
            <div className="fundrec-invalid" style={{ paddingLeft: 0 }}>
              {tContentEN.replace("test","") !== ""
                ? ""
                : "กรุณากรอก The Content in English"}
            </div>
            <JoditEditor
              ref={editor}
              value={tContentEN}
              config={oConfig}
              onBlur={EVTxUPDContentEN}
              name="EdtEN"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.conFdDetailReducer
  }),
  {
    REQArticleTag,
    REQFund,
    REQContFdEdt,
    REQContFdDel,
    CleanConFdDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(ContentFeedDetail);
