import React, { Fragment } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter
} from "react-table";

import { modifyCell_Tab3, modifyCellHeader_Tab3 } from "./cell_formatter";
import Images from "../../utils/styles/images";
import Pagination from "./Pagination";

function TableTab3({
  columns,
  data,
  handleActionHeaderClick,
  handleSortHeader,
  handleActionClick,
  HightTable
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageCount,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Fragment>
      <div
        className="acti-list-container"
        style={{
          height: HightTable,
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        <table className="acti-table" {...getTableProps()}>
          <thead className="acti-head-container">
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="acti-head-row"
              >
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="acti-header"
                    style={{
                      width: column.render("width"),
                      paddingLeft: column.render("paddingLeft"),
                      display: column.render("display")
                    }}
                  >
                    {modifyCellHeader_Tab3(
                      column.render("Header"),
                      handleActionHeaderClick,
                      handleSortHeader
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps} className="acti-table-body">
            {page.length <= 0 ? (
              <tr className="acti-table-empty-row">
                <td colSpan={14}>
                  <div className="acti-table-no-data">
                    <img src={Images.noData.board} alt="no-data-found-board" />
                    <p className="acti-table-no-data-header">No result found</p>
                    <p className="acti-table-no-data-body">
                      We couldn’t find what you are looking for.
                      <br /> Please try again.
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="acti-table-row">
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="acti-cell"
                          style={{
                            width: cell.render("width"),
                            paddingLeft: cell.render("paddingLeft"),
                            textAlign: cell.render("textAlign"),
                            display: cell.render("display")
                          }}
                        >
                          {modifyCell_Tab3(
                            cell.render("Cell"),
                            pageIndex,
                            pageSize,
                            handleActionClick
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {pageCount > 1 ? (
        <Pagination
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          total={pageCount}
          gotoPage={gotoPage}
        />
      ) : null}
    </Fragment>
  );
}

export default TableTab3;
