import { createReducer } from "redux-act";

import { requestATSList, receiveATSList, failATSList } from "./actions";

const initialState = {
  requestingATSList: false,
  page: 0,
  sortingNo: 0,
  sortDirection: "DESC",
  atsList: [],
  errorMessage: false,
  totalATS: 0
};

const atsReducer = createReducer(
  {
    [requestATSList]: (state) => {
      return {
        ...state,
        requestingATSList: true
      };
    },
    [receiveATSList]: (state, payload) => {
      let totalRecords =
        payload.data && payload.data[0] && payload.data[0].TotalRecord
          ? payload.data[0].TotalRecord
          : 0;
      return {
        ...state,
        requestingATSList: false,
        atsList: payload.data,
        totalATS: totalRecords
      };
    },
    [failATSList]: (state) => {
      return {
        ...state,
        requestingATSList: false,
        atsList: [],
        totalATS: 0
      };
    }
  },
  initialState
);

export default atsReducer;
