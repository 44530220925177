import { createAction } from "redux-act";

// Detail
export const MEMUsrActDetail = createAction("MEM_UsrActDETAIL");

// ----- Tab2 RegisDevice -------
export const REQTab2_RegisDeviceList = createAction("REQUEST_REGISDEVICE_LIST_TAB2");
export const RESTab2_RegisDeviceList = createAction("RECEIVE_REGISDEVICE_LIST_TAB2");
export const FailTab2_RegisDeviceList= createAction("FAILED_REGISDEVICE_LIST_TAB2");

// ----- Change Status Detail -------
export const REQChgSta = createAction("REQUEST_CHANGE_STA_DETAIL");
export const RESChgSta = createAction("RECEIVE_CHANGE_STA_DETAIL");
export const FailChgSta= createAction("FAILED_CHANGE_STA_DETAIL");

// ----- Get Image-------
export const REQImgCus = createAction("REQUEST_IMG_CUS_DETAIL");
export const RESImgCus = createAction("RECEIVE_IMG_CUS_DETAIL");
export const FailImgCus= createAction("FAILED_IMG_CUS_DETAIL");

// ----Get Status----
export const REQSta = createAction("REQUEST_STA_CUS_DETAIL");
export const RESSta = createAction("RECEIVE_STA_CUS_DETAIL");
export const FailSta =createAction("FAILED_STA_CUS_DETAIL");

// ----Delete UserAccount----
export const REQDelUsrAct = createAction("REQUEST_DEL_USERACCOUNT_DETAIL");
export const RESDelUsrAct = createAction("RECEIVE_DEL_USERACCOUNT_DETAIL");
export const FailDelUsrAct =createAction("FAILED_DEL_USERACCOUNT_DETAIL");
export const cleanupDelUsrAct = createAction("CLEANUP_DEL_USERACCOUNT_DETAIL");