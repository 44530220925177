import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { WarningModal } from "../../components/Modal";
import { CircularProgress } from "@material-ui/core";
import {
  REQFundCodeList,
  REQTagDetailCrt,
  CleanUpTagDetail
} from "./redux/actions";

import Select from "react-select";
import "./style.scss";
function TagCrt(props) {
  const {
    REQFundCodeList,
    FundCodeList,
    REQTagDetailCrt,
    REQStrFundCodeList,
    history
  } = props;

  const GETxFundCode = () => {
    REQFundCodeList();
  };

  useEffect(() => {
    GETxFundCode();
    return () => {
      CleanUpTagDetail();
    };
  }, []);

  const [oShw, SETxShwModelAlert] = useState(false);
  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  // ---------------Text---------------------
  const [oVal, SETxVal] = useState({
    otbTagNameTH: "",
    otbTagNameEN: ""
  });

  const SETxOnChgTxt = name => {
    return ({ target: { value } }) => {
      SETxVal(oldValues => ({ ...oldValues, [name]: value }));
    };
  };
  // ---------------Radio-------------
  const [oTypeTag, SETxTypeTag] = React.useState("FundTag");
  const [oStatus, SETxStatus] = React.useState("Publish");
  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <label>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 10 }}>{label}</span>
      </label>
    );
  };

  // ---------------Select option-------------
  const oFunCodeOp = FundCodeList
    ? FundCodeList.length === 0
      ? []
      : FundCodeList.map(d => ({
          value: d.fund_no.toString(),
          label: d.fund_code
        }))
    : [];

  
  const [oSelFundCodeOp, SETxSelFundCodeOp] = useState([]);
  const SETxOnChgSelect = e => {
    SETxSelFundCodeOp(Array.isArray(e) ? e.map(x => x) : []);
  };

  return (
    <Fragment>
      <div className="tag-feed-form-container">
        <div className="tag-feed-form-buttons-container">
          <button
            className="tag-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={() => SETxShwModelAlert(true)}
            className={
              (oVal.otbTagNameTH && oVal.otbTagNameEN) === ""
                ? "content-feed-form-publish-button-disabled"
                : "content-feed-form-publish-button"
            }
            disabled={
              (oVal.otbTagNameTH && oVal.otbTagNameEN) === "" ? true : false
            }
          >
            SAVE
          </button>
        </div>

        {REQStrFundCodeList ? (
          <div className="funds-list-progress">
            <CircularProgress />
            <p className="funds-list-progress-text">
              {REQStrFundCodeList ? "Retrieving Funds list..." : null}
            </p>
          </div>
        ) : null}

        <WarningModal
          show={oShw}
          onHide={() => SETxShwModelAlert(false)}
          onHandleGo={() => {
            SETxShwModelAlert(false);

            let oSendDataAPI = {
              tagNameTH: oVal.otbTagNameTH,
              tagNameEN: oVal.otbTagNameEN,
              type: oTypeTag,
              status: oTypeTag === "FundTag" ? oStatus : "Publish",
              modifiedBy: oNeedAuth?.Email,
              fund:
                oTypeTag === "FundTag"
                  ? oSelFundCodeOp.length === 0
                    ? [{ fundCode: "", fundNo: "" }]
                    : oSelFundCodeOp.map(x => ({
                        fundCode: x.label,
                        fundNo: x.value
                      }))
                  : [{ fundCode: "", fundNo: "" }]
            };

            REQTagDetailCrt({
              formData: oSendDataAPI,
              history
            });

          }}
          onHandleCancel={() => {
            SETxShwModelAlert(false);
          }}
          type={"Disapprove"}
          status={"1"}
          title={"Are you sure you want to save?"}
        />
        <div className="tag-feed-form-inputs-container" style={{ height: 600 }}>
          <div className="tag-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Tag Name TH (Without #)</span>
            <input
              className="textInput"
              placeholder="กองทุนแนะนำ"
              value={oVal.otbTagNameTH}
              onChange={SETxOnChgTxt("otbTagNameTH")}
              required
            />
          </div>

          <div className="tag-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Tag Name EN (Without #)</span>
            <input
              className="textInput"
              placeholder="Fund Recommend"
              value={oVal.otbTagNameEN}
              onChange={SETxOnChgTxt("otbTagNameEN")}
              required
            />
          </div>

          <div className="tag-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Type</span>
            <RadioInput
              label="Fund Tag"
              value="FundTag"
              checked={oTypeTag}
              setter={SETxTypeTag}
            />{" "}
            <span style={{ marginRight: 20 }} />
            <RadioInput
              label="Article Tag"
              value="ArticleTag"
              checked={oTypeTag}
              setter={SETxTypeTag}
            />
          </div>

          <div id="Visible" hidden={oTypeTag === "FundTag" ? "" : "hide"}>
            <div className="tag-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Status</span>
              <RadioInput
                label="Unpublish"
                value="Unpublish"
                checked={oStatus}
                setter={SETxStatus}
              />{" "}
              <span style={{ marginRight: 20 }} />
              <RadioInput
                label="Publish"
                value="Publish"
                checked={oStatus}
                setter={SETxStatus}
              />
            </div>

            <div className="tag-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText ">Fund Code</span>
              <div style={{ width: 600 }}>
                <Select
                  isMulti
                  name="ocbTagFundCode"
                  options={oFunCodeOp}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Fund Code"
                  // assign onChange function
                  onChange={SETxOnChgSelect}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.tagDetailReducer
  }),
  {
    REQFundCodeList,
    REQTagDetailCrt,
    CleanUpTagDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(TagCrt);
