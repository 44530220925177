import { createAction } from "redux-act";

export const REQConsentList = createAction("REQUEST_CONSENT_LIST");
export const RESConsentList = createAction("RECEIVE_CONSENT_LIST");
export const FailConsentList = createAction("FAILED_CONSENT_LIST");
export const CleanUpConsentList= createAction("CLEANUP_CONSENT_LIST");



// ----- GetSYNC -------
export const REQSyncConsent = createAction("REQUEST_CONSENT_SYNC");
export const RESSyncConsent = createAction("RECEIVE_CONSENT_SYNC");
export const FailSyncConsent = createAction("FAILED_CONSENT_SYNC");