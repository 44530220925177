import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQCusServiceList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import DateInput from "./DateInput";
import { MEMCusServiceDetail } from "../CusServiceDetail/redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";
import { aNDID, aCtm } from "../../utils/MockAPI";
function CusServiceList(props) {
  const {
    REQStrCusServiceList,
    CusServiceList,
    MEMCusServiceDetail,
    history
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [tStartD, SETxStartD] = useState(new Date());
  const [tEndD, SETxEndD] = useState(new Date());
  const [tUsrType, SETxUsrType] = useState("");

  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("ID Card");

  const CALLxCustomerService = () => {
    if (history.location.pathname === "/NDID") {
      SETxUsrType("NDID");
    } else if (history.location.pathname === "/CouterService") {
      SETxUsrType("CouterService");
    }
  };

  useEffect(() => {
    CALLxCustomerService();
  }, []);

  const SRCHxActi = () => {
    CusServiceList({
      tStartD,
      tEndD,
      tSrch,
      tOpSrch,
      tUserType: tUsrType
    });
    SETxSrch("");
  };

  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };

  const SETxActTableClick = (cellValue, rowData, columnName) => {
    if (columnName === "ID CARD") {
      if (history.location.pathname === "/NDID") {
        MEMCusServiceDetail({ RowDataAt: rowData, ServiceType: tUsrType });
        history.push(`/NDIDDetail/${rowData.id}`);
      } else if (history.location.pathname === "/CouterService") {
        MEMCusServiceDetail({ RowDataAt: rowData, ServiceType: tUsrType });
        history.push(`/CouterServiceDetail/${rowData.id}`);
      }
    }
  };

  return (
    <Fragment>
      <div
        className="button-create-container"
        style={{ marginBottom: 1, paddingRight: 70 }}
      >
        <div style={{ height: 40 }} />
        <div>
          <div
            className="ndid-search"
            style={{ marginTop: 0 }}
          >
            <img
              src={Images.search.search}
              alt="search-icon"
              className="transactions-list-begin-search"
              onClick={() => SRCHxActi()}
            />
            <input
              placeholder={"Search by " + tOpSrch}
              className="ndid-search-bar"
              value={tSrch}
              onChange={event => {
                SETxSrch(event.target.value);
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  SETxSrch(event.target.value);
                  SRCHxActi();
                }
              }}
            />
            {tSrch && tSrch.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="transactions-details-cancel-search"
                onClick={() => SETxSrch("")}
              />
            ) : null}
          </div>
          <div className="clients-radio-container">
            <RadioInput
              label="ID Card"
              value="ID Card"
              checked={tOpSrch}
              setter={SETxOpSrch}
            />{" "}
            <span style={{ marginRight: 20 }} />
            <RadioInput
              label="Name"
              value="Name"
              checked={tOpSrch}
              setter={SETxOpSrch}
            />
          </div>
        </div>
      </div>

      {REQStrCusServiceList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrCusServiceList ? "Retrieving Activity list..." : null}
          </p>
        </div>
      ) : null}

      <div className="date-range-container">
        <p className="date-range-filter-date">FILTER DATE</p>
        <DatePicker
          selected={tStartD}
          onChange={date => SETxStartD(date)}
          selectsStart
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <p className="date-range-separator">-</p>
        <DatePicker
          selected={tEndD}
          onChange={date => moment(date).isAfter(tStartD) && SETxEndD(date)}
          selectsEnd
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <button
          onClick={() => {
            SRCHxActi();
          }}
          className="date-range-filter"
          disabled={!tStartD || !tEndD}
        >
          FILTER
        </button>
      </div>

      <Table
        data={history.location.pathname === "/NDID" ? aNDID : aCtm}
        HightTable={900}
        columns={columns}
        handleActionClick={SETxActTableClick}
      />
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.cusServiceListReducer
  }),
  {
    REQCusServiceList,
    MEMCusServiceDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(CusServiceList);
