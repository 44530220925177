import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQRegisLogList } from "./redux/actions";
import Table from "./table";
import { aColNDID, aColCounterService } from "./list_of_columns";
import DateInput from "./DateInput";
import { MEMRegisLogDetail } from "../RegisLogDetail/redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";
import Pagination from "./Pagination";
function RegisLogList(props) {
  const {
    REQRegisLogList,
    REQStrRegisLogList,
    RegisLogList,
    totalRegisLog,
    MEMRegisLogDetail,
    history
  } = props;
  const tColumeNDID = React.useMemo(() => aColNDID, []);
  const tColumeCounterService = React.useMemo(() => aColCounterService, []);

  const [tStartD, SETxStartD] = useState(new Date());
  const [tEndD, SETxEndD] = useState(new Date());
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("Email");
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH, SETtSortH] = useState("created_at DESC");
  const [tRegisLogType, SETxRegisLogType] = useState("");
  const [tFltStaCode, SETtFltStaCode] = useState(["Success"]);

  const CALLxRegisLog = () => {
    let tRegisLogType1 = "";
    if (
      history.location.pathname === "/RegisLog" ||
      history.location.pathname === "/RegisLog_CounterService"
    ) {
      SETxRegisLogType("counter_service");
      tRegisLogType1 = "counter_service";
    } else if (history.location.pathname === "/RegisLog_NDID") {
      SETxRegisLogType("ndid");
      tRegisLogType1 = "ndid";
    }

    if (history.location.PageCurrent === undefined) {
      SETxPageIndex(0);
      REQRegisLogList({
        page: "1",
        tStartD,
        tEndD,
        tSrch,
        tOpSrch,
        tSortH,
        tRegisLogType: tRegisLogType1,
        tFltStaCode: tFltStaCode
      });
    } else {
      let nPage = history.location.PageCurrent + 1;
      
      
      if (
        history.location.pathname === "/RegisLog" ||
        history.location.pathname === "/RegisLog_CounterService" ||
        history.location.pathname === "/RegisLog_NDID"
      ) {
        
        // SET value UI
        SETxStartD(history.location.UISearch.tStartD);
        SETxEndD(history.location.UISearch.tEndD);
        SETxOpSrch(history.location.UISearch.tOpSrch);
        SETxSrch(history.location.UISearch.tSrch);

        SETxPageIndex(history.location.PageCurrent);
        REQRegisLogList({
          page: nPage.toString(),
          tStartD: history.location.UISearch.tStartD,
          tEndD: history.location.UISearch.tEndD,
          tSrch: history.location.UISearch.tSrch,
          tOpSrch: history.location.UISearch.tOpSrch,
          tSortH: history.location.UISearch.tSortH,
          tRegisLogType: tRegisLogType1,
          tFltStaCode: tFltStaCode
        });
      }
    }
  };

  useEffect(() => {
    CALLxRegisLog();
  }, [tSortH, tFltStaCode]);

  const SRCHxUsrAct = () => {
    SETxPageIndex(0);
    REQRegisLogList({
      page: "1",
      tStartD,
      tEndD,
      tSrch,
      tOpSrch,
      tSortH,
      tRegisLogType: tRegisLogType,
      tFltStaCode: tFltStaCode
    });
  };
  const SRCHxUsrActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQRegisLogList({
      page: "1",
      tStartD,
      tEndD,
      tSrch: "",
      tOpSrch,
      tSortH,
      tRegisLogType: tRegisLogType,
      tFltStaCode: tFltStaCode
    });
  };
  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };
  // start test

  const SETxActHeaderClick = cell => {
    const sortMapping = {
      "REGISTER DATE": "created_at",
      "USERNAME": "username",
      "E-MAIL": "email",
      "ACCOUNT TYPE": "hasConverted",
      "UNITHOLDER": "UnitCount"
    };

    const sortField = sortMapping[cell];
    // Check if a mapping exists  
    if (sortField) {
      const newSortH = tSortH === `${sortField} ASC` ? `${sortField} DESC` : `${sortField} ASC`;
      SETtSortH(newSortH);
    }
  };
  // end test
  const SETxActTableClick = (cellValue, rowData, columnName) => {
    if (columnName === "USER NAME") {
      MEMRegisLogDetail({
        RowDataAt: rowData,
        PageCurrent: nPageIndex,
        RegisLogType: tRegisLogType,
        UISearch: {
          tStartD: tStartD,
          tEndD: tEndD,
          tSrch: tSrch,
          tOpSrch: tOpSrch,
          tSortH: tSortH
        }
      });
      history.push(`/RegisLogDetail`);
    } else if (columnName === "EXPORT") {
      let tJson = "";
      let tType = "";
      let tDateCreate = "";
      let tUUID = "";

      let oElement1 = document.createElement("a");
      let oFile1 = new Blob([JSON.stringify(rowData, null, 2)], {
        fileType: "text/json"
      });

      let oREGLog = JSON.parse(rowData.register_log);

      let oElement2 = document.createElement("a");
          let oFile2 = new Blob([JSON.stringify(tJson, null, 2)], {
            fileType: "text/json"
          });

      switch (tRegisLogType) {
        case "counter_service":
          tType = "Counter service";
          tDateCreate = rowData.created_at
            .replace("T", " ")
            .replace(".000Z", "");
          tUUID = rowData.uuid;
          oElement1.href = URL.createObjectURL(oFile1);
          oElement1.download =
            "RegisterLog[" +
            tType +
            "]-[" +
            rowData.id +
            "]-[" +
            tUUID +
            "]-[" +
            tDateCreate +
            "].json";
          document.body.appendChild(oElement1);
          oElement1.click();

          break;
        case "ndid":
          tType = "NDID";
          tDateCreate = rowData.created_at
            .replace("T", " ")
            .replace(".000Z", "");
          tUUID = rowData.customer_uuid;
          tJson = {
            id: rowData.id,
            customer_uuid: rowData.customer_uuid,
            username: rowData.username,
            email: rowData.email,
            first_name: rowData.first_name,
            first_name_th: rowData.first_name_th,
            identification_no: rowData.identification_no,
            mobile: rowData.mobile,
            register_type: rowData.register_type,
            created_at: rowData.created_at,
            updated_at: rowData.updated_at,
            register_image: rowData.register_image,
            register_log: oREGLog
          };
          
          oElement2.href = URL.createObjectURL(oFile2);
          oElement2.download =
            "RegisterLog[" +
            tType +
            "]-[" +
            rowData.id +
            "]-[" +
            tUUID +
            "]-[" +
            tDateCreate +
            "].json";
          document.body.appendChild(oElement2);
          oElement2.click();
          break;
        default:
          tType = "";
          break;
      }
    }
  };
  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);

    REQRegisLogList({
      page: nPageAt.toString(),
      tStartD,
      tEndD,
      tSrch,
      tOpSrch,
      tSortH,
      tRegisLogType: tRegisLogType,
      tFltStaCode: tFltStaCode
    });
    
  };
  const GETxWidthSrch = () => {
    if (tOpSrch === "UUID") {
      return 450;
    } else {
      return 331;
    }
  };
  const SETxFltHeader = cell => {
    if (cell === "STATUS CODE") {
      return (
        <div className="clients-header-sortby-converted-user">
          <div className="clients-header-sortby-text-container-converted-user">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-converted-user"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>
          <div
            className="clients-header-sortby-radio-container-converted-user"
            style={{ width: 150}}
          >
            <p>FILTER</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Success"
                checked={tFltStaCode.find(f => f === "Success")}
                onChange={() => {
                  let bChk = tFltStaCode.includes("Success");
                  if (bChk === true) {
                    let tFlt = tFltStaCode.filter(f => f !== "Success");
                    SETtFltStaCode(tFlt);
                  } else if (bChk === false) {
                    let tAdd = [...tFltStaCode, "Success"];
                    SETtFltStaCode(tAdd);
                  }
                }}
              />
              <label htmlFor="Success by-user" className="checkbox-label">
                Success
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Error by"
                checked={tFltStaCode.find(f => f === "Error")}
                onChange={() => {
                  let bChk = tFltStaCode.includes("Error");
                  if (bChk === true) {
                    let tFlt = tFltStaCode.filter(f => f !== "Error");
                    SETtFltStaCode(tFlt);
                  } else if (bChk === false) {
                    let tAdd = [...tFltStaCode, "Error"];
                    SETtFltStaCode(tAdd);
                  }
                }}
              />
              <label htmlFor="Error by-user" className="checkbox-label">
                Waiting
              </label>
            </div>
          </div>
        </div>
      );
    }
  };
  const SHWxTypeTable = () => {
    
    if (tRegisLogType === "counter_service") {
      return (
        <Table
          columns={tColumeCounterService}
          data={RegisLogList}
          handleActionHeaderClick={SETxActHeaderClick}
          handleFltHeader={SETxFltHeader}
          handleActionClick={SETxActTableClick}
          pageSelect={nPageIndex}
          regisLogType={tRegisLogType}
        />
      );
    } else if (tRegisLogType === "ndid") {
      return (
        <Table
          columns={tColumeNDID}
          data={RegisLogList}
          handleActionHeaderClick={SETxActHeaderClick}
          handleFltHeader={SETxFltHeader}
          handleActionClick={SETxActTableClick}
          pageSelect={nPageIndex}
          regisLogType={tRegisLogType}
        />
      );
    }
  };
  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalRegisLog / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };
  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 1 }}>
        <div style={{ height: 40 }} />
        {REQStrRegisLogList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrRegisLogList ? "Retrieving RegisterLog list..." : null}
            </p>
          </div>
        ) : null}
      </div>

      <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div
          className="usrAct-search"
          style={{ marginTop: 0, width: GETxWidthSrch() }}
        >
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxUsrAct()}
          />
          <input
            placeholder={"Search by " + tOpSrch}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={event => {
              SETxSrch(event.target.value);
            }}
            onKeyDown={event => {
              
              if (event.keyCode === 13) {
                // enter
                SETxSrch(event.target.value);
                SRCHxUsrAct();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxUsrActClr("")}
            />
          ) : null}
        </div>
      </div>
      <div
        className="clients-radio-container"
        style={{ marginLeft: 145, marginBottom: 20 }}
      >
        <RadioInput
          label="E-mail"
          value="Email"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Username"
          value="Username"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="English Name"
          value="English Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Thai Name"
          value="Thai Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="ID No. / Passport No."
          value="ID No. / Passport No."
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Mobile"
          value="Mobile"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
      </div>

      <div className="date-range-container" style={{ width: 400 }}>
        <p className="labelInputText" style={{ width: 290 }}>
          Date
          <span style={{ color: "#FF0000" }}>*</span>
        </p>
        <p style={{ paddingRight: 10 }}>:</p>
        <DatePicker
          selected={tStartD}
          onChange={date => SETxStartD(date)}
          selectsStart
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <p className="date-range-separator">-</p>
        <DatePicker
          selected={tEndD}
          onChange={date => moment(date).isAfter(tStartD) && SETxEndD(date)}
          selectsEnd
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
      </div>

      <button
        style={{ marginLeft: 20, marginBottom: 20 }}
        onClick={() => {
          SRCHxUsrAct();
        }}
        className="conFd-synce-button"
      >
        Search
      </button>
      {REQStrRegisLogList ? null : SHWxTypeTable()}
      {REQStrRegisLogList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.regisLogListReducer
  }),
  {
    REQRegisLogList,
    MEMRegisLogDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(RegisLogList);
