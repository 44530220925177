import { createAction } from "redux-act";

// --------Get FundTag Op------------
export const REQFundTag = createAction("REQUEST_SRCHRECDETAIL_FUNDTAG");
export const RESFundTag = createAction("RECEIVE_SRCHRECDETAIL_FUNDTAG");
export const FailFundTag = createAction("FAILED_SRCHRECDETAIL_FUNDTAG");


// ------Edit----
export const MEMSrchRecDetailEdt = createAction("MEM_SRCHRECDETAIL_EDIT");
export const FailSrchRecDetailEdt = createAction("FAILED_SRCHRECDETAIL_EDIT");
export const REQSrchRecDetailEdt = createAction("REQUEST_SRCHRECDETAIL_EDIT");
export const RESSrchRecDetailEdt = createAction("RECEIVE_SRCHRECDETAIL_EDIT");
