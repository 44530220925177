import { createReducer } from "redux-act";

import {
  MEMFundRecRiskDetailEdt,
  REQByRiskD,
  RESByRiskD,
  FailByRiskD,
  // ---Edit---
  REQByRiskEdt,
  RESByRiskEdt,
  FailByRiskEdt,
  CleanUpByRiskEdt,
  // ---FundCodeList----
  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList
} from "./actions";

const initialState = {
  // --By Risk------
  REQStrByRiskD: false,
  ByRiskD: null,
  errorMessage: false,
  totalByRiskD: 0,
  // --Get FundCodeList---
  REQStrFundCodeList: false,
  FundCodeList: [],
  totalFunds: 0
};

const fundRecRiskDetailReducer = createReducer(
  {
    // -------FundRecommend Risk Edit------------
    [MEMFundRecRiskDetailEdt]: (state, payload) => {
      return {
        ...state,
        mode: payload.mode,
        RowDataAt: payload.RowDataAt
      };
    },
    // --------Get Fundcode-----
    [REQFundCodeList]: (state) => {
      return {
        ...state,
        REQStrFundCodeList: true
      };
    },
    [RESFundCodeList]: (state, payload) => {
      
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: payload.data,
        totalFundCode: payload.total
      };
    },
    [FailFundCodeList]: (state) => {
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: [],
        totalFundCode: 0
      };
    },
    // ---------By Risk Detail------
    [REQByRiskD]: (state) => {
      return {
        ...state,
        REQStrByRiskD: true
      };
    },

    [RESByRiskD]: (state, payload) => {
      
      return {
        ...state,
        REQStrByRiskD: false,
        ByRiskD: payload,
        totalByRiskD: payload.total
      };
    },

    [FailByRiskD]: (state) => {
      return {
        ...state,
        REQStrByRiskD: false,
        ByRiskD: null,
        totalByRiskD: 0
      };
    },
    // ---------By Risk Edit------
    [REQByRiskEdt]: (state) => {
      
      return {
        ...state,
        REQStrByRiskD: true
      };
    },
    [RESByRiskEdt]: (state) => {
      
      return {
        ...state,
        REQStrByRiskD: false
      };
    },
    [FailByRiskEdt]: (state) => {
      return {
        ...state,
        REQStrByRiskD: false,
        ByRiskD: [],
        totalFundCode: 0
      };
    },
    [CleanUpByRiskEdt]: () => {
      return initialState;
    }
  },
  initialState
);

export default fundRecRiskDetailReducer;
