import { INDEX_LINK } from "./constants";

const initialState = {
  indexLink: 0,
  router: "/"
};

export default function indexLinkReducer(state = initialState, action) {
  switch (action.type) {
    case INDEX_LINK:
      return {
        ...state,
        indexLink: action.indexLink,
        router: action.router
      };

    default:
      return state;
  }
}
