export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "id",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 40
  },
  {
    Header: "CONSENT TYPE",
    accessor: "consent_type",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:50,
    width: 650
  },
  {
    Header: "CONSENT VERSION",
    accessor: "consent_version",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:50,
    width: 150
  },
  {
    Header: "ACTION",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 80,
    paddingLeft:10
  }
 
];
