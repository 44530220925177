import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQUntHldList, requestDisapproveClient } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import { setCurrentCustomerUntHldDetail,CleanUpUntHldSrch } from "../UntHldDetail/redux/actions";



import Images from "../../utils/styles/images";
import { WarningModal } from "../../components/Modal";
import "./style.scss";
import Pagination from "./Pagination";
function UntHldList(props) {
  const {
    REQUntHldList,
    REQStrUntHldList,
    UntHldList,
    totalUntHldAct,
    requestDisapproveClient,
    setCurrentCustomerUntHldDetail,
    CleanUpUntHldSrch,
    history
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("Email");
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tFltDocSta, SETtFltDocSta] = useState("1,2,3");
  const [tFltAccSta, SETtFltAccSta] = useState(["ALL","1","2","3","5"]);
  const [tFltConvertUsr, SETtFltConvertUsr] = useState("'',1");

  const [isShow, setIsShow] = useState(false);
  const [clientRowData, setClientRowData] = useState({
    customerUuid: "",
    unitholderid: "",
    firstName: "",
    history: ""
  });

  const CALLxUntHld = () => {
    CleanUpUntHldSrch();
    if(history.location.PageCurrent===undefined)
    {
      SETxPageIndex(0);
      REQUntHldList({
        page: "1",
        tSrch,
        tOpSrch,
        FltDocSta:tFltDocSta,
        FltAccSta:tFltAccSta,
        FltConvertUsr:tFltConvertUsr
      });
    }
    else
    {
     let nPage = history.location.PageCurrent+1;
      // SET value UI
      SETxOpSrch(history.location.UISearch.tOpSrch);
      SETxSrch(history.location.UISearch.tSrch);

      SETxPageIndex(history.location.PageCurrent);
      REQUntHldList({
        page: nPage.toString(),
        tSrch:history.location.UISearch.tSrch,
        tOpSrch:history.location.UISearch.tOpSrch,
        FltDocSta:tFltDocSta,
        FltAccSta:tFltAccSta,
        FltConvertUsr:tFltConvertUsr
      });
    }
  };

  useEffect(() => {
    CALLxUntHld();
  }, [tFltDocSta,tFltAccSta,tFltConvertUsr]);

  const SRCHxUntHld = () => {
    SETxPageIndex(0);
    REQUntHldList({
      page: "1",
      tSrch,
      tOpSrch,
      FltDocSta:tFltDocSta,
      FltAccSta:tFltAccSta,
      FltConvertUsr:tFltConvertUsr
    });
  };

  const SRCHxUsrActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQUntHldList({
      page: "1",
      tSrch: "",
      tOpSrch,
      FltDocSta:tFltDocSta,
      FltAccSta:tFltAccSta,
      FltConvertUsr:tFltConvertUsr
    });
  };

  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };

  const SETxActViewClick = (row) => {
    console.log("SETxActViewClick:",row.original);
    setCurrentCustomerUntHldDetail({
      uuid: row.original.uuid,
      unitholderid: row.original?.unitholderId || row.original?.unitholder_id,
      UsrAccountType:row.original.accounttype,
      NameTH:row.original.firstNameTh,
      UserName:row.original.username,
      PageCurrent: nPageIndex,
      UISearch:{
        tSrch:tSrch,
        tOpSrch:tOpSrch
      }
    });
    selectedObjectUuid(row.original.firstNameEn);
    history.push(`/UnitHolderDetail/${row.original.uuid}/UnitHolderID=${row.original?.unitholderId || row.original?.unitholder_id}`);
  };

  const handleActionClick = (row, actionName) => {
    if (
      actionName === "APPROVE" ||
      actionName === "REJECT" ||
      actionName === "CANCEL" ||
      actionName === "CREATING"
    ) 
    {
      setCurrentCustomerUntHldDetail({
        uuid: row.uuid,
        unitholderid: row.unitholderId,
        UsrAccountType:row.accounttype,
        NameTH:row.firstNameTh,
        UserName:row.username,
        PageCurrent: nPageIndex,
        UISearch:{
          tSrch:tSrch,
          tOpSrch:tOpSrch
        }
      });
      selectedObjectUuid(row.firstNameEn);

      history.push(`/UnitHolderDetail/${row.uuid}/UnitHolderID=${row.unitholderId}`);
    } 
    else if (actionName === "DISAPPROVE") 
    {
     
      setClientRowData({
        customerUuid: row.uuid,
        unitholderid: row.unitholderId,
        firstName: row.firstNameEn,
        UserName:row.username,
        history: history
      });
      setIsShow(true);
    }
  };

  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQUntHldList({
      page: nPageAt.toString(),
      tSrch,
      tOpSrch,
      FltDocSta:tFltDocSta,
      FltAccSta:tFltAccSta,
      FltConvertUsr:tFltConvertUsr
    });
    
  };
  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalUntHldAct / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };
  const GETxWidthSrch = () => {
    if(tOpSrch==="UUID")
    {
      return 450;
    }else 
    {
      return 331;
    }
  };

  function hasCode(fltDocSta, code) {
    return fltDocSta.includes(code);
  }
  
  function addCode(fltDocSta, code) {
    // No change if already present
    if (hasCode(fltDocSta, code)) { 
      return fltDocSta; 
    }
    return fltDocSta ? `${fltDocSta},${code}` : code; 
  }
  
  function removeCode(fltDocSta, code) {
    return fltDocSta.replace(new RegExp(`${code},?`, "g"), "");
  }

  const SETxFltHeader = cell => 
  {
    if (cell === "DOC. STATUS") 
    {
      return (
        <div className="clients-header-sortby">
          <div className="clients-header-sortby-text-container">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>

          <div className="clients-header-sortby-radio-container">
            <p>FILTER STATUS BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="All"
                name="all-doc"
                id="all-doc"
                checked={tFltDocSta.includes("1,2,3")}
                onChange={() =>
                  tFltDocSta.includes("1,2,3")
                    ? SETtFltDocSta("4")
                    : SETtFltDocSta("1,2,3")
                }
              />
              <label htmlFor="all-doc" className="checkbox-label">
                ALL
              </label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Approved"
                name="approved-doc"
                id="approved-doc"
                checked={tFltDocSta.includes("2")}
                // start test
                onChange={() => {
                  const PendingSET = tFltDocSta.includes("1");
                  const RejectedSET = tFltDocSta.includes("3");
                
                  if (tFltDocSta.includes("2") && (PendingSET || RejectedSET)) {
                    SETtFltDocSta(tFltDocSta.replace(/,?2/g, ""));
                  } else if (tFltDocSta.includes("2") || tFltDocSta.includes(",2")) {
                    SETtFltDocSta("1,2,3");
                  } else {
                    let newFltDocSta = "";
                
                    if (PendingSET && RejectedSET) {
                      newFltDocSta = "1,2,3";
                    } else if (PendingSET) {
                      newFltDocSta = "1,2";
                    } else if (RejectedSET) {
                      newFltDocSta = "2,3";
                    } else {
                      newFltDocSta = "2";
                    }
                
                    SETtFltDocSta(newFltDocSta);
                  }
                }}
                // end test
              />
              <label htmlFor="approved-doc">APPROVED</label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Pending"
                name="pending-doc"
                id="pending-doc"
                checked={tFltDocSta.includes("1")}
                // start test
                onChange={() => {
                  const ApprovedSET = hasCode(tFltDocSta, "2"); 
                  const RejectedSET = hasCode(tFltDocSta, "3"); 

                  // Start with the current value
                  let newFltDocSta = tFltDocSta;

                  if (hasCode(newFltDocSta, "1") && (ApprovedSET || RejectedSET)) {
                    newFltDocSta = removeCode(newFltDocSta, "1");
                  } else if (hasCode(newFltDocSta, "1")) {
                    // Select all
                    newFltDocSta = "1,2,3";
                  } else {
                    // Ensure '1' is present
                    newFltDocSta = addCode(newFltDocSta, "1");
                    if (ApprovedSET) { newFltDocSta = addCode(newFltDocSta, "2"); }
                    if (RejectedSET) { newFltDocSta = addCode(newFltDocSta, "3"); }
                  }
                  SETtFltDocSta(newFltDocSta);
                }}
                // end test
              />
              <label htmlFor="pending-doc">PENDING</label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Rejected"
                name="rejected-doc"
                id="rejected-doc"
                checked={tFltDocSta.includes("3")}
                // start test
                onChange={() => {
                  const PendingSET = tFltDocSta.includes("1");
                  const ApprovedSET = tFltDocSta.includes("2");
                  const RejectedSET = tFltDocSta.includes("3");
                
                  if (RejectedSET && (ApprovedSET || PendingSET)) {
                    SETtFltDocSta(tFltDocSta.replace(/,?3/g, ""));
                  } else {
                    let newFltDocSta = "";
                
                    if (ApprovedSET) { newFltDocSta += "2"; }
                    if (PendingSET) { newFltDocSta += (newFltDocSta ? "," : "") + "1"; }
                    if (!RejectedSET) { newFltDocSta += (newFltDocSta ? "," : "") + "3"; }
                
                    SETtFltDocSta(newFltDocSta);
                  }
                }}                
                // end test
              />
              <label htmlFor="rejected-doc">REJECTED</label>
            </div>
          </div>
        </div>
      );
    }else if (cell === "ACC. STATUS") 
    {
      return (
        <div className="clients-header-sortby-accStatus">
          <div className="clients-header-sortby-text-container-accStatus">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-accStatus"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>
          <div className="clients-header-sortby-radio-container-accStatus" style={{height:210}}>
            <p>FILTER STATUS BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="ALL"
                checked={tFltAccSta.find(f => f ==="ALL")}
                 onChange={() =>   
                  {
                      let bChk= tFltAccSta.includes("ALL");
                      if(bChk===true)
                      {
                        SETtFltAccSta([]);
                      }
                      else if(bChk===false)
                      {   
                        SETtFltAccSta(["ALL","1","2","3","5"]);
                      }
                  }
                }
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                ALL
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="APPROVED"
                checked={tFltAccSta.find(f => f ==="2")}
                 onChange={() =>   
                  {
                      let bChk= tFltAccSta.includes("2");
                      let tFlt= tFltAccSta.filter(f=>f!=="2"&&f!=="ALL");
                      if(bChk===true)
                      {   
                        SETtFltAccSta(tFlt);
                      }
                      else if(bChk===false)
                      {
                        if(tFltAccSta.includes("1")&&tFltAccSta.includes("3")&&tFltAccSta.includes("5"))
                        {
                          let tAdd = [...tFlt, "2","ALL"];
                          SETtFltAccSta(tAdd);
                        }
                        else
                        {
                          let tAdd = [...tFlt, "2"];
                          SETtFltAccSta(tAdd);
                        }
                      }
                  }
                }
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                APPROVED
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="PENDING"
                checked={tFltAccSta.find(f => f ==="1")}
                 onChange={() =>   
                  {
                      let bChk= tFltAccSta.includes("1");
                      let tFlt= tFltAccSta.filter(f=>f!=="1"&&f!=="ALL");
                      if(bChk===true)
                      {
                        SETtFltAccSta(tFlt);
                      }
                      else if(bChk===false)
                      {
                        if(tFltAccSta.includes("2")&&tFltAccSta.includes("3")&&tFltAccSta.includes("5"))
                        {
                          let tAdd = [...tFlt, "1","ALL"];
                          SETtFltAccSta(tAdd);
                        }
                        else
                        {
                          let tAdd = [...tFlt, "1"];
                          SETtFltAccSta(tAdd);
                        }
                      }
                  }
                }
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                PENDING
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="REJECTED"
                checked={tFltAccSta.find(f => f ==="3")}
                 onChange={() =>   
                  {
                      let bChk= tFltAccSta.includes("3");
                      let tFlt= tFltAccSta.filter(f=>f!=="3"&&f!=="ALL");
                      if(bChk===true)
                      {
                        SETtFltAccSta(tFlt);
                      }
                      else if(bChk===false)
                      {
                        if(tFltAccSta.includes("1")&&tFltAccSta.includes("2")&&tFltAccSta.includes("5"))
                        {
                          let tAdd = [...tFlt, "3","ALL"];
                          SETtFltAccSta(tAdd);
                        }
                        else
                        {
                          let tAdd = [...tFlt, "3"];
                          SETtFltAccSta(tAdd);
                        }
                      }
                  }
                }
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
              REJECTED
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="CREATING"
                checked={tFltAccSta.find(f => f ==="5")}
                 onChange={() =>   
                  {
                      let bChk= tFltAccSta.includes("5");
                      let tFlt= tFltAccSta.filter(f=>f!=="5"&&f!=="ALL");
                      if(bChk===true)
                      {
                        SETtFltAccSta(tFlt);
                      }
                      else if(bChk===false)
                      {
                        if(tFltAccSta.includes("1")&&tFltAccSta.includes("2")&&tFltAccSta.includes("3"))
                        {
                          let tAdd = [...tFlt, "5","ALL"];
                          SETtFltAccSta(tAdd);
                        }
                        else
                        {
                          let tAdd = [...tFlt, "5"];
                          SETtFltAccSta(tAdd);
                        }
                      }
                  }
                }
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
              CREATING
              </label>
            </div>
          </div>
        </div>
      );
    }else if (cell === "CONVERTED USER") {
   
      return (
        <div className="clients-header-sortby-converted-user">
          <div className="clients-header-sortby-text-container-converted-user">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-converted-user"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>
          <div className="clients-header-sortby-radio-container-converted-user">
            <p>FILTER USER BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Yes"
                name="yes-user"
                id="yes-user"
                checked={tFltConvertUsr.includes("1")}
                onChange={() =>   
                  {
                    
                    if (tFltConvertUsr.includes(",1")) 
                    {
                      SETtFltConvertUsr(tFltConvertUsr.replace(",1", ""));
                    } 
                    else if (tFltConvertUsr.includes("1") ) 
                    {
                      
                      SETtFltConvertUsr(tFltConvertUsr.replace("1", ""));
                    }
                    else if (tFltConvertUsr.includes("''"))
                    {
                      // Add YES
                      SETtFltConvertUsr("'',1");
                    } 
                    else if (tFltConvertUsr==="")
                    {
                      SETtFltConvertUsr("1");
                    }
                  }
                }
              />
              <label htmlFor="yes-user" className="checkbox-label">
                Yes
              </label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="No"
                name="no-user"
                id="no-user"
                checked={tFltConvertUsr.includes("''")}
                onChange={() =>{
                 
                 if (tFltConvertUsr.includes(",''")) 
                 {
                   SETtFltConvertUsr(tFltConvertUsr.replace(",''", ""));
                 } else if (tFltConvertUsr.includes("''")) 
                 { 
                   SETtFltConvertUsr(tFltConvertUsr.replace("''", ""));
                 } 
                 else if (tFltConvertUsr.includes("1"))
                 {
                   // Add NO
                   SETtFltConvertUsr("'',1");
                 }  
                 else if (tFltConvertUsr==="")
                 {
                   // SET NO
                   SETtFltConvertUsr("''");
                 }
                 }
                }
              />
              <label htmlFor="no-user" className="checkbox-label">
                No
              </label>
            </div>
          </div>
        </div>
      );
    }
  };
  const handleModalGo = () => {
    if (isShow) {
      setIsShow(false);
      // Request this client's account status to be reset to Pending
      requestDisapproveClient(clientRowData);
    }
  };
  return (
    <Fragment>
      <WarningModal
        show={isShow}
        onHide={() => {
          setIsShow(false);
          setClientRowData({
            customerUuid: "",
            unitholderid: "",
            firstName: "",
            history: ""
          });
        }}
        onHandleGo={() => handleModalGo()}
        onHandleCancel={() => {
          setIsShow(false);
          setClientRowData({
            customerUuid: "",
            unitholderid: "",
            firstName: "",
            history: ""
          });
        }}
        type={"Disapprove"}
        status={"1"}
      />



      <div className="button-create-container" style={{ marginBottom: 10 }}>
        <div style={{ height: 40 }} />

        {REQStrUntHldList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrUntHldList ? "Retrieving UnitHolder list..." : null}
            </p>
          </div>
        ) : null}
      </div>

      <div
        className="labelInput"
        style={{ marginLeft: 20, marginTop: 50 }}
      >
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div className="usrAct-search" style={{ marginTop: 0,width:GETxWidthSrch() }}>
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxUntHld()}
          />
          <input
            placeholder={"Search by " + tOpSrch}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={event => {
              SETxSrch(event.target.value);
            }}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                
                SETxSrch(event.target.value);
                SRCHxUntHld();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxUsrActClr("")}
            />
          ) : null}
        </div>
      </div>
      <div className="clients-radio-container"style={{ marginLeft: 145, marginBottom: 20 }}>
        <RadioInput
          label="E-mail"
          value="Email"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />{" "}
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Username"
          value="Username"
          checked={tOpSrch}
          setter={SETxOpSrch}
        /> 
        <span style={{ marginRight: 20 }} />
          <RadioInput
          label="English Name"
          value="English Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
         <span style={{ marginRight: 20 }} />
          <RadioInput
          label="Thai Name"
          value="Thai Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
          <RadioInput
          label="ID No. / Passport No."
          value="ID No. / Passport No."
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
         <span style={{ marginRight: 20 }} />
          <RadioInput
          label="Mobile"
          value="Mobile"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
      </div>
      {}
      {REQStrUntHldList ? null : (
        <Table
          columns={columns}
          data={UntHldList || []}
          handleViewClick={SETxActViewClick}
          handleFltHeader={SETxFltHeader}
          handleActionClick={handleActionClick}
          pageSelect={nPageIndex}
        />
      )}
      {REQStrUntHldList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.untHldListReducer
  }),
  {
    REQUntHldList,
    setCurrentCustomerUntHldDetail,
    requestDisapproveClient,
    // clear state in UntHldDdetail
    CleanUpUntHldSrch
    
  }
);

export default compose(
  withConnect,
  withRouter
)(UntHldList);
