import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Images from "../../utils/styles/images";
import { WarningModal } from "./warningModal";
import {
  requestClientsListAction,
  requestDisapproveClient,
  getApplicants
} from "../Clients/redux/actions";
import { requestUserAccountUnlock } from "../ClientDetail/redux/actions";
import { list_of_columns } from "./list_of_columns";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import { setCurrentCustomer } from "../ClientDetail/redux/actions";
import Table from "./table";
import Pagination from "../Clients/pagination";
import "../Clients/style.scss";

function Clients(props) {
  const {
    requestClientsListAction,
    history,
    selectedObjectUuid,
    setCurrentCustomer,
    totalAmountOfClients,
    requestingUnlock,
    getApplicants,
    applicantList,
    requestApplicants,
    requestUserAccountUnlock
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [sortByDocStatus, setSortDocStatus] = useState(
    "&documentStatusCodes=1,2,3"
  );
  const [sortByAccStatus, setSortAccStatus] = useState("&statusCodes=1,2,3");
  const [isShow, setIsShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const columns = React.useMemo(() => list_of_columns, []);
  const pageSize = 10;
  const showPagination =
    totalAmountOfClients > 0 && totalAmountOfClients > pageSize ? true : false;

  useEffect(() => {
    getApplicants({
      offset: pageIndex * pageSize,
      limit: pageSize
    });
  }, [sortDirection, sortByDocStatus, sortByAccStatus, pageIndex]);

  const handleSearch = () => {
    setPageIndex(0);
    getApplicants({
      offset: pageIndex * pageSize,
      limit: pageSize,
      email: searchValue
    });
  };

  const clearSearch = () => {
    setPageIndex(0);
    setSearchValue("");
    setSearchQuery("");
    setSortDocStatus("&documentStatusCodes=1,2,3");
    requestClientsListAction({
      limit: pageSize,
      offset: 0,
      sortDirection: "DESC",
      sortByDocStatus: "&documentStatusCodes=1,2,3",
      sortByAccStatus: "&statusCodes=1,2,3"
    });
  };

  const handleViewClick = row => {
    // Move admin user to the client detail page with information on this particular user
    setCurrentCustomer({
      uuid: row.original.uuid,
      unitholderid: row.original.unitholderId
    });
    selectedObjectUuid(row.original.firstNameEn);
    history.push(`/clients/${row.original.uuid}`);
  };

  const handleActionClick = (rowData) => {
    // Confirm with the admin user if this is what they want to do by showing modal
    setSelectedCustomer(rowData["Email Address"]);
    setIsShow(true);
  };

  const handleActionHeaderClick = cell => {
    if (cell === "SUBMISSION DATE") {
      if (sortDirection === "ASC") {
        setSortDirection("DESC");
      } else if (sortDirection === "DESC") {
        setSortDirection("ASC");
      }
    }
  };

  const statusCodesMap = {
    "1": "1",
    "2": "1,2",
    "3": "1,3",
    "1,2": "1,2,3", 
    "1,3": "1,2,3"
  };

  const handleSortHeader = cell => {
    if (cell === "DOC. STATUS") {
      return (
        <div className="clients-header-sortby">
          <div className="clients-header-sortby-text-container">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>

          <div className="clients-header-sortby-radio-container">
            <p>FILTER STATUS BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="All"
                name="all-doc"
                id="all-doc"
                checked={sortByDocStatus.includes("1,2,3")}
                onChange={() =>
                  sortByDocStatus.includes("1,2,3")
                    ? setSortDocStatus("&documentStatusCodes=4")
                    : setSortDocStatus("&documentStatusCodes=1,2,3")
                }
              />
              <label htmlFor="all-doc" className="checkbox-label">
                ALL
              </label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Approved"
                name="approved-doc"
                id="approved-doc"
                checked={sortByDocStatus.includes("2")}
                // start test
                onChange={() => {
                  const PendingSET = sortByDocStatus.includes("1");
                  const RejectedSET = sortByDocStatus.includes("3");
                  let newSortDocStatus = "";
                
                  if (sortByDocStatus.includes(",2") || sortByDocStatus.includes("2")) {
                    if (PendingSET || RejectedSET) {
                      newSortDocStatus = sortByDocStatus.replace(",2", "").replace("2", "");
                    } else {
                      newSortDocStatus = "&documentStatusCodes=1,2,3";
                    }
                  } else {
                    if (PendingSET && RejectedSET) {
                      newSortDocStatus = "&documentStatusCodes=1,2,3";
                    } else if (PendingSET) {
                      newSortDocStatus = "&documentStatusCodes=1,2";
                    } else if (RejectedSET) {
                      newSortDocStatus = "&documentStatusCodes=2,3";
                    } else {
                      newSortDocStatus = "&documentStatusCodes=2";
                    }
                  }
                
                  setSortDocStatus(newSortDocStatus);
                }}
                // end test                
              />
              <label htmlFor="approved-doc">APPROVED</label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Pending"
                name="pending-doc"
                id="pending-doc"
                checked={sortByDocStatus.includes("1")}
                // start test
                onChange={() => {
                  const ApprovedSET = sortByDocStatus.includes("2");
                  const RejectedSET = sortByDocStatus.includes("3");
                
                  if (sortByDocStatus.includes("1,")) {
                    setSortDocStatus(sortByDocStatus.replace("1,", ""));
                  } else if (sortByDocStatus.includes("1")) {
                    setSortDocStatus(sortByDocStatus.replace("1", ""));
                  } else if (ApprovedSET && RejectedSET) {
                    setSortDocStatus("&documentStatusCodes=1,2,3");
                  } else if (ApprovedSET) {
                    setSortDocStatus("&documentStatusCodes=1,2");
                  } else if (RejectedSET) {
                    setSortDocStatus("&documentStatusCodes=1,3");
                  } else {
                    setSortDocStatus("&documentStatusCodes=1");
                  }
                }}
                // end test                
              />
              <label htmlFor="pending-doc">PENDING</label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Rejected"
                name="rejected-doc"
                id="rejected-doc"
                checked={sortByDocStatus.includes("3")}
                // start test
                onChange={() => {
                  const PendingSET = sortByDocStatus.includes("1");
                  const ApprovedSET = sortByDocStatus.includes("2");
                
                  if (sortByDocStatus.includes(",3")) {
                    setSortDocStatus(sortByDocStatus.replace(",3", ""));
                  } else if (sortByDocStatus.includes("3")) {
                    setSortDocStatus(sortByDocStatus.replace("3", ""));
                  } else if (ApprovedSET && PendingSET) {
                    setSortDocStatus("&documentStatusCodes=1,2,3");
                  } else if (ApprovedSET) {
                    setSortDocStatus("&documentStatusCodes=2,3");
                  } else if (PendingSET) {
                    setSortDocStatus("&documentStatusCodes=1,3");
                  } else {
                    setSortDocStatus("&documentStatusCodes=3");
                  }
                }}
                // end test                
              />
              <label htmlFor="rejected-doc">REJECTED</label>
            </div>
          </div>
        </div>
      );
    } else if (cell === "ACC. STATUS") {
      return (
        <div className="clients-header-sortby-accStatus">
          <div className="clients-header-sortby-text-container-accStatus">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-accStatus"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>
          <div className="clients-header-sortby-radio-container-accStatus">
            <p>FILTER STATUS BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="All"
                name="all-acc"
                id="all-acc"
                checked={sortByAccStatus.includes("1,2,3")}
                onChange={() =>
                  sortByAccStatus.includes("1,2,3")
                    ? setSortAccStatus("&statusCodes=4")
                    : setSortAccStatus("&statusCodes=1,2,3")
                }
              />
              <label htmlFor="all-acc" className="checkbox-label">
                ALL
              </label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Approved"
                name="approved-acc"
                id="approved-acc"
                checked={sortByAccStatus.includes("2")}
                // start test
                onChange={() => {
                  const hasCommaTwo = sortByAccStatus.includes(",2"); 
                  const hasTwo = sortByAccStatus.includes("2");
                  const includesPending = sortByAccStatus.includes("1");
                  const includesRejected = sortByAccStatus.includes("3");
                
                  let newSortAccStatus = "&statusCodes=";

                  if (hasCommaTwo) {
                    setSortAccStatus(sortByAccStatus.replace(",2", "")); 
                  } else if (hasTwo) {
                    setSortAccStatus(sortByAccStatus.replace("2", "")); 
                  } else {
                    if (includesPending && includesRejected) {
                      newSortAccStatus += "1,2,3";
                    } else if (includesPending) {
                      newSortAccStatus += "1,2";
                    } else if (includesRejected) {
                      newSortAccStatus += "2,3";
                    } else {
                      newSortAccStatus += "2";
                    }
                    setSortAccStatus(newSortAccStatus);
                  }
                }}
                // end test
              />
              <label htmlFor="approved-acc">APPROVED</label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Pending"
                name="pending-acc"
                id="pending-acc"
                checked={sortByAccStatus.includes("1")}
                // start test
                onChange={() => {
                  const ApprovedSET = sortByAccStatus.includes("2");
                  const RejectedSET = sortByAccStatus.includes("3");
                  const hasComma1 = sortByAccStatus.includes("1,");
                  const has1 = sortByAccStatus.includes("1");
                
                  let newSortAccStatus = "&statusCodes=";
                
                  if (hasComma1) {
                    newSortAccStatus = sortByAccStatus.replace("1,", "");
                  } else if (has1) {
                    newSortAccStatus = sortByAccStatus.replace("1", "");  
                  }
                
                  const statusCodeKey = [has1 ? "1" : "", ApprovedSET ? "2" : "", RejectedSET ? "3" : ""].filter(Boolean).join(",");
                  // Default to '1' if no match
                  newSortAccStatus += statusCodesMap[statusCodeKey] || "1";
                
                  setSortAccStatus(newSortAccStatus);
                }}
                // end test
              />
              <label htmlFor="pending-acc">PENDING</label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Rejected"
                name="rejected-acc"
                id="rejected-acc"
                checked={sortByAccStatus.includes("3")}
                onChange={() => {
                }}
              />
              <label htmlFor="rejected-acc">REJECTED</label>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleModalGo = () => {
    if (isShow) {
      setIsShow(false);
      // Request this client's account status to be reset to Pending
      requestUserAccountUnlock({
        customerId: selectedCustomer
      });
    }
  };

  return (
    <Fragment>
      <WarningModal
        show={isShow}
        onHide={() => {
          setIsShow(false);
        }}
        onHandleGo={() => handleModalGo()}
        onHandleCancel={() => {
          setIsShow(false);
        }}
        type={"Disapprove"}
        status={"1"}
      />
      {requestApplicants || requestingUnlock ? (
        <div className="clients-list-progress">
          <CircularProgress />
          <p className="clients-list-progress-text">
            {requestApplicants
              ? "Retrieving Applicants list..."
              : "Unlocking Applicant"}
          </p>
        </div>
      ) : null}
      <div className="clients-search-header" style={{marginRight:20}}>
        <div className="clients-search-container">
          <div className="clients-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="clients-details-begin-search"
              onClick={() => searchQuery.length > 0 && handleSearch()}
            />
            <input
              placeholder="Search by Email Address"
              className="clients-search-bar"
              value={searchValue}
              onChange={event => {
                setSearchValue(event.target.value);
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
            {searchQuery && searchQuery.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="client-details-cancel-search"
                onClick={() => clearSearch()}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="clients-container" style={{marginRight:20}}>
        {applicantList.users ? (
          <Table
            columns={columns}
            data={applicantList.users}
            handleViewClick={handleViewClick}
            handleActionClick={handleActionClick}
            handleActionHeaderClick={handleActionHeaderClick}
            handleSortHeader={handleSortHeader}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        ) : null}
      </div>
      {showPagination ? (
        <Pagination
          handlePageChange={page => setPageIndex(page)}
          total={totalAmountOfClients}
          pageSize={pageSize}
          defaultPage={0}
        />
      ) : null}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.clientsListReducer,
    ...state.clientDetailReducer
  }),
  {
    requestClientsListAction,
    selectedObjectUuid,
    setCurrentCustomer,
    requestDisapproveClient,
    getApplicants,
    requestUserAccountUnlock
  }
);

export default compose(
  withConnect,
  withRouter
)(Clients);
