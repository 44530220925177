export const list_of_columns = [
  {
    Header: "NO.",
     disableSortBy: true,
     textAlign:"left",
     paddingLeft:5,
     width: 40
  },
  {
    Header: "DEVICE NAME",
    accessor: "deviceName",
    textAlign:"left",
    width: 140,
    paddingLeft:0
  },
  {
    Header: "VERIFIED BY",
    accessor: "verifyBy",
    textAlign:"left",
    paddingLeft:0,
    width: 150
  },
  {
    Header: "REGISTERED DATE",
    accessor: "registeredAt",
    textAlign:"left",
    paddingLeft:5,
    width: 130
  },
  {
    Header: "DEREGISTERED DATE",
    accessor: "deregisteredAt",
    textAlign:"left",
    paddingLeft:0,
    width: 135
  },
  {
    Header: "PLATFORM",
    accessor: "appPlatform",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:0,
    width: 80
  },
  {
    Header: "STATUS",
    accessor: "registerStatus",
    textAlign:"left",
    paddingLeft:10,
    width: 80
  }
];
