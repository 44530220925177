export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no"
  },
  {
    Header: "UNITHOLDER ID",
    accessor: "UnitholderId"
  },
  {
    Header: "FULL NAME",
    accessor: "UnitholderName"
  },
  {
    Header: "BANK NAME",
    accessor: "BankName"
  },
  {
    Header: "ACCOUNT NO.",
    accessor: "AccountNo"
  },
  {
    Header: "ACCOUNT NAME",
    accessor: "AccountName"
  },
  {
    Header: "REQUEST DATE",
    accessor: "TransDate"
  },
  {
    Header: "STATUS",
    accessor: "Status"
  }
];
