import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Images from "../../../utils/styles/images";
import { resetErrorMessage } from "../redux/actions";
import "./style.scss";

function ErrorFDModal(props) {
  const { errorMessage, resetErrorMessage } = props;
  return (
    <div className={"error-fd-modal"}>
      <div className={"error-fd-modal-content"}>
        <img src={Images.closeButton.close} alt="close" />
        <h3>FAILED!</h3>
        <p className={"error-fd-modal-message"}>The API returned the following error(s):</p>
        <p className={"error-fd-modal-message"}>{errorMessage}</p>
        <button
          onClick={() => resetErrorMessage()}
          className={"error-fd-modal-button"}
        >
          OK
        </button>
      </div>
    </div>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundDetailsReducer
  }),
  {
    resetErrorMessage
  }
);

export default compose(withConnect)(ErrorFDModal);
