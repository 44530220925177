import React, { Fragment, useEffect } from "react";
import {
  CheckboxInput,
  DateInput,
  DropdownInput,
  RadioInput,
  TextInput
} from "../../../components/input_hook_form/";
import Images from "../../../utils/styles/images";
import FundDetailsExtra from "./FundDetailsExtra";
import "./style.scss";

const riskOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" }
];
const configurationOptions = [
  { value: 1, label: "Buy", name: "can_buy" },
  { value: 1, label: "Switch", name: "can_switch" },
  { value: 1, label: "Redeem", name: "can_redeem" }
];
const recommendOptions = [{ value: 1, label: "Recommend", name: "recommend" }];
const websiteOptions = [
  { value: "Thailand", label: "Thailand", name: "Thailand" }
];

// start test
// FundStatus component
const FundStatus = ({ fundStatus, mode, setValue, fundDetailsEN, setFundStatus }) => {
  const handleStatusChange = () => {
    if (mode !== "VIEW") {
      setFundStatus(!fundStatus);
      setValue("fund-active", !fundStatus, true);
    }
  };

  return (
    <div className="funddetails-status-container">
      <h3>FUND DETAILS</h3>
      <div className={fundStatus ? "funddetails-status-active-container" : "funddetails-status-inactive-container"}>
        <button
          type="button"
          disabled={mode === "VIEW"}
          onClick={handleStatusChange}
          className={fundStatus ? "funddetails-status-active" : "funddetails-status-inactive"}
        >
          {fundStatus ? "Active" : "Inactive"}
        </button>
      </div>
      {!fundStatus && <p className="funddetails-inactivated">{fundDetailsEN.last_active_date}</p>}
    </div>
  );
};

// FundDetailsInputs component
const FundDetailsInputs = ({
  register,
  control,
  getValues,
  setValue,
  errors,
  mode,
  resetFundCodeExists,
  requestFundCodeExists,
  fundCodeExistsInDB,
  fundCodeExistsInPentacle,
  fundIDFromCheckingFundCodeExists,
  trigger,
  defaultValues,
  formState,
  fundDetailsEN
}) => {
  const { touched } = formState;

  const SearchButton = ({ name, disabled }) => {
    let fieldValue = getValues()[name];
    return (
      <Fragment>
        {fieldValue ? (
          <button
            type="button"
            className={
              errors[name] && mode !== "VIEW"
                ? "search-button-cancel-error"
                : "search-button-cancel"
            }
            onClick={e => {
              e.preventDefault();
              // clear the form input
              setValue("funddetails-fundcode", "", true);
              // reset the store state (just for the search part)
              resetFundCodeExists();
            }}
            disabled={disabled}
          >
            <img src={Images.closeButton.close} alt="cancel" />
          </button>
        ) : null}
        <button
          type="button"
          className={
            errors[name] && mode !== "VIEW"
              ? "search-button-error"
              : "search-button"
          }
          onClick={(e) => {
            e.preventDefault();
            // only fire the request if there's an actual input by the user
            getValues()["funddetails-fundcode"] && requestFundCodeExists({ fund_code: getValues()["funddetails-fundcode"] });
          }}
          disabled={disabled}
        >
          <img src={Images.search.search} alt="search" />
        </button>
      </Fragment>
    );
  };
  
  const PreviewButton = ({ name, disabled }) => {
    let url = getValues()[name];
    if (url === "") {
      disabled = true;
    }
    return (
      <button
        type="button"
        className={"preview-button"}
        onClick={e => {
          e.preventDefault();
          window.open(url, "_blank");
        }}
        disabled={disabled}
      >
        PREVIEW
      </button>
    );
  };
  const validateURL = url => {
    // URL must begin with either HTTP:// or HTTPS:// otherwise it will not work in app
    if (!/^http:\/\//.test(url) && !/^https:\/\//.test(url)) {
      // does not contain either http or https, invalid input
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="funddetails-inputs">
      <TextInput
        label="Fund Code"
        placeholder="Search Fund Code"
        register={register({
          required: true,
          minLength: 1,
          validate: () => {
            if (fundDetailsEN.fund_code && !touched["funddetails-fundcode"]) {
              return true;
            } else if (fundCodeExistsInDB === false && fundCodeExistsInPentacle) {
              return true;
            } else {
              return false;
            }
          }
        })}
        error={mode !== "VIEW" && errors["funddetails-fundcode"]}
        readOnly={mode === "EDIT" || mode === "VIEW"}
        name="funddetails-fundcode"
        type={"text"}
        onChange={(e) => {
          const value = e.target.value;
          setValue("funddetails-fundcode",value);
          requestFundCodeExists({ fund_code: value });
        }}
        inputStyle={
          getValues()["funddetails-fundcode"] 
          ? "fundcode-modified-has-cancel" 
          : "fundcode-modified"
        }
        action={
        <SearchButton name="funddetails-fundcode" disabled={mode === "EDIT" || mode === "VIEW"} />}
      />
      {fundCodeExistsInDB || fundCodeExistsInPentacle === false ? (
            <div className={"fundcode-error-message"}>
              <div />
              <img src={Images.error} alt="error" />
              {fundCodeExistsInDB ? (
                <p>
                  Fund already exists with this fund code. Please try again.
                </p>
              ) : null}
              {fundCodeExistsInPentacle === false ? (
                <p>Fund code not found. Please try again.</p>
              ) : null}
            </div>
          ) : null}
          {/* If a search has not been done yet, but there's already input from user, we tell them why it's not enough. */}
          {errors["funddetails-fundcode"] &&
          errors["funddetails-fundcode"].type === "validate" &&
          fundCodeExistsInDB === null ? (
            <div className={"fundcode-error-message"}>
              <div />
              <p>
                You must verify the entered fund code by running the search on
                it before it can be considered valid.
              </p>
            </div>
          ) : null}
          <div className={"funddetails-spacer"} />
          <DropdownInput
            label={"Website"}
            placeholder={"Select Website"}
            error={mode !== "VIEW" && errors["funddetails-website"]}
            register={register({ required: true })}
            name={"funddetails-website"}
            disabled={mode === "VIEW"}
            options={websiteOptions}
          />
          <div className={"funddetails-spacer"} />
          <TextInput
            label={"Fund Article Initial"}
            placeholder={"Fund Article Initial"}
            error={mode !== "VIEW" && errors["funddetails-articleinitial"]}
            register={register({
              required: true,
              minLength: 1
            })}
            type={"text"}
            disabled={mode === "VIEW"}
            name="funddetails-articleinitial"
          />
          <div className={"funddetails-spacer"} />
          <DateInput
            label={"First subscription / Switching in"}
            placeholder={"Select Date"}
            setValue={setValue}
            control={control}
            error={mode !== "VIEW" && errors["funddetails-launchdate"]}
            rules={{ required: false }}
            disabled={mode === "VIEW"}
            name={"funddetails-launchdate"}
            defaultValues = {defaultValues["funddetails-launchdate"]}
          />
          <div className={"funddetails-spacer"} />
          <DateInput
            label={"Closed Date"}
            placeholder={"Select Date"}
            control={control}
            setValue={setValue}
            error={mode !== "VIEW" && errors["funddetails-closeddate"]}
            rules={{ required: false }}
            disabled={mode === "VIEW"}
            name={"funddetails-closeddate"}
            defaultValues = {defaultValues["funddetails-closeddate"]}
          />
          <div className={"funddetails-spacer"} />
          <TextInput
            label={"Fund Photo URL"}
            placeholder={"Enter Photo URL"}
            name={"funddetails-fundphoto"}
            type={"text"}
            getValues={getValues}
            setValue={setValue}
            error={mode !== "VIEW" && errors["funddetails-fundphoto"]}
            defaultValue={fundDetailsEN.fund_photo_url}
            register={register({
              required: true,
              minLength: 1,
              validate: value => validateURL(value)
            })}
            inputStyle="funddetails-preview-input"
            action={
              <PreviewButton
                name="funddetails-fundphoto"
                disabled={mode === "VIEW" || errors["funddetails-fundphoto"]}
              />
            }
            disabled={mode === "VIEW"}
          />
          <div className={"funddetails-spacer"} />
          <DateInput
            label={"Maturity Dates"}
            placeholder={"Select Date"}
            control={control}
            setValue={setValue}
            error={mode !== "VIEW" && errors["funddetails-maturitydates"]}
            rules={{ required: false }}
            name={"funddetails-maturitydates"}
            disabled={mode === "VIEW"}
            defaultValues = {defaultValues["funddetails-maturitydates"]}

          />
          <div className={"funddetails-spacer"} />
          <RadioInput
            label={"Risk Level"}
            name="funddetails-risklevel"
            options={riskOptions}
            control={control}
            rules={{ required: true }}
            disabled={mode === "VIEW"}
          />
          <div className={"funddetails-spacer"} />
          <TextInput
            label={"Fund ID"}
            placeholder={"Fund ID"}
            error={mode !== "VIEW" && errors["funddetails-fundid"]}
            register={register({
              required: true,
              minLength: 1
            })}
            type={"number"}
            readOnly={mode === "EDIT" || mode === "VIEW"}
            name="funddetails-fundid"
          />
          <div className={"funddetails-spacer"} />
          <DateInput
            label={"Inception Date"}
            placeholder={"Select Date"}
            control={control}
            setValue={setValue}
            error={mode !== "VIEW" && errors["funddetails-inceptiondate"]}
            rules={{ required: false }}
            name={"funddetails-inceptiondate"}
            disabled={mode === "VIEW"}
            defaultValues = {defaultValues["funddetails-inceptiondate"]}

          />
          <div className={"funddetails-spacer"} />
          <DateInput
            label={"IPO Start Date"}
            placeholder={"Select Date"}
            control={control}
            setValue={setValue}
            error={mode !== "VIEW" && errors["funddetails-ipostartdate"]}
            rules={{ required: false }}
            name={"funddetails-ipostartdate"}
            disabled={mode === "VIEW"}
            defaultValues = {defaultValues["funddetails-ipostartdate"]}

          />
          <div className={"funddetails-spacer"} />
          <DateInput
            label={"IPO End Date"}
            placeholder={"Select Date"}
            control={control}
            setValue={setValue}
            error={mode !== "VIEW" && errors["funddetails-ipoenddate"]}
            rules={{ required: false }}
            name={"funddetails-ipoenddate"}
            disabled={mode === "VIEW"}
            defaultValues = {defaultValues["funddetails-ipoenddate"]}

          />
          <div className={"funddetails-spacer"} />
          <CheckboxInput
            label={"Fund Configuration"}
            name="funddetails-configuration"
            options={configurationOptions}
            control={control}
            register={register({ required: false })}
            disabled={mode === "VIEW"}
          />
          <div className={"funddetails-spacer"} />
          <CheckboxInput
            label={"Fund Recommend"}
            name="funddetails-recommend"
            options={recommendOptions}
            control={control}
            disabled={mode === "VIEW"}
            register={register({ required: false })}
          />
    </div>
  );
};


function FundDetails(props) {
  const {
    register,
    control,
    getValues,
    setValue,
    show,
    fundDetailsEN,
    resetFundCodeExists,
    requestFundCodeExists,
    fundCodeExistsInDB,
    fundCodeExistsInPentacle,
    fundIDFromCheckingFundCodeExists,
    trigger,
    defaultValues,
    fundStatus,
    setFundStatus,
    formState,
    mode,
    errors
  } = props;

  useEffect(() => {
    // if we received a fund id and the conditions are valid, we proceed to prefill the field fund id
    if (
      fundCodeExistsInDB === false &&
      fundCodeExistsInPentacle &&
      fundIDFromCheckingFundCodeExists
    ) {
      // we got the right values, tell the Fund Code input to re-check validation
      trigger("funddetails-fundcode");
      // pre-fill fund id with the value received from backend
      setValue("funddetails-fundid", fundIDFromCheckingFundCodeExists, true);
    }
  }, [fundIDFromCheckingFundCodeExists]);

  return (
    <Fragment>
      <div className={show ? "funddetails-container" : "funddetails-container-hide"}>
        {/* Fund Status */}
        <FundStatus
          fundStatus={fundStatus}
          mode={mode}
          setValue={setValue}
          fundDetailsEN={fundDetailsEN}
          setFundStatus={setFundStatus}
        />
        {/* Fund Details Inputs */}
        <FundDetailsInputs
          register={register}
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          mode={mode}
          requestFundCodeExists={requestFundCodeExists}
          fundCodeExistsInDB={fundCodeExistsInDB}
          fundCodeExistsInPentacle={fundCodeExistsInPentacle}
          fundIDFromCheckingFundCodeExists={fundIDFromCheckingFundCodeExists}
          trigger={trigger}
          resetFundCodeExists={resetFundCodeExists}
          defaultValues={defaultValues}
          formState={formState}
          fundDetailsEN={fundDetailsEN}
        />
      </div>
      {/* Fund Details Extra Inputs */}
      <FundDetailsExtra
        errors={errors}
        register={register}
        show={show}
        getValues={getValues}
        mode={mode}
        control={control}
      />
    </Fragment>
  );
}
// end test

export default FundDetails;
