import { createAction } from "redux-act";

// RegisLogDetail
export const MEMRegisLogDetail = createAction("MEM_RESGIS_LOG_DETAIL");

// NDIDLogDetail
export const REQNDIDLogDetail = createAction("REQUEST_NDID_LOG_DETAIL");
export const RESNDIDLogDetail= createAction("RECEIVE_NDID_LOG_DETAIL");
export const FailNDIDLogDetail = createAction("FAILED_NDID_LOG_DETAIL");
export const CleanUpNDIDLogDetail= createAction("CLEANUP_NDID_LOG_DETAIL");
export const MEMNDIDLogDetail = createAction("MEM_NDID_LOG_DETAIL");