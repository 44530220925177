import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import languageProviderReducer from "../../containers/LanguageProvider/reducer";
import globalReducer from "../../globalRedux/reducer";
import authReducer from "../../containers/Authentication/redux/reducer";
import clientDetailReducer from "../../containers/ClientDetail/redux/reducer";
import fundsListReducer from "../../containers/FundList/redux/reducer";

import fundRecListReducer from "../../containers/FundRecommendList/redux/reducer";
import fundRecCodiListReducer from "../../containers/FundRecommendList/FundRecCodi_redux/reducer";
import fundRecRiskListReducer from "../../containers/FundRecommendList/FundRecRisk_redux/reducer";
import fundRecRiskDetailReducer from "../../containers/FundRecommendDetail/FundRecRisk_redux/reducer";
import fundRecCodiDetailReducer from "../../containers/FundRecommendDetail/FundRecCodi_redux/reducer";




import tagListReducer from "../../containers/TagList/redux/reducer";
import tagDetailReducer from "../../containers/TagDetail/redux/reducer";

import srchRecListReducer from "../../containers/SrchRecList/redux/reducer";
import srchRecDetailReducer from "../../containers/SrchRecDetail/redux/reducer";

import conFdSortListReducer from "../../containers/ContentFeedSortList/redux/reducer";

import conFdDetailReducer from "../../containers/ContentFeedDetail/redux/reducer";

import fundDetailsReducer from "../../containers/FundDetail/redux/reducer";
import transactionReducer from "../../containers/TransactionDetails/redux/reducer";
import atsReducer from "../../containers/RequestATSDetails/redux/reducer";
import rspReducer from "../../containers/RSPAccountDetails/redux/reducer";
import breadCrumbsReducer from "../../components/BreadCrumbs/redux/reducer";
import clientsListReducer from "../../containers/Clients/redux/reducer";
import indexLinkReducer from "../../components/SideBar/redux/reducer";
import forgotPasswordReducer from "../../containers/ForgotPassword/redux/reducer";
import resetPasswordReducer from "../../containers/LetsSetupYourAccount/redux/reducer";
import contentFeedListReducer from "../../containers/ContentFeedList/redux/reducer";

import regisDeviceListReducer from "../../containers/RegisDeviceList/redux/reducer";
import regisDeviceDetailReducer from "../../containers/RegisDeviceDetail/redux/reducer";

import actiListReducer from "../../containers/ActiList/redux/reducer";
import actiDetailReducer from "../../containers/ActiDetail/redux/reducer";

import cusServiceListReducer from "../../containers/CusServiceList/redux/reducer";
import cusServiceDetailReducer from "../../containers/CusServiceDetail/redux/reducer";

import usrActListReducer from "../../containers/UsrActList/redux/reducer";
import usrActDetailReducer from "../../containers/UsrActDetail/redux/reducer";

import impRGTtoB2CListReducer from "../../containers/ImpRGTtoB2CList/redux/reducer";


import untHldListReducer from "../../containers/UntHldList/redux/reducer";
import untHldDetailReducer from "../../containers/UntHldDetail/redux/reducer";

import regisLogListReducer from "../../containers/RegisLogList/redux/reducer";
import regisLogDetailReducer from "../../containers/RegisLogDetail/redux/reducer";


import consentListReducer from "../../containers/ConsentList/redux/reducer";
import consentVSListReducer from "../../containers/ConsentVSList/redux/reducer";
import consentVSDetailReducer from "../../containers/ConsentVSDetail/redux/reducer";

import analyticListReducer from "../../containers/AnalyticList/redux/reducer";
import analyticDetailReducer from "../../containers/AnalyticDetail/redux/reducer";

import loaderReducer from "./loader";

const reducers = combineReducers({
  auth: authReducer,
  atsReducer,
  rspReducer,
  breadCrumbsReducer,
  clientsListReducer,
  clientDetailReducer,
  contentFeedListReducer,
  forgotPassword: forgotPasswordReducer,
  fundsListReducer,

  fundRecListReducer,
  fundRecCodiListReducer,
  fundRecRiskListReducer,
  fundRecCodiDetailReducer,
  fundRecRiskDetailReducer,

  tagListReducer,
  tagDetailReducer,

  srchRecListReducer,
  srchRecDetailReducer,
  
  conFdSortListReducer,
  
  conFdDetailReducer,

  fundDetailsReducer,

  regisDeviceListReducer,
  regisDeviceDetailReducer,

  actiListReducer,
  actiDetailReducer,

  cusServiceListReducer,
  cusServiceDetailReducer,
  
  usrActListReducer,
  usrActDetailReducer,

  impRGTtoB2CListReducer,

  untHldListReducer,
  untHldDetailReducer,

  regisLogListReducer,
  regisLogDetailReducer,

  consentListReducer,
  consentVSListReducer,
  consentVSDetailReducer,

  analyticListReducer,
  analyticDetailReducer,
  
  form,
  globalReducer,
  indexLinkReducer,
  language: languageProviderReducer,
  loaderReducer,
  resetPasswordReducer,
  transactionReducer
});

export default reducers;
