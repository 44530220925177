import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useForm } from "react-hook-form";
import { CircularProgress } from "@material-ui/core";
import Images from "../../../utils/styles/images";
import { requestUploadDocuments, resetUploadDocuments } from "../redux/actions";
import "./style.scss";

function UploadDocument(props) {
  const {
    setShowUploadDocs,
    requestUploadDocuments,
    resetUploadDocuments,
    requestingDocumentUpload,
    uploadDocStatus
  } = props;

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    errors,
    watch
  } = useForm({
    mode: "onChange"
  });

  const { isValid } = formState;
  const { performanceDocument, benchmarkDocument, dividendDocument } = watch();

  const perforDocExists = performanceDocument && performanceDocument.length > 0;
  const benchDocExists = benchmarkDocument && benchmarkDocument.length > 0;
  const divDocExists = dividendDocument && dividendDocument.length > 0;
  // Can submit if form is valid and at least one doc has been selected
  const canSubmit =
    isValid && (perforDocExists || benchDocExists || divDocExists);

  const onSubmit = (data) => {
    requestUploadDocuments(data);
  };

  const docValidation = (event) => {
    let doc = event[0];

    // we only accept CSV filetypes
    // CSV type is detected as
    // application/vnd.ms-excel
    let fileFormat = false;
    let fileName = false;
    if (doc && doc.type) {
      // there is a document type to check against
      fileFormat =
        doc.type === "application/vnd.ms-excel" || doc.type === "text/csv";
    } else if (doc && doc.name) {
      fileName = doc.name.split(".").pop().toUpperCase() === "CSV";
    } else if (doc && doc.length === 0) {
      // no ducment, so nothing to validate
      return true;
    } else if (typeof doc === "undefined") {
      // no ducment, so nothing to validate
      return true;
    }

    if (fileFormat || fileName) {
      // everything is in order, image is valid
      return true;
    }

    // bad input, not valid
    return false;
  };

  const closeModal = (e) => {
    if (!requestingDocumentUpload) {
      // don't allow exit if documents are in process of being uploaded
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();

      setShowUploadDocs(false);
      resetUploadDocuments();
    }
  };

  const docHasBeenSelected = (inputName) => {
    if (getValues()[inputName] && getValues()[inputName].length > 0) {
      return true;
    }

    return false;
  };

  const renderCancelDoc = (inputName) => {
    return (
      <button
        onClick={() => setValue(inputName, "")}
        className="upload-doc-remove-doc"
      >
        <img src={Images.closeButton.close} alt="close" />
      </button>
    );
  };

  const renderDocStatus = (inputName) => {
    if (
      getValues()[inputName] &&
      getValues()[inputName].length > 0 &&
      errors[inputName]
    ) {
      return (
        <div className="upload-doc-error-icon">
          <img src={Images.uploadDocuments.error} alt="error" />
        </div>
      );
    } else if (getValues()[inputName] && getValues()[inputName].length > 0) {
      return (
        <div className="upload-doc-tick-icon">
          <img src={Images.uploadDocuments.tick} alt="tick" />
        </div>
      );
    } else {
      return (
        <div className="upload-doc-upload-icon">
          <img src={Images.uploadDocuments.upload} alt="upload" />
        </div>
      );
    }
  };

  return (
    <div className="upload-doc-container">
      <form className="upload-doc-modal" onSubmit={handleSubmit(onSubmit)}>
        {uploadDocStatus === "SUCCESS" ? (
          <div className={"upload-doc-success"}>
            <div className="upload-doc-header">
              <h1>Success!</h1>
              <button onClick={(e) => closeModal(e)}>
                <img src={Images.closeButton.close} alt="close" />
              </button>
            </div>
            <p>All documents were successfully uploaded</p>
            <img
              src={Images.success}
              alt="success"
              className={"upload-doc-success-img"}
            />
            <button
              className="upload-doc-ok"
              onClick={(e) => {
                resetUploadDocuments();
                closeModal(e);
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <Fragment>
            {" "}
            {requestingDocumentUpload ? (
              <div className="upload-doc-progress">
                <CircularProgress />
                <p className="upload-doc-progress-text">
                  {requestingDocumentUpload ? "Uploading documents..." : null}
                </p>
              </div>
            ) : null}
            <div className="upload-doc-header">
              <h1>Upload Document</h1>
              <button onClick={(e) => closeModal(e)}>
                <img src={Images.closeButton.close} alt="close" />
              </button>
            </div>
            <div className="upload-doc-input-container">
              <input
                ref={register({
                  required: false,
                  validate: (value) => docValidation(value)
                })}
                type="file"
                name="performanceDocument"
                id="performanceDocument"
                className="upload-doc-input-hidden"
              />
              <label
                htmlFor="performanceDocument"
                className="upload-doc-label-inner"
              >
                {docHasBeenSelected("performanceDocument") ? (
                  <p>{getValues()["performanceDocument"][0].name}</p>
                ) : (
                  <p>Select Performance CSV</p>
                )}
                {docHasBeenSelected("performanceDocument")
                  ? renderCancelDoc("performanceDocument")
                  : null}
                {renderDocStatus("performanceDocument")}
              </label>
            </div>
            <div className="upload-doc-input-container">
              <input
                ref={register({
                  required: false,
                  validate: (value) => docValidation(value)
                })}
                type="file"
                name="benchmarkDocument"
                id="benchmarkDocument"
                className="upload-doc-input-hidden"
              />
              <label
                htmlFor="benchmarkDocument"
                className="upload-doc-label-inner"
              >
                {docHasBeenSelected("benchmarkDocument") ? (
                  <p>{getValues()["benchmarkDocument"][0].name}</p>
                ) : (
                  <p>Select Benchmark CSV</p>
                )}
                {docHasBeenSelected("benchmarkDocument")
                  ? renderCancelDoc("benchmarkDocument")
                  : null}
                {renderDocStatus("benchmarkDocument")}
              </label>
            </div>
            <div className="upload-doc-input-container">
              <input
                ref={register({
                  required: false,
                  validate: (value) => docValidation(value)
                })}
                type="file"
                name="dividendDocument"
                id="dividendDocument"
                className="upload-doc-input-hidden"
              />
              <label
                htmlFor="dividendDocument"
                className="upload-doc-label-inner"
              >
                {docHasBeenSelected("dividendDocument") ? (
                  <p>{getValues()["dividendDocument"][0].name}</p>
                ) : (
                  <p>Select Dividend CSV</p>
                )}
                {docHasBeenSelected("dividendDocument")
                  ? renderCancelDoc("dividendDocument")
                  : null}
                {renderDocStatus("dividendDocument")}
              </label>
            </div>
            {uploadDocStatus === "FAILED" ? (
              <p className={"upload-doc-failed"}>
                Failed to upload documents, something went wrong.
              </p>
            ) : null}
            <div className="upload-doc-buttons">
              <button
                className="upload-doc-cancel"
                onClick={(e) => closeModal(e)}
              >
                CANCEL
              </button>
              <button
                className="upload-doc-submit"
                type="submit"
                disabled={!canSubmit}
              >
                SUBMIT
              </button>
            </div>
          </Fragment>
        )}
      </form>
    </div>
  );
}

const withConnect = connect(
  (state) => ({
    ...state.fundsListReducer
  }),
  {
    requestUploadDocuments,
    resetUploadDocuments
  }
);

export default compose(withConnect)(UploadDocument);
