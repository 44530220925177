import { createAction } from "redux-act";

export const requestTransactionsList = createAction(
  "REQUEST_TRANSACTIONS_LIST"
);
export const receiveTransactionsList = createAction(
  "RECEIVE_TRANSACTIONS_LIST"
);
export const failTransactionsList = createAction("FAILED_TRANSACTIONS_LIST");
export const cleanupTransactionsList = createAction(
  "CLEANUP_TRANSACTIONS_LIST"
);
