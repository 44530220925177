import { createAction } from "redux-act";

export const requestFundsList = createAction("REQUEST_FUNDS_LIST");
export const receiveFundsList = createAction("RECEIVE_FUNDS_LIST");
export const failFundsList = createAction("FAILED_FUNDS_LIST");
export const cleanupFundsList = createAction("CLEANUP_FUNDS_LIST");
export const requestUploadDocuments = createAction("REQUEST_UPLOAD_DOCUMENTS");
export const receiveUploadDocuments = createAction("RECEIVE_UPLOAD_DOCUMENTS");
export const failUploadDocuments = createAction("FAILED_UPLOAD_DOCUMENTS");
export const resetUploadDocuments = createAction("RESET_UPLOAD_DOCUMENTS");

// ----- GetSynce NAV -------
export const REQSyncNAV = createAction("REQUEST_FUNDS_SYNC_NAV");
export const RESSyncNAV = createAction("RECEIVE_FUNDS_SYNC_NAV");
export const FailSyncNAV = createAction("FAILED_FUNDS_SYNC_NAV");
