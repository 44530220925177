import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  // --------------EasyInest-----------------
  REQAnalyticList,
  RESAnalyticList,
  FailAnalyticList,
  // --------------UntHld-----------------
  REQAnalyticUntHldList,
  RESAnalyticUntHldList,
  FailAnalyticUntHldList
} from "./actions";
import moment from "moment";

function* fetchAnalyticListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { tStartD, tEndD, bAllD } = payload;
    const tStartD1 = moment(tStartD).format("YYYY-MM-DD");
    let tEndD1 = moment(tEndD).format("YYYY-MM-DD");
    tEndD1 = tEndD1 + " 23:59";
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/analyticUserAccount`,
      {
        method: "POST",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          // ---Search----
          startDate: tStartD1,
          endDate: tEndD1,
          allDate: bAllD
        })
      }
    );

    const response = yield request.json();
    
    if (response) {
      yield put(RESAnalyticList(response));
    } else {
      yield put(RESAnalyticList());
    }
  } catch (error) {
    
    yield put(FailAnalyticList());
  }
}

function* fetchAnalyticUntHldListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { tStartD, tEndD, bAllD } = payload;
    const tStartD1 = moment(tStartD).format("YYYY-MM-DD");
    let tEndD1 = moment(tEndD).format("YYYY-MM-DD");
    tEndD1 = tEndD1 + " 23:59";
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/analyticUnitHolder`,
       {
        method: "POST",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          // ---Search----
          startDate: tStartD1,
          endDate: tEndD1,
          allDate: bAllD
        })
      }
    );

    const response = yield request.json();
    
    if (response) {
      yield put(RESAnalyticUntHldList(response));
    } else {
            yield put(RESAnalyticUntHldList());
    }
  } catch (error) {
    
    yield put(FailAnalyticUntHldList());
  }
}

export function* analyticListSaga() {
  yield all([
    takeLatest(REQAnalyticList, fetchAnalyticListWorker),
    takeLatest(REQAnalyticUntHldList, fetchAnalyticUntHldListWorker)
  ]);
}
