import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as Button from "../../components/Buttons";

const DoubleButtonPanel = ({
  onHandleClickRedBorderButton,
  onHandleClickRedButton,
  contentRedBorderButton,
  contentRedButton,
  width_1,
  width_2,
  height,
  loadingRedButton
}) => {
  return (
    <Fragment>
      <Button.PrimaryBorder
        contentButton={contentRedBorderButton}
        onClick={onHandleClickRedBorderButton}
        width={width_1}
        height={height}
      />
      <Button.PrimaryEdit
        contentButton={contentRedButton}
        onClick={onHandleClickRedButton}
        width={width_2}
        height={height}
        loading={loadingRedButton}
      />
    </Fragment>
  );
};

const TripleButtonPanel = ({
  onHandleBasketRemoveButton,
  onHandleClickRedBorderButton,
  onHandleClickRedButton,
  contentRedBorderButton,
  contentBasketRemoveButton,
  contentRedButton,
  width_1,
  width_2,
  width_3,
  height
}) => {
  return (
    <Fragment>
      <Button.NonBorder
        contentButton={contentBasketRemoveButton}
        onClick={onHandleBasketRemoveButton}
        width={width_1}
        height={height}
      />
      <Button.PrimaryBorder
        contentButton={contentRedBorderButton}
        onClick={onHandleClickRedBorderButton}
        width={width_2}
        height={height}
      />
      <Button.PrimaryEdit
        contentButton={contentRedButton}
        onClick={onHandleClickRedButton}
        width={width_3}
        height={height}
      />
    </Fragment>
  );
};

DoubleButtonPanel.defaultProps = {
  onHandleClickRedBorderButton: () => {},
  onHandleClickRedButton: () => {},
  contentRedBorderButton: "Cancel",
  contentRedButton: "Submit",
  width_1: "auto",
  width_2: "auto",
  height: "auto"
};

TripleButtonPanel.defaultProps = {
  onHandleClickRedBorderButton: () => {},
  onHandleClickRedButton: () => {},
  onHandleBasketRemoveButton: () => {},
  contentRedBorderButton: "Cancel",
  contentBasketRemoveButton: "Delete",
  contentRedButton: "Submit",
  width_1: "auto",
  width_2: "auto",
  width_3: "auto",
  height: "auto"
};

DoubleButtonPanel.propTypes = {
  onHandleClickRedBorderButton: PropTypes.func,
  onHandleClickRedButton: PropTypes.func,
  contentRedBorderButton: PropTypes.string,
  contentRedButton: PropTypes.string,
  width_1: PropTypes.string,
  width_2: PropTypes.string,
  height: PropTypes.string
};

TripleButtonPanel.propTypes = {
  onHandleClickRedBorderButton: PropTypes.func,
  onHandleClickRedButton: PropTypes.func,
  onHandleBasketRemoveButton: PropTypes.func,
  contentRedBorderButton: PropTypes.string,
  contentBasketRemoveButton: PropTypes.any,
  contentRedButton: PropTypes.string,
  width_1: PropTypes.string,
  width_2: PropTypes.string,
  width_3: PropTypes.string,
  height: PropTypes.string
};

export const ButtonPanel = props => <DoubleButtonPanel {...props} />;
export const TripleButton = props => <TripleButtonPanel {...props} />;
