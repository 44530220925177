import { createReducer } from "redux-act";

import {
  requestTransactionsList,
  receiveTransactionsList,
  failTransactionsList,
  cleanupTransactionsList
} from "./actions";

const initialState = {
  requestingTransactionList: false,
  transactionList: [],
  errorMessage: false,
  totalTransactions: 0
};

const transactionReducer = createReducer(
  {
    [requestTransactionsList]: (state) => {
      return {
        ...state,
        requestingTransactionList: true
      };
    },
    [receiveTransactionsList]: (state, payload) => {
      return {
        ...state,
        requestingTransactionList: false,
        transactionList: payload.data,
        totalTransactions: payload.total
      };
    },
    [failTransactionsList]: (state) => {
      return {
        ...state,
        requestingTransactionList: false,
        transactionList: [],
        totalTransactions: 0
      };
    },
    [cleanupTransactionsList]: () => {
      return initialState;
    }
  },
  initialState
);

export default transactionReducer;
