import React, { Fragment,useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQNDIDLogDetail } from "./redux/actions";
import { withRouter  } from "react-router-dom";
import ReactJson from 'react-json-view';
import "./style.scss";
function NDIDLogDetail(poPrp) {
  const {
    REQNDIDLogDetail,
    RowDataAt,
    history,
    PageCurrent,
    REQStrNDIDLoglist,
    NDIDLoglist,
    UISearch
  } = poPrp;

  const GETxNDIDLogDetail = () => {
    REQNDIDLogDetail({
      identification_no:RowDataAt.identification_no
    });
  };  
 
  useEffect(() => {
    GETxNDIDLogDetail();

    return () => {      
       if (history.action === "POP") {
        history.replace({
          pathname:'/NDIDLog',
          PageCurrent: PageCurrent,
          UISearch:UISearch
        });
       }
    };

 }, []); 

 const SHWxTypeTable =()=>{
  return(
    <ReactJson 
    src={NDIDLoglist}
    theme="monokai"
    iconStyle="circle"
    style={{paddingTop:10,paddingBottom:10,paddingLeft:5
          ,fontFamily: "Lato"
          ,wordBreak:"break-all"
        }}
      />
   );
 };
 
  return (
    <Fragment>
    <div className="tag-feed-form-container">
    <div className="tag-feed-form-buttons-container">
      <button
            className="tag-detail-cancel"
            onClick={() => 
              history.push({
                  pathname:'/NDIDLog',
                  PageCurrent: PageCurrent,
                  UISearch:UISearch
              })
            }
          >
            CANCEL
      </button> 
    </div>   
  
      <div className="acti-feed-form-inputs-container">
      {REQStrNDIDLoglist ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrNDIDLoglist ? "Retrieving NDIDLog list..." : null}
            </p>
          </div>
        ) : null}

       {REQStrNDIDLoglist ? null : (SHWxTypeTable())}
      </div>
    </div>
  </Fragment>

  );
}

const withConnect = connect(

  state => ({
    ...state.regisLogDetailReducer
  }),
  {
    REQNDIDLogDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(NDIDLogDetail);
