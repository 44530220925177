import { createReducer } from "redux-act";

import {
  // --------------EasyInest-----------------
  REQAnalyticList,
  RESAnalyticList,
  FailAnalyticList,
  CleanUpAnalyticList,
  // --------------UntHld-----------------
  REQAnalyticUntHldList,
  RESAnalyticUntHldList,
  FailAnalyticUntHldList,
  CleanUpAnalyticUntHldList
} from "./actions";

const initialState = {
  REQStrAnalyticList: false,
  AnalyticList: [],

  REQStrAnalyticUntHldList: false,
  AnalyticUntHldList: []

};

const analyticListReducer = createReducer(
  {
  // --------------EasyInest-----------------
    [REQAnalyticList]: (state) => {
      return {
        ...state,
        REQStrAnalyticList: true
      };
    },
   
    [RESAnalyticList]: (state, payload) => {
      return {
        ...state,
        REQStrAnalyticList: false,
        AnalyticList: payload
      };
    },
     
    [FailAnalyticList]: (state) => {
      return {
        ...state,
        REQStrAnalyticList: false,
        AnalyticList: []
      };
    } , 
    [CleanUpAnalyticList]: () => {
      return initialState;
    },
   // --------------UntHld-----------------
   [REQAnalyticUntHldList]: (state) => {
    return {
      ...state,
      REQStrAnalyticUntHldList: true
    };
  },
 
  [RESAnalyticUntHldList]: (state, payload) => {
   
    return {
      ...state,
      REQStrAnalyticUntHldList: false,
      AnalyticUntHldList: payload
    };
  },
   
  [FailAnalyticUntHldList]: (state) => {
    return {
      ...state,
      REQStrAnalyticUntHldList: false,
      AnalyticUntHldList: []
    };
  } , 
  [CleanUpAnalyticUntHldList]: () => {
    return initialState;
  }
  },

  initialState
);

export default analyticListReducer;
