import React from "react";
import Images from "../../../utils/styles/images";
import "./style.scss";

function Pagination(props) {
  const { total, nPageIndex, gotoPage } = props;

  const totalNumberOfPages = total;
  const handleFirstClick = () => {
    gotoPage(0);
  };

  const handleLastClick = () => {
    let nTotal = total - 1;
    gotoPage(nTotal);
  };

  const handleNextPageClick = () => {
    let nPageNxt = nPageIndex + 1;
    gotoPage(nPageNxt);
  };

  const handlePreviousPageClick = () => {
    let nPagePrevious = nPageIndex - 1;
    gotoPage(nPagePrevious);
  };

  const handlePageClick = buttonPageIndex => {
    gotoPage(buttonPageIndex);
  };

  const renderPaginationButtons = () => {
    let arrayOfButtons = [];

    // limit the number of shown buttons to 4
    for (let index = 1; index < 5; index += 1) {
      let displayValueForPageIndex = nPageIndex + index;
      
      if (displayValueForPageIndex <= totalNumberOfPages) {
        
        arrayOfButtons.push(
          <button
            className={
              displayValueForPageIndex === nPageIndex + 1
                ? "pagination-button-selected"
                : "pagination-button"
            }
            onClick={() => handlePageClick(displayValueForPageIndex - 1)}
            key={"pagination-button" + displayValueForPageIndex}
          >
            {displayValueForPageIndex}
          </button>
        );
      }
    }

    return arrayOfButtons;
  };

  return (
    <div className="pagination-container">
      <button className="pagination-first" onClick={() => handleFirstClick()}>
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      <button
        className={
          nPageIndex - 1 < 0
            ? "UsrAct-table-pagination-previous-disabled"
            : "UsrAct-table-pagination-previous"
        }
        onClick={() => handlePreviousPageClick()}
        disabled={nPageIndex - 1 < 0}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      {renderPaginationButtons()}
      <button
        className={
          nPageIndex + 1 >= totalNumberOfPages
            ? "UsrAct-table-pagination-next-disabled"
            : "UsrAct-table-pagination-next"
        }
        onClick={() => handleNextPageClick()}
        disabled={nPageIndex + 1 >= totalNumberOfPages}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
      <button className="pagination-last" onClick={() => handleLastClick()}>
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
    </div>
  );
}

export default Pagination;
