import React from "react";
import { TextInput } from "../../../components/input_hook_form";
import "./style.scss";

function Performance(props) {
  const { register, show, mode, errors } = props;

  return (
    <div
      className={show ? "performance-container" : "performance-container-hide"}
    >
      <TextInput
        label={"Country Code"}
        placeholder={"Country Code"}
        error={mode !== "VIEW" && errors["performance-countrycode"]}
        register={register({
          required: true,
          minLength: 1
        })}
        type={"text"}
        name="performance-countrycode"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Fund Code"}
        placeholder={"Fund Code"}
        error={mode !== "VIEW" && errors["performance-fundcode"]}
        register={register({
          required: true,
          minLength: 1
        })}
        type={"text"}
        name="performance-fundcode"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Fund ID"}
        placeholder={"Fund ID"}
        error={mode !== "VIEW" && errors["performance-fundid"]}
        register={register({ required: true })}
        type={"number"}
        name="performance-fundid"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return YTD"}
        placeholder={"Return YTD"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-returnytd"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return 03months"}
        placeholder={"Return 03months"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-return03months"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return 06months"}
        placeholder={"Return 06months"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-return06months"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return 12months"}
        placeholder={"Return 12months"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-return12months"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return 03years"}
        placeholder={"Return 03years"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-return03years"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return 05years"}
        placeholder={"Return 05years"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-return05years"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return 10years"}
        placeholder={"Return 10years"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-return10years"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Return Since Inception"}
        placeholder={"Return Since Inception"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-returnsinceinception"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"NAV per unit"}
        placeholder={"NAV per unit"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0001}
        type={"number"}
        name="performance-navperunit"
        disabled={mode === "VIEW"}
      />
      <div className="performance-spacer" />
      <TextInput
        label={"Total NAV"}
        placeholder={"Total NAV"}
        register={register({
          required: false,
          min: -9999999999,
          max: 9999999999
        })}
        step={0.0000000001}
        type={"number"}
        name="performance-totalnav"
        disabled={mode === "VIEW"}
      />
    </div>
  );
}

export default Performance;
