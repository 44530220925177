export default {
  "app.components.SideMenu.LeftMenu.clients": "clients",
  "app.components.SideMenu.LeftMenu.tag": "tag",
  "app.components.SideMenu.LeftMenu.SearchRec ": "Search Recommend",

  "app.components.SideMenu.LeftMenu.FundRec": "Fund Recommend",
    "app.components.SideMenu.LeftMenu.FundRec_FundMan": "By Fund Manager",
    "app.components.SideMenu.LeftMenu.FundRec_Condi": "By Condition",
    "app.components.SideMenu.LeftMenu.FundRec_Risk": "SIMPLE ADVISORY",

  "app.components.SideMenu.LeftMenu.transaction": "transaction",
    "app.components.SideMenu.LeftMenu.transactionDetails": "Transaction details",
    "app.components.SideMenu.LeftMenu.requestATSDetails": "Transaction details",
    
  "app.components.SideMenu.LeftMenu.ConFd": "content feed",
    "app.components.SideMenu.LeftMenu.ConFd_NewsOld": "ข่าวสาร",
    "app.components.SideMenu.LeftMenu.ConFd_Invest": "มุมมองการลงทุน",
    "app.components.SideMenu.LeftMenu.ConFd_News": "ข่าวสารและรางวัล",
    "app.components.SideMenu.LeftMenu.ConFd_ConInvest": "สาระการลงทุน",
    "app.components.SideMenu.LeftMenu.ConFd_ManualInvest": "คู่มือนักลงทุน",
    "app.components.SideMenu.LeftMenu.ConFd_NotiInvest": "ประกาศกองทุนรวม",

  "app.components.SideMenu.LeftMenu.ContFS": "Content Feed Sort",

  "app.container.ClientDetail.Conservative": "อนุรักษ์นิยม (ระดับ 1)",
  "app.container.ClientDetail.ModerateConservative": "ยับยั้งปานกลาง (ระดับ 2)",
  "app.container.ClientDetail.Moderate": "ปานกลาง (ระดับ 3)",
  "app.container.ClientDetail.ModerateAggressive": "ปานกลางก้าวร้าว (ระดับ 4)",
  "app.container.ClientDetail.Aggressive5": "ก้าวร้าว (ระดับ 5)",
  "app.container.ClientDetail.Aggressive6": "ก้าวร้าว (ระดับ 6)"
};
