import React from "react";
import { Modal } from "react-bootstrap";
import * as Button from "../../components/Buttons";
import { ButtonPanel } from "../../components/DoubleButtonsPanel";

import "../Clients/style.scss";

export const ErrorModal = props => {
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={props.onHide} />
      </div>
      <div className="text-modal">
        <h4>{props.errorMessageText}</h4>
      </div>
    </Modal>
  );
};

export const SaveFundModal = props => {
  const onHandleClick = () => {
    props.onHide();
  };
  const actionText = props.fundAction === "edit" ? "saved" : "created";

  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHandleClick} />
      </div>
      <div className="text-modal">
        <h4 color="green">Fund is successfully {actionText}!!!</h4>
      </div>
    </Modal>
  );
};

export const WarningModal = ({
  onHide,
  status,
  onHandleCancel,
  onHandleGo,
  title,
  body,
  ...props
}) => {
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHide} />
      </div>
      <div className="text-modal m-p">
        <h4 className="pb">
          {title
            ? title
            : `Are you sure you want to unlock the access status for this client?`}
        </h4>
        {body ? <h5 className="text-modal-body-text">{body}</h5> : null}
      </div>
      <div className="button-modal-wrap">
        <ButtonPanel
          onHandleClickRedBorderButton={onHandleCancel}
          onHandleClickRedButton={() => onHandleGo(status)}
          contentRedBorderButton="NO"
          contentRedButton="YES"
          width_1="148px"
          width_2="148px"
          height="40px"
        />
      </div>
    </Modal>
  );
};
export const WarningLeaveEditModal = ({
  onHide,
  onHandleCancel,
  onHandleGo,
  ...props
}) => {
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHide} />
      </div>
      <div className="text-modal">
        <h4 className="pb">Do you want to save changes?</h4>
      </div>
      <div className="button-modal-wrap">
        <ButtonPanel
          onHandleClickRedBorderButton={onHandleGo}
          onHandleClickRedButton={onHandleCancel}
          contentRedBorderButton="NO"
          contentRedButton="YES"
          width_1="148px"
          width_2="148px"
          height="40px"
        />
      </div>
    </Modal>
  );
};
