import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { WarningModal, AlertModal } from "../../components/Modal";
import Select from "react-select";
import TableTab1 from "./tableTab1";
import TableTab2 from "./tableTab2";
import TableTab3 from "./tableTab3";
import { aColTab1, aColTab2, aColTab3 } from "./list_of_columns";
import {
  REQSta,
  REQChgSta,
  REQImgCus,
  REQDelUsrAct,
  cleanupDelUsrAct
} from "./redux/actions";
import { MEMActiDetail } from "../ActiDetail/redux/actions";
import { MEMRegisDeviceDetail } from "../RegisDeviceDetail/redux/actions";

import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import { setCurrentCustomerUntHldDetail } from "../UntHldDetail/redux/actions";
import Images from "../../utils/styles/images";
import { REQTab2_RegisDeviceList } from "./redux/actions";
import "./style.scss";
import { aConsentType } from "../../utils/MockAPI";
function UsrActDetail(poPrp) {
  const {
    RowDataAt,
    history,
    PageCurrent,
    UISearch,
    // ----Tab1 UnitHolder-----
    selectedObjectUuid,
    setCurrentCustomerUntHldDetail,
    // ----Tab2 RegisDevice----
    REQTab2_RegisDeviceList,
    REQTab2_StrRegisDeviceList,
    Tab2_RegisDeviceList,
    MEMRegisDeviceDetail,
    // ----Change Status----
    REQChgSta,
    REQStrChgSta,
    ChgStaList,
    // ----Get Image----
    REQImgCus,
    ImgCusList,
    REQStrImgCus,
    // ----Get Status----
    REQSta,
    StaList,
    REQStrSta,
    // ----Delete UserAccount----
    REQDelUsrAct,
    DelUsrActList,
    cleanupDelUsrAct,
    REQStrDelUsrAct
  } = poPrp;
  const tColumeTab1 = React.useMemo(() => aColTab1, []);
  const tColumeTab2 = React.useMemo(() => aColTab2, []);
  const tColumeTab3 = React.useMemo(() => aColTab3, []);

  if (!RowDataAt) {
    window.location.href = '/UsrAct';
    return 0;
  }


  const [oShwModelAlert, SETxShwModelAlert] = useState(false);
  const [oShwDelUserActAlr, SETxShwDelUserActAlr] = useState(false);
  const [oValSta, SETxValSta] = useState();

  const oRowDataAt= (RowDataAt===undefined? 
                history.location.RowDataAt
                :RowDataAt
  );

  const [tUnlock, SETtUnlock] = useState(false);
  const [oShwErrAlr, SETxShwErrAlr] = useState(false);
  const CHGxLock = ptSta => {
    SETxValSta({
      id: oRowDataAt.id,
      email: oRowDataAt.email,
      username: oRowDataAt.username,
      Sta: ptSta,
      history
    });
    SETxShwModelAlert(true);
  };

  const [tTab, SETtTab] = useState("Unitholder");
  const CHGxTab = ptSta => {
    SETtTab(ptSta);
    if (ptSta === "Unitholder") {
      console.log('CHGxTab > ptSta === "Unitholder"');
    } else if (ptSta === "Register Device") {
      
      REQTab2_RegisDeviceList({ customerUuid: oRowDataAt.id });
    }
  };

  const oConsentTypeOp = aConsentType;
  const [oSelConsentTypeViewOp, SETxSelConsentTypeViewOp] = useState(
    oRowDataAt.CUS_PDPA
  );
  const SETxOnChgConsentView = e => {
    switch (e.value) {
      case "0":
        SETxSelConsentTypeViewOp(
          RowDataAt.CUS_PDPA === null ? [] : RowDataAt.CUS_PDPA
        );
        break;
      default:
        SETxSelConsentTypeViewOp(
          RowDataAt.CUS_PDPA === null
            ? []
            : RowDataAt.CUS_PDPA.filter(x => x.consent_type === e.value)
        );
        break;
    }
  };


  
  useEffect(() => {
    REQSta({ email: oRowDataAt.email, username: oRowDataAt.username });
    REQImgCus({ customerUuid: oRowDataAt.id });

    // ---------browser back button fires---------
    return () => {
      cleanupDelUsrAct();
      if (history.action === "POP") {
        history.replace({
          pathname: "/UsrAct",
          PageCurrent: PageCurrent,
          UISearch: UISearch
        });
      }
    };
  }, []);

  const SETxActTableClick = (cellValue, rowData, columnName) => {
    if (columnName === "UNITHOLDER ID" && tTab === "Unitholder") {
      // tab1
      if (history.location.pathname === `/UsrActDetail/${oRowDataAt.id}`) {
        setCurrentCustomerUntHldDetail({
          uuid: oRowDataAt.id,
          unitholderid: rowData.unitholder_id,
          UsrAccountType: oRowDataAt.accounttype,
          NameTH: oRowDataAt.first_name_th,
          UserName: oRowDataAt.username,
          UISearch:UISearch,
          RowDataAt:oRowDataAt
        });
        // NameEn
        selectedObjectUuid(oRowDataAt.first_name);
        history.push(
          `/UnitHolderTab1Detail/${oRowDataAt.id}/UnitHolderID=${
            rowData.unitholder_id
          }`
        );
      }
    } else if (columnName === "DEVICE NAME" && tTab === "Register Device" && history.location.pathname === `/UsrActDetail/${oRowDataAt.id}`) {
      // tab2
        MEMRegisDeviceDetail({
          oRowDataAt: Tab2_RegisDeviceList.find(x => x.id === rowData.id),
          UserName: oRowDataAt.username
        });
        history.push(`/RegisTab2DeviceDetail/${rowData.id}`);
    }
  };
  const SHWxTab1 = () => {
    return (
      <div>
        <TableTab1
          data={
            oRowDataAt.CUS_UnitHolder === null ? [] : oRowDataAt.CUS_UnitHolder
          }
          AccountType={oRowDataAt.hasConverted}
          HightTable={600}
          columns={tColumeTab1}
          handleActionClick={SETxActTableClick}
        />
      </div>
    );
  };
  const SHWxTab2 = () => {
    return (
      <div>
        {REQTab2_StrRegisDeviceList ? (
          <div className="usrAct-tab-progress">
            <CircularProgress />
            <p className="usrAct-tab-progress-text">
              {REQTab2_StrRegisDeviceList
                ? "Retrieving Tab2 Regis device list..."
                : null}
            </p>
          </div>
        ) : null}
        <TableTab2
          data={Tab2_RegisDeviceList}
          HightTable={600}
          columns={tColumeTab2}
          handleActionClick={SETxActTableClick}
        />
      </div>
    );
  };
  const SHWxTab3 = () => {
    return (
      <div>
        <TableTab3
          data={oSelConsentTypeViewOp === null ? [] : oSelConsentTypeViewOp}
          HightTable={600}
          columns={tColumeTab3}
          handleActionClick={SETxActTableClick}
        />
      </div>
    );
  };
  const GOxViewLog = () => {
    
    if (history.location.pathname === `/UsrActDetail/${oRowDataAt.id}`) {
      history.push({
        pathname: "/UsrActDetail_ViewLog",
        search: "?srch=" + oRowDataAt.email
      });
    }
  };
  const SHWxSta = oSta => {
    if (oSta === null) {
      return "Okta user E-mail is null";
    } else {
      if (StaList === "ACTIVE") {
        return (
          <div className="clients-table-cell-account-status">
            <img alt="unlock" src={Images.accessStatus.unlocked} />
            <p className="applicants-table-cell-account-status-unlocked">
              Unlocked
            </p>
          </div>
        );
      } else if (StaList === "LOCKED_OUT") {
        return (
          <div className="clients-table-cell-account-status">
            <img alt="unlock" src={Images.accessStatus.locked} />
            <p className="applicants-table-cell-account-status-locked">
              Locked
            </p>
            <span style={{ marginLeft: 10 }}>
              <button
                onClick={() => CHGxLock("ACTIVE")}
                className="feed-list-article-button"
              >
                Unlock
              </button>
            </span>
          </div>
        );
      } else if (StaList === "User not found") {
        return "Okta user E-mail not found";
      }
    }
  };
  const SHWxStaUnlock = () => {
    
    return (
      <div className="clients-table-cell-account-status">
        <img alt="unlock" src={Images.accessStatus.unlocked} />
        <p className="applicants-table-cell-account-status-unlocked">
          Unlocked
        </p>
      </div>
    );
  };
  const SHWxRegDate = (oDate) => {
    if (oDate !== null) {
      const dateObject = new Date(oDate);
      dateObject.setHours(dateObject.getHours() - 1);
    
      const formattedDate = `${dateObject.toISOString().slice(0, 19).replace("T", " ")}`;
      return formattedDate;
    } else {
      return "-";
    }
  };
  const SHWxDocType = oDocType => {
    if (oDocType === null) {
      return "-";
    } else {
      return oDocType === "1" ? "ID Card (Thai)" : "Passport ID";
    }
  };
  const SHWxImg = oImg => {
    if (REQStrImgCus && REQStrSta) {
      return (
        <img
          
          width="110px"
          height="110px"
          src="/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"
        />
      );
    } else {
      if (oImg.length === 0) {
        return (
          <img
            
            width="110px"
            height="110px"
            src="/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"
          />
        );
      } else {
        console.log("oImg:",oImg);
        if (oImg?.detail === null) { 
          return (
            <img
              
              width="110px"
              height="110px"
              src="/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"
            />
          );
        } else if (oImg?.detail?.avatar === null) {
          return (
            <img
              
              width="110px"
              height="110px"
              src="/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"
            />
          );
        } else {
          return (
            <img
              
              width="110px"
              height="110px"
              src={oImg?.detail?.avatar?.url}
            />
          );
        }
      }
    }
  };
  const SHWxAlrDeleteAccount = () => {
    if (DelUsrActList.length === 0 && DelUsrActList.Code !== 200) {
        return (
          <AlertModal
            show={oShwErrAlr}
            onHide={() => SETxShwErrAlr(false)}
            onHandleOK={() => {
              SETxShwErrAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={
              "Fail code status " + DelUsrActList.Code + ".\nPlease try again."
            }
          />
        );
    }
  };
  const SHWxActType = () => {
    console.log("SHWxActType:",oRowDataAt);
    if (oRowDataAt.accounttype === "B2C") { //oRowDataAt.FTActType === "B2C")
      return <span>B2C</span>;
    } else if (oRowDataAt.accounttype === "EasyInvest") {
      return <span>Easyinvest</span>;
    } else if (oRowDataAt.accounttype === "Has customer_detail") {
      return <span>B2C</span>;
    } else if (oRowDataAt.accounttype === "EasyInvest has customer_detail") {
      return <span>EasyInvest</span>;
    } else if (oRowDataAt.accounttype === "Invalid") {
      return <span>-</span>;
    } else {
      return "";
    }
  };
  return (
    <Fragment>
      <div className="tag-feed-form-container">
        <div className="tag-feed-form-buttons-container">
          <button
            className="tag-detail-cancel"
            onClick={() =>
              history.push({
                pathname: "/UsrAct",
                PageCurrent: PageCurrent,
                UISearch: UISearch
              })
            }
          >
            CANCEL
          </button>
        </div>
        <div
          className="ndid-feed-form-inputs-container"
        >
          {REQStrImgCus ? (
            <div className="usrAct-list-progress">
              <CircularProgress />
              <p className="usrAct-list-progress-text">
                {REQStrImgCus ? "Retrieving UserImage list..." : null}
              </p>
            </div>
          ) : null}
          {REQStrSta ? (
            <div className="usrAct-list-progress">
              <CircularProgress />
              <p className="usrAct-list-progress-text">
                {REQStrSta ? "Retrieving Status list..." : null}
              </p>
            </div>
          ) : null}
          {/*---------------------Chage Status-------------------*/}
          {REQStrChgSta === true ? (
            <div className="usrAct-list-progress">
              <CircularProgress />
              <p className="usrAct-list-progress-text">
                {REQStrChgSta ? "Retrieving Chage Unlock..." : null}
              </p>
            </div>
          ) : null}
          {REQStrDelUsrAct === true ? (
            <div className="usrAct-list-progress">
              <CircularProgress />
              <p className="usrAct-list-progress-text">
                {REQStrDelUsrAct ? "Retrieving Delete account..." : null}
              </p>
            </div>
          ) : null}

          <WarningModal
            show={oShwModelAlert}
            onHide={() => SETxShwModelAlert(false)}
            onHandleGo={() => {
              SETxShwModelAlert(false);
              // ----------- ดูจาก spect ----
              REQChgSta(oValSta);
              if (ChgStaList === 200) {
                SETtUnlock(true);
              } else {
                
                SETtUnlock(true);
              }
            }}
            onHandleCancel={() => {
              SETxShwModelAlert(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={
              oValSta != null
                ? `Are you sure you want to ${
                    oValSta.Sta === "LOCKED_OUT" ? "LOCK" : "UNLOCK"
                  }  the access status for this client?`
                : "null"
            }
          />

          <WarningModal
            show={oShwDelUserActAlr}
            onHide={() => SETxShwDelUserActAlr(false)}
            onHandleGo={() => {
              SETxShwDelUserActAlr(false);
              REQDelUsrAct({
                UUID: oRowDataAt.id,
                history
              });
              SETxShwErrAlr(true);
            }}
            onHandleCancel={() => {
              SETxShwDelUserActAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={`Are you sure you want to delete the account?`}
          />
          {/*--------------------Show alert Delete when error-----------------------*/}
          {REQStrDelUsrAct ? null : SHWxAlrDeleteAccount()}
          <div className="client-detail-profile-account-container">
            <div
              style={{
                float: "left",
                fontFamily: "Lato",
                fontSize: 14,
                fontWeight: 700,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 3,
                letterSpacing: 0.02,
                color: "#000"
              }}
            >
              Account Details
            </div>
            <div style={{ float: "right" }}>
              <button
                className="usrAct-detail-delete"
                onClick={() => SETxShwDelUserActAlr(true)}
              >
                Delete Account
              </button>
            </div>
          </div>

          <div className="client-detail-profile-account-photo-container">
            <p style={{ width: 0 }}>Photo</p>

            <div>
              {SHWxImg(ImgCusList)}
              <p>NDID/Counter Service</p>
            </div>
          </div>

          <div style={{ marginLeft: 20 }}>
            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                Account Type
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{SHWxActType()}</span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                Register Date
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{SHWxRegDate(oRowDataAt.created_at)} </span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                Username
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{oRowDataAt.username}</span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                E-mail
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{oRowDataAt.email}</span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                Lock Status
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>
                {tUnlock === false ? SHWxSta(StaList) : SHWxStaUnlock("ACTIVE")}
              </span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                Mobile No.
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{oRowDataAt.mobile}</span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                ID No. / Passport No.
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>
                {oRowDataAt.identification_no !== null
                  ? oRowDataAt.identification_no
                  : "-"}
              </span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                ID Type
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>{SHWxDocType(oRowDataAt.identification_type_code)}</span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                First Name in Thai
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>
                {oRowDataAt.first_name_th !== null
                  ? oRowDataAt.first_name_th
                  : "-"}
              </span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                Last Name in Thai
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>
                {oRowDataAt.last_name_th !== null ? oRowDataAt.last_name_th : "-"}
              </span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                First Name in English
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>
                {oRowDataAt.first_name !== null ? oRowDataAt.first_name : "-"}
              </span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span className="labelInputText" style={{ width: 150 }}>
                Last Name in English
              </span>
              <span style={{ paddingRight: 10 }}>:</span>
              <span>
                {oRowDataAt.last_name !== null ? oRowDataAt.last_name : "-"}
              </span>
            </div>

            <div className="usrAct-feed-form-input-spacer" />
            <div className="labelInput">
              <span style={{ width: 200 }} className="labelInputText ">
                <button
                  className="usrAct-viewlog-button"
                  onClick={() => GOxViewLog()}
                >
                  View Activity Logs
                </button>
              </span>
            </div>
          </div>

          {/*--------------------------------------------Tab-------------------------------------------*/}

          <div className="tabs-container">
            <div className="tabs-bar" style={{ width: 380, marginLeft: 20 }}>
              <button
                className={tTab === "Unitholder" ? "tabs active-tabs" : "tabs"}
                onClick={() => CHGxTab("Unitholder")}
              >
                Unitholder
              </button>
              <button
                className={
                  tTab === "Register Device" ? "tabs active-tabs" : "tabs"
                }
                onClick={() => CHGxTab("Register Device")}
              >
                Register Device
              </button>
              <button
                className={tTab === "Consent" ? "tabs active-tabs" : "tabs"}
                onClick={() => CHGxTab("Consent")}
              >
                Consent
              </button>
            </div>
            <div className="content-tabs">
              <div
                className={
                  tTab === "Unitholder" ? "content  active-content" : "content"
                }
              >
                <SHWxTab1 />
              </div>

              <div
                className={
                  tTab === "Register Device"
                    ? "content  active-content"
                    : "content"
                }
              >
                <SHWxTab2 />
              </div>
              <div
                className={
                  tTab === "Consent" ? "content  active-content" : "content"
                }
              >
                <div
                  className="labelInput"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <span
                    style={{
                      paddingRight: 110,
                      fontFamily: "Lato",
                      color: "#000000"
                    }}
                  >
                    Consent Type
                  </span>

                  <div style={{ width: 420 }}>
                    <Select
                      name="ocbConsentTypeOp"
                      options={oConsentTypeOp}
                      defaultValue={{ value: "0", label: "All" }}
                      maxMenuHeight={200}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Consent Type"
                      onChange={SETxOnChgConsentView}
                    />
                  </div>
                </div>

                <SHWxTab3 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.usrActDetailReducer
  }),
  {
    REQTab2_RegisDeviceList,
    MEMActiDetail,
    MEMRegisDeviceDetail,
    REQChgSta,
    REQImgCus,
    REQSta,
    REQDelUsrAct,
    cleanupDelUsrAct,
    selectedObjectUuid,
    setCurrentCustomerUntHldDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(UsrActDetail);
