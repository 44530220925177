import React, {useEffect, Fragment} from "react";
import { useForm } from "react-hook-form";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { HeaderLabel, TextInput } from "../../components/input_hook_form";
import { ErrorModal } from "../../components/Modal";
import * as Button from "../../components/Buttons";
import {
  requestResetPasswordAction,
  clearErrorMessageResetPassword,
  resetStateAction
} from "./redux/actions";
import "./style.scss";

/*
  Renders the LETS SETUP YOUR ACCOUNT page where user can enter their new password and login
  https://zpl.io/aBGAyXe
*/

function LetsSetupYourAccount(props) {
  const {
    location: { search },
    requestResetPasswordAction,
    clearErrorMessageResetPassword,
    requestingResetPassword,
    success,
    resetStateAction,
    errorMessage
  } = props;
  const { register, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      newpassword: "",
      confirmnewpassword: ""
    }
  });

  useEffect(() => {
    // on component did mount
    // we do this here so that reducer can always be refreshed to default when visiting this page
    resetStateAction();
  }, []);

  const onSubmit = data => {
    requestResetPasswordAction({
      newPassword: data.confirmnewpassword,
      resetToken: search.split("=")[1],
      userName: search.split("=")[2]
    });
  };

  const renderError = () => {
    if (
      errors.newpassword ||
      (errors.confirmnewpassword &&
        !errors.confirmnewpassword.type === "validate") || (errors.confirmnewpassword && errors.confirmnewpassword.type === "pattern")
    ) {
      return <p className="error-message">Invalid Password</p>;
    } else if (errors.confirmnewpassword) {
      return (
        <p className="error-message">
          New and Confirm password is not the same
        </p>
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <ErrorModal
        show={errorMessage && errorMessage.length > 0}
        onHide={() => clearErrorMessageResetPassword()}
        errorMessageText={errorMessage ? errorMessage : ""}
      />
<div className="wrapper">
      <div className="imageBackgroundWrap" />
      <div className="formWrap">
        <div className="formContainer">
          {success ? (
            <div className="success-container">
              <HeaderLabel text="SUCCESS!" />
              <span className="password-reset-success">
                You have successfully updated your password. Please head back to the login screen and login with
                your new password.
              </span>
              <Link to="/login" className="Back-to-login">
                Back to login
              </Link>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="formForgotPassword">
                <HeaderLabel text="LET'S SETUP YOUR ACCOUNT!" />
                <div className="header-spacer" />
                <TextInput
                  label=""
                  placeholder="New Password"
                  name="newpassword"
                  register={register({
                    required: true,
                    minLength: 8,
                    maxLength: 12,
                    pattern: /^\S*(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,12}$/
                  })}
                  inputStyle={
                    errors.newpassword || errors.confirmnewpassword ? "input-error" : "reset-password-input"
                  }
                  type="password"
                  disabled={requestingResetPassword}
                />
                <div className="input-spacer" />
                <TextInput
                  label=""
                  placeholder="Confirm New Password"
                  name="confirmnewpassword"
                  register={register({
                    required: true,
                    minLength: 8,
                    maxLength: 12,
                    pattern: /^\S*$/,
                    validate: value => value === getValues().newpassword
                  })}
                  inputStyle={
                    errors.confirmnewpassword || errors.newpassword
                      ? "input-error"
                      : "reset-password-input"
                  }
                  type="password"
                  disabled={requestingResetPassword}
                />
                {renderError()}

                <span className="password-instruction-text">
                • Password must be a combination of letters and numbers and 8-12 characters long.<br />
                • Special characters are allowed<br />
                • Spaces are not allowed
                </span>
                <Button.Login
                  contentButton={
                    <div className="login-button-text">
                      {requestingResetPassword ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Reset Password"
                      )}
                    </div>
                  }
                  buttonType="primary"
                  width="145px"
                  height="46px"
                  className="login-button"
                  type="submit"
                />
              </div>
            </form>
          )}
        </div>
        <div className="copyright">&#169; 2024 All Rights Reserved.</div>
      </div>
    </div>
    </Fragment>
    
  );
}

const withConnect = connect(
  state => ({
    ...state.resetPasswordReducer
  }),
  {
    requestResetPasswordAction,
    clearErrorMessageResetPassword,
    resetStateAction
  }
);

export default compose(
  withConnect,
  withRouter
)(LetsSetupYourAccount);
