import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { indexLink } from "./redux/actions";
import Images from "../../utils/styles/images";
import { MenuList } from "../../components/SideBar/LeftMenu";
import { menuListArray } from "./routeList";
import "./style.scss";
import { aConfigUrl } from "../../utils/ConfigUrl";
class SideBar extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    menuListArray: PropTypes.array.isRequired,
    indexLinkId: PropTypes.object.isRequired,
    indexLink: PropTypes.func.isRequired
  };

  static defaultProps = {
    history: {},
    menuListArray: [],
    indexLinkId: {},
    indexLink: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      currentRouter: "/"
    };
  }

  // start test pass
  updateIndex = index => {
    if (this.state.activeIndex === index) {
      return index;
    }
    if (this.state.activeIndex > 5 || this.state.activeIndex < 9) {
      const indexMap = {
        11: 115,
        3: 31,
        6: 62,
        12: 121,
        15: 151,
        16: 161,
        18: 181
      };
      return indexMap[index] || index;
    }
    return index;
  };

  onHandleClick = (index, router) => e => {
    e.stopPropagation();
    e.preventDefault();
    this.props.history.push(router);
    
    const updatedIndex = this.updateIndex(index);
  
    this.props.indexLink(updatedIndex, router);
    
    this.setState(prevState => ({
      ...prevState,
      activeIndex: updatedIndex,
      currentRouter: router
    }));
  };
  // end test
  
  componentDidMount() {
    const { indexLinkId, history } = this.props;
    history.push(indexLinkId.router);
  }

  render() {
    const { indexLinkId, history } = this.props;
    return (
      <div className="sideBarMenuWrap">
        <div className="logoWrap" onClick={() => history.push("/")}>
          <img src={Images.logo.sx} alt="Logo" />
        </div>

        <div className="sideBar">
          <ul>
            <MenuList
              onHandleClick={this.onHandleClick}
              menuListArray={menuListArray}
              activeIndex={indexLinkId.indexLink}
              Images={Images}
              history={history}
            />
          </ul>
        </div>

        <span className="sideBar-copyright-text">
          © 2024 All Rights Reserved. {aConfigUrl().TextPhase}{" "}
          {aConfigUrl().Version}
        </span>
      </div>
    );
  }
}

const withConnect = connect(
  state => ({
    indexLinkId: state.indexLinkReducer,
    currentRouter: state.router
  }),
  { indexLink }
);
export default compose(
  withRouter,
  withConnect
)(SideBar);
