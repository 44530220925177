import { createAction } from "redux-act";
// ------EasyInvest---
export const REQEasyInv= createAction("REQUEST_EASYINV_DETAIL");
export const RESEasyInv = createAction("RECEIVE_EASYINV_DETAIL");
export const FailEasyInv = createAction("FAILED_EASYINV_DETAIL");
export const CleanUpEasyInv= createAction("CLEANUP_EASYINV_DETAIL");
// ------UntHld---
export const REQUntHld= createAction("REQUEST_UNTHLD_DETAIL");
export const RESUntHld = createAction("RECEIVE_UNTHLD_DETAIL");
export const FailUntHld = createAction("FAILED_UNTHLD_DETAIL");
export const CleanUpUntHld= createAction("CLEANUP_UNTHLD_DETAIL");