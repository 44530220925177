import { createReducer } from "redux-act";

import {
  MEMRegisLogDetail,
  // ----------------NDIDLogdetail----------------
  REQNDIDLogDetail,
  RESNDIDLogDetail,
  FailNDIDLogDetail,
  CleanUpNDIDLogDetail,
  MEMNDIDLogDetail
} from "./actions";

const initialState = {
  // --------------NDIDLogdetail-----------------
  REQStrNDIDLoglist: false,
  NDIDLoglist: [],
  errorMessage: false,
  totalNDIDLoglist: 0
};

const regisLogDetailReducer = createReducer(
  {
    // -------Edit to RegisLogtail----------------
    [MEMRegisLogDetail]: (state, payload) => {
   
      return {
        ...state,
        RowDataAt:payload.RowDataAt,
        PageCurrent:payload.PageCurrent,
        RegisLogType:payload.RegisLogType,
        UISearch:payload.UISearch
      };
    },
   // -------Edit to NDIDLogdetail----------------
   
  // --------------Cusdetail Log-----------------
  [REQNDIDLogDetail]: (state) => {
  return {
    ...state,
    REQStrNDIDLoglist: true
  };
  },
  [RESNDIDLogDetail]: (state, payload) => {
    return {
      ...state,
      REQStrNDIDLoglist: false,
      NDIDLoglist: payload.NDIDHistory,
      totalNDIDLoglist: payload.TotalNDIDHistory

    };
  },
  [FailNDIDLogDetail]: (state) => {
    return {
      ...state,
      REQStrNDIDLoglist: false,
      NDIDLoglist: [],
      totalNDIDLoglist: 0
    };
  } , 
  [CleanUpNDIDLogDetail]: () => {
    return initialState;
  },


   [MEMNDIDLogDetail]: (state, payload) => {
    
       return {
         ...state,
         RowDataAt:payload.RowDataAt,
         PageCurrent:payload.PageCurrent,
         UISearch:payload.UISearch
       };
     }
  },
  initialState
);

export default regisLogDetailReducer;
