import { createReducer } from "redux-act";

import {
  requestForgotPasswordAction,
  receiveForgotPasswordAction,
  failForgotPasswordAction,
  clearErrorMessageAction,
  resetStateAction
} from "./actions";

const initialState = {
  requestingForgotPassword: false,
  errorMessage: null,
  success: false
};

const forgotPasswordReducer = createReducer(
  {
    [requestForgotPasswordAction]: (state) => {
      return {
        ...state,
        requestingForgotPassword: true,
        success: false
      };
    },
    [receiveForgotPasswordAction]: (state) => {
      return {
        ...state,
        requestingForgotPassword: false,
        success: true
      };
    },
    [failForgotPasswordAction]: (state, payload) => {
      return {
        ...state,
        requestingForgotPassword: false,
        errorMessage: payload,
        success: false
      };
    },
    [clearErrorMessageAction]: (state) => {
      return {
        ...state,
        errorMessage: null
      };
    },
    [resetStateAction]: (state) => {
      return {
        ...state,
        requestingForgotPassword: false,
        success: false,
        errorMessage: null
      };
    }
  },
  initialState
);

export default forgotPasswordReducer;
