import React, {forwardRef} from "react";
import Images from "../../../utils/styles/images";
import "./style.scss";

// We need forwardRef otherwise it complains you can't use ref on SFC components, only classes

function DateInput(props, ref) {
  const { value, onClick } = props;

  return (
    <div className="date-range-input" onClick={onClick} ref={ref}>
      <p>{value ? value : "DD/MM/YY"}</p>
      <img src={Images.transactions.calendar} alt="calendar" />
    </div>
  );
}

export default forwardRef(DateInput);
