import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
function RegisDeviceDetail(poPrp) {
  const { RowDataAt, history } = poPrp;
  return (
    <Fragment>
      <div className="tag-feed-form-container">
        <div className="tag-feed-form-buttons-container">
          <button
            className="tag-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
        </div>

        <div
          className="regisDevice-feed-form-inputs-container"
          style={{ height: 900 }}
        >
          <div className="regisDevice-feed-form-input-spacer" />

          <div className="labelInput">
            <span className="labelInputText ">Customer UUID</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.customer_uuid.toString()}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Firebase User ID</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.firebaseFID}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">App Version</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.appVersion}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">App Platform</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.appPlatform}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Device Name</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.deviceName}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Registration Status</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.registerStatus}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Registered Date</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.registeredAt}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Deregistered Date</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.deregisteredAt}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">IP Address</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.registerIpAddress}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Verified Status</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.verifyStatus}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Verified Type</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.verifyType}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Verified By</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.verifyBy}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Internet Service Provider</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.ISP}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Debice Version</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.deviceVersion}
              disabled
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.regisDeviceDetailReducer
  }),
  {}
);

export default compose(
  withConnect,
  withRouter
)(RegisDeviceDetail);
