import React from "react";
import Images from "../../utils/styles/images";

// start test
function formatActionCell(cellValue, rowNumber, pageSelect, rowData, handleActionClick) {
  if (rowData) {
    return (
      <div className="funds-action-buttons">
        <button
          className="funds-action-view"
          onClick={() =>
            handleActionClick({ mode: "view", rowData: rowData })
          }
        >
          <img src={Images.fundListIcon.view} alt="eye" />
          View
        </button>
        <button
          className="funds-action-edit"
          onClick={() =>
            handleActionClick({ mode: "edit", rowData: rowData })
          }
        >
          <img src={Images.fundListIcon.edit} alt="pencil" />
          Edit
        </button>
      </div>
    );
  } else {
    return cell;
  }
}

function formatNumberCell(cellValue, rowNumber, pageSelect, rowData, handleActionClick) {
  if(pageSelect!==0) {
     return rowNumber+(pageSelect*10);
  } else {
      return rowNumber;
  }
}

function formatUpdateDateCell(cellValue, rowNumber, pageSelect, rowData, handleActionClick) {
  if (cellValue) {
    const formattedDate = cellValue.split(" ").slice(0,2).join(" ");
    return formattedDate; 
  } else {
    return "-";
  }
}

// Similar formatCreateDateCell function...

function formatUnitholderIDCell(cellValue, rowNumber, pageSelect, rowData, handleActionClick) {
  if (rowData.response_data) {
    return (
      <div>
        <div>Rows : {rowData.response_data.File.RowAt}</div>
        <div>UnitholderID : {rowData.response_data.File.UnitholderIDErrorAt}</div>
      </div>
    );
  } else {
    return "-";
  }
}

function formatCreateDateCell(cellValue, rowNumber, pageSelect, rowData, handleActionClick) {
  if (cellValue) {
    const formattedDate = cellValue.split("T").slice(0,2).join(" ");  // Adjust formatting as needed
    return formattedDate;
  } else {
    return "-";
  }
}


function formatIDCell(cellValue, rowNumber, pageSelect, rowData, handleActionClick) {
  return cellValue ? cellValue : cell;
}


export function modifyCell(cell, pageSelect, pageSize, handleActionClick) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1;
  const rowData = props?.row?.original || null;

  const formatters = {
    "ACTION": formatActionCell,
    "NO.": formatNumberCell,
    "UPDATEAT DATE": formatUpdateDateCell,
    "CREATE DATE": formatCreateDateCell,
    "UNITHOLDER ID": formatUnitholderIDCell,
    "ID": formatIDCell
  };

  // Early return for known columns
  if (formatters[columnName]) { return formatters[columnName](cellValue, rowNumber, pageSelect, rowData, handleActionClick); }

  // Default: return the cell as-is
  return cell; 
}
// end test

// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleFltHeader
) {
 
 
  if(cell === "ACCOUNT STAGE") 
  {
     return handleFltHeader ? handleFltHeader(cell) : cell;
   } 
  else {
    return cell;
  }
}
