export function config() {
  try {
    const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
    const config = {
      headers: {
        "content-language": "en",
        "userId": oNeedAuth ? oNeedAuth.b2cUserId : '',
        "id_token": oNeedAuth ? "Bearer " + oNeedAuth.b2cAccessToken : '' 
      },
      headersTH: {
        "content-language": "th",
        "userId": oNeedAuth ? oNeedAuth.b2cUserId : '',
        "id_token": oNeedAuth ? "Bearer " + oNeedAuth.b2cAccessToken : '' 
      }
    };
    return config;
  } catch (error) {
    console.error("catch error",error);
    return null;
  }

}