export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no"
  },
  {
    Header: "NAME",
    accessor: "fullName"
  },
  {
    Header: "EMAIL ADDRESS",
    accessor: "email"
  },
  {
    Header: "ACCESS STATUS",
    accessor: "lockout"
  },
  {
    Header: "ACTION",
    accessor: "action"
  }
];
