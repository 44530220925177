import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { REQUsrActList, RESUsrActList, FailUsrActList } from "./actions";

// start test

function buildSearchParams(tOpSrch, tSrch) {
  const searchParams = {
    emailSrch: "",
    usernameSrch: "",
    uuidSrch: "",
    nameENSrch: "",
    nameTHSrch: "",
    idnumberSrch: "",
    mobileSrch: ""
  };

  switch (tOpSrch) {
    case "Email":
        searchParams.emailSrch = tSrch;
        break;
      case "Username":
        searchParams.usernameSrch = tSrch;
        break;
      case "UUID":
        searchParams.uuidSrch = tSrch;
        break;
      case "English Name":
        searchParams.nameENSrch = tSrch;
        break;
      case "Thai Name":
        searchParams.nameTHSrch = tSrch;
        break;
      case "ID No. / Passport No.":
        searchParams.idnumberSrch = tSrch;
        break;
      case "Mobile":
        searchParams.mobileSrch = tSrch;
        break;
      default:
        break;
  }

  return searchParams;
}

function buildStageFilter(tFltActStage) {
  const bChk = tFltActStage.includes("Verified by");
  let tActStg = "";
  let tActStgVerify = "";

  if (bChk) {
    const tFlt = tFltActStage.filter(f => f !== "Verified by");
    tActStg = tFlt.map(m => `'${m}'`).join(",");
    tActStgVerify = "Verified by";
  } else {
    tActStg = tFltActStage.map(m => `'${m}'`).join(",");
  }

  // Ensure at least one element in the tActStg array
  tActStg = tActStg === "" ? "''" : `${tActStg},''`;

  return { tActStg, tActStgVerify };
}

function* fetchUsrActListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page, tSrch, tOpSrch, tSortH, tFltActStage } = payload;
  
    const searchParams = buildSearchParams(tOpSrch, tSrch);
    const { tActStg, tActStgVerify } = buildStageFilter(tFltActStage);

    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/userAccount?page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          ...searchParams,
          sortH: tSortH,
          FltAccountStage: tActStg,
          FltAccountStageByVerify: tActStgVerify,
          FltAccountType:
            "'B2C','EasyInvest','Has customer_detail','EasyInvest has customer_detail','Invalid'"
        })
      }
    );

    const response = yield request.json();
    if (response) {
      yield put(RESUsrActList(response));
    } else {
      yield put(RESUsrActList());
    }
  } catch (error) {
    console.error("fetchUsrActListWorker error:",error);
    yield put(FailUsrActList());
  }
}
// end test

export function* usrActListSaga() {
  yield all([takeLatest(REQUsrActList, fetchUsrActListWorker)]);
}
