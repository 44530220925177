import { createAction } from "redux-act";

export const requestForgotPasswordAction = createAction(
  "REQUEST_FORGOT_PASSWORD"
);
export const receiveForgotPasswordAction = createAction(
  "RECEIVE_FORGOT_PASSWORD"
);
export const failForgotPasswordAction = createAction("FAIL_FORGOT_PASSWORD");

export const clearErrorMessageAction = createAction(
  "CLEAR_ERROR_MESSAGE_ACTION"
);

export const resetStateAction = createAction("CLEAR_FORGOT_PASSWORD_STATE_ACTION");