import { createReducer } from "redux-act";

import {
// --------------------Import view--------------
  REQImpRGT2B2CList,
  RESImpRGT2B2CList,
  FailImpRGT2B2CList,
  CleanUpImpRGT2B2CList,
  // --------------UPD----------------
  REQImpRGT2B2CUPD,
  RESImpRGT2B2CUPD,
  FailImpRGT2B2CUPD,
  CleanUpImpRGT2B2CUPD
} from "./actions";

const initialState = {
  REQStrImpRGT2B2CList: false,
  ImpRGT2B2CList: [],
  totalImpRGT2B2CList: 0,

  REQStrImpRGT2B2CUPD: false,
  ImpRGT2B2CUPD: [],
  totalImpRGT2B2CUPD: 0
};

const impRGTtoB2CListReducer = createReducer(
  {
    // -------------Import view-----------
    [REQImpRGT2B2CList]: (state) => {
       return {
         ...state,
         REQStrImpRGT2B2CList: true
       };
     },
    
     [RESImpRGT2B2CList]: (state, payload) => {
       return {
         ...state,
         REQStrImpRGT2B2CList: false,
         ImpRGT2B2CList: payload.ImpData,
         totalImpRGT2B2CList: payload.TotalImpRGT2B2C
       };
     },
      
     [FailImpRGT2B2CList]: (state) => {
       return {
         ...state,
         REQStrImpRGT2B2CList: false,
         ImpRGT2B2CList: [],
         totalImpRGT2B2CList: 0
       };
     } , 
     [CleanUpImpRGT2B2CList]: () => {
       return initialState;
     },
    // --------------UPD----------------
    [REQImpRGT2B2CUPD]: (state) => {
     
      return {
        ...state,
        REQStrImpRGT2B2CUPD: true
      };
    },
   
    [RESImpRGT2B2CUPD]: (state, payload) => {
      return {
        ...state,
        REQStrImpRGT2B2CUPD: false,
        ImpRGT2B2CUPD: payload
      };
    },
     
    [FailImpRGT2B2CUPD]: (state) => {
      return {
        ...state,
        REQStrImpRGT2B2CUPD: false,
        ImpRGT2B2CUPD: []
      };
    } , 
    [CleanUpImpRGT2B2CUPD]: () => {
      return initialState;
    }
 
  },

  initialState
);

export default impRGTtoB2CListReducer;
