import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import reducers from "./reducers";
import rootSaga from "./sagas";
import axios from "axios";

//import storage from "redux-persist/lib/storage";

//new
import compress from "redux-persist-transform-compress";
import createIdbStorage from "redux-persist-indexeddb-storage";
const storage = createIdbStorage({ name: "AdminDashboard", storeName: "persistStore" });

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["form", "loaderReducer"],
  // whitelist is auth
  transforms: [compress()]
};

const globalReducer = (state, action) => {

  if (action.type === "USER_SIGNED_OUT") {
    state = undefined;
  } else if ((action.type === "STOP_LOADING" || action.type === "persist/REHYDRATE") && state.auth.accessToken){
    // This is likely where rehydration has completed.
    // We try to set the accessToken here, in case the persistStore callback is too late.

    // On rehydrating persistence (or basically refresh the page), we set the axios headers with the token
    axios.defaults.headers.common["Authorization"] = `Bearer ${state.auth.accessToken}`;
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, globalReducer);

function isTokenExpired(state) {
  const { accessToken, ttl, tokenCreatedAt } = state.auth;
  const now = new Date();
  const timePassed = (now - new Date(tokenCreatedAt)) / 1000;

  return accessToken && timePassed > ttl;
}

const checkTokenExpirationMiddleware = store => next => action => {
  // this middleware will run pretty much on anything that accesses the store
  // and will check if token is EXPIRED. If it is, will automatically log user out.
  // It will also clean out redux state (memory) and clear redux persist (local storage)
  const state = store.getState();

  if (state.auth.accessToken) {
    next(action);
    if (isTokenExpired(state)) {
      store.dispatch({ type: 'USER_SIGNED_OUT' });
      localStorage.clear();
    }
  } else {
    next(action);
  }
};


export default function configureStore(initialState = {}) {
  // Create the store with two middlewares
  // x. sagaMiddleware: Makes redux-sagas work
  // x. checks token expiry on each action (so on refreshing browser, or re-opening browser or really any redux action)
  const middlewares = [
    sagaMiddleware,
    checkTokenExpirationMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false
        })
      : compose;

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers),
  );

  // Extensions
  
  const persistor = persistStore(store, {}, () => {

    // on redux persist rehydrate, if token is still valid
    // re-add it to axios headers
    // good example of when rehydrate occurs is on browser refresh (F5)
    const state = store.getState();

    if(state.auth.accessToken){
      // On rehydrating persistence (or basically refresh the page), we set the axios headers with the token
      // This is usually too late though, the app will have rendered by this time.
      // Instead it's usually handled in above globalReducer.
      axios.defaults.headers.common["Authorization"] = `Bearer ${state.auth.accessToken}`;
    }
  });

  store.runSaga = sagaMiddleware.run(rootSaga);

  return { persistor, store };
}