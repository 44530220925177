export const list_of_columns = [
  {
    Header: "NO.",
     width: 20,
     disableSortBy: true
  },
  {
    Header: "FUND  TAG",
    accessor: "fundTag",
    width: 185
  },
  {
    Header: "MODIFIED BY",
    accessor: "created_by",
    width: 130
  },
  {
    Header: "CREATED DATE",
    accessor: "created_at",
    width: 110
  },
  {
    Header: "MODIFIED DATE",
    accessor: "updated_date",
    width: 120
  }

];
