import { call, put, all, select, takeLatest } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  FundManagerConvertForBE,
  FundTypeConvertForBE
} from "../data_converter";
import {
  requestFundDetails,
  receiveFundDetails,
  failFundDetails,
  requestFundCreate,
  receiveFundCreate,
  failFundCreate,
  requestFundCodeExists,
  receiveFundCodeExists,
  failFundCodeExists,
  requestFundEdit,
  receiveFundEdit,
  failFundEdit
} from "./actions";
import { DateTime } from "luxon";

function* fetchFundDetailsWorker() {
  try {
    const { detailsFromList } = yield select(state => state.fundDetailsReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    //Get Fund Detail
    const requestTH = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/fund/${detailsFromList?.fund_no}`,
      {
        method: "GET",  
        headers: {
          "content-language": "th",
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    const responseTH = yield requestTH.json();

    const requestEN = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/fund/${detailsFromList?.fund_no}`,
      {
        method: "GET",  
        headers: {
          "content-language": "en",
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    const responseEN = yield requestEN.json();
    console.log("responseEN:",responseEN);
    console.log("responseTH:",responseTH);
    if (
      responseEN &&
      responseEN.data &&
      responseTH &&
      responseTH.data 
    ) {
      yield put(
        receiveFundDetails({
          en: responseEN.data[0],
          th: responseTH.data[0]
        })
      );
    } else {
      
      yield put(failFundDetails());
    }
  } catch (error) {
    console.error("error:",error);
    yield put(failFundDetails());
  }
}

function* createFundWorker({ payload }) {
  try {
    const { formValues, active, history } = payload;
    

    // This handles CREATE fund

    // need to convert the following values before adding to body
    // - dates (should be in YYYY-MM-DD format for BE to accept)
    // convert timestamp to date then format date
    // new Date(1585612800000).toISOString() wil lgive "2020-03-31T00:00:00.000Z"
    // - fund manager e.g  "Add Fund - Test Manager 1\nAdd Fund - Test Manager 2"
    // - fund types e.g "Mixed Fund, Fund of Funds, RMF"

    let body = {
      fund_EN: {
        fund_code: formValues["funddetails-fundcode"],
        website: formValues["funddetails-website"],
        fund_name: formValues["funddetailsextra-english-fundname"],
        fund_category: formValues["funddetailsextra-english-fundcategory"],
        fund_article_initial: formValues["funddetails-articleinitial"],
        launch_date: formValues["funddetails-launchdate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-launchdate"])).toFormat('yyyy-MM-dd')
          : null,
        fund_currency: formValues["funddetailsextra-english-fundcurrency"],
        closed_date: formValues["funddetails-closeddate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-closeddate"])).toFormat('yyyy-MM-dd')
          : null,
        fund_photo_url: formValues["funddetails-fundphoto"],
        maturity_date: formValues["funddetails-maturitydates"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-maturitydates"])).toFormat('yyyy-MM-dd')
          : null,
        risk_level: formValues["funddetails-risklevel"],
        prospectus_url: formValues["funddetailsextra-english-prospectusurl"],
        fund_fact_sheet_url:
          formValues["funddetailsextra-english-factsheeturl"],
        product_highlight_sheet_url:
          formValues["funddetailsextra-english-highlightsheeturl"],
        fund_no: formValues["funddetails-fundid"],
        fund_manager: FundManagerConvertForBE(
          formValues["fundmanager-english"]
        ),
        fund_type: FundTypeConvertForBE(formValues["fundtype-english"]),
        fund_size: formValues["fundsize-english"],
        dividend_policy: formValues["dividendpolicy-english"],
        dividend_policy_status: formValues["dividendpolicy-option"],
        benchmark: formValues["benchmark-english"],
        hedging_policy: formValues["hedgingpolicy-english"],
        subscription_period: formValues["periodipo-english"],
        subscription_period_after_ipo: formValues["periodipoafter-english"],
        redemption_period: formValues["redemptionperiod-english"],
        inception_date: formValues["funddetails-inceptiondate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-inceptiondate"])).toFormat('yyyy-MM-dd')
          : null,
        minimum_subscription: formValues["minsubscription-english"],
        next_subscription: formValues["nextsubscription-english"],
        settlement: formValues["settlement-english"],
        dividend_payment_history: formValues["dividendpaymenthistory-english"],
        investment_policy: formValues["investmentpolicy-english"],
        minimum_redemption: formValues["minsubscription-english"],
        front_end_fee_and_switching_in_fee_prospectus:
          formValues["frontendprospectus-english"],
        front_end_fee_and_switching_in_fee_actual:
          formValues["frontendactual-english"],
        back_end_fee_and_switching_out_fee_prospectus:
          formValues["backendprospectus-english"],
        back_end_fee_and_switching_out_fee_actual:
          formValues["backendactual-english"],
        brokerage_fee_prospectus: formValues["brokerageprospectus-english"],
        brokerage_fee_actual: formValues["brokerageactual-english"],
        management_fee_prospectus: formValues["managementprospectus-english"],
        management_fee_actual: formValues["managementactual-english"],
        trustee_fee_prospectus: formValues["trusteeprospectus-english"],
        trustee_fee_actual: formValues["trusteeactual-english"],
        registration_fee_prospectus:
          formValues["registrationprospectus-english"],
        registration_fee_actual: formValues["registrationactual-english"]
      },
      fund_TH: {
        fund_code: formValues["funddetails-fundcode"],
        website: formValues["funddetails-website"],
        fund_name: formValues["funddetailsextra-thai-fundname"],
        fund_category: formValues["funddetailsextra-thai-fundcategory"],
        fund_article_initial: formValues["funddetails-articleinitial"],
        launch_date: formValues["funddetails-launchdate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-launchdate"])).toFormat('yyyy-MM-dd')
          : null,
        fund_currency: formValues["funddetailsextra-thai-fundcurrency"],
        closed_date: formValues["funddetails-closeddate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-closeddate"])).toFormat('yyyy-MM-dd')
          : null,
        fund_photo_url: formValues["funddetails-fundphoto"],
        maturity_date: formValues["funddetails-maturitydates"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-maturitydates"])).toFormat('yyyy-MM-dd')
          : null,
        risk_level: formValues["funddetails-risklevel"],
        prospectus_url: formValues["funddetailsextra-thai-prospectusurl"],
        fund_fact_sheet_url: formValues["funddetailsextra-thai-factsheeturl"],
        product_highlight_sheet_url:
          formValues["funddetailsextra-thai-highlightsheeturl"],
        fund_no: formValues["funddetails-fundid"],
        fund_manager: FundManagerConvertForBE(formValues["fundmanager-thai"]),
        fund_type: FundTypeConvertForBE(formValues["fundtype-thai"]),
        fund_size: formValues["fundsize-thai"],
        dividend_policy: formValues["dividendpolicy-thai"],
        dividend_policy_status: formValues["dividendpolicy-option"],
        benchmark: formValues["benchmark-thai"],
        hedging_policy: formValues["hedgingpolicy-thai"],
        subscription_period: formValues["periodipo-thai"],
        subscription_period_after_ipo: formValues["periodipoafter-thai"],
        redemption_period: formValues["redemptionperiod-thai"],
        inception_date: formValues["funddetails-inceptiondate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-inceptiondate"])).toFormat('yyyy-MM-dd')
          : null,
        minimum_subscription: formValues["minsubscription-thai"],
        next_subscription: formValues["nextsubscription-thai"],
        settlement: formValues["settlement-thai"],
        dividend_payment_history: formValues["dividendpaymenthistory-thai"],
        investment_policy: formValues["investmentpolicy-thai"],
        minimum_redemption: formValues["minsubscription-thai"],
        front_end_fee_and_switching_in_fee_prospectus:
          formValues["frontendprospectus-thai"],
        front_end_fee_and_switching_in_fee_actual:
          formValues["frontendactual-thai"],
        back_end_fee_and_switching_out_fee_prospectus:
          formValues["backendprospectus-thai"],
        back_end_fee_and_switching_out_fee_actual:
          formValues["backendactual-thai"],
        brokerage_fee_prospectus: formValues["brokerageprospectus-thai"],
        brokerage_fee_actual: formValues["brokerageactual-thai"],
        management_fee_prospectus: formValues["managementprospectus-thai"],
        management_fee_actual: formValues["managementactual-thai"],
        trustee_fee_prospectus: formValues["trusteeprospectus-thai"],
        trustee_fee_actual: formValues["trusteeactual-thai"],
        registration_fee_prospectus: formValues["registrationprospectus-thai"],
        registration_fee_actual: formValues["registrationactual-thai"]
      },
      status: {
        fund_no: formValues["funddetails-fundid"],
        active: active,
        last_active_date: null,
        can_buy: formValues["can_buy"],
        can_topup: formValues["can_topup"],
        can_switch: formValues["can_switch"],
        can_redeem: formValues["can_redeem"],
        ipo_status:
          formValues["funddetails-ipostartdate"] &&
          formValues["funddetails-ipoenddate"]
            ? true
            : false,
        ipo_started_date: formValues["funddetails-ipostartdate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-ipostartdate"])).toFormat('yyyy-MM-dd')
          : null,
        ipo_ended_date: formValues["funddetails-ipoenddate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-ipoenddate"])).toFormat('yyyy-MM-dd')
          : null
      },
      dividend: {
        fund_no: formValues["funddetails-fundid"],
        year: formValues["dividend-year"],
        closing_date: formValues["dividend-closingdate"] 
          ? DateTime.fromJSDate(new Date(formValues["dividend-closingdate"])).toFormat('yyyy-MM-dd')
          : null,
        per_unit: formValues["dividend-rate"]
      },
      benchmark: {
        country_code: formValues["benchmark-countrycode"],
        fund_code: formValues["funddetails-fundcode"],
        benchmark_code: formValues["benchmark-benchmarkcode"],
        benchmark_name: formValues["benchmark-benchmarkname"],
        fund_no: formValues["funddetails-fundid"],
        benchmarkytd: formValues["benchmark-benchmarkytd"],
        benchmark03m: formValues["benchmark-03months"],
        benchmark06m: formValues["benchmark-06months"],
        benchmark12m: formValues["benchmark-12months"],
        benchmark03y: formValues["benchmark-03years"],
        benchmark05y: formValues["benchmark-05years"],
        benchmark10y: formValues["benchmark-10years"],
        benchmarksin: formValues["benchmark-sinceinception"],
        asof: formValues["benchmark-asofdate"] 
          ? DateTime.fromJSDate(new Date(formValues["benchmark-asofdate"])).toFormat('yyyy-MM-dd')
          : null
      },
      performance: {
        country_code: formValues["performance-countrycode"],
        fund_code: formValues["funddetails-fundcode"],
        fund_no: formValues["funddetails-fundid"],
        returnytd: formValues["performance-returnytd"],
        return03m: formValues["performance-return03months"],
        return06m: formValues["performance-return06months"],
        return12m: formValues["performance-return12months"],
        return03y: formValues["performance-return03years"],
        return05y: formValues["performance-return05years"],
        return10y: formValues["performance-return10years"],
        returnsin: formValues["performance-returnsinceinception"],
        navperunit: formValues["performance-navperunit"],
        totalnav_mb: formValues["performance-totalnav"]
      }
    };

    
    console.log("Create Fund:",body);
    console.log("Create Fund ID:",formValues["funddetails-fundid"]);
    // Create the fund!
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/fund/${
        formValues["funddetails-fundid"]
      }`,
      body,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    console.log("Create response:",response);
    

    if (response && response.data) {
      yield put(receiveFundCreate());
      history.goBack();
    } else {
      
      let errorMessage =
        response && response.response && response.response.error
          ? response.response.error.message.message
          : "Failed to push up fund details. Something went wrong when sending the data.";
      yield put(failFundCreate(errorMessage));
    }
  } catch (error) {
    
    let errorMessage =
      error && error.response && error.response.error
        ? error.response.error.message.message
        : "Failed to push up fund details. Something went wrong when sending the data.";

    const { history, fileFromLocal } = payload;

    if (fileFromLocal && errorMessage === "No file uploaded") {
      // TEMPORARY DUE TO BACKEND NOT WORKING, treat image upload fail as success
      yield put(receiveFundCreate());
      history.goBack();
    } else {
      yield put(failFundCreate(errorMessage));
    }
  }
}

function* updateFundWorker({ payload }) {
  try {
    // This handles EDIT fund
    const { formValues, active, history } = payload;
    console.log("updateFundWorker:",payload);
    // UPLOAD ENGLISH PORTION
    let bodyEnglish = {
      fund: {
        fund_code: formValues["funddetails-fundcode"],
        website: formValues["funddetails-website"],
        fund_name: formValues["funddetailsextra-english-fundname"],
        fund_category: formValues["funddetailsextra-english-fundcategory"],
        fund_article_initial: formValues["funddetails-articleinitial"],
        fund_photo_url: formValues["funddetails-fundphoto"],
        launch_date: formValues["funddetails-launchdate"] 
        ? DateTime.fromJSDate(new Date(formValues["funddetails-launchdate"])).toFormat('yyyy-MM-dd')
        : null,
        fund_currency: formValues["funddetailsextra-english-fundcurrency"],
        closed_date: formValues["funddetails-closeddate"] 
        ? DateTime.fromJSDate(new Date(formValues["funddetails-closeddate"])).toFormat('yyyy-MM-dd')
        : null,
        maturity_date: formValues["funddetails-maturitydates"] 
        ? DateTime.fromJSDate(new Date(formValues["funddetails-maturitydates"])).toFormat('yyyy-MM-dd')
        : null,
        risk_level: formValues["funddetails-risklevel"],
        prospectus_url: formValues["funddetailsextra-english-prospectusurl"],
        fund_fact_sheet_url:
          formValues["funddetailsextra-english-factsheeturl"],
        product_highlight_sheet_url:
          formValues["funddetailsextra-english-highlightsheeturl"],
        fund_manager: FundManagerConvertForBE(
          formValues["fundmanager-english"]
        ),
        fund_type: FundTypeConvertForBE(formValues["fundtype-english"]),
        fund_size: formValues["fundsize-english"],
        dividend_policy: formValues["dividendpolicy-english"],
        dividend_policy_status: formValues["dividendpolicy-option"],
        benchmark: formValues["benchmark-english"],
        hedging_policy: formValues["hedgingpolicy-english"],
        subscription_period: formValues["periodipo-english"],
        subscription_period_after_ipo: formValues["periodipoafter-english"],
        redemption_period: formValues["redemptionperiod-english"],
        inception_date: formValues["funddetails-inceptiondate"] 
        ? DateTime.fromJSDate(new Date(formValues["funddetails-inceptiondate"])).toFormat('yyyy-MM-dd')
        : null,
        minimum_subscription: formValues["minsubscription-english"],
        next_subscription: formValues["nextsubscription-english"],
        settlement: formValues["settlement-english"],
        dividend_payment_history: formValues["dividendpaymenthistory-english"],
        investment_policy: formValues["investmentpolicy-english"],
        minimum_redemption: formValues["minsubscription-english"],
        front_end_fee_and_switching_in_fee_prospectus:
          formValues["frontendprospectus-english"],
        front_end_fee_and_switching_in_fee_actual:
          formValues["frontendactual-english"],
        back_end_fee_and_switching_out_fee_prospectus:
          formValues["backendprospectus-english"],
        back_end_fee_and_switching_out_fee_actual:
          formValues["backendactual-english"],
        brokerage_fee_prospectus: formValues["brokerageprospectus-english"],
        brokerage_fee_actual: formValues["brokerageactual-english"],
        management_fee_prospectus: formValues["managementprospectus-english"],
        management_fee_actual: formValues["managementactual-english"],
        trustee_fee_prospectus: formValues["trusteeprospectus-english"],
        trustee_fee_actual: formValues["trusteeactual-english"],
        registration_fee_prospectus: formValues["registrationprospectus-english"],
        registration_fee_actual: formValues["registrationactual-english"]
      },
      status: {
        active: active,
        last_active_date: null,
        can_buy: formValues["can_buy"],
        can_topup: formValues["can_topup"],
        can_switch: formValues["can_switch"],
        can_redeem: formValues["can_redeem"],
        recommend: formValues["recommend"],
        ipo_status:
          formValues["funddetails-ipostartdate"] &&
          formValues["funddetails-ipoenddate"]
            ? true
            : false,
        ipo_started_date: formValues["funddetails-ipostartdate"] 
        ? DateTime.fromJSDate(new Date(formValues["funddetails-ipostartdate"])).toFormat('yyyy-MM-dd')
        : null,
        ipo_ended_date: formValues["funddetails-ipoenddate"] 
        ? DateTime.fromJSDate(new Date(formValues["funddetails-ipoenddate"])).toFormat('yyyy-MM-dd')
        : null
      },
      dividend: {
        year: formValues["dividend-year"],
        closing_date: formValues["dividend-closingdate"] 
        ? DateTime.fromJSDate(new Date(formValues["dividend-closingdate"])).toFormat('yyyy-MM-dd')
        : null,
        per_unit: formValues["dividend-rate"]
      },
      benchmark: {
        country_code: formValues["benchmark-countrycode"],
        fund_code: formValues["funddetails-fundcode"],
        benchmark_code: formValues["benchmark-benchmarkcode"],
        benchmark_name: formValues["benchmark-benchmarkname"],
        benchmarkytd: formValues["benchmark-benchmarkytd"],
        benchmark03m: formValues["benchmark-03months"],
        benchmark06m: formValues["benchmark-06months"],
        benchmark12m: formValues["benchmark-12months"],
        benchmark03y: formValues["benchmark-03years"],
        benchmark05y: formValues["benchmark-05years"],
        benchmark10y: formValues["benchmark-10years"],
        benchmarksin: formValues["benchmark-sinceinception"],
        asof: formValues["benchmark-asofdate"] 
        ? DateTime.fromJSDate(new Date(formValues["benchmark-asofdate"])).toFormat('yyyy-MM-dd')
        : null
      },
      performance: {
        country_code: formValues["performance-countrycode"],
        fund_code: formValues["funddetails-fundcode"],
        returnytd: formValues["performance-returnytd"],
        return03m: formValues["performance-return03months"],
        return06m: formValues["performance-return06months"],
        return12m: formValues["performance-return12months"],
        return03y: formValues["performance-return03years"],
        return05y: formValues["performance-return05years"],
        return10y: formValues["performance-return10years"],
        returnsin: formValues["performance-returnsinceinception"],
        navperunit: formValues["performance-navperunit"],
        totalnav_mb: formValues["performance-totalnav"]
      }
    };

    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);

    console.log("bodyEnglish:",bodyEnglish);
    const responseEN = yield call(
      api.put,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/fund/${formValues["funddetails-fundid"]}`,
        bodyEnglish, 
       {
        headers: {
          "content-Language":'en',
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    // UPLOAD THAI PORTION
    let bodyThai = {
      fund: {
        fund_code: formValues["funddetails-fundcode"],
        website: formValues["funddetails-website"],
        fund_name: formValues["funddetailsextra-thai-fundname"],
        fund_category: formValues["funddetailsextra-thai-fundcategory"],
        fund_article_initial: formValues["funddetails-articleinitial"],
        fund_photo_url: formValues["funddetails-fundphoto"],
        launch_date: formValues["funddetails-launchdate"] 
        ? DateTime.fromJSDate(new Date(formValues["funddetails-launchdate"])).toFormat('yyyy-MM-dd')
        : null,
        fund_currency: formValues["funddetailsextra-thai-fundcurrency"],
        closed_date: formValues["funddetails-closeddate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-closeddate"])).toFormat('yyyy-MM-dd')
          : null,
        maturity_date: formValues["funddetails-maturitydates"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-maturitydates"])).toFormat('yyyy-MM-dd')
          : null,
        risk_level: formValues["funddetails-risklevel"],
        prospectus_url: formValues["funddetailsextra-thai-prospectusurl"],
        fund_fact_sheet_url: formValues["funddetailsextra-thai-factsheeturl"],
        product_highlight_sheet_url:
          formValues["funddetailsextra-thai-highlightsheeturl"],
        fund_manager: FundManagerConvertForBE(formValues["fundmanager-thai"]),
        fund_type: FundTypeConvertForBE(formValues["fundtype-thai"]),
        fund_size: formValues["fundsize-thai"],
        dividend_policy: formValues["dividendpolicy-thai"],
        dividend_policy_status: formValues["dividendpolicy-option"],
        benchmark: formValues["benchmark-thai"],
        hedging_policy: formValues["hedgingpolicy-thai"],
        subscription_period: formValues["periodipo-thai"],
        subscription_period_after_ipo: formValues["periodipoafter-thai"],
        redemption_period: formValues["redemptionperiod-thai"],
        inception_date: formValues["funddetails-inceptiondate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-inceptiondate"])).toFormat('yyyy-MM-dd')
          : null,
        minimum_subscription: formValues["minsubscription-thai"],
        next_subscription: formValues["nextsubscription-thai"],
        settlement: formValues["settlement-thai"],
        dividend_payment_history: formValues["dividendpaymenthistory-thai"],
        investment_policy: formValues["investmentpolicy-thai"],
        minimum_redemption: formValues["minsubscription-thai"],
        front_end_fee_and_switching_in_fee_prospectus:
          formValues["frontendprospectus-thai"],
        front_end_fee_and_switching_in_fee_actual:
          formValues["frontendactual-thai"],
        back_end_fee_and_switching_out_fee_prospectus:
          formValues["backendprospectus-thai"],
        back_end_fee_and_switching_out_fee_actual:
          formValues["backendactual-thai"],
        brokerage_fee_prospectus: formValues["brokerageprospectus-thai"],
        brokerage_fee_actual: formValues["brokerageactual-thai"],
        management_fee_prospectus: formValues["managementprospectus-thai"],
        management_fee_actual: formValues["managementactual-thai"],
        trustee_fee_prospectus: formValues["trusteeprospectus-thai"],
        trustee_fee_actual: formValues["trusteeactual-thai"],
        registration_fee_prospectus: formValues["registrationprospectus-thai"],
        registration_fee_actual: formValues["registrationactual-thai"]
      },
      status: {
        active: active,
        last_active_date: null,
        can_buy: formValues["can_buy"],
        can_topup: formValues["can_topup"],
        can_switch: formValues["can_switch"],
        can_redeem: formValues["can_redeem"],
        recommend: formValues["recommend"],
        ipo_status:
          formValues["funddetails-ipostartdate"] &&
          formValues["funddetails-ipoenddate"]
            ? true
            : false,
        // hour + 7
        ipo_started_date: formValues["funddetails-ipostartdate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-ipostartdate"])).toFormat('yyyy-MM-dd 15:30:00')
          : null,
        ipo_ended_date: formValues["funddetails-ipoenddate"] 
          ? DateTime.fromJSDate(new Date(formValues["funddetails-ipoenddate"])).toFormat('yyyy-MM-dd 22:30:00')
          : null
      },
      dividend: {
        year: formValues["dividend-year"],
        closing_date: formValues["dividend-closingdate"] 
          ? DateTime.fromJSDate(new Date(formValues["dividend-closingdate"])).toFormat('yyyy-MM-dd')
          : null,
        per_unit: formValues["dividend-rate"]
      },
      benchmark: {
        country_code: formValues["benchmark-countrycode"],
        fund_code: formValues["funddetails-fundcode"],
        benchmark_code: formValues["benchmark-benchmarkcode"],
        benchmark_name: formValues["benchmark-benchmarkname"],
        benchmarkytd: formValues["benchmark-benchmarkytd"],
        benchmark03m: formValues["benchmark-03months"],
        benchmark06m: formValues["benchmark-06months"],
        benchmark12m: formValues["benchmark-12months"],
        benchmark03y: formValues["benchmark-03years"],
        benchmark05y: formValues["benchmark-05years"],
        benchmark10y: formValues["benchmark-10years"],
        benchmarksin: formValues["benchmark-sinceinception"],
        asof: formValues["benchmark-asofdate"] 
          ? DateTime.fromJSDate(new Date(formValues["benchmark-asofdate"])).toFormat('yyyy-MM-dd')
          : null
      },
      performance: {
        country_code: formValues["performance-countrycode"],
        fund_code: formValues["funddetails-fundcode"],
        returnytd: formValues["performance-returnytd"],
        return03m: formValues["performance-return03months"],
        return06m: formValues["performance-return06months"],
        return12m: formValues["performance-return12months"],
        return03y: formValues["performance-return03years"],
        return05y: formValues["performance-return05years"],
        return10y: formValues["performance-return10years"],
        returnsin: formValues["performance-returnsinceinception"],
        navperunit: formValues["performance-navperunit"],
        totalnav_mb: formValues["performance-totalnav"]
      }
    };

    console.log("bodyThai:",bodyThai);

    const responseTH = yield call(
      api.put,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/fund/${formValues["funddetails-fundid"]}`,
        bodyThai,
        {
          headers: {
            "content-Language":'th',
            "Content-Type": "application/json",
            userid: tGUID,
            id_token: "Bearer " + accessToken
          }
        }
      
    );

    if (responseEN && responseEN.data && responseTH && responseTH.data) {
      // Everything was successful, send user back to fund list screen
      console.log("Edit Fund completed")
      yield put(receiveFundEdit());
      history.goBack();
    } else {
      
      let errorMessage =
        responseEN && responseEN.response && responseEN.response.error
          ? responseEN.response.error.message.message
          : responseTH && responseTH.response && responseTH.response.error
          ? responseTH.response.error.message.message
          : "";
      yield put(failFundEdit('Edit Fund failed'));
    }
  } catch (error) {
    
    let errorMessage =
      error && error.response && error.response.error
        ? error.response.error.message.message
        : "Failed to update fund, something went wrong when sending up data.";

    const { history, fileFromLocal } = payload;

    if (fileFromLocal && errorMessage === "No file uploaded") {
      // TEMPORARY DUE TO BACKEND NOT WORKING, treat image upload fail as success
      yield put(receiveFundEdit());
      history.goBack();
    } else {
      yield put(failFundEdit(errorMessage));
    }
  }
}

function* fetchFundCodeExists({ payload }) {
  try {
    // This handles CREATE and EDIT of funds
    const { fund_code } = payload;

    let body = {
      FundCode: fund_code
    };

    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/fund/fundCode/exists`, 
      body,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );

    if (response && response.data && response.data.data && response.data.data) {
      yield put(receiveFundCodeExists(response.data.data));
    } else {
      
      yield put(failFundCodeExists());
    }
  } catch (error) {
    
    yield put(failFundCodeExists());
  }
}

export function* fundDetailsSaga() {
  yield all([
    takeLatest(requestFundDetails, fetchFundDetailsWorker),
    takeLatest(requestFundCreate, createFundWorker),
    takeLatest(requestFundCodeExists, fetchFundCodeExists),
    takeLatest(requestFundEdit, updateFundWorker)
  ]);
}
