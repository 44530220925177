import { CircularProgress } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { AlertModal, WarningModal } from "../../components/Modal";
import Images from "../../utils/styles/images";
import { MEMConsentVSList } from "../ConsentVSList/redux/actions";
import { list_of_columns } from "./list_of_columns";
import Pagination from "./Pagination";
import { REQConsentList, REQSyncConsent } from "./redux/actions";
import "./style.scss";
import Table from "./table";

function ConsentList(props) {
  const {
    REQConsentList,
    REQStrConsentList,
    ConsentList,
    totalConsent,
    MEMConsentVSList,
    // -----SYNC-----
    REQSyncConsent,
    SyncConsentList,
    REQStrSyncConsentList,
    history
  } = props;

  const columns = React.useMemo(() => list_of_columns, []);
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH, SETtSortH] = useState("id ASC");
  const [oShwModelSyncAlr, SETxModelSyncAlr] = useState(false);
  const [oShwAlrSyncConsent, SETxShwAlrSyncConsent] = useState(false);

  const CALLxConsent = () => {
    if(history.location.PageCurrent===undefined)
    {
        
        SETxPageIndex(0);
        REQConsentList({
          page: "1",
          tSortH
        });
    }
    else
    {
      let nPage = history.location.PageCurrent+1;
      if(history.location.pathname === "/Consent")
      {
      SETxPageIndex(history.location.PageCurrent);
      REQConsentList({
        page: nPage.toString(),
        tSortH:history.location.UISearch.tSortH
      });
      }
    }
    };

  const SETxActHeaderClick = cell => {
    
      if (cell === "CONSENT TYPE") 
      {
        if (tSortH === "consent_type ASC") 
        {
        SETtSortH("consent_type DESC");
        } 
        else if (tSortH === "consent_type DESC") 
        {
        SETtSortH("consent_type ASC");
        }
        else
        {
        SETtSortH("consent_type DESC");
        }
      }
      else if (cell === "CONSENT VERSION") 
      {
        if (tSortH === "consent_version ASC") 
        {
        SETtSortH("consent_version DESC");
        } 
        else if (tSortH === "consent_version DESC") 
        {
        SETtSortH("consent_version ASC");
        }
        else
        {
        SETtSortH("consent_version DESC");
        }
      }
    };
  
  const SETxActTableClick = (cellValue, rowData, columnName) => {
    if (columnName === "ACTION") {
      MEMConsentVSList({ RowDataAt: rowData ,PageCurrent: nPageIndex,
      UISearch:{
          tSortH:tSortH
        }
      });
      history.push(`/Version`);
    }
  };

  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQConsentList({
      page: nPageAt.toString(),
      tSortH
    });
  };

  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalConsent / 10);

    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };

  const SETxRecallREQ = () => {
    CALLxConsent();
  };

  const SETxFltHeader = cell => 
  {
    if (cell === "STATUS CODE") 
    {
      return (
        <div className="clients-header-sortby-converted-user">
          <div className="clients-header-sortby-text-container-converted-user">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-converted-user"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>

        </div>
      );
    }
  };

  const SHWxAlrSynConsent = ()=>
  {
    if(SyncConsentList.length===0)
    {
      console.log('SHWxAlrSynConsent > SyncConsentList.length===0');
    }
    else
    {
        return(
          <AlertModal
              show={oShwAlrSyncConsent}
              onHide={() => SETxShwAlrSyncConsent(false)}
              onHandleOK={() => {
                SETxShwAlrSyncConsent(false);
                SETxRecallREQ();
              
              }}
              type={"Disapprove"}
              status={"1"}
              title={SyncConsentList.status===true?"Sync consent success.":"Sync consent fail."}
            />
    
        );
    }
  };

  useEffect(() => {
    CALLxConsent();
  }, [tSortH]);
  
  return (
    <Fragment>
    <div className="button-create-container" style={{ marginBottom: 1 }}>
      <div style={{ height: 40 }} />
      {REQStrConsentList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrConsentList ? "Retrieving Consent list..." : null}
          </p>
        </div>
      ) : null}

          {REQStrSyncConsentList===true ? (
            <div className="regisDevice-list-progress">
              <CircularProgress />
              <p className="regisDevice-list-progress-text">
                {REQStrSyncConsentList ? "Retrieving Sync consent..." : null}
              </p>
            </div>
            ) :null
          }


          <WarningModal
            show={oShwModelSyncAlr}
            onHide={() => SETxModelSyncAlr(false)}
            onHandleGo={() => {
              SETxModelSyncAlr(false);
              REQSyncConsent({
                history
              });
              SETxShwAlrSyncConsent(true);
            }}
            onHandleCancel={() => {
              SETxModelSyncAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={ 
              `Are you sure you want to sync consents from salesforce ?`
            }
          />
        {/*--------------------Show alert Sync consent when error-----------------------*/}
        {
          REQStrSyncConsentList ? null : SHWxAlrSynConsent()
        }

      <button
        style={{ marginLeft: 20,marginBottom:20 }}
        className="conFd-synce-button"
        onClick={() =>
          SETxModelSyncAlr(true)
        }
      >
        SYNC
    </button>

    </div>
    {
     ConsentList && (
      <Table
        columns={columns}
        data={ConsentList}
        handleActionHeaderClick={SETxActHeaderClick}
        handleFltHeader={SETxFltHeader}
        handleActionClick={SETxActTableClick} 
        pageSelect={nPageIndex}
      />
    )}
    {REQStrConsentList ? null : <CALxPaging />}
  </Fragment>
  );
}

 
const withConnect = connect(
  state => ({
    ...state.consentListReducer
  }),
  {
    REQConsentList,
    MEMConsentVSList,
    REQSyncConsent
  }
);

export default compose(
  withConnect,
  withRouter
)(ConsentList);
