import React from "react";
import Images from "../../utils/styles/images";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import "./style.scss";

import * as Button from "../../components/Buttons";

const Header = ({ signOut, isLoading }) => {
  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  return (
    <div className="header">
      {isLoading && <Loader />}
      <div
        className="logoOutWrap"
        style={{ marginRight: "20px", fontSize: 16, color: "#0275b6" }}
      >
        {oNeedAuth?.Email}
      </div>
      <div className="logoOutWrap">
        <img src={Images.logOut.icon} alt="Icon" className="logout-icon" />
        <Button.Link
          contentButton="LOGOUT"
          onClick={signOut}
          className="logout-button"
        />
      </div>
    </div>
  );
};

export default withRouter(Header);
